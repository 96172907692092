<template>
    <div class="main">
        <div class="form-box">
            <div class="headers">
                <p>机构入驻</p>
            </div>
            <div class="top-head">
                <p>上传您的营业执照/机构简称/所属行业/企业LOGO</p>
            </div>
            <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
            >
                <el-form-item label="机构名称" prop="name">
                    <br/>
                    <el-input v-model="ruleForm.name" class="name-input" placeholder="请填写您公司的名字"></el-input>
                </el-form-item>
                <el-form-item label="机构简称" prop="ename">
                    <br/>
                    <el-input v-model="ruleForm.ename" class="name-input" placeholder="请填写您的机构简称"></el-input>
                </el-form-item>
                <el-form-item label="机构规模" prop="peopleNum">
                    <br/>
                    <el-select v-model="ruleForm.peopleNum" placeholder="请选择" class="people-input">
                        <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属行业" required>
                    <br/>
                    <el-cascader
                            v-model="ruleForm.hangyeSelect"
                            :options="industryList"
                            :props="{
              value: 'code',
              label: 'cateName',
               expandTrigger: 'hover',
                checkStrictly: true
            }"
                            clearable
                            size="big"
                            class="name-input"
                            @change="handleChange"
                    ></el-cascader>

                    <!-- <el-input
                      v-model="ruleForm.industry"
                      class="name-input"
                      placeholder="请填写您的所属行业"
                    ></el-input>-->
                </el-form-item>
                <el-form-item label="机构简介" required>
                    <br/>
                    <el-input
                            type="textarea"
                            placeholder="请输入内容"
                            v-model="ruleForm.textArea"
                            show-word-limit
                            class="jieshao-input"
                            resize="none"
                    ></el-input>
                </el-form-item>
                <el-form-item label="营业执照" class="picture-certificate" required>
                    <p>建议上传800*500的JPG丶PNG图片</p>
                    <br/>
                    <img
                            src="../CraftsmanCertification/uploads/CraftsmanCertification 5.png"
                            alt
                            class="picture-five"
                            v-if="!imageUrl"
                    />
                    <el-upload
                            action
                            class="avatar-uploader"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :limit="3"
                            :on-change="handleVideo"
                            :before-upload="beforeUploadVideo"
                            style="overflow: hidden;"
                    >
                        <img style="width:100%" v-if="imageUrl" :src="imageUrl" class="avatar"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="机构logo" required class="business-upload">
                    <p>建议上传500*500的JPG丶PNG图片</p>

                    <br/>
                    <img
                            src="../CraftsmanCertification/uploads/CraftsmanCertification 5.png"
                            alt
                            class="business-pic"
                            v-if="!imageUrl2"
                    />
                    <el-upload
                            class="avatar-uploader fl"
                            action
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :limit="3"
                            :on-change="handleVideo2"
                            :before-upload="beforeUploadVideo"
                            style="overflow: hidden;"
                    >
                        <el-image v-if="imageUrl2" fit="contain" :src="imageUrl2" class="avatar"></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
            >
                <span v-if="attribute===1">正在审核中请您等待</span>
                <span v-else-if="attribute===2">恭喜您通过审核</span>
                <div v-else>
                    <p>抱歉审核未通过</p>
                    <p>原因:</p>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button v-if="attribute===1" class="btn2"
                               @click="$router.go(-1)">确定</el-button>
    <el-button v-if="attribute===2" class="btn2" @click="dialogVisible = false">确 定</el-button>
                     <el-button v-if="attribute===3" class="btn2" @click="reset">确 定</el-button>
  </span>
            </el-dialog>
            <div class="submit">
                <!-- <el-button class="btn2" v-if="ruleForm.name != null" disabled>已入驻</el-button> -->
                <div>
                    <!--          <el-button class="btn1">上一步</el-button>-->
                    <el-button v-if="attribute !==3" class="btn2" @click="submitBtn">提交</el-button>
                    <el-button v-else class="btn2" @click="againSubmitBtn">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {institutionInfo} from "@/api/institutional/user";
import {jigou} from "@/api/userinformation";
import {ossDirect, ossDirectVideo, uuid} from "@/utils/ossDirect.js";
import {direct, sts} from "@/api/direct.js";
import {professionClassify} from "@/api/common";

export default {
    name: "Company",
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("规模不能为空"));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error("请输入数字值"));
                } else {
                    if (value < 18) {
                        callback(new Error("必须年满18岁"));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };
        return {
            frequency: 1,
            dialogVisible: false,
            imageUrl: "",
            imageUrl2: "",
            props: {multiple: false},
            options: [
                {
                    value: 1,
                    label: "东南",
                    children: [
                        {
                            value: 2,
                            label: "上海",
                            children: [
                                {value: 3, label: "普陀"},
                                {value: 4, label: "黄埔"},
                                {value: 5, label: "徐汇"},
                                {value: 4, label: "黄埔"},
                                {value: 4, label: "黄埔"},
                                {value: 4, label: "黄埔"},
                                {value: 4, label: "黄埔"},
                                {value: 4, label: "黄埔"}
                            ]
                        },
                        {
                            value: 7,
                            label: "江苏",
                            children: [
                                {value: 8, label: "南京"},
                                {value: 9, label: "苏州"},
                                {value: 10, label: "无锡"}
                            ]
                        },
                        {
                            value: 12,
                            label: "浙江",
                            children: [
                                {value: 13, label: "杭州"},
                                {value: 14, label: "宁波"},
                                {value: 15, label: "嘉兴"}
                            ]
                        }
                    ]
                },
                {
                    value: "西北",
                    label: "西北",
                    children: [
                        {
                            value: 18,
                            label: "陕西",
                            children: [
                                {value: 19, label: "西安"},
                                {value: 20, label: "延安"}
                            ]
                        },
                        {
                            value: "新疆维吾尔族自治区",
                            label: "新疆维吾尔族自治区",
                            children: [
                                {value: 22, label: "乌鲁木齐"},
                                {value: "克拉玛依", label: "克拉玛依"}
                            ]
                        }
                    ]
                },
                {
                    value: 22,
                    label: "shanghai",
                    children: [
                        {value: 22, label: "乌鲁木齐"},
                        {value: "克拉玛依", label: "克拉玛依"}
                    ]
                }
            ],
            options1: [
                {
                    value: 1,
                    label: "小于15人"
                },
                {
                    value: 2,
                    label: "15-50人"
                },
                {
                    value: 3,
                    label: "50-150人"
                },
                {
                    value: 4,
                    label: "150-500人"
                },
                {
                    value: 5,
                    label: "500-2000人"
                },
                {
                    value: 6,
                    label: "2000人以上"
                }
            ],
            attribute: '',
            value: "",
            ruleForm: {
                name: "",
                ename: "",
                textArea: "",
                peopleNum: "",
                date1: "",
                date2: "",
                delivery: false,
                type: [],
                resource: "",
                desc: "",
                hangyeSelect: ""
            },
            rules: {
                name: [{required: true, message: "请输入机构名称", trigger: "blur"}],
                ename: [{required: true, message: "请输入机构简称", trigger: "blur"}],
                peopleNum: [{required: true, validator: checkAge, trigger: "blur"}],
                date2: [
                    {
                        type: "date",
                        required: true,
                        message: "请选择时间",
                        trigger: "change"
                    }
                ]
            },
            industryList: [] // 行业列表
        };
    },
    mounted() {
        this.frequency = sessionStorage.getItem("frequency")
        this.frequency = this.frequency + 1
        sessionStorage.setItem("frequency", this.frequency)
        // console.log(this.frequency.length)
        // console.log(sessionStorage.getItem("frequency").length)
        professionClassify().then(res => {
            if (res.code == 200) {
                this.industryList = this.getTreeData(res.data);
            }
        });
        jigou().then(res => {
            console.log(res)
            // console.log(res.data)
            this.imageUrl = res.data.picUrl;
            this.imageUrl2 = res.data.logo;
            this.ruleForm = {
                name: res.data.institutionName,
                ename: res.data.institutionAbbreviation,
                textArea: res.data.briefIntroduction,
                peopleNum: res.data.level,
                hangyeSelect: res.data.industry
            };
            this.attribute = res.data.status
            console.log(this.frequency.length)
            if (this.attribute === 2 && this.frequency.length === 2) {
                this.dialogVisible = true
            }
            if(this.attribute === 1){
                this.dialogVisible = true
            }
            if(this.attribute === 3){
                this.dialogVisible = true
            }
        });
    },
    methods: {
        // 未通过信息重置
        reset() {
            this.ruleForm.name = ""
            this.ruleForm.ename = ""
            this.ruleForm.textArea = ""
            this.ruleForm.peopleNum = ""
            this.ruleForm.date1 = ""
            this.ruleForm.date2 = ""
            this.ruleForm.delivery = ""
            this.ruleForm.type = []
            this.ruleForm.resource = ""
            this.ruleForm.desc = ""
            this.ruleForm.hangyeSelect = ""
            this.dialogVisible = false
        },
        // 行业类型
        handleChange(val) {
            this.ruleForm.hangyeSelect = val[val.length - 1];
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            console.log(dialogImageUrl);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        handleVideo(file) {
            let beforeBool = true;
            if (beforeBool) {
                this.videoLoading = true;
                direct().then(res => {
                    // res = res.data;
                    if (res.code == 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb
                            });
                            return;
                        }
                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then(con => {
                            // con = con.data;
                            if (con.code == 200) {
                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.raw,
                                    suc => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        // console.log(
                                        //     "url",
                                        //     "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                        //     key
                                        // );
                                        this.imageUrl =
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;
                                    },
                                    err => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        handleVideo2(file) {
            let beforeBool = true;
            if (beforeBool) {
                this.videoLoading = true;
                direct().then(res => {
                    // res = res.data;
                    if (res.code == 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb
                            });
                            return;
                        }
                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then(con => {
                            // con = con.data;
                            if (con.code == 200) {
                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.raw,
                                    suc => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        // console.log(
                                        //     "url",
                                        //     "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                        //     key
                                        // );
                                        this.imageUrl2 =
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;
                                    },
                                    err => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        beforeUploadVideo() {
            return true;
        },

        submitBtn() {
            this.frequency = 1
            sessionStorage.setItem("frequency", this.frequency)
            console.log(sessionStorage.getItem("frequency"))
            const data = {
                institutionName: this.ruleForm.name, // 机构名称
                institutionAbbreviation: this.ruleForm.ename, // 机构简称
                level: this.ruleForm.peopleNum, // 机构规模
                industry: this.ruleForm.hangyeSelect, // 所属行业
                briefIntroduction: this.ruleForm.textArea, // 机构简介
                picUrl: this.imageUrl, // 营业执照
                logo: this.imageUrl2 // 机构logo
            };
            institutionInfo(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: res.message
                    });
                    this.$router.push({
                        path: "/submitcertification"
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    });
                }
            });
        },
        againSubmitBtn(){
            this.frequency = 1
            sessionStorage.setItem("frequency", this.frequency)
            // console.log(sessionStorage.getItem("frequency"))
            const data = {
                status:3,
                institutionName: this.ruleForm.name, // 机构名称
                institutionAbbreviation: this.ruleForm.ename, // 机构简称
                level: this.ruleForm.peopleNum, // 机构规模
                industry: this.ruleForm.hangyeSelect, // 所属行业
                briefIntroduction: this.ruleForm.textArea, // 机构简介
                picUrl: this.imageUrl, // 营业执照
                logo: this.imageUrl2 // 机构logo
            };
            institutionInfo(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: res.message
                    });
                    this.$router.push({
                        path: "/submitcertification"
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    });
                }
            });
        },
        getTreeData(data) {
            for (var i = 0; i < data.length > 0; i++) {
                if (data[i].children == null || data[i].children.length <= 0) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        }
    }
};
</script>

<style scoped>
.btn1 {
    width: 200px;
    height: 40px;
    border: 0;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eb5139;
    color: #eb5139;
    margin-left: 0;
    background-color: transparent;
}

.btn2 {
    width: 200px;
    height: 40px;
    background: #eb5139;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #ffffff;
    margin-right: 0;
    border: 0;
}

.btn2:nth-child(1):hover {
    border: 1px solid #eb5139;
}

.submit {
    position: relative;
    top: 55px;
    display: flex;
    width: 469px;
}

/* 营业执照start */
.business-upload {
    position: relative;
    margin-left: 0;
}

/* 营业执照一整块 */
.business-upload >>> .el-form-item__content {
    margin-left: 0px !important;
}

/* 建议上传的文字 */
.business-upload p {
    color: #ccc;
    font-size: 12px;
}

.picture-certificate p {
    color: #ccc;
    font-size: 12px;
}

/* 照片 */
.business-upload .business-pic {
    position: absolute;
    left: 130px;
    top: 65px;
    width: 90px;
    height: 77px;
}

/* 营业执照加号图标 */
.business-upload >>> .avatar-uploader-icon {
    position: absolute;
    left: 15%;
    top: 50%;
    font-size: 24px;
    color: #fafafb;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 50%;
    z-index: 1;
}

/* 营业执照end */

/* 机构logo start */
/* 机构logo一整块 */
.picture-certificate >>> .el-form-item__content {
    margin-left: 0px !important;
}

/* 机构logo加号图标 */
.picture-certificate >>> .avatar-uploader-icon {
    position: absolute;
    left: 15%;
    top: 50%;
    font-size: 24px;
    color: #fafafb;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 50%;
    z-index: 1;
}

/* 需要定位的加号图片 */
.picture-certificate .picture-five {
    position: absolute;
    left: 23px;
    top: 65px;
    width: 300px;
    height: 151px;
}

/* 机构logo end */

/* element提供的上传照片样式 start */
/* 上传照片的宽高 */
.avatar-uploader {
    margin-left: 22px;
    width: 150px;
    height: 150px;
    background: #fafafb;
    border: 1px solid #efefee;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar {
    width: 100%;
    height: 100%;
    display: block;
}

/* element提供的上传照片样式 end */

/* 机构简介文本框 */
.jieshao-input {
    margin-left: -78px;
}

.jieshao-input >>> .el-textarea__inner {
    width: 697px;
    height: 150px;
    outline: none;
    font-size: 14px;
    vertical-align: top;
    text-align: left;
    background-color: #fafafb;
    border: 1px solid #efefee;
    border-radius: 4px;
}

/* 姓名、职位的el-input框 */
.name-input >>> .el-input__inner,
.people-input >>> .el-input__inner {
    width: 300px;
    height: 40px;
    outline: none;
    font-size: 14px;
    background-color: #fafafb;
    border: 1px solid #efefee;
    border-radius: 4px;
    margin-left: -78px;
}

.people-input >>> .el-input--suffix {
    width: 220px;
}

.main {
    background-size: 100%;
    height: 1555px;
}

.top-head {
    display: flex;
}

.top-head > p {
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 16px;
}

.headers {
    width: 96px;
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
}

.headers > p {
    padding-top: 60px;
}

.form-box {
    background-color: #ffffff;
    position: relative;
    top: 30px;
    width: 80%;
}

.form-box >>> .el-form {
    margin: 0 35px;
    margin-top: 24px;
}

.form-box >>> .el-form-item__label {
    padding: 0px;
    margin-bottom: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}
</style>
