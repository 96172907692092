<template>
  <p class="agreement">
    注册登录即表示已阅读并同意
    <a href="javascript:;" @click="userservices">《用户服务协议》</a>
    和
    <a href="javascript:;" @click="userprivacy">《用户隐私协议》</a>
  </p>
</template>

<script>
export default {
  methods: {
    userservices() {
      this.$router.push({
        path: "/userservices",
      });
    },
    userprivacy() {
      this.$router.push({
        path: "/userprivacy",
      });
    },
  },
};
</script>

<style class='less' scoped>
.agreement {
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
.agreement > a {
  color: #ed7d45;
}
</style>