<template>
    <div>
        <div class="heads-total">
            <span class="detail-head">视频管理</span>

            <button class="new-btn" @click="goVideoUp">上传视频</button>
        </div>
        <hr style="background-color:#E5E5E5; height:1px; border:none"/>
        <div class="file-box">
            <button class="head-btn">文件上传</button>
            <span class="head-tabs">(推荐采用mp4丶flv格式，可有效缩短审核转码耗时)</span>
            <!-- <input class="head-search" type="text" placeholder="按课程名搜索" /> -->
        </div>

        <div>
            <!--        <el-upload-->
            <!--               -->
            <!--                class="upload-demo"-->
            <!--                drag-->
            <!--                multiple>-->
            <!--            <i class="el-icon-upload"></i>-->
            <!--            <div class="el-upload__text"><em>点击上传</em></div>-->
            <!--            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
            <!--        </el-upload>-->
            <el-empty v-if="vedioList.records && !vedioList.records.length" description="暂无数据"></el-empty>
            <div class="video-box" v-for="item in vedioList.records" :key="item.id">
                <div class="cards-box">
                    <div class="img-box">
                        <img id="img1" src="../../../assets/images/Group162.png" alt/>
                    </div>
                    <div class="text-box">
                        <p class="text-top">{{ item.hourHeadline }}</p>
                        <p class="text-bottom">上传成功</p>
                    </div>
                    <div class="crud-box">
                        <button @click="goVideoUp">
                            <i class="iconfont icon-shangchuan"></i>
                            上传
                        </button>
                        <!--            <button>-->
                        <!--              <i class="iconfont icon-fabu"></i>-->
                        <!--              发布-->
                        <!--            </button>-->
                        <button class="delete-btn" @click="deleteVedio(item.id)">
                            <i class="iconfont icon-shanchu"></i>
                            删除
                        </button>
                        <button class="sel-box">
                            <!-- <el-select v-model="item.sequence" style="width:55px" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>-->
                            <p>第{{ item.sequence }}集</p>
                        </button>
                        <span class="icon-tab">{{ value }}</span>
                    </div>
                </div>
                <hr class="line-bottom"/>
            </div>
        </div>
        <div class="Pga" v-if="vedioList.total>10">
            <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :page-size="10"
                    :current-page.sync="currentPage"
                    :total="vedioList.total"
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import {getUserVedioList, delVedio} from "@/api/user/videomanagement";

export default {
    data() {
        return {
            options: [
                {
                    value: "1",
                    label: "1"
                },
                {
                    value: "2",
                    label: "2"
                },
                {
                    value: "3",
                    label: "3"
                },
                {
                    value: "4",
                    label: "4"
                },
                {
                    value: "5",
                    label: "5"
                },
                {
                    value: "6",
                    label: "6"
                },
                {
                    value: "7",
                    label: "7"
                },
                {
                    value: "8",
                    label: "8"
                },
                {
                    value: "9",
                    label: "9"
                }
            ],
            value: "",
            vedioList: {},
            currentPage: 1
        };
    },
    methods: {
        goVideoUp() {
            this.$router.push("/user/videoup?id=" + this.$route.query.id);
            //   this.$router.push({path:"/user/videoup?id=" + this.$route.query.id,query:{id:this.$route.query.id}});
        },
        handleCurrentChange(val) {
            getUserVedioList(1, val, 10).then(res => {
                if (res.code == 200) {
                    this.vedioList = res.data;
                }
            });
        },

        deleteVedio(id) {
            this.$confirm("内容即将删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                delVedio(id).then(res => {
                    if (res.code == 200) {
                        this.getVedio();
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    }
                });
            });
        },
        getVedio() {
            getUserVedioList(1).then(res => {
                if (res.code == 200) {
                    this.vedioList = res.data;
                }
            });
        }
    },
    mounted() {
        getUserVedioList(this.$route.query.id).then(res => {
            if (res.code == 200) {
                this.vedioList = res.data;
            }
        });
    }
};
</script>

<style scoped>
.FirstUpBox {
    width: 500px;
    padding: 20px;
    background-color: white;
}

.firstUpload {
    width: 400px;
    height: 200px;
    border: 1px solid black;
}

.Pga {
    display: flex;
}

.heads-total {
    position: relative;
}

.video-box {
    margin-left: 1px;
    width: 100%;
}

.line-bottom {
    height: 2px;
    background-color: green;
    border: none;
    width: 96%;
    margin-left: 40px;
    margin-top: 0.5%;
}

.icon-tab {
    position: absolute;
    left: 9px;
    top: 14px;
    color: white;
}

>>> .el-input__inner {
    border: none;
}

.crud-box {
    display: flex;
    width: 27%;
    position: absolute;
    margin-left: 1px;
    right: 1px;
    top: 5px;
}

.crud-box > button {
    width: 28%;
    height: 40px;
    margin: auto;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    color: #676c71;
    font-size: calc(100vw * 14 / 1920);
}

.crud-box > button:hover {
    color: #47a7ff;
    border: 1px solid #47a7ff;
    cursor: pointer;
}

.delete-btn:hover {
    color: red !important;
    border: 1px solid red !important;
    cursor: pointer;
}

.sel-box {
    width: 23%;
    height: 40px;
    overflow: hidden;
}

.text-box {
    margin: 0;
    margin-right: 17%;
}

.text-bottom {
    font-size: calc(100vw * 12 / 1920);
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #6ecb69;
    margin-top: 1%;
    position: absolute;
    bottom: 1px;
}

.text-top {
    font-size: calc(100vw * 14 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
}

.head-tabs {
    width: 394px;
    height: 17px;
    font-size: calc(100vw * 12 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e5e5e5;
    line-height: 14px;
}

.detail-head {
    width: 64px;
    height: 22px;
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 19px;
}

.new-btn {
    width: 95px;
    height: 29px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    background-color: #eb5139;
    margin-bottom: 0.5%;
    position: absolute;
    right: 3px;
    top: -13px;
}

.head-btn {
    height: 20px;
    width: 56px;
    font-size: 14px;
    background-color: #f5f7fa;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
}

.head-search {
    border: 1px solid #e5e5e5;
    height: 25px;
    font-size: 14px;
    position: absolute;
    right: 3px;
    top: 0;
    margin-top: 0.2%;
}

.cards-box {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 55px;
    position: relative;
}

.file-box {
    position: relative;
}

#img1 {
    width: 30px;
}

.img-box {
    padding-top: 10px;

    margin: 0 8px;
}

>>> .el-select-dropdown__item {
    height: 45px !important;
    font-size: 20px !important;
    line-height: 45px;
}
</style>