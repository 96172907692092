<template>
    <div class="bigBox">
        <div>
            <div>
                <div>
                    搜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;索：
                </div>
                <div>
                    <el-input v-model="keyName" type="text" placeholder="请输入想要搜索的案例"></el-input>
                    <button @click="keySearch">搜索</button>
                </div>
            </div>
            <div>
                <div>事故行业：</div>
                <div><p v-for="item in proList" :key="item.code"
                        :style="{ backgroundColor: proValue === item.code ? '#EB5139' : '', color: proValue === item.code ? 'white' : '' }"
                        @click="getProValue(item.code)">
                    {{ item.cateName }}</p></div>
            </div>
            <div>
                <div>事故等级：</div>
                <div><p v-for="item in lvList" :key="item.id"
                        :style="{ backgroundColor: lvValue === item.id ? '#EB5139' : '', color: lvValue === item.id ? 'white' : '' }"
                        @click="getLvValue(item.id)">
                    {{ item.name }}</p></div>
            </div>
            <div>
                <div>事故类型：</div>
                <div v-if="unfold"><p v-for="item in typeList.slice(0,10)" :key="item.occurId"
                                      :style="{ backgroundColor: typeValue === item.occurId ? '#EB5139' : '', color: typeValue === item.occurId ? 'white' : '' }"
                                      @click="getTypeValue(item.occurId)">
                    {{ item.occurName }}</p>
                    <button @click="unfold=false">更多<i style="margin-left: 3px;font-weight: bold"
                                                         class="el-icon-arrow-down"></i></button>
                </div>
                <div v-if="!unfold"><p v-for="item in typeList" :key="item.occurId"
                                       :style="{ backgroundColor: typeValue === item.occurId ? '#EB5139' : '', color: typeValue === item.occurId ? 'white' : '' }"
                                       @click="getTypeValue(item.occurId)">
                    {{ item.occurName }}</p>
                    <button @click="unfold=true">收起<i style="margin-left: 3px;font-weight: bold"
                                                        class="el-icon-arrow-up"></i></button>
                </div>
            </div>
        </div>
        <div class="contentBigBox">
            <ul>
                <div>共匹配到 <span>{{ searchList.total }}</span> 个案例符合您的筛选条件</div>
                <li v-for="item in searchList.records" :key="item.id"
                >
                    <div @click="goDetail(item.id,item.isType)">
                        <img :src="item.indexUrl" alt="">
                        <img v-if="item.isType===1" src="@/assets/images/word.png"
                             alt="">
                        <img v-if="item.isType===0" src="@/assets/images/pdf3.png"
                             alt="">
                        <img v-if="item.isType===2" src="@/assets/images/jpg-1.png"
                             alt="">
                    </div>
                    <div>
                        <div v-html="item.accidentName" @click="goDetail(item.id,item.isType)"></div>
                        <div>事故直接原因：<span>{{ item.directCause }}</span></div>
                        <div>
                            <div><span>发生日期：</span><span>{{ timestampToTime(item.occurDate) }}</span></div>
                            <div><span>发生地点：</span><span>{{ item.economizeName }}&nbsp;{{ item.markentName }}&nbsp;{{
                                item.countyName
                                }}</span></div>
                            <div><span>所属行业：</span><span
                                    :style="{'color':proValue!=='0'?'#EB5139':''}">{{ item.industryName }}</span></div>
                            <div><span>事故类型：</span><span
                                    :style="{'color':typeValue!==0?'#EB5139':''}">{{ item.occurName }}</span></div>
                            <div><span>事故等级：</span><span :style="{'color':lvValue!==-1?'#EB5139':''}">{{
                                item.grade
                                }}</span></div>
                        </div>
                    </div>
                </li>
                <div v-if="searchList.total===0" style="display: block">
                    <el-empty description="暂无案例"></el-empty>
                </div>
                <div style="display: flex;justify-content: center">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            background
                            layout="prev, pager, next, jumper"
                            :page-size="5"
                            :total="searchList.total">
                    </el-pagination>
                </div>
            </ul>
            <div>

            </div>
        </div>
    </div>
</template>

<script>

import {getListPopular, occurCateList} from "@/api/case/caseList";
import {professionClassify} from "@/api/common";

export default {
    name: "InformationSearch",
    data() {
        return {
            keyName: '',//关键词
            proList: [],//事故行业
            proValue: "0",//行业
            typeList: [],//事故类型
            typeValue: 0,//事故
            lvList: [
                {
                    name: '全部',
                    id: -1
                },
                {
                    name: '一般事故',
                    id: 0
                },
                {
                    name: '较大事故',
                    id: 1
                },
                {
                    name: '重大事故',
                    id: 2
                },
                {
                    name: '特别重大事故',
                    id: 3
                }
            ],//事故等级
            lvValue: -1,
            unfold: true,
            search: {
                accidentName: '',
                current: 1,
                grade: '',
                id: '',
                industryCode: '',
                occurId: '',
                size: 5
            },//搜索参数
            searchList: []//搜索列表
        };
    },
    mounted() {
        console.log(this.$route)
        this.search.accidentName = this.keyName = this.$route.query.keyName
        //事故行业
        professionClassify().then(res => {
            if (res.code === 200) {
                res.data[res.data.length - 1].cateName = "其他综合"
                this.proList = [{
                    cateName: '全部',
                    code: "0"
                }, ...res.data]
            }
        })
        // 事故类型
        occurCateList().then(res => {
            if (res.code === 200) {
                this.typeList = [{
                    occurName: '全部',
                    occurId: 0
                }, ...res.data]
            }
        })
        this.getSearchList(this.search)
    },
    methods: {
        // 封装时间
        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d;
        },
        // 封装的
        getSearchList(data) {
            const requestData = {};
            // 遍历 search 对象的属性
            for (const key in data) {
                // 只有在属性值非空的情况下添加到请求参数中
                if (data[key] !== '') {
                    requestData[key] = data[key];
                }
            }
            getListPopular(requestData).then(res => {
                if (res.code === 200) {
                    // console.log(res.data)
                    res.data.records.forEach(item => {
                        const {grade} = item;
                        item.grade = grade === this.lvList[grade + 1].id ? this.lvList[grade + 1].name : grade;
                    });
                    if (this.keyName !== "") {
                        res.data.records.map(item => {
                            // 使用正则表达式创建一个全局匹配的正则，不区分大小写
                            const regex = new RegExp(this.keyName, "gi");
                            // 替换匹配到的关键字并加上高亮样式
                            item.accidentName = item.accidentName.replace(regex, match => `<span style="color: #EB5139">${match}</span>`);
                        });
                    }
                    // console.log(this.searchList)
                    this.searchList = res.data

                }
            })
        },
        getProValue(e) {
            this.proValue = e
            this.search.current = 1
            if (e === '0') {
                this.search.industryCode = ""
            } else {
                this.search.industryCode = e
            }
            this.getSearchList(this.search)
        },
        getLvValue(e) {
            this.lvValue = e
            this.search.current = 1
            if (e === -1) {
                this.search.grade = ""
            } else {
                this.search.grade = e
            }
            this.getSearchList(this.search)
        },
        getTypeValue(e) {
            this.typeValue = e
            this.search.current = 1
            if (e === 0) {
                this.search.occurId = ""
            } else {
                this.search.occurId = e
            }
            this.getSearchList(this.search)
        },
        keySearch() {
            if (this.keyName === "") {
                this.search.accidentName = ""
            } else {
                this.search.accidentName = this.keyName
            }
            this.getSearchList(this.search)
        },
        goDetail(e, t) {
            this.$router.push({path: "/informationdetail", query: {id: e, type: t}})
        },
        //     分页
        handleCurrentChange(val) {
            console.log(val)
            this.search.current = val
            this.getSearchList(this.search)
        }
    },
};
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

.bigBox {

  > div:nth-child(1) {
    padding: 20px;
    width: 1250px;
    margin: 20px auto auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 5px 5px 5px #f1f0f0;

    > div {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 10px 0 10px 10px;

      > div:nth-child(1) {
        margin-top: 7px;
        width: 8%;
      }

      > div:nth-child(2) {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        > p {
          width: 90px;
          text-align: center;
          padding: 3px;
          margin-right: 5px;
          border-radius: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
          cursor: pointer;
        }

        > p:hover {
          background-color: #EB5139;
          color: white;
        }

        > button {
          width: 7%;
          background-color: white;
          color: green;
          cursor: pointer;
          text-align: center;
          padding: 3px 3px 3px 20px;
        }
      }
    }

    > div:nth-child(1) {
      > div:nth-child(1) {
        margin-top: 5px;
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        /deep/ .el-input--small {
          width: 200px;
        }

        /deep/ .el-input__inner {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border: 1px solid #EB5139;
          height: 33px;
        }


        > button {
          background-color: #EB5139;
          color: white;
          padding: 7px 15px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  > .contentBigBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1290px;
    margin: 20px auto auto;
    padding-bottom: 20px;


    > ul {
      width: 100%;
      background-color: white;
      padding: 20px;
      border-radius: 20px;
      height: auto;
      box-shadow: 5px 5px 5px #f1f0f0;

      > div:nth-child(1) {
        font-weight: 600;
        font-size: 16px;

        > span {
          color: #EB5139;
        }
      }

      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px dashed #E0E0E0;

        > div:nth-child(1) {
          width: 80px;
          height: 102px;
          border: 1px solid #E0E0E0;
          position: relative;
          cursor: pointer;

          > img:nth-child(1) {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }

          > img:nth-child(2) {
            width: 18px;
            height: 18px;
            position: absolute;
            right: -2px;
            bottom: 0;
          }
        }

        > div:nth-child(2) {
          flex: 1;
          padding: 10px 10px 10px 20px;

          > div:nth-child(1) {
            font-size: 16px;
            cursor: pointer;
            font-weight: bolder;
          }

          > div:nth-child(3) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            color: #999999;
          }

          > div:nth-child(2) {
            //color: #999999;
            height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-align: justify;
            margin: 10px 0 5px;
            line-height: 1.5;
          }
        }
      }

      > li:nth-last-child(2) {
        //border: 0;
        //padding-bottom: 0;
      }

      > li:nth-child(1) {
        padding-top: 0;
      }

      > div:nth-last-child(1) {
        padding-top: 40px;
      }
    }

    //> div {
    //  width: 20%;
    //  background-color: white;
    //  height: 20px;
    //}
  }
}
</style>
