<template>
    <div>
        <span class="detail-head">编辑信息</span>
        <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none"/>
        <span class="head-btn">
      <i style="color:#FF6767">*</i>视频封面上传
    </span>
        <span class="head-tabs">(格式jpeg丶png，文件大小<5MB，建议尺寸>1146*717,最低尺寸>960*600)</span>
        <br/>
        <div class="uoload-f">
            <el-upload
                    action
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :limit="5"
                    :on-change="handleVideo"
                    :before-upload="beforeUploadVideo"
            >
                <img style="width:100%;height: 122px" v-if="imageUrl" :src="imageUrl" class="avatar"/>
            </el-upload>
        </div>
        <span class="head-btn">
      <i style="color:#FF6767">*</i>视频文件上传
    </span>
        <span class="head-tabs">((推荐采用mp4丶flv丶avi丶mpg4格式，可有效缩短审核转码耗时))</span>
        <br/>
        <div @change="fileChange($event)" style="height:120px;position: relative">
            <div v-if="progressNum == 100">
                <video width="320" height="120" controls id="myVideo">
                    <source :src="videoSrc" type="video/mp4"/>
                    <source :src="videoSrc" type="video/ogg"/>
                    您的浏览器不支持Video标签。
                </video>
            </div>
            <div class="uoload-d" style="position:relative" v-else>
                <el-upload
                        action
                        :show-file-list="false"
                        list-type="picture-card"
                        :on-remove="handleRemove"
                        :on-change="fileChange"
                        :limit="1"
                ></el-upload>
                <el-progress
                        style="position:absolute;left:55px;top:0"
                        v-if="videoSrc && progressNum != 100"
                        type="circle"
                        :percentage="progressNum"
                ></el-progress>
            </div>
            <!--            <div v-if="upIng"-->
            <!--                 style="position: absolute;height: 130px;width: 240px;cursor: no-drop;background: #0e0d0d;top: 0"></div>-->
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <video width="100%" :src="dialogImageUrl" alt/>
        </el-dialog>
        <br/>
        <span class="head-btn">
      <i style="color:#FF6767">*</i>视频时长(分钟)
    </span>
        <br/>
        <!--        <el-col :span="14">-->
        <el-input disabled maxlength="41" show-word-limit v-model="timeinput" placeholder="请输入内容"
                  @input="handleInput"></el-input>
        <!--        </el-col>-->
        <br/>
<!--        <br/>-->
        <!--        <span class="head-btn">-->
        <!--      <i style="color:#FF6767">*</i>视频排序-->
        <!--    </span>-->
        <!--        <br/>-->
        <!--        <el-select v-model="value2" placeholder="请选择">-->
        <!--            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
        <!--        </el-select>-->
        <!--        <br/>-->
        <span class="head-btn">
      <i style="color:#FF6767">*</i>课时标题
    </span>
        <br/>
        <el-col :span="14">
            <el-input type="text" maxlength="41" show-word-limit v-model="className"
                      placeholder="请输入内容"></el-input>
        </el-col>
        <br/>
        <br/>
<!--        <span class="head-btn">-->
<!--      <i style="color:#FF6767">*</i>是否为试看视频-->
<!--    </span>-->
<!--        <div class="kan-v">-->
<!--            <el-radio v-model="radio" label="0">不可试看</el-radio>-->
<!--            <el-radio v-model="radio" label="1">可以试看</el-radio>-->
<!--        </div>-->
        <!--        <button style=" background-color: #47A7FF;" class="commit-btn" @click="vedioUpCancel">继续上传</button>-->
        <!--        <button style=" background-color: #eb5139;" class="commit-btn" @click="vedioUp(1)">发布</button>-->
        <div>
<!--            <button style=" background-color: #47A7FF;" class="commit-btn" @click="vedioUp(1)">继续上传</button>-->
<!--            <button style=" background-color: #eb5139;" class="commit-btn" @click="uploadOK">审核发布</button>-->
            <button style=" background-color: #47A7FF;" class="commit-btn" @click="vedioUpCancel">取消</button>
            <button style=" background-color: #eb5139;" class="commit-btn" @click="uploadOK(1)">审核</button>
        </div>
    </div>
</template>

<script>
import {direct, sts} from "@/api/direct.js";
import {ossDirectVideo, uuid} from "@/utils/ossDirect.js";
import axios from "axios";
import {userUploadVideo} from "@/api/user/video";
import {createUrl, vedioPlayer} from "@/api/ailiplayer";
import {listSaveCourseInfo} from "@/api/user/addclass";
import {getUserVedioList} from "@/api/user/videomanagement";
import {getIntegralCalculation} from "@/api/login/personal";

export default {
    data() {
        return {
            options: [
                {
                    value: 1,
                    label: "1"
                },
                {
                    value: 2,
                    label: "2"
                },
                {
                    value: 3,
                    label: "3"
                },
                {
                    value: 4,
                    label: "4"
                },
                {
                    value: 5,
                    label: "5"
                }
            ],
            value: "", //选择器值
            imageUrl: "", //封面图片地址,
            dialogImageUrl: "",
            dialogVisible: false,
            imageUrlList: [],
            timeinput: 0,
            radio: "0", //单选按钮状态
            className: "",
            value2: "",
            //ceshi
            timeout: "",
            partSize: "",
            parallel: "",
            retryCount: "",
            retryDuration: "",
            region: "cn-shanghai",
            userId: "1303984639806000",
            file: null,
            authProgress: 0,
            uploadDisabled: true,
            resumeDisabled: true,
            pauseDisabled: true,
            uploader: null,
            statusText: "",
            player: null,
            videoId: "",
            PlayAuth: "",
            videoSrc: "",
            progressNum: 0,
            videoElement: '',
            queryId: '',
            upIng: false,
            records: ''
            //
        };
    },
    mounted() {
        this.queryId = this.$route.query.id
        // this.videoElement = this.$refs.videoElement;
        // // videoElement.onloadedmetadata = () => {
        // //     const duration = videoElement.duration;
        // //     console.log('视频时长:', duration);
        // // };
        getUserVedioList(this.$route.query.id).then(res => {
            if (res.code == 200) {
                // console.log(res)
                this.records = res.data.records.length+1
                // console.log(this.records)
            }
        });
    },
    methods: {
        handleInput() {
            // 使用正则表达式替换非数字字符为空字符串
            this.input = this.input.replace(/\D/g, '');
        },
        authUpload() {
            // 然后调用 startUpload 方法, 开始上传
            if (this.uploader !== null) {
                this.uploader.startUpload();
                this.uploadDisabled = true;
                this.pauseDisabled = false;
            }
        },
        handleChange(value) {
            // console.log(value);
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            // console.log(this.imageUrl);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            console.log(dialogImageUrl);
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handleVideo(file) {
            let beforeBool = true;
            if (beforeBool) {
                this.videoLoading = true;
                direct().then(res => {
                    // res = res.data;
                    if (res.code == 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb
                            });
                            return;
                        }
                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then(con => {
                            // con = con.data;
                            if (con.code == 200) {
                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.raw,
                                    suc => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        // console.log(
                                        //   "url",
                                        //   "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                        //     key
                                        // );
                                        this.imageUrl =
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;
                                    },
                                    err => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        handleAvatarSuccess1(file) {
            // console.log(file);
        },
        beforeUploadVideo() {
            return true;
        },
        vedioUpCancel() {
            this.$router.go(-1);
        },
        //建立一个可存取到该file的url
        getObjectURL(file) {
            // console.log(file)
            var url = null;
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file);
                // console.log(url)
            } else if (window.URL != undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file);
                // console.log(url)
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
                // console.log(url)
            }
            return url;
        },
        fileChange(e) {
            // console.log(e)
            // console.log(e.target)
            this.file = e.target.files[0];
            // console.log(111, this.file)
            // console.log(e.target.files);
            if (!this.file) {
                alert("请先选择需要上传的文件!");
                return;
            }
            this.progressNum = 0;
            this.videoSrc = this.getObjectURL(this.file);
            // console.log(1111, this.videoSrc);
            const Title = this.file.name;
            const userData = '{"Vod":{}}';
            if (this.uploader) {
                this.uploader.stopUpload();
                this.authProgress = 0;
                this.statusText = "";
            }
            this.uploader = this.createUploader();
            // console.log(userData);
            this.uploader.addFile(this.file, null, null, null, userData);
            this.uploadDisabled = false;
            this.pauseDisabled = true;
            this.resumeDisabled = true;
            this.authUpload();
        },
        createUploader(type) {
            let self = this;
            // console.log(1223);
            let uploader = new AliyunUpload.Vod({
                timeout: self.timeout || 60000,
                partSize: Math.round(self.partSize || 1048576),
                parallel: self.parallel || 5,
                retryCount: self.retryCount || 3,
                retryDuration: self.retryDuration || 2,
                region: self.region,
                userId: self.userId,
                // 添加文件成功
                addFileSuccess: function (uploadInfo) {
                    self.uploadDisabled = false;
                    self.resumeDisabled = false;
                    self.statusText = "添加文件成功, 等待上传...";
                    // console.log("addFileSuccess: " + uploadInfo.file.name);
                    // console.log(uploadInfo);
                },

                // 开始上传
                onUploadstarted: function (uploadInfo) {
                    this.upIng = true
                    // console.log('开始上传')
                    // console.log(uploadInfo.videoId)
                    // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                    // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                    // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                    // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                    // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                    // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
                    if (!uploadInfo.videoId) {
                        console.log('!uploadInfo.videoId')
                        createUrl(uploadInfo.file.name).then(data => {
                            let uploadAuth = data.uploadAuth;
                            let uploadAddress = data.uploadAddress;
                            this.videoId = data.videoId;
                            // console.log("videoId" + this.videoId);
                            // console.log(uploadAuth);
                            // console.log(uploadAddress);
                            console.log(data.uploadAddress)
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                this.videoId
                            );
                            if (this.videoId) {
                                console.log(111)
                                vedioPlayer(this.videoId, this.$route.query.id).then(
                                    ({data}) => {
                                        console.log(data)
                                        this.PlayAuth = data.PlayAuth;
                                        console.log("测试-凭证" + this.PlayAuth);
                                    }
                                );
                            }
                        }).catch(error => {
                            // console.error("Failed to get uploadAuth and uploadAddress:", error);
                        });
                        self.statusText = "文件开始上传...";
                        // console.log(
                        //     "onUploadStarted:" +
                        //     uploadInfo.file.name +
                        //     ", endpoint:" +
                        //     uploadInfo.endpoint +
                        //     ", bucket:" +
                        //     uploadInfo.bucket +
                        //     ", object:" +
                        //     uploadInfo.object
                        // );
                    } else {
                        // 如果videoId有值，根据videoId刷新上传凭证
                        // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
                        // console.log("uploadInfo.videoId")
                        // console.log(this.videoId)
                        // console.log(uploadInfo.file.name)
                        createUrl(uploadInfo.file.name).then(data => {
                            // console.log(data);
                            let uploadAuth = data.uploadAuth;
                            let uploadAddress = data.uploadAddress;
                            this.videoId = data.videoId;
                            // console.log("videoId" + this.videoId);
                            // console.log(uploadAuth);
                            // console.log(uploadAddress);
                            console.log(uploader)
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                this.videoId
                            );
                            // console.log(this.videoId);
                            if (this.videoId) {
                                vedioPlayer(this.videoId, this.$route.query.id).then(
                                    ({data}) => {
                                        this.PlayAuth = data.PlayAuth;
                                        // console.log("测试-凭证" + this.PlayAuth);
                                    }
                                );
                            }
                        }).catch(error => {
                            // console.error("Failed to get uploadAuth and uploadAddress:", error);
                        });

                        vedioPlayer(this.videoId, this.$route.query.id).then(({data}) => {
                            // console.log("成功")
                            let uploadAuth = data.UploadAuth;
                            let uploadAddress = data.UploadAddress;
                            let videoId = data.videoId;
                            this.PlayAuth = UploadAuth;
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                videoId
                            );
                        });
                    }
                },
                // 文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    console.log(uploadInfo)
                    this.upIng = false
                    // console.log(
                    //     "onUploadSucceed: " +
                    //     uploadInfo.file.name +
                    //     ", endpoint:" +
                    //     uploadInfo.endpoint +
                    //     ", bucket:" +
                    //     uploadInfo.bucket +
                    //     ", object:" +
                    //     uploadInfo.object
                    // );


                    self.statusText = "文件上传成功!";
                },


                // 文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    // console.log(
                    //     "onUploadFailed: file:" +
                    //     uploadInfo.file.name +
                    //     ",code:" +
                    //     code +
                    //     ", message:" +
                    //     message
                    // );
                    self.statusText = "文件上传失败!";
                },
                // 取消文件上传
                onUploadCanceled: function (uploadInfo, code, message) {
                    console.log(
                        "Canceled file: " +
                        uploadInfo.file.name +
                        ", code: " +
                        code +
                        ", message:" +
                        message
                    );
                    self.statusText = "文件已暂停上传";
                },
                // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                onUploadProgress: function (uploadInfo, totalSize, progress) {
                    // console.log(
                    //     "onUploadProgress:file:" +
                    //     uploadInfo.file.name +
                    //     ", fileSize:" +
                    //     totalSize +
                    //     ", percent:" +
                    //     Math.ceil(progress * 100) +
                    //     "%"
                    // );
                    self.progressNum = Math.ceil(progress * 100);
                    let progressPercent = Math.ceil(progress * 100);
                    self.authProgress = progressPercent;
                    self.statusText = "文件上传中...";
                },
                // 上传凭证超时
                onUploadTokenExpired: function (uploadInfo) {
                    // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
                    // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
                    // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
                    let refreshUrl =
                        "https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=" +
                        uploadInfo.videoId;
                    axios.get(refreshUrl).then(({data}) => {
                        let uploadAuth = data.UploadAuth;
                        uploader.resumeUploadWithAuth(uploadAuth);
                        console.log(
                            "upload expired and resume upload with uploadauth " + uploadAuth
                        );
                    });
                    self.statusText = "文件超时...";
                },
                // 全部文件上传结束
                onUploadEnd: (uploadInfo) => {
                    console.log("onUploadEnd: uploaded all the files");
                    self.statusText = "文件上传完毕";
                    const video = document.getElementById("myVideo");
                    console.log(video);
                    this.timeinput = video.duration
                    const roundedSeconds = Math.round(this.timeinput); // 四舍五入为整数
                    const minutes = Math.floor(roundedSeconds / 60); // 分钟数
                    const seconds = roundedSeconds % 60; // 秒数
                    this.timeinput = `${minutes}:${seconds.toString().padStart(2, '0')}`;
                    console.log(this.timeinput)
                }
            });
            return uploader;
        },
        // 恢复上传
        resumeUpload() {
            if (this.uploader !== null) {
                this.uploader.startUpload();
                this.resumeDisabled = true;
                this.pauseDisabled = false;
            }
        },
        pauseUpload() {
            console.log(222);
        },
        vedioUp(state) {
            if (!this.imageUrl) {
                this.$message.error({
                    title: "错误",
                    message: "课时封面不能为空！"
                });
            } else if (!this.uploader.options.videoId) {
                this.$message.error({
                    title: "错误",
                    message: "视频文件不能为空！"
                });
            } else if (!this.timeinput) {
                this.$message.error({
                    title: "错误",
                    message: "视频时长不能为空！"
                });
            }
            // else if (!this.value2) {
            //     this.$message.error({
            //         title: "错误",
            //         message: "视频排序不能为空！"
            //     });
            // }
            else if (!this.className) {
                this.$message.error({
                    title: "错误",
                    message: "课时标题不能为空！"
                });
            } else {
                userUploadVideo(
                    this.$route.query.id,
                    this.uploader.options.videoId,
                    this.imageUrl,
                    this.timeinput,
                    this.records,
                    this.className,
                    this.radio,
                    state
                ).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            title: "成功",
                            message: "上传成功",
                            type: "success"
                        });
                        // this.$router.go(-1);
                        this.$router.push({
                            path: `/user/videomanagement?id=${this.$route.query.id}`
                        })
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        uploadOK(state) {
            console.log(this.uploader.options)
            if (!this.imageUrl) {
                this.$message.error({
                    title: "错误",
                    message: "课时封面不能为空！"
                });
            } else if (!this.uploader.options.videoId) {
                this.$message.error({
                    title: "错误",
                    message: "视频文件不能为空！"
                });
            } else if (!this.timeinput) {
                this.$message.error({
                    title: "错误",
                    message: "视频时长不能为空！"
                });
            }
            else if (!this.records) {
                this.$message.error({
                    title: "错误",
                    message: "视频排序不能为空！"
                });
            }
            else if (!this.className) {
                this.$message.error({
                    title: "错误",
                    message: "课时标题不能为空！"
                });
            } else {
                userUploadVideo(
                    this.$route.query.id,
                    this.uploader.options.videoId,
                    this.imageUrl,
                    this.timeinput,
                    this.records,
                    this.className,
                    this.radio,
                    state
                ).then(res => {
                    if (res.code == 200) {
                        console.log(res)
                        // this.$notify({
                        //     title: "成功",
                        //     message: "上传成功",
                        //     type: "success"
                        // });
                        // // this.$router.go(-1);
                        // this.$router.push({
                        //     path: `/user/videomanagement?id=${this.$route.query.id}`
                        // })
                        listSaveCourseInfo(this.$route.query.id).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: "正在审核,请您稍等!"
                                });

                                this.$router.push({
                                    path:'/user/course',
                                    query:{id:this.$route.query.id}
                                })
                            } else {
                                this.$message.error({
                                    title: "错误",
                                    message: res.message
                                });
                            }
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
    }
};
</script>

<style scoped>
.upload {
    position: absolute;
    right: 11px;
}

.kan-v {
    margin: 5px 0;
}

.commit-btn {
    margin-top: 42px;
    width: 134px;
    height: 33px;

    text-align: center;
    line-height: 33px;
    font-size: 14px !important;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 4px;
    margin-right: 33px;
    margin-bottom: 33px;
}

>>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #eb5139;
    background: #eb5139;
}

.detail-head {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 19px;
}

.head-btn {
    display: inline-block;
    width: 155px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
    margin-top: 10px;
}

.head-tabs {
    width: 394px;
    height: 17px;
    font-size: 12px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e5e5e5;
    line-height: 14px;
    margin-left: -58px;
}

/**上传部分样式 */
.uoload-f {
    margin-bottom: 20px;
}

.uoload-f >>> .el-upload--picture-card {
    background-image: url(../../../assets/images/Group255.png);

    background-size: 100% 100%;
}

.uoload-d >>> .el-upload--picture-card {
    background-image: url(../../../assets/images/Group259.png);

    background-size: 100% 100%;
}

>>> .el-upload {
    width: 238px;
    height: 125px;
    position: relative;
}

>>> #p-load {
    background-image: url(../../../assets/images/Group255.png);
    background-repeat: no-repeat;
    background-size: 19% 100%;
}

.avatar-uploader .el-upload {
    border: 1px dashed black;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

>>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #eb5139;
    background: #eb5139;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 238px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    border: 1px dashed gray;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/**上传部分样式结束 */
</style>
