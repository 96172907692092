import request from "@/utils/request";

export function institutionLogin(account,password,code,uuid) {
  const data = {
     'account':account,
     'password':password,
     'code':code,
     'uuid':uuid
   }
   return request({
     url:'api/institutionInfo/login',
     method:'post',
     data
 
   })
 }

 export function getCodeImg() {
  return request({
    url: 'api/auth/code',
    method: 'get'
  })
}
 