<template>
  <div>
    <Header></Header>
    <div class="head-back" @click="back"><span class="box"></span>返回</div>
    <div class="v-title">
      <span>{{ title }}</span>
      <!-- <div style="display:inline-block;color:#EB5139">
        <i class="iconfont icon-shoucang"></i>
        <i>收藏</i>
      </div>-->
    </div>
    <div class="video-play-box">
      <div class="video-left">
        <ali-player
          :vid="videoId"
          :playauth="PlayAuth"
          :useFlashPrism="false"
          :useH5Prism="true"
          :isLive="false"
          :rePlay="false"
          :autoplay="true"
          :showBuffer="true"
          showBarTime="5000"
          format="mp4"
          width="100%"
          controlBarVisibility="always"
          style="height: 760px"
          volume
        ></ali-player>
      </div>
      <div class="video-right">
        <div class="teacher-box">
          <div class="tea-p">
            <el-image
              :src="teacherInfo?.picture"
              style="
                width: 85px;
                height: 85px;
                border-radius: 50%;
                margin: 15px 19px;
                display: inline-block;
                vertical-align: text-top;
              "
              fit="cover"
            ></el-image>
            <i>讲师</i>
            <span>{{ teacherInfo?.name }}</span>
            <p>关注人数:{{ teacherInfo?.fans }}</p>
            <div
              v-if="teacherInfo?.isAttention == false"
              class="guanzhu"
              @click="collection"
            >
              <p>关注</p>
              <p style="font-size: 25px; margin-left: -11px; margin-top: -2px">
                +
              </p>
            </div>
            <div
              v-else
              class="guanzhu"
              style="color: red"
              @click="cancelCollection"
            >
              <p>已关注</p>
            </div>
          </div>
        </div>
        <div class="right-title">目录</div>
        <div class="vedio-items" style="color: white">
          <ul class="vedio-items2">
            <li
              class="vedio-tabs"
              v-for="(item, index) in table"
              :key="index"
              @click="player(index, item.videoId, item.hourHeadline, item.id)"
            >
              <i style="font-size: 24px" class="iconfont icon-a-shipin1"></i>
              <i class="tabs-card" style="font-size: 14px"
                >{{ item.sequence }}. {{ item.hourHeadline }}</i
              >
<!--              <p class="time">时长:04分24秒</p>-->
              <!-- <div class="study-on">
                <div class="radis"></div>
                <div class="study-after">已学过</div>
              </div>-->
              <div :class="{ mask: index != indexNow }"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="v-bottom">
      <!-- <i class="iconfont icon-Vector">&nbsp&nbsp咨询</i> -->
      <i @click="copyUrl">&nbsp&nbsp分享</i>
      <!-- <i>&nbsp&nbsp收藏</i>
      <i>|</i>
      <i>评分:</i>&nbsp&nbsp
      <div style="display:inline-block">
        <el-rate v-model="score"></el-rate>
      </div>-->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import AliPlayer from "./Aliplayer.vue";
import { getCourseDirectoryAll } from "@/api/coursedetails";
import { vedioPlayer } from "@/api/ailiplayer";
import { getTeacherInfo } from "@/api/user/video";
import {
  userCollectionTeacher,
  userCancelCollectionTeacher,
} from "@/api/collectionId";
export default {
  components: { AliPlayer, Header },

  data() {
    return {
      score: 0,
      indexNow: 0,
      playList: [
        {
          a: 1,
        },
        {
          b: 2,
        },
        {
          c: 3,
        },
      ],

      videoId: "",
      PlayAuth: this.$route.query.PlayAuth,
      table: {},
      title: "",
      teacherInfo: {},
    };
  },
  methods: {
    copyUrl() {
      let invitelink = location.href;
      this.$copyText(invitelink).then(
        (res) => {
          this.$message({
            title: "成功",
            message: "链接已成功复制到剪切板！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            title: "错误",
            message: err,
            type: "error",
          });
        }
      );
    },
    back() {
      this.$router.go(-1);
    },
    player(index, videoId, item, classId) {
      // console.log(index, videoId, item, 123);
      this.indexNow = index;
      this.title = item;
      vedioPlayer(videoId, classId).then((res) => {
        if (res.code === 400) {
          this.$message.error(res.message);
        } else {
          const playAuth = res.PlayAuth;
          // console.log("测试-凭证" + this.$route.query.videoId);

          this.videoId = videoId;
          this.PlayAuth = playAuth;
            console.log(this.videoId)
            console.log(this.PlayAuth)
        }
      });
      // console.log(this.videoId, 456);
    },
    collection() {
      userCollectionTeacher(this.teacherInfo.teacherId).then((res) => {
        if (res.code == 200) {
          this.$message({
            title: "成功",
            message: "关注成功",
            type: "success",
          });

          this.teacherInfo.isAttention = true;
          // console.log(this.teacherInfo.isAttention);
        }
      });
    },
    cancelCollection() {
      userCancelCollectionTeacher(this.teacherInfo.teacherId).then((res) => {
        if (res.code == 200) {
          this.$message({
            title: "成功",
            message: "取消关注成功",
            type: "success",
          });
          this.teacherInfo.isAttention = false;
        }
      });
    },
  },
  mounted() {
    getCourseDirectoryAll(this.$route.query.id).then((res) => {
      if (res.code == 200) {
        this.table = res.data;
        // if (this.$route.query.name != "") {
        this.title = this.$route.query.name;
        // }
        for (let gc in this.table) {
          // console.log(
          //   this.table[gc].videoId,
          //   this.$route.query.videoId,
          //   "****"
          // );
          if (this.table[gc].videoId == this.$route.query.videoId) {
            this.indexNow = gc;
            // console.log(this.indexNow, "-----");
            break;
          }
        }
      }
    });
    getTeacherInfo(this.$route.query.id).then((res) => {
      this.teacherInfo = res.data;
    });
    vedioPlayer(this.$route.query.videoId, this.$route.query.classId).then(
      (res) => {
        if (res.code == 400) {
          this.$message.error(res.message);
        } else {
          const playAuth = res.PlayAuth;
          console.log("测试-凭证" + this.$route.query.videoId);

          this.videoId = this.$route.query.videoId;
          this.PlayAuth = playAuth;
            console.log(this.PlayAuth)
        }
      }
    );
  },
};
</script>

<style scoped lang="scss">
.v-bottom > i {
  line-height: 44px;
  color: #999999;
  margin-left: 25px;
}
.v-bottom {
  width: 92%;
  height: 44px;
  background-color: #26262b;
}
/*播放器差价样式*/
.video-player {
  height: 78.3vh;
  width: 100%;
  background: #000;
  .video-js.vjs-fluid {
    height: 100%;
    padding: 0 !important;
  }
  .vjs-progress-control {
    pointer-events: none;
  }
  .vjs-big-play-button {
    border: none;
    background: transparent !important;
    &:active {
      background: transparent;
    }
    .vjs-icon-placeholder {
      font-size: 1rem;
    }
  }
}

/*播放器样式结束*/
滚动条 .vedio-items2::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.vedio-items2::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(124, 120, 120, 0.2);
  background: #7e7d7d;
}
.vedio-items2::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #575656;
}

.mask {
  position: absolute;
  left: -10px;
  top: 0;
  width: 105%;
  height: 64px;
  background: rgba(44, 47, 44, 0.8);
}
.study-after {
  color: #eb5139;
  margin-left: 4px;
}
.study-on {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  align-items: center;
}
.radis {
  width: 10px;
  height: 10px;
  background-color: #eb5139;
  border-radius: 50%;
}
.time {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: #3a3a3a;
}
.tabs-card {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 222px;
  margin-left: 5%;
}
.vedio-tabs {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 85%;
  position: relative;
}
.vedio-items {
  width: 407px;
  background-color: #444444;
  height: 597px;
}
.vedio-items2 {
  height: 597px;
  overflow: auto;
}

.right-title {
  width: 407px;
  height: 40px;
  background-color: #2d2d2d;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
}
.guanzhu {
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 72px;
  height: 29px;
  background-color: #444444;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}
.tea-p > i {
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 23px;
  overflow: hidden;
  text-align: center;
  top: 100px;
  left: 24px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.04);
  top: 77px;
  z-index: 0;
}
.tea-p > p {
  position: absolute;
  top: 77px;
  left: 123px;
  font-size: 14px;
  color: #999999;
}
.tea-p > span {
  position: absolute;
  top: 28px;
  color: #ffffff;
}
.tea-p > img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin: 15px 19px;
}
.tea-p {
  overflow: hidden;
}
.teacher-box {
  width: 100%;
  height: 123px;
  background: rgba(53, 50, 53);
  position: relative;
}
.video-right {
  width: 407px;
  height: 100%;
}
.video-left {
  height: 100%;
  width: 78%;
  background-color: #fff;
}
.video-play-box {
  height: 759px;
  width: 92%;
  display: flex;
}
.head-back {
  width: 100%;
  height: 35px;
  background-color: #282828;
  color: white;
  line-height: 35px;
  cursor: pointer;
}
.box {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid red;
  border-color: #282828 #282828 white white;
  transform: rotate(45deg);
  margin-left: 55px;
  margin-right: 11px;
  position: relative;
  top: 3px;
}
.v-title {
  background-color: #191919;
  color: white;
  width: 100%;
  white-space: nowrap;
  height: 55px;
}
.v-title > span {
  margin: 10px 20px 1px 200px;
  display: inline-block;
  font-size: 24px;
}
</style>
