<template>
  <div class="main">
    <div class="user-left">
      <p :class="['left-tabs',{active:this.isShow==0}]" @click="goList1">消息和通知</p>
      <p :class="['left-tabs',{active:this.isShow==1}]" @click="goList2">课程更新</p>
    </div>
    <div class="user-right">
      <ul>
        <li v-for="(item,index) in userMessage.records" :key="item.id">
          <span class="information-head">{{item.noticeTitle}}</span>
          <span class="information-time">{{item.releaseTime| formatDate}}</span>
          <p>{{item.noticeContent}}</p>
          <i @click="deleteM(item)" class="iconfont icon-shanchu delete"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getUserNotice, DeleteUserMessage } from "@/api/userinformation";
export default {
  data() {
    return {
      isShow: 0,
      userMessage: {}
    };
  },
  methods: {
    goList2() {
      this.isShow = 1;
      getUserNotice(1, 10, 2).then(res => {
        if (res.code == 200) {
          this.userMessage = res.data;
        }
      });
    },
    goList1() {
      this.isShow = 0;
      getUserNotice(1, 10, 1).then(res => {
        if (res.code == 200) {
          this.userMessage = res.data;
        }
      });
    },
    deleteM(item) {
      this.$confirm("内容即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        DeleteUserMessage(item.id).then(res => {
          if (res.code == 200) {
            this.getUserMessage();
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    getUserMessage() {
      getUserNotice(1, 10, 1).then(res => {
        if (res.code == 200) {
          this.userMessage = res.data;
        }
      });
    }
  },
  mounted() {
    this.getUserMessage();
  },
  filters: {
    formatDate: value => {
      if (value != "") {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        return y + "-" + MM + "-" + d + "    " + h + ":" + m + ":" + s;
      } else {
        return "选择日期";
      }
    }
  }
};
</script>

<style scoped>
.delete {
  position: absolute;
  right: 22px;
  top: 50px;
  font-size: 26px;
  color: #999999;
}
.user-right > ul > li > p {
  width: 1106px;
  height: 40px;
  font-size: 14px;
  color: #666666;
  margin-left: 0px;
}
.information-time {
  margin-left: 8px;
  color: #999999;
}
.information-head {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}
.user-right > ul > li {
  position: relative;
  height: 72px;
  width: 90%;
  padding: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.main {
  width: 90%;
  display: flex;
  margin-top: 30px;
}
.user-left {
  width: 19%;
  height: 695px;
  background-color: #ffffff;
  padding-top: 20px;
}
.user-right {
  width: 80%;
  height: 695px;
  background-color: #ffffff;
}
.left-tabs {
  width: 288px;
  height: 65px;
  text-align: center;
  line-height: 65px;
  font-size: 18px;
  color: #666666;
  cursor: pointer;
}
.active {
  color: #000000 !important;
  background-color: #fafafa !important;
}
</style>