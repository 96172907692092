import request from '@/utils/request'

export function callback() {
    return request({
      url:'api/ucenter/wx/callback',
      method:'get',
  })
}

// export function weixinLogin() {
//   return request({
//     url: 'api/ucenter/wx/weixinLogin',
//     method: 'get',
//   })
// }

export function getLoginParam() {
  return request({
    url:'api/ucenter/wx/getLoginParam',
    method:'get'
  })
}

