<template>
    <div class="main">
        <div class="user-head">
            <div class="user-info">
                <div
                        class="user-portrait"
                        :style="'background-image:url(' + userInfoList.picture + ');'">
                </div>
                <div class="user-name">
                    <!--                    <span>{{ this.$store.state.user.userInfo.nickName }}</span>-->
                    <span>{{ userInfoList.nickName }}</span>
                    <i
                            v-if="this.$store.state.user.userInfo.identity == 2"
                            style="color: #fff; margin-left: 15px; font-size: 22px"
                            class="iconfont icon-zhaozhuanjia"
                    ></i>
                    <!--                    <p class="user-phoneNum" v-if="phoneOld">-->
                    <!--                        <span>{{ phoneNum }}</span>-->
                    <!--                        <i class="el-icon-edit" @click="goPhone"></i></p>-->
                    <!--                    <div v-else-if="!phoneOld" class="editPhoneNum">-->
                    <!--                        <div class="phoneNum">-->
                    <!--                            &lt;!&ndash;                            <i class="user-tab" style="color: white">请输入新手机号:</i>&nbsp;&nbsp;&ndash;&gt;-->
                    <!--                            <el-input class="phone-num" v-model="newPhoneNum" placeholder="请输入新手机号"></el-input>-->
                    <!--                        </div>-->
                    <!--                        <div class="phoneCode">-->
                    <!--                            &lt;!&ndash;                            <i class="user-tab" style="color: white">验证码:</i>&nbsp;&nbsp;&ndash;&gt;-->
                    <!--                            <el-input class="phone-code" v-model="phoneCode" type="text" placeholder="请输入验证码"/>-->
                    <!--                            <button @click="countDown(newPhoneNum)" :style="{  pointerEvents: none}"-->
                    <!--                                    class="yz-btn">{{ content }}-->
                    <!--                            </button>-->
                    <!--                        </div>-->
                    <!--                        <div class="buttonGroup" style="display: flex;justify-content: space-evenly">-->
                    <!--                            <el-button  class="submit" @click="goPhoneNext(newPhoneNum,phoneCode)">确定</el-button>-->
                    <!--                            <el-button class="back-btn" @click="forBack">取消</el-button>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <p class="user-jifen">会员积分：{{ userInfoList.integral }}</p>
                    <div>
                        <p v-if="userInfoList.isVip == 1" class="user-isvip">
                            会员到期时间：{{ timestampToTime(userInfoList.expire) }}
                        </p>
                        <p v-else class='user-vip' @click="goVip">立即开通会员</p>
                        <span v-if="flag" class="sign" @click="SignIn">签到</span>
                        <span v-else style="background-color: #999999;cursor: no-drop" class="sign">已签到</span>
                    </div>
                </div>
            </div>
            <!--            <div class="user-fans">-->
            <!--                <ul>-->
            <!--                    <li>关注</li>-->
            <!--                    <li>收藏</li>-->
            <!--                    <li>作品</li>-->
            <!--                    <li>粉丝</li>-->
            <!--                </ul>-->
            <!--                <ul>-->
            <!--                    <li>{{ userInfoList.attention }}</li>-->
            <!--                    <li>{{ userInfoList.collector }}</li>-->
            <!--                    <li>{{ userInfoList.works }}</li>-->
            <!--                    <li>{{ userInfoList.fans }}</li>-->
            <!--                </ul>-->
            <!--            </div>-->
        </div>
        <div class="user-center">
            <div class="user-nav">
                <ul>
                    <!-- <li :class="{active:this.$route.path=='/user/userinfo'}">
                      <div class="nav-detail">
                        <i
                          :class="[{'nav-icon-color':this.$route.path=='/user/userinfo'},'nav-icon', 'icon-gerenzhongxin','iconfont']"
                        ></i>
                        <router-link
                          :class="{'link-color':this.$route.path=='/user/userinfo'}"
                          to="/user/userinfo"
                        >个人中心</router-link>
                      </div>
                    </li>-->
                    <div :class="{ box: this.$route.path == '/user/userinfo' }"></div>
                    <li :class="{ active: this.classShow == true }">
                        <div class="nav-detail">
                            <i :class="[{ 'nav-icon-color': this.classShow == true },'nav-icon','icon-neirongguanli','iconfont',]"></i>
                            <router-link :class="{ 'link-color': this.classShow == true }" to="/user/purchased">
                                个人中心
                            </router-link>
                        </div>
                    </li>
                    <div :class="{ box2: this.classShow == true }"></div>
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{ 'font-color': this.$route.path == '/user/purchased' }"
                                         to="/user/purchased">我的已购
                            </router-link>
                        </div>
                        <div :class="{ 'box-left': this.$route.path == '/user/purchased' }"></div>
                    </li>
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{'font-color': this.$route.path == '/user/collection',}"
                                         to="/user/collection">我的收藏
                            </router-link>
                        </div>
                        <div :class="{ 'box-left': this.$route.path == '/user/collection' }"></div>
                    </li>
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{ 'font-color': this.$route.path == '/user/focus' }" to="/user/focus">
                                我的关注
                            </router-link>
                        </div>
                        <div :class="{ 'box-left': this.$route.path == '/user/focus' }"></div>
                    </li>
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{ 'font-color': this.$route.path == '/user/integral' }"
                                         to="/user/integral">我的积分
                            </router-link>
                        </div>
                        <div :class="{ 'box-left': this.$route.path == '/user/integral' }"></div>
                    </li>
                    <!-- <li class="mini">
                      <div class="nav-detail-child">
                        <router-link
                          :class="{'font-color':this.$route.path=='/user/information'}"
                          to="/user/information"
                        >资料管理</router-link>
                      </div>
                      <div :class="{'box-left4':this.$route.path=='/user/information'}"></div>
                    </li> -->

                    <div v-if="stat">
                        <li class="mini">
                            <div class="nav-detail-child">
                                <router-link :class="{'font-color':
                    this.$route.path == '/user/course' ||
                    this.$route.path == '/user/addclass' ||
                    this.$route.path == '/user/videomanagement' ||
                    this.$route.path == '/user/editclass' ||
                    this.$route.path == '/user/videoup' ||
                    this.$route.path == '/user/audit',
                }" to="/user/course">课程管理
                                </router-link>
                            </div>
                            <div :class="{'box-left':
                  this.$route.path == '/user/course' ||
                  this.$route.path == '/user/addclass' ||
                  this.$route.path == '/user/videomanagement' ||
                  this.$route.path == '/user/editclass' ||
                  this.$route.path == '/user/videoup' ||
                  this.$route.path == '/user/audit',
              }"></div>
                        </li>
                        <li class="mini">
                            <div class="nav-detail-child">
                                <router-link :class="{'font-color':this.$route.path == '/user/sell' ||
                    this.$route.path == '/user/sellPerson',
                }" to="/user/sell">课程销售
                                </router-link>
                            </div>
                            <div :class="{'box-left':
                  this.$route.path == '/user/sell' ||
                  this.$route.path == '/user/sellPerson',
              }"></div>
                        </li>
                    </div>
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{'font-color':this.$route.path == '/user/Securityworkerssettlingin'}"
                                         to="/user/Securityworkerssettlingin">安工入驻
                            </router-link>
                        </div>
                        <div :class="{'box-left':this.$route.path == '/user/Securityworkerssettlingin'}"></div>
                    </li>

                    <!--                    <li :class="{ active: this.$route.path == '/user/material' }">-->
                    <!--                        <div class="nav-detail">-->
                    <!--                            <i :class="[-->
                    <!--                            { 'nav-icon-color': this.$route.path == '/user/material' },-->
                    <!--                            'iconfont',-->
                    <!--                            'icon-gerenziliao',-->
                    <!--                          ]"-->
                    <!--                            ></i>-->
                    <!--                            <router-link :class="{ 'link-color': this.$route.path == '/user/material' }" to="/user/material">个人资料</router-link>-->
                    <!--                        </div>-->
                    <!--                    </li>-->
                    <li class="mini">
                        <div class="nav-detail-child">
                            <router-link :class="{'font-color':this.$route.path == '/user/invitation'}"
                                         to="/user/invitation">填写邀请码
                            </router-link>
                        </div>
                        <div :class="{'box-left':this.$route.path == '/user/invitation'}"></div>
                    </li>
                    <li class="mini">
                        <div  class="nav-detail-child">
                            <router-link :class="{'font-color':this.$route.path == '/user/material'}"
                                         to="/user/material">个人资料
                            </router-link>
                        </div>
                        <div :class="{'box-left':this.$route.path == '/user/material'}"></div>
                    </li>
                    <!--                    <div :class="{ box1: this.$route.path == '/user/material' }"></div>-->
                </ul>
            </div>
            <div class="user-detail">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import {getUserInfo} from "@/api/user/userinfo";
import {getCode, getIntegralCalculation, isIntegralSuccess} from "@/api/login/personal";
import {setUserPhoneNum} from "@/api/user/material";
import {isAuthentication} from "@/api/login";

export default {
    data() {
        return {
            flag: true,
            stat: true,
            pirUrl: "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/",
            classShow: false,
            userInfoList: {},
            userNameNew: this.$store.state.user.userInfo.nickName,
            phoneNum: this.$store.state.user.userInfo.phone,
            // 需改手机号需要
            phoneOld: true,
            newPhoneNum: "",
            phoneCode: "",
            totalTime: 60,
            content: "获取验证码", // 获取验证码按钮文字
            next24Hours: '',
            now: "",
            midnight: "",
            time: "",
            timeNum: '',
        }
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        }
    },
    methods: {
        SignIn() {
            //  this.next24Hours = 24 * 60 * 60 * 1000;
            // this.now = new Date();
            //  this.midnight = new Date().setHours(0, 0, 0, 0);
            //  // console.log(this.next24Hours-(this.now-this.midnight))
            //  localStorage.setItem("time",this.next24Hours-(this.now-this.midnight))
            //  // this.flag = false
            //  // localStorage.setItem("flag",this.flag)
            //  // console.log(Boolean(localStorage.getItem("flag")))
            //  this.flag = false;
            //  localStorage.setItem("flag", JSON.stringify(this.flag));
            //  console.log(JSON.parse(localStorage.getItem("flag")));
            getIntegralCalculation(0, 1, 0, JSON.parse(localStorage.getItem("USER_AUTH_INFO")).id).then((res) => {
                if (res.code === 200) {
                    this.flag = false
                    this.getUserInfo();
                }
            })
        },
        // 修改手机号
        countDown(userPhoneNum) {
            {
                this.$confirm("确认修改手机号, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    getCode(userPhoneNum).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: "验证码以发送!"
                            });
                            this.content = `${this.totalTime}s后重新发送`; //这里解决60秒不见了的问题

                            let clock = window.setInterval(() => {
                                this.totalTime--;

                                this.content = `${this.totalTime}s后重新发送`;

                                if (this.totalTime < 0) {
                                    //当倒计时小于0时清除定时器

                                    window.clearInterval(clock);

                                    this.content = "发送验证码";

                                    this.totalTime = 60;
                                }
                                if (this.totalTime < 60) {
                                    this.none = "none";
                                } else {
                                    this.none = "";
                                }
                            }, 1000);
                        } else {
                            this.$message.error({
                                title: "错误",
                                message: res.message
                            });
                        }
                    });
                });
            }
        },
        // 确定修改手机号
        goPhoneNext(userPhoneNum, phoneCode) {
            setUserPhoneNum(userPhoneNum, phoneCode).then(res => {
                if (res.code == 200) {
                    this.$alert("修改完成，再次登陆后生效！", "提示", {
                        confirmButtonText: "确定"
                    });
                    this.pageShow = 1;
                } else {
                    this.$alert(res.message, "警告", {
                        confirmButtonText: "确定"
                    });
                    this.pageShow = 1;
                }
            });
        },
        forBack() {
            this.phoneOld = !this.phoneOld;
        },
        goPhone() {
            this.phoneOld = !this.phoneOld;
        },
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.code == 200) {
                    this.userInfoList = res.data;
                    console.log(this.userInfoList.nickName);
                }
            });
        },
        goVip() {
            this.$router.push({
                path: "/joinvip",
            });
        },
        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
    },
    mounted() {
        isIntegralSuccess(JSON.parse(localStorage.getItem("USER_AUTH_INFO")).id).then((res) => {
            this.flag = res.message !== "成功";
        })
        // const timerCallback = () => {
        //     this.timeNum = Number(localStorage.getItem("time")) - 1;
        //     localStorage.setItem("time",this.timeNum)
        //     // console.log(this.timeNum)
        //     // 在此处处理每秒执行的逻辑
        //     // console.log(11111);
        //     // console.log(this.timeNum)
        //     if (this.timeNum < 0) {
        //         this.flag = true;
        //         localStorage.setItem("flag",this.flag)
        //         clearTimeout(this.time);
        //     } else {
        //         this.time = setTimeout(timerCallback, 1000);
        //     }
        // };
        // this.time = setTimeout(timerCallback, 1000);
        // // console.log(this.flag)
        // // console.log(localStorage.getItem("flag"))
        // this.flag =JSON.parse(localStorage.getItem("flag"))
        // console.log(this.flag)
        // 如果未通过隐藏模块
        isAuthentication().then((res) => {
            // console.log(res)
            if (res.code === 200) {
                if (res.data.status === 1 || res.data.status === 3) {
                    this.stat = false
                }
            }
            if (res.code === 400) {
                this.stat = false
            }
        })
        this.getUserInfo();
        this.classShow = this.$route.path != "/user/userinfo";
    },
    watch: {
        $route(to, from) {
            if (
                to.path == "/user/purchased" ||
                to.path == "/user/collection" ||
                to.path == "/user/focus" ||
                to.path == "/user/information" ||
                to.path == "/user/course" ||
                to.path == "/user/addclass" ||
                to.path == "/user/videomanagement" ||
                to.path == "/user/editclass" ||
                to.path == "/user/videoup" ||
                to.path == "/user/integral" ||
                to.path == "/user/audit" ||
                to.path == "/user/sell" ||
                to.path == "/user/sellPerson"
            ) {
                this.classShow = true;
                console.log(this.$route.path);
            } else {
                this.classShow = false;
            }
        },
    },
};
</script>

<style scoped>
.sign {
    cursor: pointer;
    width: 70px !important;
    height: 33px !important;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    color: white;
    background-color: #EB5139;
    position: absolute;
    right: -80px;
    top: 110px;
    font-size: 18px !important;
}

.buttonGroup {
    margin-top: 5px;
}

.buttonGroup > button {
    padding: 5px 10px;
}

.phoneNum {
    margin-bottom: 5px;
}

.yz-btn {
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: dodgerblue;
}

.user-isvip {
    margin-top: 35px !important;
    font-size: 16px !important;
    width: 253px !important;
    display: inline-block !important;
}

.box-left {
    position: absolute;
    top: 50px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left1 {
    position: absolute;
    top: 90px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left2 {
    position: absolute;
    top: 130px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left3 {
    position: absolute;
    top: 130px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left4 {
    position: absolute;
    top: 210px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left {
    position: absolute;
    top: 5px;
    height: 30px;
    border-left: 3px solid #eb5139;
}

.box-left6 {
    position: absolute;
    top: 250px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.box-left7 {
    position: absolute;
    top: 290px;
    height: 22px;
    border-left: 3px solid #eb5139;
}

.font-color {
    color: #eb5139;
}

.box {
    width: 0;
    height: 0;
    border: 20px solid red;
    border-color: #f5f7fa #f5f7fa #f5f7fa #eb5139;
    position: absolute;
    top: 0px;
    left: 122px;
}

.box1 {
    width: 0;
    height: 0;
    border: 20px solid red;
    border-color: #f5f7fa #f5f7fa #f5f7fa #eb5139;
    position: absolute;
    top: 280px;
    left: 122px;
}

.box2 {
    width: 0;
    height: 0;
    border: 20px solid red;
    border-color: #f5f7fa #f5f7fa #f5f7fa #eb5139;
    position: absolute;
    top: 0px;
    left: 122px;
}

.nav-icon {
    font-size: 22px;
}

.nav-icon-color {
    color: #ffffff;
}

.link-color {
    color: #ffffff;
}

.nav-detail-child {
    width: 185px;
    height: 40px;
    display: flex;
    box-sizing: border-box;
    margin-left: 3px;
}

.active {
    background-color: #eb5139;
}

.nav-detail {
    line-height: 40px;
    margin-left: 40px;
}

.nav-detail > i {
    display: inline-block;
    vertical-align: -4%;
    margin: 0 5px 0 0;
}

.user-nav {
    line-height: 40px;
    margin-left: 6.5%;
    position: relative;
    margin-right: 30px;
}

.user-detail {
    margin-left: 0;
    width: 91%;
    margin-right: 0;
    min-height: 588px;
}

.user-nav > ul > li {
    width: 185px;
    height: 40px;
    color: #676c71;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    margin-left: -35px;
}

.phone-code {
    width: 140px;
//margin-left: 20px;
}

.phone-num {
    width: 200px;
}

.user-fans > ul {
    display: flex;
    height: 55px;
    width: 320px;
    margin-left: 70%;
}

.user-fans > ul > li {
    text-align: center;
    width: 25%;
    color: #ffffff;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 16px;
}

.user-fans {
    padding-top: 272px;
}

.user-head {
    background-image: url("../../assets/images/Maskgroup.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    position: relative;
}

.user-info {
    position: absolute;
    bottom: -26px;
    left: 200px;
    display: flex;
}

.user-portrait > img {
    width: 200px;
    height: 200px;
}

.user-portrait {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
}

.user-name {
    position: relative;
    margin-left: 20px;
}

.user-name > p {
    width: 200px;
//height: 34px; color: white; //font-size: 24px; margin-left: 1px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}

.user-name > span {
    width: 200px;
    height: 34px;
    color: white;
    font-size: 24px;
    margin-left: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-phoneNum {
    margin-top: 14px;
    font-size: 16px;
}

.user-phoneNum > span {
    margin-right: 10px;
}

.user-phoneNum > i {
    cursor: pointer;
}

.user-jifen {
    display: block !important;
    margin-top: 15px !important;
    color: white !important;
    font-size: 16px !important;
    width: 200px !important;
}

.user-vip {
    cursor: pointer;
    margin-top: 42px;
    width: 184px;
    height: 33px;
    background-color: #eb5139;
    text-align: center;
    line-height: 33px;
    font-size: 16px !important;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 4px;
}

.user-vip-true {
    margin-top: 15px;
    width: 134px;
    height: 33px;
    background-color: #eb5139;
    text-align: center;
    line-height: 33px;
    font-size: 16px !important;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 4px;
}

.user-center {
    margin-top: 56px;
    display: flex;
    margin-left: 8%;
    width: 80%;
}

.mini {
    font-size: 14px !important;
    font-family: PingFang SC-Regular, PingFang SC !important;
    font-weight: 400 !important;
    color: #676c71 !important;
    margin-left: -35px !important;
    position: relative;
}

.main {
    overflow: hidden;
    width: 100%;
}
</style>
