import request from '@/utils/request'
export function userCollection(collectionId, type) {
    return request({
        url: 'api/personalUser/collection',
        method: 'post',
        params: {
            collectionId: collectionId,
            type: type
        }
    })
}
export function userCancelCollection(collectionId, type) {
    return request({
        url: 'api/personalUser/cancelCollection',
        method: 'post',
        params: {
            collectionId: collectionId,
            type: type
        }
    })
}

export function userCollectionTeacher(attentionId) {
    return request({
        url: 'api/puserAuthentication/user/concern?attentionId=' + attentionId,
        method: 'post',

    })
}
export function userCancelCollectionTeacher(attentionId) {
    return request({
        url: 'api/puserAuthentication/user/cancel?attentionId=' + attentionId,
        method: 'post',

    })
}