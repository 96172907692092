import request from '@/utils/request'

// 询专家-首页-讲师推荐
export function Recommend(data) {
  return request({
    url: 'api/puserAuthentication/indexList?count=' + data.count,
    // url: 'api/puserAuthentication/indexListnew?count=' + data.count,
    method: 'get',
  })
}

// 询专家-搜索结果讲师  - 按行业类型
export function searchByIndustry(data) {
  return request({
    url: '/api/puserAuthentication/searchByIndustry?current=' + data.current + '&keyWord=' + data.keyWord + '&size=' + data.size + '&type=' + data.type,
    method: 'get'
  })
}



// 询专家-搜索结果页-讲师
export function searchList(data) {
  return request({
    url: 'api/puserAuthentication/searchList?current=' + data.current + '&name=' + data.name + '&size=' + data.size,
    method: 'get'
  })
}

// 取消关注讲师
export function cancel(data) {
  return request({
    url: 'api/puserAuthentication/user/cancel?attentionId=' + data.attentionId,
    method: 'post',
    data
  })
}

// 关注讲师
export function concern(data) {
  return request({
    url: 'api/puserAuthentication/user/concern?attentionId=' + data.attentionId,
    method: 'post',
    data
  })
}


// 询专家-详细页-讲师课程   id=3
export function courseList(id) {
  return request({
    url: 'api/puserAuthentication/list/courseList?id=' + id,
    method: 'get'
  })
}

// 讲师资料   id=3
export function documentList(id) {
  return request({
    url: 'api/puserAuthentication/list/documentList?id=' + id,
    method: 'get'

  })
}

// 更多讲师课程
export function moreCourse(data) {
  return request({
    url: 'api/puserAuthentication/list/moreCourse?current=' + data.current + '&id=' + data.id + '&size=' + data.size,
    method: 'get'
  })
}

// 更多讲师资料
export function moreDocument(data) {
  return request({
    url: 'api/puserAuthentication/list/moreDocument?current=' + data.current + '&id=' + data.id + '&size=' + data.size,
    method: 'get'
  })
}

// 同领域讲师
export function sameResearchField(data) {
  return request({
    url: 'api/puserAuthentication/list/sameResearchField?field=' + data.unit + "&id=" + data.id,
    method: 'get'
  })
}

// 同单位讲师
// export function sameUnit(data) {
//   return request({
//     url:'api/puserAuthentication/list/sameUnit?unit=' + data.unit,
//     method:'get'
//   })
// }

export function sameUnit2(data) {
  return request({
    url: 'api/puserAuthentication/list/sameRange?range=' + data.field + "&id=" + data.id,
    method: 'get'
  })
}

