<template>
  <div>
    <span class="detail-head">课程管理</span>
    <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none;width:1315px" />
    <div class="head-schedule">
      <i class="iconfont icon-shoucang" style="font-size:30px;margin-left:33px"></i>
      <i style="color:#eb5139;padding-top:5px;font-size:16px;margin-left:4px">填写基本信息</i>
      <hr class="line-top" style="margin-left:10px" />
      <i class="secend" style="margin-left:10px">2</i>
      <i style="font-size:16px;padding-top:5px">提交信息加急审核中</i>
      <hr class="line-top" style="background-color:#E5E5E5" />
      <i class="secend1">3</i>
      <i style="font-size:16px;padding-top:5px;color:#E5E5E5 ;margin-right:103px">完成</i>
    </div>
    <div class="img-box">
      <img src="../../../assets/uploads/CraftsmanCertification 6.png" alt />
    </div>
    <div class="text-bottom">审核中</div>
    <hr class="line-bottom" />
    <div class="btn-box">
      <div class="btn-box2">
        <button>查看课程</button>
        <button>再发一个</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-box2 > button {
  width: 141px;
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e5e5e5;
  margin: 27px 100px;
  color: #676c71;
  background-color: #fffeff;
}
.btn-box {
  text-align: center;
}
.text-bottom {
  text-align: center;
  margin-top: 58px;
  font-size: 32px;
  font-weight: 600;
}
.line-bottom {
  width: 579px;
  margin-top: 29px;
}
.img-box {
  margin-top: 35px;
  text-align: center;
}
.img-box > img {
  width: 243px;
  height: 243px;
  border-radius: 50%;
}
.secend1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  color: #e5e5e5;
  text-align: center;
  line-height: 30px;
}
.secend {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eb5139;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
}
.line-top {
  height: 2px;
  width: 345px;
  border: none;
  background-color: red;
  margin-top: 14px;
}
.head-schedule {
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
</style>