<template>
    <div class="metting-details">
        <div class="metting-content clearfix">
            <!-- 左侧内容 -->
            <div class="metting-LeftContent fl">
                <!-- 左侧内容的头部 -->
                <div class="introduce">
                    <div class="metting-LeftContent-top">
                        <h3>{{ detailList.title }}</h3>
                        <!-- 阅读量区域 -->
                        <div class="metting-LeftContent-read">
                            <img
                                    v-if="logo !== ''"
                                    :src="logo"
                                    alt=""
                                    class="fl"
                                    style="object-fit: contain"
                            >
                            <h4 style="cursor: pointer" onmouseover="this.style.color='red'" onmouseout="this.style.color=''" @click="goInstitutionId(detailList.institutionId)">{{ detailList.sponsor }}</h4>
                            <!-- 时间 -->
                            <span>{{ detailList.holdingTime | timeFormat }}</span>
                            <span
                                    style="margin-left:20px;font-weight:600">{{ detailList.source }}</span>
                            <!-- 阅读量 -->
                            <span style="float:right">{{ detailList.pageviews }}</span>
                            <span style="float:right"> 阅读量:</span>

                        </div>
                    </div>

                    <!-- 大图片 -->
                    <div class="metting-LeftContent-pic">
                        <img
                                v-if="detailList.surfacePlotUrl != ''"
                                :src="detailList.surfacePlotUrl"
                                alt=""
                        >
                    </div>
                </div>

                <!-- 会议举办详情 -->
                <div class="metting-LeftContent-content">
                    <p style="display: flex"><span style="margin: 0 5px 0 0;white-space: nowrap;">主办单位 : </span> {{ detailList.sponsor }}</p>
                    <p style="display: flex"><span style="margin: 0 5px 0 0">协办单位 : </span>{{ detailList.assistanceUnit }}</p>
                    <p style="display: flex"><span style="margin: 0 5px 0 0">承办单位 : </span> {{ detailList.organizer }}</p>
                    <p><span>举办时间 : </span>
                        <span style="font-weight:300">{{ detailList.holdingTime | timeFormat }} </span>
                    </p>
                    <p><span>举办地点 : </span>{{ detailList.hostPlace }}</p>

                </div>
                <div class="meetingsummary">
                    <p class="summary">
                        <span>会议摘要:</span>
                        <pre style="white-space:pre-wrap;margin-top:10px;font-family:'微软雅黑';font-weight:300;line-height: 2;"
                             v-html="detailList.summary"></pre>
                    </p>
                </div>
                <!-- 文字区域 -->
                <div class="metting-LeftContent-text">
                    <p style="margin-bottom:30px">
                        <span style="font-size:14px;font-weight:600" class="contents">会议内容:</span>
                        <pre style="white-space:pre-wrap;margin-top:10px;font-family:'微软雅黑';font-size:14px;font-weight:300"
                             v-html="detailList.content"></pre>
                    </p>
                </div>

                <div class="meetingattachments">
                    <span>会议附件 : </span>
                    <!--          <span v-if="detailList.file == null"> 无 </span>-->
                    <!--          <a v-if="detailList.file != null" style="display:inline-block;width:400px;height:18px;margin-left:15px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;" :href="detailList.file">{{detailList.file}}</a>-->
                    <p v-if="fileNameUrl.length !== 0"
                       style="width:400px;white-space: nowrap;text-overflow:ellipsis;overflow:hidden;"><span
                            v-for="(item,index) in fileNameUrl" :key="index"><a
                            :href="item.url">{{ item.name }}</a><br></span></p>
<!--                    <p v-if="fileNameUrl === ''">无</p>-->
                    <p v-else>无</p>
                </div>
            </div>
            <!--  左侧内容 end -->
            <!-- 右侧内容 start -->
            <div class="metting-RightContent fr">
                <!-- 最新文章 -->
                <div class="new-article">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-notebook-2"></i>
                            <h4>热门会议</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="moremeeting"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="new-article-content">
                        <el-empty
                                v-if="articleList && !articleList.length"
                                description="暂无数据"
                        ></el-empty>
                        <li
                                v-for="(article,index) in articleList"
                                :key="index"
                                @click="goNewsDetail(article)"
                        >
                            <img
                                    :src="article.surfacePlotUrl"
                                    alt=""
                                    class="fl"
                            >
                            <div class="cont">
                                <div class="title">{{ article.title }}</div>
                                <div class="text">
                                    <div class="time_readingvolume">
                                        <span class="readingvolume">阅读量：</span><span>{{ article.pageviews }}</span>
                                    </div>
                                    <div class="time">{{ article.auditTime | timeFormat }}</div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
                <!-- 相关推荐 -->
                <div class="new-article">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-s-claim"></i>
                            <h4>相关推荐</h4>
                        </div>
                        <a class="more" href="javascript:;"
                           @click="moremeeting"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="new-article-content">
                        <el-empty
                                v-if="relatedList && !relatedList.length"
                                description="暂无数据"
                        ></el-empty>
                        <li
                                v-for="(related,index) in relatedList"
                                :key="index"
                                @click="goMeetingInfo(related)"
                        >
                            <img
                                    :src="related.surfacePlotUrl"
                                    alt=""
                                    class="fl"
                            >
                            <div class="cont">
                                <div class="title">{{ related.title }}</div>
                                <div class="text">
                                    <div class="time_readingvolume">
                                        <span class="readingvolume">阅读量：</span><span>{{ related.pageviews }}</span>
                                    </div>
                                    <div class="time">{{ related.auditTime | timeFormat }}</div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- 右侧内容 end -->
        </div>
    </div>
</template>

<script>
import {meetingInfo, newArticle, relatedArticle} from "@/api/common";
import {getInstitution} from "@/api/job";

export default {
    name: "MeetingDetails",
    data() {
        return {
            logo:'',
            fileNameUrl: [], //会议附件
            detailList: [], // 会议详细
            articleList: [], //最新文章
            relatedList: [], // 相关推荐
        };
    },
    mounted() {
        meetingInfo(this.$route.query.id).then((res) => {
            if (typeof res.data.file === "string") {
                res.data.file = res.data.file.split(';').map(item => item.trim());
                res.data.file.pop()
            }

            // 名字
            if (typeof res.data.fileName === "string") {
                res.data.fileName = res.data.fileName.split(';').map(item => item.trim());
                if (res.data.fileName.length !== 1) {
                    res.data.fileName.pop()
                }
            }
            console.log(res.data.fileName)
            if (res.data.fileName !== null) {
                let keys = res.data.fileName;
                let values = res.data.file;
                this.fileNameUrl = keys.map((item, index) => {
                    return {
                        name: item,
                        url: values[index]
                    }
                })

            }
            console.log(this.fileNameUrl)

            this.detailList = res.data;
            // console.log(this.detailList)
            newArticle(this.detailList.institutionId).then((res) => {
                this.articleList = res.data;
            });
            getInstitution(this.detailList.institutionId).then((res)=>{
                this.logo = res.data.logo
            })
        });
        const data = {
            industryType: this.$route.query.industryType,
        };
        relatedArticle(data).then((res) => {
            this.relatedList = res.data;
        });
    },
    filters: {
        timeFormat(data) {
            const date = new Date(data);
            return `${date.getFullYear()}-${
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
            }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()} `;
        },
    },
    methods: {
        goInstitutionId(id){
            // console.log(id)
          this.$router.push({
            path:'/companydetails?id='+id
          })
        },
        moremeeting() {
            this.$router.push({
                path: "/meetingsearch/?keyword=",
            });
        },

        goNewsDetail(article) {
            this.$router.push("/meetingdetails?id=" + article.id);
            // location.reload()
        },
        goMeetingInfo(related) {
            this.$router.push("/meetingdetails?id=" + related.id);
            // location.reload()
        },
        // 时间戳转换为当前时间
        timestampToTime(date) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(date);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日" + h + ":" + m;
        },
    },
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

.metting-details {
    margin-bottom: 31px;
    background: linear-gradient(#edf4f9, #f5f7f8);
}

.metting-content {
    width: 1200px;
    /* height: 2150px; */
    /* background-color: #f5f7fa; */
    display: flex;
}

/* 左侧内容 */
.metting-LeftContent {
    flex: 1;
    height: auto;
    /* background-color: #fff; */
    margin: 0;
    margin-top: 20px;
}

.introduce {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
    padding-bottom: 20px;
}

/* 左侧内容的头部 */
.metting-LeftContent-top {
    padding: 0 30px;
    position: relative;
    width: 789px;
    height: 137px;
    font-weight: 600;
}

/* 下面的横线 */
.metting-LeftContent-top::after {
    content: "";
    position: absolute;
    left: 0px;
    margin-top: 9px;
    width: 789px;
    height: 0px;
    border: 1px solid #e5e5e5;
}

.metting-LeftContent-top h3 {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.metting-LeftContent-top p {
    text-align: center;
    font-size: 12px;
    color: #676c71;
}

/* 阅读量区域 */
.metting-LeftContent-read {
    position: relative;
    margin-top: 10px;
    margin-bottom: 14px;
    height: 50px;
}

/* 时间中间那条竖线 */
.metting-LeftContent-read::after {
    content: "";
    position: absolute;
    left: 210px;
    top: 35px;
    width: 0px;
    height: 11px;
    border: 1px solid #e5e5e5;
}

.metting-LeftContent-read img {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.metting-LeftContent-read h4 {
    margin-bottom: 10px;
    font-weight: 600;
    color: #000;
}

/* 时间 和 阅读量 */
.metting-LeftContent-read span {
    font-size: 12px;
    color: #676c71;
}

/* 大图片 */
.metting-LeftContent .metting-LeftContent-pic {
    width: 730px;
    height: 412px;
    overflow: hidden;
    /* padding: 24px 30px 0px 30px; */
    padding: 0 30px;
    box-sizing: border-box;
}

.metting-LeftContent .metting-LeftContent-pic img {
    width: 670px;
    height: 457px;
}

/* 会议举办详情 */
.metting-LeftContent-content {
    width: 100%;
    background: #fff;
    padding: 20px;
    margin: 10px 0;
    border-radius: 12px;
}

.metting-LeftContent-content p {
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 300;
    color: #000;
}

.metting-LeftContent-content p span {
    font-weight: 600;
    color: #2c292c;
}

/* 文字区域 */
.metting-LeftContent .metting-LeftContent-text {
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
}

.metting-LeftContent .metting-LeftContent-text p {
    margin-bottom: 10px;
    font-size: 12px;
    color: #000;
}

.contents {
    font-size: 18px !important;
    font-weight: 600;
    color: #222;
    margin-bottom: 12px;
}

/* 左侧内容 end*/

/* 右侧内容 start*/
.metting-RightContent {
    padding: 20px;
    width: 371px;
    height: auto;
    background-color: #fff;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 12px;
}

/* 最新文章 */
.new-article {
    position: relative;
    height: auto;
    padding-bottom: 20px;
    overflow: hidden;
}

/* 最新文章文字 */
.new-article .new-article-head {
    position: absolute;
    left: 31px;
    top: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

/* 下面的橙色线 */
.new-article strong {
    display: inline-block;
    margin-bottom: 58px;
    margin-left: 30px;
    width: 65px;
    height: 9px;
    background: #eb5139;
}

/* 下面的橙色线 */
.new-article b {
    margin-bottom: 58px;
    margin-left: 30px;
    display: inline-block;
    width: 65px;
    height: 9px;
    background: #eb5139;
}

/* 最新文章的每一行 */
.new-article-content li {
    width: 346px;
    height: 100px;
    cursor: pointer;
}

.new-article-content li img {
    width: 150px;
    height: 100px;
}

/* 最新文章的每一行里面第一行的文字 */
.new-article-content li p {
    margin-left: 8px;
    margin-bottom: 20px;
    width: 180px;
    height: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}

/* 最新文章的每一行里面第二行的文字 */
.new-article-content li em {
    margin-left: 5px;
    margin-top: 25px;
    font-size: 12px;
    color: #676c71;
}

/* 右侧内容 end*/

.nav_hot {
    position: relative;
    width: 100%;
    height: 40px;
    padding-bottom: 12px;
    border-bottom: 1px solid #edf0f5;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
}

.nav_hot a {
    /* float: right; */
    font-size: 12px;
    line-height: 30px;
    text-align: right;
    color: #666;
    margin: 0;
}

.ico_h {
    display: flex;
    color: #eb5139;
    width: 100px;
    margin: 0;
}

.icon_t {
    /* color: #ff3f29; */
    font-size: 30px;
    margin: 0 10px 0 0;
}

.ico_h h4 {
    margin: 0;
    line-height: 30px;
    font-weight: 600;
}

.new-article-content li {
    width: 100%;
    height: 100px;
    cursor: pointer;
    display: flex;
    padding: 6px 0;
    box-sizing: border-box;
}

.new-article-content li img {
    width: 130px;
    height: 100%;
    margin-right: 10px;
    border-radius: 6px;
}

.new-article-content li .cont {
    margin: 0;
    padding: 10px 0px;
    box-sizing: border-box;
}

.new-article-content li .cont .title {
    width: 193px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new-article-content li .cont .text {
    font-size: 12px;
}

.new-article-content li .cont .text .time_readingvolume {
    margin: 10px 0px;
}

.meetingattachments {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
}
.meetingattachments p{
    margin: 0;
    width: 100%;
}
.meetingattachments p>span {
    font-size: 14px;
    margin-right: 5px;
    color: #222 !important;
}

.meetingsummary {
    background: #fff;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 12px;
}

.summary span {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}
</style>
