<script>
// import {defineComponent} from 'vue'
import {encrypt} from "@/utils/rsaEncrypt";
import {register} from "@/api/register/institution";
import {getCodeImg} from "@/api/login/institution";
import {getnewpassword} from "@/api/emitNewPassword/getnewpassword";

export default ({
    name: "newPassword",
    data() {
        //表单验证——自定义验证邮箱
        var checkEmail = (rule, value, callback) => {
            console.log(this.isPhone, "this.isPhone");
            const regEmail =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (regEmail.test(value)) {
                console.log(regEmail.test(value));
                return callback();
            } else {
                callback(new Error("请输入合法邮箱"));
            }
        };
        //表单验证——自定义验证密码
        var validPassword = (rule, value, callback) => {
            const reg =
                /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{6,18}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(
                    new Error(
                        "密码以字母开头 长度在6~18之间 包含字母、数字、以及特殊符号等任意两种"
                    )
                );
            }
        };
        return {
            text: "机构入驻",
            newPasswordForm: {
                username: "",
                password: "",
                againPassword: ""
            },
            codeUrl: "",
            registerRules: {
                username: [
                    {required: true, message: "请输入邮箱！", trigger: "change"},
                    {validator: checkEmail, trigger: "blur"},
                ],
                password: [
                    {required: true, message: "请输入密码!", trigger: "change"},
                    {validator: validPassword, trigger: "blur"},
                ],
            },
        };
    },
    created() {
        this.newPasswordForm.username = this.$route.query.email
        // 获取验证码
        this.getCode();
    },
    methods: {
        newOk() {
            if (this.newPasswordForm.againPassword === "" || this.newPasswordForm.password === "") {
                this.$message({
                    message: '密码不得为空',
                    type: 'warning'
                });
            }
            if (this.newPasswordForm.password !== this.newPasswordForm.againPassword) {
                this.$message.error("您两次输入的密码不一致");
            } else {
                const password = encrypt(this.newPasswordForm.password);
                console.log(password)
                getnewpassword(
                    this.newPasswordForm.username,
                    password,
                ).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                            message: "修改成功！",
                            type: "success",
                        });
                        // this.$store.dispatch("institutionLogin", res);
                        this.$router.push({
                            path: "instructionlogin",
                        });
                    } else {
                        this.$message({
                            title: "错误",
                            message: res.message,
                            type: "error",
                        });
                    }
                });
            }
        },
    },
})
</script>

<template>
    <div class="scan-bg">
        <div class="scan-center">
            <!-- 左侧小背景图 -->
            <div class="scan-img fl"></div>
            <!-- 注册信息模块 start-->
            <div class="scan-info fl">
                <!-- 个人和机构 -->
                <div class="personal">

                    <span class="institution-login">输入密码</span>
                    <!-- 机构入驻下面的线 -->
                </div>
                <!-- 注册信息模块 end-->
                <!-- 验证部分 start -->
                <div class="verification">
                    <el-form
                            ref="registerForm"
                            :model="newPasswordForm"
                            :rules="registerRules"
                    >
                        <!-- 账号/邮箱 输入框 -->
                        <el-form-item prop="username">
                            <el-input
                                    v-model="newPasswordForm.username"
                                    class="mobile-number"
                                    auto-complete="off"
                                    placeholder="请输入邮箱"
                            ></el-input>
                        </el-form-item>
                        <!--                         密码输入框-->
                        <el-form-item prop="password">
                            <el-input
                                    v-model="newPasswordForm.password"
                                    class="mobile-number"
                                    type="password"
                                    auto-complete="off"
                                    placeholder="请输入新密码"
                            ></el-input>
                        </el-form-item>
                        <!-- 验证码输入框 -->
                        <el-form-item prop="password">
                            <el-input
                                    v-model="newPasswordForm.againPassword"
                                    class="mobile-number"
                                    type="password"
                                    auto-complete="off"
                                    placeholder="请再次输入新密码"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <!-- 验证部分 end -->
                    <!-- 返回登录 -->
                    <div class="reg-account">
                        <router-link to="regpassword">返回</router-link>
                    </div>
                    <!-- 注册账号按钮 -->
                    <el-button class="log common" @click="newOk">
                        完成
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* 公共颜色 */
.verification-code-obtain {
    width: 128px;
    height: 44px;
    font-size: 16px;
    text-align: center;
    border-radius: 20px;
    border: 0;
    cursor: pointer;
}

.common {
    color: #fff;
    background-color: #eb5139;
}

/* 整体大背景图 */
.scan-bg {
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/login-background.png") center no-repeat;
    background-size: cover;
    padding-top: 100px;
    overflow: hidden;
}

/* 中间区域的模块 */
.scan-bg .scan-center {
    display: flex;
    align-items: flex-start;
    width: 1238px;
    height: 682px;
    margin: 0 auto 0;
    border-radius: 8px;
    overflow: hidden;
}

/* 左侧小背景图 */
.scan-bg .scan-center .scan-img {
    width: 49.99%;
    height: 100%;
    background: url("../../../assets/images/login-bg.png") no-repeat center center;
    background-size: contain;
}

/*  注册信息模块start  */
.scan-bg .scan-center .scan-info {
    position: relative;
    width: 49.99%;
    height: 100%;
    background-color: #fff;
}

/* 个人和机构 */
.personal {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 81px;
    width: 407px;
    height: 34px;
    font-size: 24px;
    color: #333;
}

/* 个人注册 */
.personal .personal-login {
    width: 96px;
    font-weight: 600;
}

/* 中间那条竖线 */
.personal .personal-line {
    width: 0px;
    height: 54px;
    margin: 106px;
    border: 1px solid #d0d0d0;
}

/* 机构入驻 */
.personal .institution-login {
    width: 96px;
    font-weight: 600;
}

/* 机构入驻下面的橙色线 */
.institution-login-line {
    position: absolute;
    top: 36px;
    right: 0;
    width: 94px;
    height: 10px;
    color: #333;
    background-color: #eb5139;
}

/*  登录信息模块end  */

/*  验证部分 start*/
/* 手机号、密码输入框 */
.mobile-number >>> .el-input__inner {
    width: 499px;
    height: 44px;
    outline: none;
    font-size: 16px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 验证码输入框 */
.verification {
    width: 499px;
}

.verification /deep/ .verification-code {
    width: 321px;
    height: 44px;
    margin-right: 50px;
}

.verification-code >>> .el-input__inner {
    font-size: 16px;
    height: 44px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 获取验证码图片 */
.verification-code-obtain img {
    float: left;
    width: 128px;
    height: 44px;
}

/*  验证部分 end*/

/* 返回登录 */
.reg-account {
    width: 64px;
    height: 22px;
    margin-top: 10px;
    margin-left: 5px;
}

.reg-account a {
    font-size: 16px;
    color: #eb5139;
}

/* 发送验证码的文字 */
.agreement {
    margin-top: 10px;
    margin-left: 5px;
    width: 470px;
    height: 20px;
    line-height: 16px;
    font-size: 14px;
    color: #adadad;
}

/* 注册账号按钮 */
.log {
    width: 499px;
    height: 44px;
    border-radius: 44px;
    margin-top: 30px;
    border: 0;
}
</style>
