<template>
    <div>
        <span class="detail-head">编辑信息</span>
        <hr
                style="
        margin-top: 10px;
        background-color: #e5e5e5;
        height: 1px;
        border: none;
      "
        />
        <div class="file-box">
            <span class="head-btn"><i style="color: #ff6767">*</i>课程封面设置</span>
            <span class="head-tabs"
            >(格式jpeg丶png，文件大小<5MB，建议尺寸>1146*717,最低尺寸>960*600)</span
            >
        </div>
        <el-upload
                action
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-change="handleVideo"
                :before-upload="beforeUploadVideo"
                style="overflow: hidden"
        >

            <img style="width: 100%" v-if="imageUrl" :src="imageUrl" class="avatar" alt=""/>
        </el-upload>
<!--        <span class="head-btn"> <i style="color: #ff6767">*</i>课程形式 </span>-->
<!--        <div style="margin: 5px 0">-->
<!--&lt;!&ndash;            <el-radio v-model="radio" label="1">系列课程</el-radio>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-radio v-model="radio" label="0">单个课程</el-radio>&ndash;&gt;-->
<!--            <el-radio-group v-model="radio">-->
<!--                <el-radio :label="1">系列课程</el-radio>-->
<!--                <el-radio :label="0">单个课程</el-radio>-->
<!--            </el-radio-group>-->
<!--        </div>-->
        <span class="head-btn"> <i style="color: #ff6767">*</i>课程名称 </span>
        <br/>
        <el-col :span="17">
            <el-input
                    type="text"
                    placeholder="请输入内容"
                    v-model="className"
                    maxlength="41"
                    show-word-limit
            ></el-input>
        </el-col>
        <br/>
        <br/>
        <span class="head-btn"> <i style="color: #ff6767">*</i>课程介绍 </span>
        <br/>
        <el-input
                type="textarea"
                resize="none"
                placeholder="请输入内容"
                v-model="classTextarea"
                maxlength="200"
                show-word-limit
        ></el-input>
        <br/>
        <span class="head-btn">
<!--      <i style="color: #ff6767">*</i>-->
      课程宣传长图
    </span>
        <span class="head-tabs">(该图片显示在课程介绍文字下方)</span>
        <br/>
        <el-upload
                action
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :limit="5"
                :on-change="handleVideo2"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeUploadVideo"
                style="overflow: hidden"
        >
            <img
                    style="width: 100%"
                    v-if="imageUrl2"
                    :src="imageUrl2"
                    class="avatar"
             alt=""/>
        </el-upload>
        <span class="head-btn">
      <i style="color: #ff6767">*</i>
      行业选择
    </span>
        <br/>
        <!--    <el-col>-->
        <!--      <el-cascader-->
        <!--        :options="IndustryList"-->
        <!--        :props="{-->
        <!--          expandTrigger: 'hover',-->
        <!--          value: 'code',-->
        <!--          label: 'cateName',-->
        <!--          checkStrictly: true,-->
        <!--        }"-->
        <!--        v-model="selectedOptions"-->
        <!--        @change="selecetH"-->
        <!--        clearable-->
        <!--        size="big"-->
        <!--      ></el-cascader>-->
        <!--    </el-col>-->
        <el-select v-model="selectedOptions" placeholder="请选择">
            <el-option
                    v-for="item in IndustryList"
                    :key="item.id"
                    :label="item.cateName"
                    :value="item.code">
            </el-option>
        </el-select>
        <br/>
        <span class="head-btn"> <i style="color: #ff6767">*</i>课程性质 </span>
        <br/>
        <el-select v-model="value" placeholder="请选择">
            <el-option
                    v-for="item in specialList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    @click.native="changeH(item)"
            ></el-option>
        </el-select>
        <br/>
        <span class="head-btn"> <i style="color: #ff6767">*</i>课程定价 </span>
        <br/>
<!--        <el-col :span="4">-->
<!--            <el-input-->
<!--                    type="text"-->
<!--                    style="width: 150px"-->
<!--                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"-->
<!--                    v-model="classPirce"-->
<!--                    :disabled="!havePrice"-->
<!--            ></el-input>-->
<!--        </el-col>-->
        <el-col :span="4">
            <el-input
                type="text"
                style="width: 150px"
                oninput="value=value.replace(/^\.+|(\d+)\.(\d{3})+/g, '$1.$2').replace(/[^\d.]/g, '').replace(/^(\d+\.\d{2}).*/, '$1')"
                v-model="classPirce"
                :disabled="!havePrice"
            ></el-input>
        </el-col>

        <span class="class-price">元</span>
        <br/>
        <span style="margin-top: 1px" class="head-btn">
      <i style="color: #ff6767">*</i>是否付费
    </span>
        <div style="margin: 5px 0">
<!--            <el-radio v-model="radio2" label="1">付费</el-radio>-->
<!--            <el-radio v-model="radio2" label="2">会员免费</el-radio>-->
<!--            <el-radio v-model="radio2" label="3">免费</el-radio>-->
            <el-radio-group v-model="radio2">
                <el-radio :label="1">付费</el-radio>
                <el-radio :label="2">会员免费</el-radio>
                <el-radio :label="3">免费</el-radio>
            </el-radio-group>
            <br/>
<!--            <span class="head-btn"> <i style="color: #ff6767">*</i>讲师姓名 </span>-->
<!--            <br/>-->
<!--            <el-col :span="5">-->
<!--                <el-input type="text" v-model="name" :disabled="true"></el-input>-->
<!--            </el-col>-->
        </div>
<!--        <br/>-->
<!--        <br/>-->
<!--        <span class="head-btn"> <i style="color: #ff6767">*</i>讲师单位 </span>-->
<!--        <br/>-->
<!--        <el-col :span="15">-->
<!--            <el-input type="text" v-model="teacherUnit"></el-input>-->
<!--        </el-col>-->
<!--        <br/>-->
<!--        <br/>-->
<!--        <button-->
<!--                style="background-color: #47a7ff"-->
<!--                class="commit-btn"-->
<!--                @click="goAudit(0)"-->
<!--        >-->
<!--            保存为草稿-->
<!--        </button>-->
        <button

                v-if="eidtId"
                style="background-color: #eb5139"
                class="commit-btn"
                @click="okEdit"
        >
            保存
        </button>
        <button
                v-else
                style="background-color: #eb5139"
                class="commit-btn"
                @click="goAudit(1)"
        >
            下一步
        </button>

    </div>
</template>

<script>
import {direct, sts} from "@/api/direct.js";
// import {client, getFileNameUUID} from "../EditClass/alioss";
import { ossDirectVideo, uuid} from "@/utils/ossDirect.js";
import {getHomeProfessionCate} from "@/api/home/home";
import {getSpecialList} from "@/api/study";
import {userPostClassForm, getUserCourseInfo} from "@/api/user/addclass";
import {getUserInfo} from "@/api/user/userinfo";
import {userCourse} from "@/api/userCourse";
import {saveUpdateCourseInfo} from "@/api/EditInformationSaving";
import {isAuthentication} from "@/api/login";

export default {
    data() {
        return {
            videoId:'',
            name: '',
            classPirce: 0, //课程名字
            className: "", //课程名称
            classTextarea: "", //课程介绍
            radio: 1, //单选按钮状态
            radio2: 1, //单选按钮状态
            imageUrl: "", //封面图片地址
            imageUrl2: "",
            imageUrlList: [], //多张图数组
            teacherName: "", //教师姓名
            teacherUnit: "", //讲师单位
            dialogImageUrl: "",
            dialogVisible: false,
            props: {multiple: false},
            value: "",
            dynamicTags: [], //标签数组
            inputVisible: false,
            inputValue: "",
            selectedOptions: "",
            IndustryList: [],
            backUserInfo: {},
            specialList: [],
            valueId: "",
            havePrice: true,
            eidtId:'',
            eidtInfo:{

            }
        };
    },
    watch: {
        radio2: function (newVal, oldVal) {
            this.havePrice = newVal !== 3;
        },
    },
    mounted() {
        this.eidtId=this.$route.query.id
        getUserInfo().then((res) => {
            // console.log(res)
            if (res.code === 200) {
                this.videoId=res.data.id
            }
        })
        isAuthentication().then((res)=>{
            if (res.code === 200){
                this.name = res.data.name
            }
        })
        getSpecialList().then((res) => {
            if (res.code === 200) {
                // console.log(res)
                this.specialList = res.data;
            }
        });
        getHomeProfessionCate().then((res) => {
            if (res.code === 200) {
                // this.IndustryList = this.getTreeData(res.data);
                this.IndustryList = res.data
            }
        });
        if (this.$route.query.id) {
            getUserCourseInfo(this.$route.query.id).then((res) => {
                if (res.code === 200) {
                    // console.log(res.data)
                    this.backUserInfo = res.data;
                    this.radio = this.backUserInfo.catena.toString();
                    this.imageUrl = this.backUserInfo.surfacePlotUrl;
                    this.className = this.backUserInfo.headline;
                    this.classTextarea = this.backUserInfo.courseDescription;
                    this.imageUrl2 = this.backUserInfo.propagandaPicture;
                    this.selectedOptions = this.backUserInfo.industryType;
                    this.inputValue = this.backUserInfo.curriculumNature;
                    this.classPirce = this.backUserInfo.originalPrice;
                    this.radio2 = this.backUserInfo.isPay;
                    this.teacherName = this.backUserInfo.teacherName;
                    this.teacherUnit = this.backUserInfo.affiliated;
                    this.value=this.backUserInfo.specialId
                }
            });
        }
    },
    methods: {
        okEdit(){
            // console.log(this.value.id)
            saveUpdateCourseInfo(this.teacherUnit,this.radio,this.classTextarea,0,this.className,this.$route.query.id,this.selectedOptions,this.radio2,this.classPirce,this.imageUrl2,this.value.id,1,this.imageUrl,this.teacherName).then((res)=>{
                if (res.code === 200){
                    this.$message({
                        message: res.data,
                        type: 'success'
                    });
                    this.$router.push(
                        '/user/course'
                    )
                }else{
                    this.$message.error(res.data)
                }
            })
        },
        changeH(item) {
            // console.log(item)
            this.valueId = item.id;
        },
        selecetH(value) {
            this.selectedOptions = value[value.length - 1];
            // console.log(value);
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            // console.log(this.imageUrl);
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrlList.push(URL.createObjectURL(file.raw));
            // console.log(this.imageUrlList);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            // console.log(dialogImageUrl);
        },
        handleClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
        uploadSuccess1(file) {
            // console.log(file);
        },
        goAudit(state) {
            if (!this.imageUrl) {
                this.$message.error({
                    title: "错误",
                    message: "课程封面不能为空！",
                });
            } else if (!this.className) {
                this.$message.error({
                    title: "错误",
                    message: "课程名称不能为空！",
                });
            } else if (!this.classTextarea) {
                this.$message.error({
                    title: "错误",
                    message: "课程简介不能为空！",
                });
            }
                else if (!this.value) {
                  this.$message.error({
                    title: "错误",
                    message: "课程性质不能为空！",
                  });
                }
                // else if (!this.imageUrl2) {
                //   this.$message.error({
                //     title: "错误",
                //     message: "课程宣传长图不能为空！",
                //   });
            // }
            else if (!this.selectedOptions) {
                this.$message.error({
                    title: "错误",
                    message: "行业选择不能为空！",
                });
            } else if (this.havePrice === true && !this.classPirce) {
                this.$message.error({
                    title: "错误",
                    message: "课程定价不能为空！",
                });
            } else if (this.havePrice === true && this.classPirce === 0) {
                this.$message.error({
                    title: "错误",
                    message: "课程定价不能为0！",
                });
            } else if (!this.name) {
                this.$message.error({
                    title: "错误",
                    message: "讲师姓名不能为空！",
                });
            }
            // else if (!this.teacherUnit) {
            //     this.$message.error({
            //         title: "错误",
            //         message: "讲师单位不能为空！",
            //     });
            // }
            else {
                userPostClassForm(
                    this.imageUrl,
                    this.radio,
                    this.className,
                    this.classTextarea,
                    this.imageUrl2,
                    this.selectedOptions,
                    this.valueId,
                    this.classPirce,
                    this.radio2,
                    this.name,
                    "",
                    state
                ).then((res) => {
                    if (res.code === 200) {
                        userCourse(this.videoId).then((res)=>{
                            // console.log(res)
                            if(res.code===200){
                                this.$router.push({
                                // /user/videoup?id=" + this.$route.query.id
                                    path:`/user/videoup?id=${res.data[0].id}`
                                    // path:`/user/videomanagement?id=${res.data[0].id}`
                                });
                            }
                        })
                        this.$message({
                            title: "成功",
                            message: res.data,
                            type: "success",
                        });

                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        handleVideo(file) {
            let beforeBool = true;
            if (beforeBool) {
                this.videoLoading = true;
                direct().then((res) => {
                    // res = res.data;
                    if (res.code === 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb,
                            });
                            return;
                        }
                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then((con) => {
                            // con = con.data;
                            if (con.code === 200) {
                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.raw,
                                    (suc) => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        console.log(
                                            "url",
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key
                                        );
                                        this.imageUrl =
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;
                                    },
                                    (err) => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb,
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        handleVideo2(file) {
            let beforeBool = true;
            if (beforeBool) {
                this.videoLoading = true;
                direct().then((res) => {
                    // res = res.data;
                    if (res.code === 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb,
                            });
                            return;
                        }
                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then((con) => {
                            // con = con.data;
                            if (con.code === 200) {
                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.raw,
                                    (suc) => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        console.log(
                                            "url",
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key
                                        );
                                        this.imageUrl2 =
                                            "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;
                                    },
                                    (err) => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb,
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        beforeUploadVideo() {
            return true;
        },
        getTreeData(data) {
            for (var i = 0; i < data.length > 0; i++) {
                if (data[i].children == null || data[i].children.length <= 0) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
    },
};
</script>
<style>
.el-notification {
    width: 225px;
}
</style>
<style scoped>
.commit-btn {
    margin-top: 42px;
    width: 134px;
    height: 33px;

    text-align: center;
    line-height: 33px;
    font-size: 14px !important;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 4px;
    margin-right: 33px;
    margin-bottom: 33px;
}

.class-price {
    display: inline-block;
    color: #000000;
    height: 40px;
    padding-top: 6px;
}

/**标签卡样式 */
.add-icon {
    font-size: 26px;
    font-weight: 100;
    vertical-align: middle;
    color: #676c71;
}

>>> .el-tag__close {
    color: #eb5139;
    font-size: 22px;
}

>>> .el-tag--small {
    width: 106px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #eb5139;
    background-color: #ffdfda;
    border: 1px solid #ff9a8a;
}

.el-tag + .el-tag {
    margin-left: 15px;
    width: 106px;
    height: 40px;
    display: inline-block;
}

.button-new-tag {
    margin-left: 10px;
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-block;
    font-size: 16px;
    transform: translateY(-3px);
    color: #676c71;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
}

/**标签卡样式结束 */
>>> .el-upload--picture-card {
    width: 238px;
    height: 125px;
    position: relative;
    background-image: url(../../../assets/images/Group255.png);
    background-size: 100% 100%;
}

>>> .el-textarea {
    width: 892px;
    height: 165px;
    margin-top: 5px;
}

>>> .el-textarea__inner {
    height: 165px;
}

/**上传部分样式 */
.avatar-uploader .el-upload {
    border: 1px dashed black;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

>>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #eb5139;
    background: #eb5139;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 238px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    border: 1px dashed gray;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/**上传部分样式结束 */
.head-tabs {
    width: 394px;
    height: 17px;
    font-size: 12px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e5e5e5;
    line-height: 14px;
}

.head-btn {
    display: inline-block;
    width: 100px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
    margin-top: 20px;
}

.detail-head {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 19px;
}
</style>
