<template>
  <!-- 修改名称 -->

  <!-- 右侧内容 -->
  <div class="MeetingAdministration-rightContent fr">
    <!-- 头部 -->
    <div class="MeetingAdministration-rightContent-top">
      <h4 class="fl">
        <router-link
          to="name"
          style="color:#676c71"
        >帐号设置/修改名称</router-link>
      </h4>
    </div>
    <!-- 中间 -->
    <div class="MeetingAdministration-rightContent-center">
      <!-- 中心内容 -->
      <div class="center-content">
        <el-form
          :label-position="labelPosition"
          label-width="70px"
          :model="formLabel"
          :rules="rules"
          ref="formLabel"
        >
          <el-form-item
            label="机构名称:"
            prop="nickName"
          >
            <el-input
              type="text"
              placeholder="请输入名称"
              v-model="formLabel.nickName"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 下面的按钮 -->
        <el-button
          class="center-draft fl"
          @click.native="ChangePassword"
        > 确定 </el-button>
      </div>

    </div>

  </div>

</template>

<script>
import { institutionName } from '@/api/institutional/user'
import { encrypt } from "@/utils/rsaEncrypt";
export default {
  name: 'ModifyPassword',
  data () {
    return {
      labelPosition: 'left',
      formLabel: {
        nickName: '',
      },
      rules: {
        nickName: [
          { required: true, message: '请输入机构名称！', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    ChangePassword () {
      const data={
         name:this.formLabel.nickName
      }
      this.$confirm("确认修改机构名称, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        institutionName(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功,请重新登录！"
            });
            this.$router.push({
              path: '/instructionlogin'
            })
          } else {
            this.$notify({
              type: "error",
              message: "修改失败！"
            });
          }
        })
      })
    }

  }

}
</script>

<style scoped>
/* 右侧内容 */
.MeetingAdministration-rightContent {
  height: 1000px;
}
.MeetingAdministration-rightContent-top {
  position: relative;
  margin-bottom: 10px;
  width: 992px;
  height: 34px;
}
.MeetingAdministration-rightContent-top::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 992px;
  height: 0px;
  opacity: 1;
  border: 1px solid #e5e5e5;
}
/* 会议管理 */
.MeetingAdministration-rightContent-top h4 {
  margin-top: 5px;
  width: 137px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #676c71;
}

/* 中心内容的上部 */
.center-top {
  width: 992px;
  height: 26px;
}
.center-top .center-top-text {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
/* 中心内容 */
/* 左边的label框 */
.center-content >>> .el-form-item__label {
  padding-right: 0px;
  width: 75px !important;
}
/* 包着label和input框的盒子 */
.center-content >>> .el-form-item__content {
  margin-bottom: 8px;
  margin-left: 75px !important;
  width: 360px;
  height: 30px;
}
/* input框 */
.center-content >>> .el-input--small {
  outline: 0;
}
.center-content >>> .el-form-item__error {
  padding-top: 10px;
  width: 360px;
}
.center-content >>> .el-input__inner {
  padding-left: 5px;
  width: 360px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  color: #333;
  border: 1px solid #e5e5e5;
  /* -webkit-text-security: disc; */
}
/* 两个框的距离 */
.center-content >>> .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
/* 下面的按钮 */
.center-draft {
  margin-top: 11px;
  width: 83px;
  height: 28px;
  text-align: center;
  background: #eb5139;
  border-radius: 4px;
  color: #fff;
  border: 0;
}
</style>
