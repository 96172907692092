import Cookies from 'js-cookie'
import Config from '@/settings'

const TokenKey = Config.TokenKey

export function getToken() {
  if (localStorage.getItem("COMPANY_AUTH_INFO")) { return localStorage.getItem("COMPANY_AUTH_INFO").replace(/\"/g, ""); }
  else if (localStorage.getItem("COMPANY_AUTH_INFO_ONE")) { return localStorage.getItem("COMPANY_AUTH_INFO_ONE").replace(/\"/g, ""); }
  return "";
}

export function setToken(token, rememberMe) {
  console.log(TokenKey, token)
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function isUser() {
  let userRoles = [];
  if (localStorage.getItem("USER_ROLES_INFO")){
    userRoles = JSON.parse(localStorage.getItem("USER_ROLES_INFO"));
  }
  if(userRoles.indexOf("user") >= 0) {
    return true;
  }
  return false;
}

export function isInstitution() {
  let userRoles = [];
  if (localStorage.getItem("USER_ROLES_INFO")){
    userRoles = JSON.parse(localStorage.getItem("USER_ROLES_INFO"));
  }
  if(userRoles.indexOf("Institution") >= 0) {
    return true;
  }
  return false;
}