import request from '@/utils/request'


export function occurCateList() {
    return request({
        url: 'api/comment/subsetCate/occurCateList',
        method: 'get'
    })
}

//最新的
export function latestAccidents() {
    return request({
        url: 'api/accident/classify/latestAccidents',
        method: 'get'
    })
}

//行业分类
export function getPopular(id) {
    return request({
        url: `api/accident/classify/getPopular?industryType=${id}`,
        method: 'get'
    })
}

//案例列表
export function getListPopular(data) {
    return request({
        url: `api/accident/classify/getListPopular`,
        method: 'post',
        params:data
    })
}

//详情页
export function getByDetailsId(id) {
    return request({
        url: `api/accident/classify/getByDetailsId?id=${id}`,
        method: 'get'
    })
}
//文件下载
export function getCaseId(id) {
    return request({
        url: `api/accident/classify/getCaseId`,
        method: 'post',
        params:{id:id}
    })
}

// 同类型
export function sameType(occurId) {
    return request({
        url: `api/accident/classify/sameType?occurId=${occurId}`,
        method: 'get',

    })
}
