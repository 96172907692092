
const state = {
  institutionToken: sessionStorage.getItem("COMPANY_AUTH_INFO_ONE"),
}

const mutations = {
  INSTITUTIONLOGIN(state, data) {
    state.institutionToken = data.token
    sessionStorage.setItem("COMPANY_AUTH_INFO_ONE", data.token)
  },
  // USERLOGOUT(state) {
  //   state.token = ''
  //   sessionStorage.removeItem('COMPANY_AUTH_INFO')

  // }


}

const actions = {
  //登录业务
  institutionLogin({ commit }, data) {
    //服务器下发token，用户唯一标识符(uuid)
    //将来经常通过带token找服务器要用户信息进行展示

    //用户已经登录成功且获取到token
    commit("INSTITUTIONLOGIN", data);
    //持久化存储token
  },
  // userLogOut({ commit }) {
  //   commit("USERLOGOUT", data);
  // }



}

export default {
  state,
  mutations,
  actions,

};
