import request from '@/utils/request'

export function getUserNotice(current, size, type) {
    return request({
        url: `api/notice/list`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'current': current,
            'size': size,
            'type': type,

        }
    })
}
export function DeleteUserMessage(id) {
    return request({
        url: `api/notice/delete`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'id': id,


        }
    })
}
export function jigou() {
    return request({
        url: 'api/institutionInfo/echoAuthentication',
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function insVip(){
    return request({
        url:`api/institutionInfo/institutionVipTime`,
        method:'get'
    })
}
