import request from '@/utils/request'

export function getUserVedioList(courseId, current, size) {
    return request({
        url: `api/personalUser/selectHourAll`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            courseId: courseId,
            current: current,
            size: size
        }
    })
}

export function delVedio(id) {
    return request({
        url: `api/personalUser/delHour`,
        // url: "api/meeting/searchMeeting",
        method: 'delete',
        // data
        params: {
            'id': id,
        }
    })
}