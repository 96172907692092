import request from '@/utils/request'

export function userPostClassForm(surfacePlotUrl, catena, headline, courseDescription, propagandaPicture, industryType, specialId, originalPrice, isPay, teacherName, affiliated, state) {
    return request({
        url: `api/personalUser/saveCourseInfo`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data: {
            'surfacePlotUrl': surfacePlotUrl,
            'catena': catena,
            'headline': headline,
            'courseDescription': courseDescription,
            'propagandaPicture': propagandaPicture,
            'industryType': industryType,
            'specialId': specialId,
            'originalPrice': originalPrice,
            'isPay': isPay,
            'teacherName': teacherName,
            'affiliated': affiliated,
            'state': state

        }
    })
}

export function getUserCourseInfo(id) {
    return request({
        url: `api/personalUser/selCourseInfo?id=${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function listSaveCourseInfo(id) {
    return request({
        url: `api/personalUser/listSaveCourseInfo?id=${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}
