<template>
  <div class="main">
    <div>
      <span class="detail-head">我的收藏</span>
      <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none" />
      <button class="head-btn">课程收藏</button>
    </div>
    

    <div class="tea-box">
      <ul class="hot-courses-pic">
        <el-empty v-if="myTeachersData.records && !myTeachersData.records.length" description="暂无数据"></el-empty>
        <!-- 图片 -->
        <li
          class="p-box"
          v-for="item in myTeachersData.records"
          :key="item.id"
          @click="goCourseDetails(item.id)"
        >
          <img class="cla-img" :src="item.surfacePlotUrl" alt />
          <p class="hot-font">{{item.headline}}</p>
        </li>
      </ul>
    </div>
    <div class="Pga" v-if="myTeachersData.total>9">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :page-size="9"
        :current-page.sync="currentPage"
        :total="myTeachersData.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Config from "@/settings";

import { getUserCollection } from "@/api/user/collection";
export default {
  data() {
    return {
      currentPage: 1,
      myTeachersData: {},
      picUrl: ""
    };
  },
  mounted() {
    this.picUrl = Config.aliyunBaseUrl;

    this.handleCurrentChange(1);
  },
  methods: {
    handleCurrentChange(val) {
      getUserCollection(val, 9).then(res => {
        if (res.code == 200) {
          this.myTeachersData = res.data;
        }
      });
    },
    goCourseDetails(id) {
      this.$router.push("/coursedetails?id=" + id);
    }
  }
};
</script>

<style scoped>
.tea-box {
  width: 100%;
  overflow: hidden;
}
.main {
  width: 100%;
  min-height: 588px;
}
.Pga {
  display: flex;
}
.hot-courses-pic {
  display: flex;
  flex-wrap: wrap;
}
.hot-font {
  margin: 10px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-box {
  width: 28%;
  border: 1px solid #e5e5e5;
  border-radius: 11px;
  margin: 10px 13px;
}
.cla-img {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
}
.head-btn {
  display: inline-block;
  width: 100px;
  height: 26px;
  background: #eb5139;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 10px 5px;
  font-size: calc(100vw * 16 / 1920);
  color: white;
  cursor: pointer;
  padding-bottom: 2px;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: calc(100vw * 16 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
</style>