export const regulationsData = [
    {
        id: 1,
        title: "部门规章",
        butText: '展开全部',
        trggleBtn: false,
        icon: 'el-icon-caret-bottom',
        list: [
            // { id: 1, title: "住房和城乡建设部" },
            // { id: 2, title: "住房和城乡建设部" },
            // { id: 3, title: "住房和城乡建设部" },
            // { id: 4, title: "城乡建设部" },
            // { id: 5, title: "城乡建设部" },
            // { id: 6, title: "城乡建设部" },
            // { id: 7, title: "城乡建设部" },
            // { id: 8, title: "城乡建设部" },
            // { id: 9, title: "城乡建设部" },
            // { id: 10, title: "城乡建设部" },
            // { id: 11, title: "城乡建设部" },
            // { id: 12, title: "城乡建设部" },
        ],
    },
    {
        id: 2,
        title: "地方政府规章",
        butText: '展开全部',
        trggleBtn: false,
        icon: 'el-icon-caret-bottom',
        list: [
            // { id: 1, title: "城乡建设部" },
            // { id: 2, title: "城乡建设部" },
            // { id: 3, title: "城乡建设部" },
            // { id: 4, title: "城乡建设部" },
            // { id: 5, title: "城乡建设部" },
            // { id: 6, title: "城乡建设部" },
            // { id: 7, title: "城乡建设部" },
            // { id: 8, title: "城乡建设部" },
            // { id: 9, title: "城乡建设部" },
            // { id: 10, title: "城乡建设部" },
            // { id: 11, title: "城乡建设部" },
            // { id: 12, title: "城乡建设部" },
        ],
    },
]
export const ZK = '展开全部'
export const SQ = '收起'
export const SearchCondition = [
    // {
    //     id: '01',
    //     title: '排序',
    //     list: [{ id: 3, cateName: '不限', code: '' }, { id: 1, cateName: '按相关度' }, { id: 2, cateName: '按时间' }]
    // },
    {
        id: '01',
        title: '搜索位置',
        list: [{ id: 1, cateName: '不限', code: '' }, { id: 2, cateName: '标题', code: 0 }, { id: 3, cateName: '全文', code: 1 }]
    },
    {
        id: '02',
        title: '日期筛选',
        list: [{ id: 3, cateName: '不限', code: '' }, { id: 1, cateName: '一月内', code: 0 }, { id: 2, cateName: '一年内', code: 1 }]
    },
    // {
    //     id: '04',
    //     title: '行业类型',
    //     list: [{
    //         id: -1,
    //         code: "",
    //         cateName: '不限'
    //     }]
    // },
]
export const content = [
    {
        id: 1,
        title: '公路水路关键信息基础设施安全保护管理办法',
        content: `第一条 为了保障公路水路关键信息基础设施安全`,
        regulation: ['交通运输部', '交通运输部', '交通运输部', '交通运输部', '交通运输部', '交通运输部', '交通运输部', '交通运输部', '交通运输部']
    },
    {
        id: 2,
        title: '公路水路关键信息基础设施安全保护管理办法',
        content: `，根据《中华人民共和国网络安全法》《关键信息基础设施安全保护条例》等法律、行政法规，制定本办法。
第二条 公路水路关键信息基础设施的安全保护和监督管理工作，`,
        regulation: ['交通运输部']
    },
    {
        id: 3,
        title: '公路水路关键信息基础设施安全保护管理办法',
        content: `施是指在公路水路领域，一旦遭到破坏、丧失功能或者数据泄露，可能严重危害国家安全、国计民生和公共利益的重要网络设施、信息系统等。`,
        regulation: ['交通运输部']
    }
]
export const hylx = {
    id: 4,
    Condition: '行业类型',//
    list: [
        11111,
        222222,
        33333,
        444444,
        5555555,
        11111,
        222222,
        33333,
        444444,
        5555555,
        11111,
        222222,
        33333,
        444444,
        5555555,
        11111,
        222222,
        33333,
        444444,
        5555555

    ]
}