<template>
  <!-- 底部版权 -->
    <div class="footer">
        <div class="footer-content">
            <div class="about-company">
                <ul>
                    <li>
                        <i style="font-size: 20px">中安工匠</i>
                        <i style="font-size: 12px"
                        >- 弘扬工匠精神，共建安全生产工作者之家</i
                        >
                    </li>
                    <li>
                        <a style="cursor: pointer" @click="goDetails(1)">关于我们</a>&nbsp; <span>•</span>&nbsp;
                        <a style="cursor: pointer" @click="goDetails(2)">联系我们</a>&nbsp; <span>•</span>&nbsp;
                        <a style="cursor: pointer" @click="goDetails(3)">版权声明</a>&nbsp; <span>•</span>&nbsp;
                        <a style="cursor: pointer" @click="goDetails(4)">操作指南</a>&nbsp;
                    </li>
                </ul>
                <hr/>
                <p style="font-size: 12px">
                    Copyright &copy; 2021-2023 版权所有
                    <a style="font-size: 12px">京ICP备2022024457号-1</a>
                </p>
                <br/>
                <p style="font-size: 12px">咨询电话：010-82965088</p>
            </div>

            <!-- 二维码 -->
            <div class="pos-code fr">
                <!-- <div class="fl">
                  <img src="../../assets/uploads/二维码.png" width="78" height="78" alt title />
                  <span style="display:block">手机在线学课</span>
                </div>-->
                <div class="fl">
                    <img
                            src="../../assets/images/二维码1.png"
                            width="78"
                            height="78"
                            alt
                            title
                    />
                    <span style="display: block">官方微信</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goDetails(flag) {
            this.$router.push("/footermore?flag=" + flag);
        },
    },
};
</script>

<style scoped>
span,
a,
p,
i {
    color: #ffffff;
}

ul > li {
    margin: 11px 0;
}

hr {
    border: 1px solid gray;
    width: 888px;
    margin: 18px 0;
}

.footer-content {
    display: flex;
    justify-content: space-evenly;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 250px;
    width: 100%;
    background-color: rgb(49, 47, 47);
}

.footer {
    /* background-color: rgb(49, 47, 47); */
    height: 250px;
}

.pos-code {
    display: flex;
}

.fl {
    display: flex;
    flex-direction: column;
    padding-left: 44px;
    font-size: 12px;
    align-items: center;
}
</style>
