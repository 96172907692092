<template>
  <div class="submit-certification">
    <!-- 内容区域 -->
    <div class="submit-content">
      <div class="submit-HeadContent">
        <h3>提交成功</h3>
        <p>正在加急审核中~ 预计1个工作日完成。</p>
        <div class="submit-content-img">
          <img src="../uploads/CraftsmanCertification 6.png" alt />
        </div>
      </div>
      <!-- 提交按钮 -->
      <div class="submit-btn">
        <div class="submit">
<!--          <el-button class="btn1" @click="goCompany">上一步</el-button>-->
          <el-button class="btn2" @click="gohome">完成</el-button>
        </div>
      </div>
    </div>
    <div style="clear:both"></div>
  </div>
</template>

<script>

export default {
  name: "SubmitCertification",

  methods: {
    goCompany () {
      this.$router.push({
        path: '/company'
      })
    },
    gohome() {
      this.$router.push("/home");
    }
  }
};
</script>

<style scoped>
/* 内容区域 */
.submit-content {
  width: 1200px;
  height: 600px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}
/* 内容区域的头部 */
.submit-HeadContent {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  height: auto;
  font-weight: 600;
}
.submit-HeadContent h3 {
  margin-top: 60px;
  margin-bottom: 4px;
  font-size: 24px;
  color: #000;
}
.submit-HeadContent p {
  margin-top: 4px;
  width: 248px;
  height: 20px;
  font-size: 14px;
  color: #676c71;
}

.submit-HeadContent .submit-content-img img {
  margin-top: 67px;
  width: 243px;
  height: 243px;
}
.submit-btn {
  position: relative;
  top: 55px;
  display: flex;
  width: 469px;
}
.btn1 {
  width: 200px;
  height: 40px;
  border: 0;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #eb5139;
  color: #eb5139;
  margin-left: 0;
  background-color: transparent;
}
.btn2 {
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  background-color: #eb5139;
  color: #fff;
  cursor: pointer;
}
.right-btn a {
  color: #fff;
}
</style>