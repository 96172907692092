import Config from '../../setting.js';

// 判断运行环境
export function processEnv() {
	if (Config.environment === 'develop') {
		return 'dev'
	} else if (Config.environment === 'production') {
		return 'pro'
	} else {
		return 'dev'
	}
}