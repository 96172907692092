import request from '@/utils/request'
export function hotListMore(data) {
    return request({
        url: `api/recruitment/hotListMore?current=${data.current}&size=${data.size}&title=${data.title}`,
        method: 'get'
    })
}
export function professionCatelist() {
    return request({
        url: 'api/professionCate/list',
        method: 'get'
    })
}
export function hotEnterpriseMoreAll(data) {
    return request({
        url: `api/recruitment/hotEnterpriseMore?current=${data.current}&size=${data.size}&title=${data.title}`,
        method: 'get'
    })
}

export function hotEnterpriseMore() {
    return request({
        url: `api/InstitutionCouresInfo/hotInstitution`,
        method: 'get'
    })
}
