<template>
  <div class="main">
    <div class="header-card">
      <div>
        <span class="header-left">分类 :</span>
        <button
          class="o-btn1"
          :class="{ active: active1 == true }"
          @click="clickAll"
        >
          全部
        </button>
      </div>
      <div class="tabs-box">
        <button class="first-btn">按行业</button>
        <button
          class="o-btn"
          v-for="(o, index) in ProfessionCateList"
          :key="o.id"
          @click="tabsCheck(index, o.code)"
          :class="{ active: index == active }"
        >
          {{ o.cateName }}
        </button>
      </div>
      <div class="tabs-box">
        <button class="first-btn">按专项</button>
        <button
          class="o-btn"
          v-for="(s, index) in specialList"
          :key="s.id"
          @click="tabsCheck2(index, s.id)"
          :class="{ active2: index == active2 }"
        >
          {{ s.name }}
        </button>
      </div>
    </div>

    <div class="study-detail">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部课程" name="first">
          <strong v-if="keyword">
            共匹配到
            <span>{{ CourseInfo.total }}</span>
            个“{{ keyword }}”相关的课程
          </strong>
          <el-empty
            v-if="CourseInfo.records && !CourseInfo.records.length"
            description="暂无数据"
          ></el-empty>
          <div class="cla-detail" v-else>
            <ul class="classItem">
              <li
                v-for="item in CourseInfo.records"
                :key="item.id"
                @click="goCourseDetails(item.id, item.isPay)"
              >
                <div class="imgs">
                  <img :src="item.surfacePlotUrl" alt="" class="cla-p" />
                </div>
                <div class="title" v-html="item.headline"></div>
                <div class="information">
                  <span style="font-size: 14px" class="fl"
                    >讲师：{{ item.teacherName }}</span
                  >
                </div>
                <br />
                <div class="times-card">
                  <div class="left">
                    <span>{{ timestampToTime(item.onlineTime) }}</span>
                  </div>
                  <div class="center"></div>
                  <div class="right">
                    <span v-if="item.isPay != 3">￥</span>
                    <i>{{ item.isPay == 3 ? "免费" : item.originalPrice }}</i>
                  </div>
                </div>
                <p class="public" v-show="item.isPay == 2">会员免费</p>
                <p class="public" v-show="item.isPay == 3">免费课程</p>
              </li>
            </ul>
          </div>
          <div class="Pga" v-if="CourseInfo.total > 8">
            <el-pagination
              background
              layout="prev, pager, next, jumper"
              :page-size="8"
              :current-page.sync="currentPage"
              :total="CourseInfo.total"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="会员免费" name="second">
          <strong v-if="keyword">
            共匹配到
            <span>{{ CourseInfo.total }}</span>
            个“{{ keyword }}”相关的会员免费课程
          </strong>
          <el-empty
            v-if="CourseInfo.records && !CourseInfo.records.length"
            description="暂无数据"
          ></el-empty>
          <div class="cla-detail" v-else>
            <ul class="classItem">
              <li
                v-for="item in CourseInfo.records"
                :key="item.id"
                @click="goCourseDetails(item.id)"
              >
                <div class="imgs">
                  <img :src="item.surfacePlotUrl" alt="" class="cla-p" />
                </div>
                <div class="title" v-html="item.headline"></div>
                <div class="information">
                  <span style="font-size: 14px" class="fl"
                    >讲师：{{ item.teacherName }}</span
                  >
                </div>
                <br />
                <div class="times-card">
                  <div class="left">
                    <span>{{ timestampToTime(item.onlineTime) }}</span>
                  </div>
                  <div class="center"></div>
                  <div class="right">
                    <span>￥</span>
                    <i>{{ item.originalPrice }}</i>
                  </div>
                </div>
                <p class="public" v-show="item.isPay === 2">会员免费</p>
                <p class="public" v-show="item.isPay === 3">免费课程</p>
              </li>
            </ul>
          </div>
<!--          <div class="Pga" v-if="CourseInfo.total > 10">-->
<!--            <el-pagination-->
<!--              background-->
<!--              layout="prev, pager, next, jumper"-->
<!--              :page-size="10"-->
<!--              :current-page.sync="currentPage"-->
<!--              :total="CourseInfo.total"-->
<!--              @current-change="handleCurrentChange"-->
<!--            ></el-pagination>-->
<!--          </div>-->
        </el-tab-pane>
      </el-tabs>
      <div class="serch">
        <el-input
          class="study-serch"
          v-model="search"
          placeholder="输入课程关键词"
        ></el-input>
        <i class="el-icon-search" @click="searchCourse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeProfessionCate } from "@/api/home/home";
import {
  getCourseInfo,
  getSpecialList,
} from "@/api/study";
export default {
  data() {
    return {
      activeName: "first",
      currentPage: 1,
      currentPage1: 1,
      ProfessionCateList: [],
      CourseInfo: {},
      vipInfo: {},
      active: -1,
      active1: true,
      active2: -1,
      search: "",
      specialList: [],
      searchData: {
        ProfessionCate: 0,
        SpecialList: 0,
      },
      keyword: "",
      isVip: 0,
    };
  },
  watch: {
    "$route.query.keyword": {
      handler(newVal, oldVal) {
        this.keyword = newVal;
        this.getCourseData();
      },
      deep: true,
    },
  },
  methods: {
    timestampToTime(date) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(date);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "年" + MM + "月" + d + "日";
    },
    handleClick(tab, event) {
      this.isVip = tab.index;
      this.currentPage = 1;
      this.getCourseData();
    },
    handleCurrentChange(val) {
        // console.log(val)
      this.currentPage = val;
      this.getCourseData();
    },
    tabsCheck(index, code) {
      this.active = index;
      this.active1 = false;
      this.searchData.ProfessionCate = code;
      this.currentPage = 1;
      this.getCourseData();
    },
    tabsCheck2(index, id) {
      this.active2 = index;
      this.active1 = false;
      this.searchData.SpecialList = id;
      this.currentPage = 1;
      this.getCourseData();
    },
    clickAll() {
      this.active2 = -1;
      this.active1 = !this.active1;
      this.active = -1;
      this.searchData.SpecialList = 0;
      this.searchData.ProfessionCate = 0;
      this.getCourseData();
    },
    searchCourse() {
      this.$router.push("/study?keyword=" + this.search);
    },
    goCourseDetails(id, isPay) {
      this.$router.push("/coursedetails?id=" + id + "&ispay=" + isPay);
    },
    getCourseData() {
        // console.log(this.currentPage,this.searchData.ProfessionCate,this.searchData.SpecialList)
      getCourseInfo(
        this.currentPage,
        8,
        this.searchData.ProfessionCate,
        this.searchData.SpecialList,
        this.keyword,
        this.isVip
      ).then((res) => {
        this.CourseInfo = res.data;
        this.CourseInfo = JSON.parse(JSON.stringify(res.data));
        for (let i = 0; i < this.CourseInfo.records.length; i++) {
          let { headline } = this.CourseInfo.records[i];
          this.CourseInfo.records[i].headline = headline.replace(
            this.search,
            `<span style="color:#ff2832">${this.search}</span>`
          );
        }
      });
    },
  },
  mounted() {
    getHomeProfessionCate().then((res) => {
      if (res.code === 200) {
        this.ProfessionCateList = res.data;
      }
    });

    getSpecialList().then((res) => {
      if (res.code === 200) {
        this.specialList = res.data;
      }
    });
    if (this.$route.query.keyword) {
      this.search = this.keyword = this.$route.query.keyword;
    }
    this.getCourseData();
  },
};
</script>

<style lang='scss' scoped >
.main {
  margin-bottom: 31px !important;
}
.serch {
  display: flex;
  position: absolute;
  top: 6px;
  right: 0px;
  width: 288px;
  border: #ccc 1px solid;
  border-radius: 3px;
  .study-serch {
    width: 80%;
  }
  i {
    flex: 1;
    text-align: center;
    line-height: 30px;
    background: #eb5139;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
  }
}
::v-deep .el-input__inner {
  border: none !important;
  border-color: transparent;
}

.o-btn1 {
  padding: 1px 18px;
  margin: 10px 1px;
  cursor: pointer;
}
.active {
  color: #eb5139 !important;
  background-color: #fef6f5;
  border-radius: 15px;
}
.active2 {
  color: #eb5139 !important;
  background-color: #fef6f5;
  border-radius: 15px;
}
//::v-deep .el-icon-search {
//   position: absolute;
//   top: 16px;
//   right: 20px;
//   font-size: 20px;
//   font-weight: bold;
// }
// .study-serch {
//   position: absolute;
//   top: 7px;
//   right: 0px;
//   width: 288px;
// }
.study-detail {
  position: relative;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #eb5139;
}
::v-deep .el-tabs__item {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 21px;
  margin-top: 22px;
  margin-bottom: 5px;
}
::v-deep .el-tabs__active-bar {
  background-color: #eb5139;
  height: 3px;
  margin-left: 10px;
}
.Pga {
  display: flex;
  margin-top: 31px;
}
.main {
  width: 70%;
  margin: 0 auto;
}
.header-card {
  margin: 20px 0 10px 0;
  height: 197px;
  background-color: #ffffff;
}
.header-left {
  display: inline-block;
  width: 52px;
  height: 22px;
  font-size: 18px;
  margin: 20px 0 0 20px;
  font-weight: bold;
}
.tabs-box {
  position: relative;
  margin-left: 122px;
  margin-top: 0.5%;
}
.first-btn {
  position: absolute;
  left: -50px;
  width: 65px;
  height: 22px;
  border-radius: 10px 0 10px 10px;
}

.o-btn {
  width: 8%;
  height: 22px;
  margin-left: 2%;
  background-color: #ffffff;
  font-size: calc(100vw * 14 / 1920);
  padding: 2px;
  color: #676c71;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.classItem {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}
.classItem > li {
  background-color: #ffffff;
  border-radius: 9px;
  float: left;
  margin: 0.7% 0.5% !important;
  box-shadow: 0 27px 26px #00000017;
  position: relative;
  width: 24%;
  overflow: hidden;
}
.classItem > li > .title {
  font-size: calc(100vw * 14 / 1920);
  margin: 10px;
  // margin-bottom: 20px;
  font-weight: bold;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.classItem > li > .imgs {
  background: #ccc;
  height: 150px;
  overflow: hidden;
}
.classItem > li > .imgs > img {
  width: 100%;
  height: 100%;
}
.times-card {
  display: flex;
  margin: 10px;
  color: gray;
  align-items: baseline;
}
.center {
  width: 100%;
  text-align: center;
}
.information {
  margin: 10px;
}
.left .right {
  margin: 10px;
}
.right {
  width: 100px;
}
.right > i {
  font-size: 16px;
  color: #ff843f;
}
.left > span {
  white-space: nowrap; /*强制span不换行*/
  display: inline-block; /*将span当做块级元素对待*/
  width: 99px; /*限制宽度*/
  overflow: hidden; /*超出宽度部分隐藏*/
  text-overflow: ellipsis; /*超出部分以点号代替*/
  line-height: 0.9; /*数字与之前的文字对齐*/
  font-size: 12px;
}
.public {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 64px;
  font-size: 12px;
  color: white;
  line-height: 16px;
  background-color: #e97f37;
  border-radius: 9px 0 10px 0;
  text-align: center;
  line-height: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
}
.o-btn {
  text-align: left !important;
}
</style>
