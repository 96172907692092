<template>
  <!-- 修改邮箱 -->

  <!-- 右侧内容 -->
  <div class="MeetingAdministration-rightContent fr">
    <!-- 头部 -->
    <div class="MeetingAdministration-rightContent-top">
      <h4 class="fl">
        <router-link
          to="account"
          style="color:#676c71"
        >帐号设置/修改邮箱</router-link>
      </h4>
    </div>
    <!-- 中间 -->
    <div class="MeetingAdministration-rightContent-center">
      <!-- 中心内容 -->
      <div class="center-content">
        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :model="formLabelAlign"
          :rules="formRules"
          ref="formLabelAlign"
        >
          <el-form-item
            label="输入新邮箱:"
            prop="newMailbox"
          >
            <el-input
              v-model="formLabelAlign.newMailbox"
              placeholder="请输入新邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="输入验证码:"
            prop="VerificationCode"
          >
            <el-input
              v-model="formLabelAlign.VerificationCode"
              placeholder="请输入验证码"
            >
            </el-input>
            <el-button
              v-show="show"
              class="verification-btn"
              @click="getCode"
            >获取验证码</el-button>
            <el-button
              v-show="!show"
              class="verification-btn"
              style="color:gray"
            >获取验证码{{count}}</el-button>
          </el-form-item>
        </el-form>
        <!-- 下面的按钮 -->
        <el-button
          class="center-draft fl"
          @click="Verification"
        >确定</el-button>
      </div>

    </div>
  </div>

</template>

<script>
import { sendCode, updateEmail } from '@/api/institutional/user'

export default {
  name: 'SendCode',
  data () {
    //表单验证——自定义验证邮箱
    var checkEmail = (rule, value, callback) => {
      console.log(this.isPhone, "this.isPhone")
      const regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (regEmail.test(value)) {
        console.log(regEmail.test(value));
        return callback();
      } else {
        callback(new Error("请输入合法邮箱"));
      }
    };
    return {
      show: true,
      count: '',
      timer: null,
      labelPosition: 'left',
      formLabelAlign: {
        newMailbox: '',
        VerificationCode: '',
      },
      formRules: {
        newMailbox: [
          { required: true, message: '邮箱不能为空!', trigger: 'change' },
          { validator: checkEmail, trigger: "change" },
        ],
        VerificationCode: [
          { required: true, message: "验证码不能为空!", trigger: "change" }
        ]
      }
    }
  },
  methods: {
    getCode () {
      this.$refs.formLabelAlign.validateField('newMailbox', (errorMessage) => {
        let valid = errorMessage == '' ? true : false;
        if (errorMessage !== '') {
          this.$notify.error({
            title: "错误",
            message: '请输入正确的邮箱！'
          });
        }
        if (valid == true) {
          sendCode(this.formLabelAlign.newMailbox).then(res => {
            if (res.code == 200) {
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
              this.$notify({
                type: "success",
                message: "验证码已发送,请前往邮箱查看！"
              });
            } else {
              this.$notify({
                type: "error",
                message: res.message
              });
            }

          })
        }
      })
    },
    Verification () {
      this.$confirm("确认修改邮箱, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        updateEmail(this.formLabelAlign.newMailbox, this.formLabelAlign.VerificationCode).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改邮箱成功,请重新登录！"
            });
            this.$router.push({
              path: '/institutionlogin',
            })
          } else {
            this.$notify({
              type: "error",
              message: res.message
            });
          }


        })
      })

    }

  }
}
</script>

<style scoped>
/* 右侧内容 */
.MeetingAdministration-rightContent {
  height: 1000px;
}
.MeetingAdministration-rightContent-top {
  position: relative;
  margin-bottom: 10px;
  width: 992px;
  height: 34px;
}
.MeetingAdministration-rightContent-top::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 992px;
  height: 0px;
  opacity: 1;
  border: 1px solid #e5e5e5;
}
/* 会议管理 */
.MeetingAdministration-rightContent-top h4 {
  margin-top: 5px;
  width: 137px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #676c71;
}

/* 中心内容的上部 */
.center-top {
  width: 992px;
  height: 26px;
}
.center-top .center-top-text {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
/* 中心内容 */
.center-content {
  position: relative;
}
/* 左边的label框 */
.center-content >>> .el-form-item__label {
  width: 100px !important;
  color: #969696;
  padding-right: 0px;
}
/* 包着label和input框的盒子 */
.center-content >>> .el-form-item__content {
  margin-left: 100px !important;
  width: 220px;
  height: 30px;
}
/* input框 */
.center-content >>> .el-input--small {
  outline: 0;
  margin-left: 0;
}
.center-content >>> .el-input__inner {
  width: 214px;
  height: 28px;
  line-height: 28px;
  padding-left: 5px;
  padding-right: 0px;
  font-size: 14px;
  color: #000;
  border: 1px solid #e5e5e5;
}
/* 两个框的距离 */
.center-content >>> .el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.center-content .verification-btn {
  display: inline-block;
  position: absolute;
  padding: 0 !important;
  left: 113px;
  top: 2px;
  width: 80px !important;
  height: 30px !important;
  font-size: 14px;
  color: #5297e4;
  border: 0;
  background-color: transparent;
}
/* 获取验证码 */
.GetVerificationCode {
  margin-top: 100px;
  position: relative;
}
.GetVerificationCode p {
  margin-left: 0px;
  width: 310px;
  height: 20px;
  font-size: 14px;
  color: #969696;
}
/* 左边的label框 */
.GetVerificationCode >>> .el-form-item__label {
  color: #969696;
  padding-right: 0px;
}

/* 下面的按钮 */
.center-draft {
  margin-top: 11px;
  width: 83px;
  height: 28px;
  text-align: center;
  background: #eb5139;
  border-radius: 4px;
  color: #fff;
  border: 0;
}
</style>
