<template>
  <!-- 搜会议-会议搜索结果页面 -->
    <div>
        <div class="meeting-search">
            <!-- 版心 -->
            <div class="meeting-search-container">
                <!-- 左侧 -->
                <div class="meeting-search-LeftContent fl">
                    <div class="NewsSearch-LeftSearch-top">
                        <!-- 搜索框 -->
                        <div class="NewsSearch-LeftSearch-input fl">
                            <el-input
                                    v-model="searchResults"
                                    placeholder="请输入搜索关键词"
                                    @keyup.enter.native="searchData"
                            ></el-input>
                            <el-button @click="searchData" :loading="loading">搜索</el-button>
                        </div>
                        <!-- 搜索结果 -->
                        <p class="fl">
                            共有
                            <span>{{ total }}</span
                            >条标题中包含 <span>"{{ this.searchResults }}"</span> 的会议
                        </p>
                    </div>
                    <!-- 左侧搜索结果的头部 end -->
                    <!-- 左侧搜索结果的内容 start -->
                    <ul class="NewsSearch-LeftSearch-content">
                        <el-empty
                                v-if="resultsList.records && !resultsList.records.length"
                                description="暂无数据"
                        ></el-empty>

                        <li style="cursor: pointer"
                            v-for="(s, index) in resultsList.records"
                            :key="index"
                            @click="goMeetingDetail(s)"
                        >
                            <div class="meeting_imgs">
                                <img :src="s.surfacePlotUrl" alt=""/>
                            </div>
                            <ul class="meeting_right">
                                <li class="meeting_title" v-html="s.title"></li>

                                <li class="unit">
                  <span class="text">主办单位 :</span
                  ><span class="content" v-html="s.sponsor"></span>
                                </li>
                                <li class="unit">
                  <span class="text">协办单位 :</span
                  ><span class="content" v-html="s.assistanceUnit"></span>
                                </li>
                                <li class="timeandplace">
                                    <div>
                    <span class="text">举办地点 :</span
                    ><span v-html="s.hostPlace"></span>
                                    </div>
                                    <div>
                    <span class="text">会议时间 :</span
                    ><span>{{ timestampToTime(s.holdingTime) }}</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- 分页 -->
                    <div class="footer-pagination" v-if="this.total != 0">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="queryInfo.pagenum"
                                :page-size="queryInfo.pagesize"
                                layout="prev, pager, next, jumper"
                                :total="total"
                                background
                        ></el-pagination>
                    </div>
                </div>
                <!-- 右侧 -->
                <RightTemplate/>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import {searchMetting} from "@/api/meeting/index";

export default {
    name: "MeetingSearch",
    data() {
        return {
            searchResults: "",
            queryInfo: {
                pagenum: 1,
                pagesize: 10,
            },
            resultsList: [], //搜索列表
            documentList: [], // 热门资料
            total: "",
            loading: false,
            param: "",
            data: ""
        };
    },
    mounted() {
        console.log(this.$route.query.keyword)
        this.param = this.$route.query.keyword;
        this.param = encodeURIComponent(this.param);
        this.data = {
            current: this.queryInfo.pagenum,
            size: this.queryInfo.pagesize,
            title: this.param,
        };
        searchMetting(this.data).then((res) => {
            this.resultsList.records = JSON.parse(JSON.stringify(res.data.records));
            console.log(this.resultsList.records)
            this.total = res.data.total;
            let searchResults = this.searchResults;
            for (let i = 0; i < this.resultsList.records.length; i++) {
                let {sponsor, title, hostPlace} = this.resultsList.records[i];
                this.resultsList.records[i].sponsor = sponsor.replace(
                    searchResults,
                    `<span style="color: #ff2832;">${searchResults}</span>`
                );
                this.resultsList.records[i].title = title.replace(
                    searchResults,
                    `<span style="color: #ff2832;">${searchResults}</span>`
                );
                this.resultsList.records[i].hostPlace = hostPlace.replace(
                    searchResults,
                    `<span style="color: #ff2832;">${searchResults}</span>`
                );
            }
        });
        if (this.$route.query.keyword != undefined) {
            this.searchResults = this.$route.query.keyword;
        } else {
            this.searchResults = "";
        }
        const data = {
            current: this.queryInfo.pagenum,
            size: this.queryInfo.pagesize,
            title: "",
        };

        searchMetting(data).then((res) => {
            this.resultsList = res.data;
            this.total = res.data.total;
        });
    },
    watch: {
        searchResults: {
            // 监视的处理函数
            handler(val) {
                this.searchData(val);
            },
        },
    },

    methods: {
        handleSizeChange(newSize) {
            console.log(newSize);
            this.queryInfo.pagesize = newSize;
            this.searchData();
        },
        // 监听page页码值改变的事件
        handleCurrentChange(newPage) {
            console.log(newPage);
            this.queryInfo.pagenum = newPage;
            this.searchData();
        },
        searchData() {
            this.loading = true;
            this.param = this.searchResults;
            this.param = encodeURIComponent(this.param);
            this.data = {
                current: this.queryInfo.pagenum,
                size: this.queryInfo.pagesize,
                title: this.param,
            };
            searchMetting(this.data).then((res) => {
                this.resultsList.records = JSON.parse(JSON.stringify(res.data.records));
                this.total = res.data.total;
                let searchResults = this.searchResults;
                for (let i = 0; i < this.resultsList.records.length; i++) {
                    let {sponsor, title, hostPlace} = this.resultsList.records[i];
                    this.resultsList.records[i].sponsor = sponsor.replace(
                        searchResults,
                        `<span style="color: #ff2832;">${searchResults}</span>`
                    );
                    this.resultsList.records[i].title = title.replace(
                        searchResults,
                        `<span style="color: #ff2832;">${searchResults}</span>`
                    );
                    this.resultsList.records[i].hostPlace = hostPlace.replace(
                        searchResults,
                        `<span style="color: #ff2832;">${searchResults}</span>`
                    );
                }
                this.loading = false;
            });
        },
        goMeetingDetail(s) {
            this.$router.push({
                path: "/meetingdetails?id=" + s.id + "&industryType=" + s.industryType,
            });
        },
        // 时间戳转换为当前时间
        timestampToTime(date) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(date);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
    },
};
</script>

<style lang="less" scoped>
.meeting-search /deep/ .el-meeting-search {
  left: 0 !important;
}

.meeting-search-container {
  width: 1200px;
  display: flex;
  margin-top: 20px;
}

/* 左侧搜索结果 */
.meeting-search-LeftContent {
  position: relative;
  width: 789px;
  padding-bottom: 20px;
  margin: 0;
  margin-bottom: 31px;
}

.NewsSearch-LeftSearch-top {
  position: relative;
  width: 789px;
  height: 90px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}

/* 搜索结果 */
.NewsSearch-LeftSearch-top p {
  position: absolute;
  top: 80px;
  left: 0;
  margin: 20px 0;
  font-size: 14px;
  color: #999;
}

.NewsSearch-LeftSearch-top p span {
  color: #333;
}

/* 搜索框 */
.NewsSearch-LeftSearch-input {
  width: 100%;
  height: 50px;
}

.el-input--small {
  height: 100%;
  width: 85%;
}

.NewsSearch-LeftSearch-input /deep/ .el-input__inner {
  float: left;
  height: 100% !important;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #eb5139;
  border-right: 0;
}

/* button按钮 */
.NewsSearch-LeftSearch-input .el-button {
  float: right;
  width: 15%;
  height: 100%;
  background: #eb5139;
  font-size: 16px;
  color: #fff;
  border: 0;
  border-radius: 0px;
}

/* 左侧搜索结果的头部 end */
.NewsSearch-upload-time h3 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 21px;
  font-weight: 600;
  color: #333;
}

.NewsSearch-upload-time > .time {
  width: 100%;
  font-size: 15px;
  line-height: 30px;
  margin: 0;
}

.NewsSearch-upload-time div {
  color: #676c71;
  line-height: 30px;
  display: inline-block;
  margin-right: 30px;
  margin-top: 10px;
}

.NewsSearch-upload-time em {
  font-size: 12px;
  color: #333333;
}

/* 分页 */
.footer-pagination {
  box-sizing: border-box;
  margin-left: 0;
  width: 789px;
  position: relative;
  top: 20px;
  height: auto;
}

.footer-pagination /deep/ .el-pagination {
  width: 490px;
  height: 24px;
  color: #555656;
}

/* 经过每一个没有选中的数字的颜色 */
.footer-pagination /deep/ .el-pager li.number {
  color: #555656;
}

/* 左箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-prev {
  background-color: transparent;
}

/* 右箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-next {
  background-color: transparent;
}

/* 数字框 */
.footer-pagination /deep/ .el-input__inner {
  width: 45px;
  height: 24px;
  border-radius: 2px 2px 2px 2px;
}
</style>
<style lang='less' scoped>
.NewsSearch-LeftSearch-content {
  background: #fff;
  margin-top: 40px;
  padding: 5px 15px 20px;

  > li {
    height: 140px;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    padding: 15px 0px;
    box-sizing: border-box;

    .meeting_imgs {
      width: 180px;
      height: 100%;
      background: red;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .meeting_right {
      width: 500px;
      //flex: 1;
      padding: 5px 0 5px 0;
      margin-left: 5px;

      li {
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 22px;

        .text {
          width: 70px;
        }
      }

      .meeting_title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "emoji";
      }

      .unit {
        display: flex;
        margin-bottom: 5px;

        .content {
          flex: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          line-height: 22px;
        }
      }

      .timeandplace {
        display: flex;

        div {
          margin: 0;
          margin-right: 35px;
          display: flex;
        }
      }
    }

    &:last-child {
      border: none;
    }
  }
}
</style>
