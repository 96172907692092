<template>
    <div class="scan-bg">
        <div class="scan-center">
            <!-- 左侧小背景图 -->
            <div class="scan-img fl"></div>
            <!-- 注册信息模块 start-->
            <div class="scan-info fl">
                <!-- 个人和机构 -->
                <div class="personal">
                    <span class="institution-login">找回密码</span>
                    <!-- 机构入驻下面的线 -->
                </div>
                <!-- 注册信息模块 end-->
                <!-- 验证部分 start -->
                <div class="verification">
                    <el-form
                            ref="registerForm"
                            :model="registerForm"
                            :rules="registerRules"
                    >
                        <!-- 账号/邮箱 输入框 -->
                        <el-form-item prop="username">
                            <el-input
                                    v-model="registerForm.username"
                                    class="mobile-number"
                                    auto-complete="off"
                                    placeholder="请输入邮箱"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="VerificationCode">
                            <el-input
                                    v-model="registerForm.VerificationCode"
                                    class="verification-code fl"
                                    auto-complete="off"
                                    placeholder="请输入验证码"
                            ></el-input>
                            <!-- 验证码图片 -->
                            <button
                                    v-show="show"
                                    @click="getcode"
                                    class="verification-code-obtain common"
                                    type="button">获取验证码
                            </button>
                            <button
                                    v-show="!show"
                                    class="verification-code-obtain common"
                                    style="background-color: gray; cursor: not-allowed"
                                    type="button"
                            >
                                获取验证码{{ count }}
                            </button>
                        </el-form-item>
                    </el-form>
                    <!-- 验证部分 end -->
                    <!-- 返回登录 -->
                    <div class="reg-account">
                        <router-link to="instructionlogin">返回登录</router-link>
                    </div>
                    <!-- 注册邮件文字 -->
                    <p class="agreement">
                        我们会发送一封邮件到您输入的邮箱，请注意查收。
                    </p>

                    <!-- 注册账号按钮 -->
                    <el-button class="log common" @click="gonewpassword">下一步</el-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>


import {forget} from "@/api/emitNewPassword/getCode";
import {getCodeAndEmail} from "@/api/emitNewPassword/gonewpassword";

export default ({
    name: "regPassword",
    data() {
        //表单验证——自定义验证邮箱
        var checkEmail = (rule, value, callback) => {
            // console.log(this.isPhone, "this.isPhone");
            const regEmail =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (regEmail.test(value)) {
                // console.log(regEmail.test(value));
                return callback();
            } else {
                callback(new Error("请输入合法邮箱"));
            }
        };
        //表单验证——自定义验证密码
        var validPassword = (rule, value, callback) => {
            const reg =
                /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{6,18}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(
                    new Error(
                        "密码以字母开头 长度在6~18之间 包含字母、数字、以及特殊符号等任意两种"
                    )
                );
            }
        };
        return {
            show: true,
            count: '',
            timer: null,
            text: "机构入驻",
            registerForm: {
                username: "",
                password: "",
                VerificationCode: "",
                uuid: "",
            },
            disabled: false,
            codeUrl: "",
            registerRules: {
                username: [
                    {required: true, message: "请输入邮箱！", trigger: "change"},
                    {validator: checkEmail, trigger: "blur"},
                ],
                VerificationCode: [
                    {required: true, message: "验证码不能为空", trigger: "blur"},
                ],
            },
        };
    },
    created() {
        // 获取验证码
        this.getCode();
    },
    methods: {
        // 获取验证码
        getcode() {
            if(!this.registerForm.username){

            }
            // console.log(this.registerForm.username)
            forget(this.registerForm.username).then((res) => {
                // console.log(res)
                if (res.code === 200) {
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                    this.$message({
                        type: "success",
                        message: "验证码已发送,请前往邮箱查看！"
                    });
                }
                if (res.code === 400) {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            });
        },
        gonewpassword() {
            getCodeAndEmail(this.registerForm.VerificationCode, this.registerForm.username).then((res) => {
                if (res.code === 200) {
                    this.$router.push({path: 'newPassword', query: {email: this.registerForm.username}})
                }
            })
        },
        ok() {
            // console.log(111)
        }
    },
})


</script>


<style scoped>
/* 公共颜色 */
.verification-code-obtain {
    width: 128px;
    height: 44px;
    font-size: 16px;
    text-align: center;
    border-radius: 20px;
    border: 0;
    cursor: pointer;
}

.common {
    color: #fff;
    background-color: #eb5139;
}

/* 整体大背景图 */
.scan-bg {
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/login-background.png") center no-repeat;
    background-size: cover;
    padding-top: 100px;
    overflow: hidden;
}

/* 中间区域的模块 */
.scan-bg .scan-center {
    display: flex;
    align-items: flex-start;
    width: 1238px;
    height: 682px;
    margin: 0 auto 0;
    border-radius: 8px;
    overflow: hidden;
}

/* 左侧小背景图 */
.scan-bg .scan-center .scan-img {
    width: 49.99%;
    height: 100%;
    background: url("../../../assets/images/login-bg.png") no-repeat center center;
    background-size: contain;
}

/*  注册信息模块start  */
.scan-bg .scan-center .scan-info {
    position: relative;
    width: 49.99%;
    height: 100%;
    background-color: #fff;
}

/* 个人和机构 */
.personal {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 81px;
    width: 407px;
    height: 34px;
    font-size: 24px;
    color: #333;
}

/* 个人注册 */
.personal .personal-login {
    width: 96px;
    font-weight: 600;
}

/* 中间那条竖线 */
.personal .personal-line {
    width: 0px;
    height: 54px;
    margin: 106px;
    border: 1px solid #d0d0d0;
}

/* 机构入驻 */
.personal .institution-login {
    width: 96px;
    font-weight: 600;
}

/* 机构入驻下面的橙色线 */
.institution-login-line {
    position: absolute;
    top: 36px;
    right: 0;
    width: 94px;
    height: 10px;
    color: #333;
    background-color: #eb5139;
}

/*  登录信息模块end  */

/*  验证部分 start*/
/* 手机号、密码输入框 */
.mobile-number >>> .el-input__inner {
    width: 499px;
    height: 44px;
    outline: none;
    font-size: 16px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 验证码输入框 */
.verification {
    width: 499px;
}

.verification /deep/ .verification-code {
    width: 321px;
    height: 44px;
    margin-right: 50px;
}

.verification-code >>> .el-input__inner {
    font-size: 16px;
    height: 44px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 获取验证码图片 */
.verification-code-obtain img {
    float: left;
    width: 128px;
    height: 44px;
}

/*  验证部分 end*/

/* 返回登录 */
.reg-account {
    width: 64px;
    height: 22px;
    margin-top: 10px;
    margin-left: 5px;
}

.reg-account a {
    font-size: 16px;
    color: #eb5139;
}

/* 发送验证码的文字 */
.agreement {
    margin-top: 10px;
    margin-left: 5px;
    width: 470px;
    height: 20px;
    line-height: 16px;
    font-size: 14px;
    color: #adadad;
}

/* 注册账号按钮 */
.log {
    width: 499px;
    height: 44px;
    border-radius: 44px;
    margin-top: 30px;
    border: 0;
}
</style>