const state = {
    userSearch: {}
}

const mutations = {
    USERSEARCHDETAILS(state, data) {
        state.userSearch = data
    }
}

const actions = {

    userSearchDetails({ commit }, data) {
        commit("USERSEARCHDETAILS", data);
    }
}


export default {
    state,
    mutations,
    actions,
};