<template>
  <!-- 版心 -->
    <div class="InstructorSearch-container">
        <!-- 分类区域 -->
        <div class="InstructorSearch-classify fl">
            <div class="InstructorSearch-classify-text1 fl">分类:</div>
            <div class="InstructorSearch-classify-text2 fl" @click="allFn">全部</div>
            <!-- 一级分类 -->
            <div class="InstructorSearch-classify-first">
                <div class="classify-IndustryClassfiy fl">行业分类</div>
                <div class="detailBox">
                    <ul class="tatol">
                        <li
                                v-for="(item, index) in classifyList"
                                :class="{ totalActive: activeOne == index }"
                                @click="showOne(index, item.code)"
                                :key="index"
                        >
                            {{ item.cateName }}
                        </li>
                        <!-- 下面的内容 -->
                    </ul>
                    <!-- 搜索结果 -->
                    <strong class="fl">
                        共匹配到
                        <span>{{ resultsList.total }}</span
                        >个相关讲师
                    </strong>
                    <ul class="classify-second-ResultsContent">
                        <el-empty
                                v-if="resultsList.records && !resultsList.records.length"
                                description="暂无数据"
                        ></el-empty>
                        <li
                                v-for="(con, index) in resultsList.records"
                                :key="index"
                                @click="goDetails(con)"
                        >
                            <div class="imgs">
                                <img :src="con.picture" alt class="lecturer-pic fl"/>
                            </div>
                            <div class="classify-second-message fr">
                                <!-- 第一行 start-->
                                <div class="classify-second-message1">
                                    <h4 class="fl" v-html="con.name"></h4>
                                    <div class="symbol mt-1">
                    <span class="position-relative you-icon">
                      <i class="flash-across"></i>
                        <!-- <a
                          class="btn btn-danger btn-sm border-0 position-relative top-0 px-2"
                          >优秀讲师</a
                        > -->
                        <span>
                            <span v-if="con.honorLabel==='0'"></span>
                            <span style="padding: 5px;border-radius: 12px;color: white;background-color: #f16053"
                                  v-if="con.honorLabel==='1'">智库专家</span>
                            <span style="padding: 5px;border-radius: 12px;color: white;background-color: #f16053"
                                  v-if="con.honorLabel==='2'">中安讲师</span>
                            <span style="padding: 5px;border-radius: 12px;color: white;background-color: #f16053"
                                  v-if="con.honorLabel==='3'">安全总监</span>
                            <span style="padding: 5px;border-radius: 12px;color: white;background-color: #f16053"
                                  v-if="con.honorLabel==='4'">注册安工</span>
                            <span style="padding: 5px;border-radius: 12px;color: white;background-color: #f16053"
                                  v-if="con.honorLabel==='5'">EHS</span>
                        </span>
                    </span>
                                    </div>
                                </div>
                                <!-- 第一行 end-->
                                <!-- 第二行 start-->
                                <div class="point float-left position-relative">
                                    <p class="ft_14">{{ con.securityField }}</p>
                                </div>
                                <!-- 第二行 end-->
                                <!-- 第三行 start-->
                                <div class="infoCourse">
                  <span class="pl-0" v-if="con.title"
                  >职称 : {{ con.title }}</span
                  >
                                    <span v-if="con.industryType"
                                    >行业 : {{ con.industryType }}</span
                                    >
                                    <span v-if="con.city">常驻城市 : {{ con.city }}</span>
                                    <span class="contact"><i class="el-icon-phone-outline"
                                                             style="margin-right: 5px"></i>联系他</span>
                                </div>
                                <!-- 第三行 end -->
                                <!-- 第四行start -->
                                <div class="labelCourse mt-2 mb-3">
                                    <i
                                            class="icon_lingyn"
                                            v-for="(item, index) in con.greatField"
                                            :key="index"
                                    >{{ item }}</i
                                    >
                                </div>
                                <!-- 第四行 end -->
                                <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;line-clamp: 2;overflow: hidden;height: 31px;white-space: break-spaces;">
                                    {{ con.introduce }}</p>
                            </div>
                        </li>
                    </ul>
                    <!-- 分页 -->
                    <div class="footer-pagination" v-if="this.total !== 0">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="queryInfo.pagenum"
                                :page-size="queryInfo.pagesize"
                                layout="prev, pager, next, jumper"
                                :total="total"
                                background
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右侧推荐讲师 start-->
        <div class="right-RecommendedLecturer fr">
            <!-- 推荐讲师 start -->
            <div class="hot-teacher fl">
                <div class="nav_hot">
                    <div class="ico_h">
                        <i class="icon_t el-icon-s-custom"></i>
                        <h4>热门工匠</h4>
                    </div>
                    <a class="more" href="javascript:;" @click="lecturer">更多 &gt;</a>
                </div>
                <!-- 图片信息区域 -->
                <ul class="hot-teacher-pic">
                    <li
                            style="cursor: pointer"
                            v-for="(pull, index) in teacherList.slice(0, 4)"
                            :key="index"
                            @click="goInstructorDetails(pull)"
                    >
                        <div class="imgs">
<!--                            <span  v-if="pull.picture.include('170133107635679149')">未上传证件照</span>-->
                            <img :src="pull.picture" alt=""/>
                        </div>
                        <div class="r">
                            <ul>
                                <li class="title">{{ pull.name }}</li>
                                <!-- <li class="imgs">
                                  <img src="../../../assets/images/金牌讲师.jpg" alt="" />
                                </li> -->
                            </ul>
                            <p style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                {{ pull.securityField.split(";").join("、") }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 推荐讲师 end -->

            <!-- 行业资讯start -->
            <div class="hot-teacher fl">
                <div class="nav_hot">
                    <div class="ico_h">
                        <i class="icon_t el-icon-notebook-2"></i>
                        <h4>行业资讯</h4>
                    </div>
                    <a class="more" href="javascript:;" @click="industryinformation"
                    >更多 &gt;</a
                    >
                </div>
                <ul class="latest-upload fl">
                    <li
                            v-for="(news, index) in newsList.records"
                            :key="index"
                            @click="goNewsDeatils(news)"
                    >
            <span
                    :class="
                index >= 3
                  ? 'sequence'
                  : index == 2
                  ? 'three'
                  : index == 1
                  ? 'two'
                  : 'one'
              "
                    class="sequence"
            >{{ (index += 1) }}</span
            >
                        <p>{{ news.messageTitle }}</p>
                    </li>
                </ul>
            </div>

            <!-- 行业资讯end -->
        </div>
        <!-- 右侧推荐讲师 end-->
        <div style="clear: both"></div>
    </div>
</template>

<script>
import {cancel, concern, searchByIndustry} from "@/api/teacher";
import {allHotTeacher, topCates} from "@/api/common";
import {getPageByType} from "@/api/news";

export default {
    name: "InstructorSearch",
    data() {
        return {
            teacherList: [], // 讲师列表
            resultsList: [], // 搜索结果列表
            queryInfo: {
                pagenum: 1,
                pagesize: 5,
            },
            queryInfoAll: {
                pagenum: 1,
                pagesize: 5,
            },
            classifyList: [], // 分类
            activeOne: null, //一级分类名称显示
            searchResults: "",
            total: 0,
            type: "",
            newsList: [], // 行业资讯列表
            keyword: "",
        };
    },
    watch: {
        "$route.query.keyword": {
            handler(newVal, oldVal) {
                // console.log(newVal, oldVal);
                if (newVal !== undefined) {
                    this.keyword = newVal;
                    const data = {
                        current: this.queryInfoAll.pagenum,
                        size: this.queryInfoAll.pagesize,
                        keyWord: newVal,
                        type: this.type,
                    };
                    searchByIndustry(data).then((res) => {
                        this.resultsList = res.data;
                        this.total = res.data.total;
                    });
                } else {
                    this.keyword = ""
                }

            },
            deep: true,
        },
    },
    mounted() {
        // console.log(this.$route.query.keyword === undefined)
        if (this.$route.query.keyword !== undefined) {
            this.searchResults = this.$route.query.keyword;
        } else {
            this.searchResults = "";
        }
        // 顶级行业列表
        topCates().then((res) => {
            if (res.code == 200) {
                this.classifyList = res.data;
                // this.classifyList
            }
        });
        // 行业分类切换
        this.getPageData()
        // var param = this.searchResults;
        // param = encodeURIComponent(param);
        // const data = {
        //     current: this.queryInfoAll.pagenum,
        //     size: this.queryInfoAll.pagesize,
        //     keyWord: param,
        //     type: this.type,
        // };
        // 搜素出来的列表
        // searchByIndustry(data).then((res) => {
        //     this.resultsList = res.data.records.map((item, index) => {
        //         // let arr = item.greatField.indexOf(";")
        //         //     ? item.greatField.split(";")
        //         //     : [...item.greatField];
        //         // item.greatField = arr.length >= 2 ? arr.slice(0, arr.length - 1) : arr;
        //         item.greatField = item.greatField.indexOf(";") ? item.greatField.split(";").map(str => str.trim()).filter(Boolean).slice(0, 9) : item.greatField;
        //         console.log(item.greatField)
        //         // console.log(item.greatField)
        //         item.securityField = item.securityField.indexOf(";") ? item.securityField.replace(/;/g, "、") : item.securityField
        //
        //     });
        //     res.data.records = res.data.records.map((item) => {
        //         // 将返回的文字数据改成布尔值
        //         item.concern == "已关注"
        //             ? (item.concern = true)
        //             : (item.concern = false);
        //         return item;
        //     });
        //     this.resultsList = JSON.parse(JSON.stringify(res.data));
        //
        //     for (var i = 0; i < this.resultsList.records.length; i++) {
        //         let {name} = this.resultsList.records[i];
        //         this.resultsList.records[i].name = name.replace(
        //             this.searchResults,
        //             `<span style="color:#ff2832">${this.searchResults}</span>`
        //         );
        //     }
        //     this.total = res.data.total;
        // });

        // 推荐讲师
        allHotTeacher().then((res) => {
            // console.log(res)
            if (res.code === 200) {
                this.teacherList = res.data;
            }
        });
        // 按行业类型查找分页
        getPageByType(data).then((res) => {
            if (res.code === 200) {
                this.newsList = res.data;
            }
        });
    },
    methods: {
        industryinformation() {
            this.$router.push({
                path: "/news",
            });
        },
        lecturer() {
            this.$router.push({
                path: "/consultingexperts",
            });
        },
        allFn() {
            this.activeOne = null;
            this.type = "";
            this.queryInfo.pagenum = 1;
            this.searchResults = ""
            this.getPageData();
        },
        showOne(index, code) {
            this.activeOne = index;
            this.type = code;
            this.queryInfo.pagenum = 1;
            this.searchResults = ""
            this.getPageData();
        },
        getPageData() {
            var param = this.searchResults;
            param = encodeURIComponent(param);
            const data = {
                current: this.queryInfo.pagenum,
                size: this.queryInfo.pagesize,
                keyWord: param,
                type: this.type,
            };
            searchByIndustry(data).then((res) => {
                this.resultsList = res.data.records.map((item, index) => {
                    // let arr = item.greatField.indexOf(";")
                    //     ? item.greatField.split(";")
                    //     : [...item.greatField];
                    item.greatField = item.greatField.indexOf(";") ? item.greatField.split(";").map(str => str.trim()).filter(Boolean).slice(0, 9) : item.greatField;
                    // console.log(item.greatField)
                    item.securityField = item.securityField.indexOf(";") ? item.securityField.replace(/;/g, "、") : item.securityField
                });
                res.data.records = res.data.records.map((item) => {
                    // 将返回的文字数据改成布尔值
                    item.concern == "已关注"
                        ? (item.concern = true)
                        : (item.concern = false);
                    return item;
                });
                this.resultsList = JSON.parse(JSON.stringify(res.data));
                this.total = res.data.total;
                for (var i = 0; i < this.resultsList.records.length; i++) {
                    let {name} = this.resultsList.records[i];
                    this.resultsList.records[i].name = name.replace(
                        this.searchResults,
                        `<span style="color:#ff2832">${this.searchResults}</span>`
                    );
                }
            });
            // searchByIndustry(data);
        },
        handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize;
            this.getPageData();
        },
        handleCurrentChange(newPage) {
            this.queryInfo.pagenum = newPage;
            this.getPageData();
        },

        // 关注，取消关注
        async btnFollow(con) {
            // console.log(con);
            const data = {
                attentionId: con.id,
            };
            try {
                if (!con.concern) {
                    // 关注老师
                    const res = await concern(data);
                    // console.log(res);
                    // 这里导致点击后对象中的值变成布尔值
                    con.concern = !con.concern;
                } else {
                    // 取消关注
                    const res = await cancel(data);
                    // console.log(res);
                    // 这里导致点击后对象中的值变成布尔值
                    con.concern = !con.concern;
                }
            } catch (err) {
                let message = "操作失败,请重试！";
                if (err.response.status === 401) {
                    message = "请登录后再试！";
                }
                this.$toast(message);
            }
        },
        goDetails(con) {
            this.$router.push({
                path:
                    "/instructordetails?id=" +
                    con.id +
                    "&industryType=" +
                    con.industryType +
                    "&workUnit=" +
                    con.workUnit,
            });
        },

        goInstructorDetails(pull) {
            // console.log(1111)
            this.$router.push({
                path:
                    "/instructordetails?id=" +
                    pull.id +
                    "&industryType=" +
                    pull.industryType +
                    "&workUnit=" +
                    pull.workUnit,
            });
        },
        goNewsDeatils(news) {
            this.$router.push({
                path: "/newsdetails?id=" + news.id,
            });
        },
    },
};
</script>

<style lang='less' scoped>
.classify-second-message {
  width: 100px;
  flex: 1;

  .symbol {
    display: flex;
    padding-left: 10px;
    padding-top: 2px;
    width: 194px;
    height: 25px;
    margin: 0;

    .position-relative {
      position: relative !important;
    }

    .you-icon {
      width: 100%;
      display: block;
      background: url(../../../assets/images/goldmedal.jpg);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 17px 24px;
      height: 24px;
      padding-left: 26px;
      line-height: 24px;
      overflow: hidden;
      margin: 0;

      .btn {
        border-radius: 3px;
        // background: #ff2832;
        font-size: 17px;
        padding: 1px 4px;
        font-weight: 600;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        border: 0 !important;
        // color: #fff;
        color: #ff2832;
        display: inline-block;
        vertical-align: middle;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }

  .point {
    margin: 0;
    float: left !important;

    p {
      line-height: 32px;
      color: #666;
      margin-top: 6px;
      display: inline-block;
      position: relative;
      padding: 0 8px;
      font-size: 14px;
      width: fit-content;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      position: relative !important;

      &::before {
        content: "";
        opacity: 0.8;
        background: #ffd4d6;
        height: 6px;
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 100%;
        border-radius: 10px;
      }
    }
  }

  .infoCourse {
    font-size: 14px;
    line-height: 26px;
    clear: both;
    margin: 0;

    span {
      padding: 0 12px;
      color: #999;
      border-right: 1px solid #e5e5e5;
      font-family: "微软雅黑", Arial;
    }

    .contact {
      color: #eb5139;
      border: none;
      font-size: 12px;
      font-weight: 600;
    }

    .pl-0 {
      padding-left: 0 !important;
    }
  }

  .labelCourse {
    width: 100%;
    height: 20px;
    //overflow: hidden;
    margin: 6px 0 13px;

    i {
      border-color: #ff2832;
      color: #ff2832;
      padding: 1px 6px;
      border: 1px solid red;
      border-radius: 4px;
      margin-right: 6px;
      font-style: normal;
      font-size: 12px;
    }
  }

  p {
    width: 100%;
    color: #999;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
}

.InstructorSearch-container {
  position: relative;
  width: 1200px;
  height: 1420px;
}

/* 分类区域 */
.InstructorSearch-classify {
  width: 1200px;
  height: 100px;
  background: #fff;
  border-radius: 4px;
}

/* 分类 文字 */
.InstructorSearch-classify .InstructorSearch-classify-text1 {
  margin: 20px;
  margin-right: 10px;
  width: 56px;
  font-size: 16px;
  color: #000;
}

/* 全部 文字 */
.InstructorSearch-classify .InstructorSearch-classify-text2 {
  margin-top: 20px;
  margin-left: 0px;
  width: 53px;
  text-align: center;
  font-size: 14px;
  color: #eb5139;
  background: #fef6f5;
  border-radius: 4px;
  cursor: pointer;
}

/* 行业分类 */
/* 全部地区 */
.classify-IndustryClassfiy,
.classify-AllRegions {
  /* margin-top: 10px; */
  margin-right: 19px;
  text-align: center;
  width: 76px;
  background: #eb5139;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  border-radius: 0px 0px 10px 0px;
}

.InstructorSearch-classify .classify-AllRegions {
  margin-left: 75px;
}

/* 一级分类  start*/
.InstructorSearch-classify-first {
  position: relative;
  margin-top: 50px;
  margin-left: 0;
}

/* 总共有几条的定位 */
.InstructorSearch-classify-first strong {
  position: absolute;
  top: 60px;
  left: 10px;
  font-weight: 400;
}

.InstructorSearch-classify-first strong span {
  font-weight: 600;
}

.detailBox {
  margin-top: 55px;
  margin-left: 50px;
  width: 800px;
}

.tatol {
  display: flex;
}

.tatol > li {
  margin-left: 0px;
  width: 60px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.totalActive {
  font-size: 14px;
  color: #eb5139;
  border-radius: 4px;
}

.InstructorSearch-classify-first /deep/ .el-tabs__header {
  margin: 0px;
}

/* element默认的下面的灰长横线*/
.InstructorSearch-classify /deep/ .el-tabs__nav-wrap::after {
  width: 0;
  height: 0;
}

/* 每个选项默认的下划线 */
.InstructorSearch-classify /deep/ .el-tabs__active-bar {
  width: 0;
  height: 0;
}

/* 一级分类 start */
/* 没有被选中的每个选项的样式 */
.InstructorSearch-classify-first /deep/ .el-tabs__item {
  font-size: 14px;
  color: #676c71;
  box-shadow: none !important;
}

/* 选中的样式 */
.InstructorSearch-classify-first /deep/ .el-tabs__item.is-active {
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #eb5139;
}

/* 一级分类 end */
/* 二级分类 start */
/* 没有被选中的每个选项的样式 */
.InstructorSearch-classify-second /deep/ .el-tabs__item {
  font-size: 14px;
  color: #676c71;
  /* 去掉默认蓝色边框 */
  box-shadow: none !important;
}

/* 选中的样式 */
.InstructorSearch-classify-second /deep/ .el-tabs__item.is-active {
  height: 20px;
  font-size: 14px;
  color: #eb5139;
}

/* 每一个选项的距离 */
.InstructorSearch-classify-second /deep/ .el-tabs--top .el-tabs__item.is-top {
  margin: 0 9px;
  padding: 0;
}

.InstructorSearch-classify-second /deep/ .el-tabs__content {
  margin-left: 0;
  width: 789px;
}

/* 关键词 */
.InstructorSearch-classify-second h3 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #999;
}

/* 搜索内容 */
.InstructorSearch-classify-second .classify-second-ResultsContent {
  height: 1200px;
}

.classify-second-ResultsContent {
  margin-left: -50px;
  margin-top: 70px;
  width: 820px;
}

/* 每一行 */
.classify-second-ResultsContent li {
  background: #fff;
  margin-bottom: 20px;
  padding: 15px 5px 15px 15px;
  height: 182px;
  cursor: pointer;
  display: flex;

  .imgs {
    width: 148px;
    height: 178px;
    border-radius: 6px;
    background: white;
    margin: 0 12px 0 0;
    overflow: hidden;

    .lecturer-pic {
      //object-fit: cover;
      width: 100%;
      //height: 115%;
    }
  }
}

/* 信息区域 */
.classify-second-ResultsContent li .classify-second-message {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.classify-second-message span {
  width: 63px;
  height: 20px;
  font-size: 14px;
}

// .classify-second-message p {
//   margin-left: 0px;
//   width: 300px;
//   height: 20px;
//   font-size: 14px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   font-family: "\5b8b\4f53";
// }

.classify-second-message .classify-follow {
  width: 76px;
  height: 25px;
  background: #eb5139;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.classify-second-message1,
.classify-second-message2,
.classify-second-message3,
.classify-second-message4,
.classify-second-message5 {
  width: 553px;
  height: 25px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;

  p {
    height: 100%;
  }
}

.classify-second-message2 {
  display: flex;
  // margin: 0;
  div {
    flex: 1;
  }
}

/* 第一行 start*/
.classify-second-message1 h4 {
  font-size: 20px;
  font-weight: 700;
  color: #666;
  // color: #ff2832;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.classify-second-message1 .lecturer-pic1 {
  margin: 5px 10px 0px 5px;
  width: 18px;
  height: 18px;
}

/* 按钮 */
.classify-second-message1 .el-button {
  margin-bottom: 12px;
  width: 76px;
  height: 25px;
  line-height: 7px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border: 0;
}

/* 已关注按钮 */
.classify-second-message1 .followed-btn {
  background: #858585;
}

/* 未关注按钮 */
.classify-second-message1 /deep/ .follow-btn {
  background: #ff2832;
  padding: 1px 4px;
}

.classify-second-message1 > .follow-btn /deep/ span {
  font-size: 14px;
  font-weight: 400;
}

/* 第一行 end*/
/* 第二行 start*/
/* 第二行 end*/

/* 第四行 */

/* 分页 */
.footer-pagination {
  position: absolute;
  top: 1256px;
  left: -20px;
  margin: 20px 0;
  padding: 0 20px;
  box-sizing: border-box;
  width: 789px;
  height: 55px;
}

.footer-pagination /deep/ .el-pagination {
  width: 490px;
  height: 24px;
  color: #555656;
}

/* 经过每一个没有选中的数字的颜色 */
.footer-pagination /deep/ .el-pager li.number {
  color: #555656;
}

/* 左箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-prev {
  background-color: transparent;
}

/* 右箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-next {
  background-color: transparent;
}

/* 数字框 */
.footer-pagination /deep/ .el-input__inner {
  width: 45px;
  height: 24px;
  border-radius: 2px 2px 2px 2px;
}

/* 二级分类 end */

/* 右侧推荐讲师start */
.right-RecommendedLecturer {
  position: relative;
  z-index: 1;
  margin-top: 48px;
  width: 371px;
  height: auto;
  background-color: #fff;
}

/* 推荐讲师 start */
.hot-teacher {
  padding: 10px 16px 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

/* 推荐讲师 end */

/* 行业资讯start */
.hot-search {
  position: relative;
  width: 120px;
  padding: 15px;
  height: 22px;
}

.hot-search-word {
  position: absolute;
  top: 15px;
  left: 16px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

/* 文字下面的横条 */
.hot-search strong {
  margin-top: 15px;
  display: inline-block;
  width: 65px;
  height: 9px;
  background: #eb5139;
}

/* 下面的内容 */
.latest-upload {
  width: 100%;
  position: relative;
  height: auto;
  padding: 0 26px;
  box-sizing: border-box;
}

/* 每一行 */
.latest-upload li {
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* 小圆点 */
.latest-upload li .point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #dddddd;
  line-height: 20px;
}

/* 文字 */
.latest-upload li p {
  width: 280px;
  height: 20px;
  line-height: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #666c72;
}

.sequence {
  background: #9195a3;
  width: 20px !important;
  height: 20px !important;
  color: #000;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
}

.one {
  background: red;
  color: #fff;
}

.two {
  background: #f60;
  color: #fff;
}

.three {
  background: #faa90e;
  color: #fff;
}

/* 行业资讯end */

.hot-teacher-pic {
  > li {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    border-bottom: 1px solid #ebebeb;

    > .imgs {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background: #666;

      img {
        width: 100%;
      }
    }

    .r {
      width: 200px;
      height: 45px;
      margin-left: 12px;

      ul {
        display: flex;

        li {
          margin: 0;
        }

        .title {
          color: #333;
          font-size: 16px;
          line-height: 22px;
        }

        .imgs {
          width: 70px;
          height: 20px;
          border-radius: 3px;
          margin-left: 10px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      p {
        line-height: 25px;
        color: #666;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        font-size: 14px;
        font-family: "微软雅黑", Arial;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }
}

.nav_hot {
  position: relative;
  width: 100%;
  height: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #edf0f5;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
}

.nav_hot a {
  /* float: right; */
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  color: #909499;
  margin: 0;
}

.ico_h {
  display: flex;
  color: #eb5139;
  width: 150px;
  margin: 0;
}

.icon_t {
  /* color: #ff3f29; */
  font-size: 30px;
  margin: 0;
  margin-right: 10px;
}

.ico_h h4 {
  margin: 0;
  line-height: 30px;
  font-weight: 600;
}
</style>
