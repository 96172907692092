<template>
    <div>
        <!-- 名师推荐 -->
        <div class="index-content-box">
            <div class="index-teacher">
                <div class="index-content-tit" :style="{ display: header }">
                    <div class="btn-left" :style="{ display: header }">
                        <h3 class="tea-head">大国安工</h3>
                        <!-- <h3 class="head-bottom"></h3> -->
                    </div>

                    <div class="btn-right">
            <span style="line-height: 85px; cursor: pointer" @click="goCon"
            >更多 &gt;</span
            >
                        <button class="joinBtn" @click="joinTeacher">安工入驻</button>
                    </div>
                </div>
                <ul class="teaList" v-if="UserAttentionList.total">
                    <li
                            @click="goDetail(item)"
                            v-for="item in UserAttentionList.records"
                            :key="item.id"
                            style="margin: 0 5px"
                    >
                        <div style="height: 250px" class="follow">
                            <img
                                    style="height: 270px;  object-fit: cover;"
                                    :src=" item.picture "
                                    class="cla-p"
                                    alt=""/>
                        </div>
                        <div class="teaDetail">
                            <h4>{{ item.name }}</h4>
                            <p>
                                {{ item.researchField }}
                                <span>{{ item.position }}</span>
                            </p>
                        </div>
                        <span v-if="item.honorLabel === '0'"></span>
                        <span v-if="item.honorLabel === '1'">智库专家</span>
                        <span v-if="item.honorLabel === '2'">中安讲师</span>
                        <span v-if="item.honorLabel === '3'">安全总监</span>
                        <span v-if="item.honorLabel === '4'">注册安工</span>
                        <span v-if="item.honorLabel === '5'">EHS专家</span>
                    </li>
                </ul>
                <ul class="teaList" style="justify-content: flex-start;" v-else>
                    <li
                            @click="goDetail(item)"
                            v-for="item in UserAttentionList"
                            :key="item.id"

                    >
                        <div class="imgs">
                            <img  style="height: 270px;  object-fit: cover;" :src="item.picture" alt="" class="cla-p "/>
                        </div>

                        <div class="teaDetail">
                            <h4>
                                {{ item.name }}
                            </h4>
                            <div class="telbox" style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                {{item.securityField?item.securityField.split(";").join("、"):'' }}
                            </div>
                            <p class="badge">
                                {{ item.greatField?item.greatField.split(";").join("、"):''}}
                            </p>
                        </div>
                        <span v-if="item.honorLabel === '0'" style="opacity: 0"></span>
                        <span v-if="item.honorLabel === '1'">智库专家</span>
                        <span v-if="item.honorLabel === '2'">中安讲师</span>
                        <span v-if="item.honorLabel === '3'">安全总监</span>
                        <span v-if="item.honorLabel === '4'">注册安工</span>
                        <span v-if="item.honorLabel === '5'">EHS专家</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 名师推荐 End -->
        <div class="Pga" v-if="UserAttentionList.length > 10">
            <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :page-size="10"
                    :current-page.sync="currentPage"
                    :total="UserAttentionList.length"
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import Config from "@/settings";
import {teacherInfo} from "@/api/common";

export default {
    props: ["tea", "liWidth", "header", "UserAttentionList"],
    data() {
        return {
            active: "",
            picUrl: "",
            currentPage: 1,
        };
    },
    methods: {
        goCon() {
            this.$router.push({
                path: "/consultingexperts",
            });
        },
        yr() {
            this.active = "color:#FF4A35";
        },
        yc() {
            this.active = "";
        },
        handleCurrentChange(val) {
            this.$store.dispatch("setTeaPageNum", val);
        },
        goDetail(item) {
            console.log(item.id);
            this.$router.push({
                path: "/instructordetails?id=" + item.id,
            });
        },
        joinTeacher() {
            // if (this.$store.state.user.token) {
            this.$router.push("/user/Securityworkerssettlingin");
            // } else {
            //   this.$notify.error({
            //     title: "错误",
            //     message: "仅限个人用户登陆后进入"
            //   });
            // }
        },
    },
    mounted() {
        this.picUrl = Config.aliyunBaseUrl;
    },
};
</script>

<style scoped>
.follow >.cla-p{
    transition: transform 1s;
}
.follow:hover >.cla-p{
    transform: scale(1.2);
}
.index-content-box {
    background: #ffffff;
}

.teaDetail > h4 {
    color: #666;
    font-weight: 500;
    font-size: 18px !important;
}

.teaDetail > .telbox {
    color: #333;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
}

.teaDetail > .badge {
    color: #fff;
    background-color: #dc3545;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* margin-top: 0.5rem !important; */
    margin: 3px 0 6px 0;
}

.Pga {
    display: flex;
}

.tea-tabs {
    font-size: 14px;
}

.btn-left {
    margin-left: 0px;
}

.btn-right {
    display: flex;
    margin-right: 0;
}

.btn-right > span {
    margin-top: 8px;
    margin-right: 20px;
    color: #666;
    font-size: 16px;
}

.head-bottom {
    width: 34px;
    height: 0px;
    border: 2px solid #eb5139;
    background-color: #eb5139;
    margin-left: 7px;
}

.tea-head {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 28px;
    border-left: #eb5139 7px solid;
    padding-left: 5px;
}

.joinBtn {
    background-color: #ff4a35;
    border-radius: 15px;
    border-style: none;
    height: 32px;
    width: 85px;
    color: white;
    white-space: nowrap;
    position: relative;
    right: 0;
    top: 35%;
    font-size: calc(100vw * 14 / 1920);
    margin: 0;
    cursor: pointer;
}

.index-content-tit {
    display: flex;
}

.index-content-box .teaList {
    display: flex;
    flex-wrap: wrap;

}

.teaList > li {
    position: relative;
    width: 180px;
    height: 325px;
    margin: 0 20px 0 0 ;
    /* margin: 10px 8px; */
    background-color: #fff;
    /* box-shadow: 5px 8px 3px rgb(238, 240, 243); */
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}
.teaList > li:last-child{
    margin-right: 0;
}

.teaList > li > .imgs {
    border-radius: 6px;
    width: 100%;
    height: 238px;
    overflow: hidden;
    background: #666;
}

.teaList > li > .imgs > .cla-p {
    width: 100%;
    transition: all 1s;
}

.teaList > li:hover > .imgs > .cla-p {
    transform: scale(1.1);
}

.index-content-box .teaDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}

.teaDetail > p {
    font-size: 14px;
    color: gray;
    margin: 10px 0 15px 0;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.teaDetail > h4 {
    width: 100%;
    font-size: calc(100vw * 20 / 1920);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
}

.teaList > li > span {
    width: 70px;
    background: linear-gradient(90deg, #f97419 0%, #fc9f19 100%);
    background-color: #f97619;
    font-size: 13px;
    border-radius: 6px 0 6px 0;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    white-space: nowrap;
}

.teaList > li > div {
    overflow: hidden;
}

.teaList > li > div > img {
    width: 100%;
}

.expert .teaList > li {
    margin-bottom: 10px;
    margin-right: 39px;
}

.expert .teaList > li:nth-child(4n) {
    /* 尽量让item在list中居中，两边都没有margin */
    margin-right: 0px !important;
}

/* .expert .teaList {
  content: "";
  flex: auto;
} */
.mainbody .index-teacher {
    padding: 0 10px;
}

.mainbody .teaList {
    display: flex;
    justify-content: space-between;
}
</style>
