import axios from 'axios'
import router from '@/router/index'
import { Notification } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import Config from '@/settings'
import Cookies from 'js-cookie'
import { Store } from 'vuex'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API,

  //baseURL: 'http://192.168.21.32:8001/',
  //baseURL: 'http://192.168.21.26:8000/',
  //  baseURL: 'http://www.zhongangongjiang.com/',
  // baseURL: 'http://localhost:8080/',


  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      // console.log(getToken())
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  },
  request => {

  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    let url=encodeURIComponent(window.location.href)
    // console.log(response)

    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      if (response.data.code===1018){
        // console.log("登录")
        localStorage.clear();
        // console.log(store.state.user.token)
        store.dispatch("userLogOut")
      }
      // 如果为undefined 说明为下载接口，无code
      if (response.data.code === undefined) {
        return response.data
      }
      // } else if (response.data.code !== 0) {
      //   // Notification.error({
      //   //   title: response.data.message
      //   // })
      //   // return Promise.reject('error')
      //
      // }
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (code) {
      console.log(code)
      if (code === 401) {
        localStorage.clear();
        console.log(store.state.user.token)
        store.dispatch("userLogOut")
        // Notification.error({
        //   title: '请先登录再试',
        //   duration: 2000
        // })
        // let url=encodeURI(window.location.href)
        // router.push({ path: '/401?callback='+url })
      } else if (code === 403) {
        // router.push({ path: '/401' })
        Notification.error({
          title: errorMsg,
          duration: 5000
        })
      } else {
        const errorMsg = error.response.data.message
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          })
        }
      }
    } else {
      Notification.error({
        title: '服务器异常,请重试!',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)

export default service

