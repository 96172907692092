import Axios from 'axios';
import OSS from 'ali-oss';
import { processEnv } from '@/utils/processEnv.js'

/*
 *上传文件到阿里云oss
 *@param - config: 配置参数 {accessKeyId:'临时AccessKeyID', dir:'存储路径', host: 'OSS地址', policy: '加密策略', signature:'签名'}
 *@param - filePath: 文件路径
 *@param - fileName: 文件名称
 *@param - successc: 成功回调
 *@param - failc: 失败回调
 */
export function ossDirect(config, key, file, successc, failc) {
	const formData = new FormData()
	formData.append('key', key) // 临时AccessKeyID
	formData.append('policy', config.policy) // 加密策略
	formData.append('OSSAccessKeyId', config.accessKeyId) // 临时AccessKeyID
	formData.append('signature', config.signature) // 签名
	formData.append('success_action_status', '200')
	formData.append('file', file) // 文件路径
	Axios({
		method: 'post',
		url: config.host,
		data: formData
	}).then(() => {
		successc(config.aliyunServerURL + '/' + config.aliyunFileKey);
	}).catch((err) => {
		failc('上传失败')
	})
}

// 视频普通上传
// export function ossDirectVideo(config, key, file, successc, failc) {
// 	const formData = new FormData()
// 	formData.append('key', key) // 文件名
// 	formData.append('policy', config.policy) // 加密策略
// 	formData.append('OSSAccessKeyId', config.accessKeyId) // 临时AccessKeyID
// 	formData.append('signature', config.signature) // 签名
// 	formData.append('success_action_status', '200')
// 	formData.append('file', file) // 文件路径
// 	Axios({
// 		method: 'post',
// 		url: config.host,
// 		data: formData,
// 		timeout: 600 * 1000,
// 	}).then(() => {
// 		successc(config.aliyunServerURL+ '/' +config.aliyunFileKey);
// 	}).catch((err) => {
// 		failc('上传失败')
// 	})
// }


// 视频分片上传
export function ossDirectVideo(config, key, file, successc, failc) {
	let region = 'oss-cn-beijing'
	let bucket = ''
	if (processEnv() === 'pro') {
		bucket = 'zhongan-resource-test'
	} else {
		bucket = 'zhongan-resource-test'
	}

	var client = new OSS({
		accessKeyId: config.accessKeyId,
		accessKeySecret: config.accessKeySecret,
		stsToken: config.securityToken,
		bucket: bucket,
		region: region,
	});
	client.multipartUpload(key, file, {
		parallel: 4,
		partSize: 1024 * 1024,// 每个分片为1m
	}).then(function (result) {
		successc();
	}).catch(function (err) {
		failc()
	});
}


export function uuid(len, radix) {
	console.log(len, radix, 'radix');
	var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	var uuid = [], i;
	radix = radix || chars.length;

	if (len) {
		// Compact form
		for (i = 0; i < len; i++) {
			uuid[i] = chars[0 | Math.random() * radix];
		}
	} else {
		// rfc4122, version 4 form
		var r;

		// rfc4122 requires these characters
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';

		// Fill in random data.  At i==19 set the high bits of clock sequence as
		// per rfc4122, sec. 4.1.5
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}

	return uuid.join('');
};

