<template>
    <div class="expert">
        <!-- 轮播图 start -->
        <div class="consulting-experts-rotation">
            <el-carousel height="300px">
                <el-carousel-item
                        v-for="(item1, index) in rotationList"
                        :key="'one' + index"
                >
                    <a :href="item1.targetAddress" target="blank">
                        <el-image
                                style="width: 100%; height: 100%"
                                :src="item1.pictureUrl"
                                :fit="'cover'"
                        ></el-image>
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 轮播图 end -->
        <!-- 版心 -->
        <div class="consulting-experts-container">
            <!-- 搜索框 start -->
            <div class="consulting-experts-search">
                <el-input
                        v-model="searchResults"
                        placeholder="请输入你想找的讲师,专家"
                        @keyup.enter.native="goInstructorSearch"
                        class="inputP"
                ></el-input>
                <el-button @click="goInstructorSearch">搜索</el-button>
            </div>
            <!-- 搜索框 end -->
            <!-- 名师推荐 start -->
            <div class="consulting-experts-recommend">
                <!-- 头部 -->
                <div class="recommend-teacher">
                    <h3 class="fl">大国安工</h3>
                    <strong></strong>
                    <span
                            @click="goCon"
                            style="
              line-height: 85px;
              color: gray;
              cursor: pointer;
              position: absolute;
              right: 135px;
              top: -30px;
              font-size: 16px;
            "
                    >更多 &gt;</span
                    >
                    <el-button @click="goLecturer">安工入驻</el-button>
                </div>
                <!-- 内容 -->
                <ul class="recommend-teacher-content">
                    <li
                            class="fl"
                            style="margin-bottom: 0"
                            v-for="(teacher, index) in teacherList"
                            :key="index"
                            @click="goInstructorDetails(teacher)"
                    >
                        <div class="cla-p">
                            <img style="height: 270px;  object-fit: cover;" :src="teacher.picture" alt=""/>
                        </div>
                        <h4 class="fl">{{ teacher.name }}</h4>
                        <div
                                v-if="teacher.position != undefined"
                                class="text"
                                style="margin-left: 0"
                                :title="teacher.position"
                        >
                            {{ teacher.securityField ? teacher.securityField.split(";").join("、") : '' }}
                        </div>
                        <div class="red">
                            <p>{{ teacher.greatField ? teacher.greatField.split(";").join("、") : '' }}</p>
                        </div>
                        <!-- 左上角的定位 -->
                        <!--            <em v-if="teacher.agType === 0">智库专家</em>-->
                        <!--            <em v-if="teacher.agType === 1">智库专家</em>-->
                        <span v-if="teacher.honorLabel === '0'" style="opacity: 0"></span>
                        <em v-if="teacher.honorLabel === '1'">智库专家</em>
                        <em v-if="teacher.honorLabel === '2'">中安讲师</em>
                        <em v-if="teacher.honorLabel === '3'">安全总监</em>
                        <em v-if="teacher.honorLabel === '4'">注册安工</em>
                        <em v-if="teacher.honorLabel === '5'">EHS专家</em>
                    </li>
                </ul>
            </div>
            <!-- 名师推荐 end -->
            <!-- banner区域 start -->
            <div
                    class="consulting-experts-banner"
                    v-for="(one, index) in bannerList"
                    :key="index"
            >
                <div
                        :style="{ 'background-image': 'url(' + one.pictureUrl + ')' }"
                        class="cla-p"
                ></div>
            </div>
            <!-- banner区域 end -->

            <!-- 分类区域 start -->
            <div class="detailBox">
                <ul class="tatol">
                    <li
                            v-for="(item, index) in classifyList.slice(0, 3)"
                            :class="{ totalActive: activeOne == index }"
                            @click.stop="showOne(index, item.code)"
                            :key="index"
                    >
                        {{ item.cateName }}
                        <ul class="tatol1" v-if="activeOne === index">
                            <div
                                    v-for="(t, index2) in item.children"
                                    :class="{ totalActive1: activeTwo == index2 }"
                                    :key="'child-node' + index2"
                                    @click.stop="showTwo(index2, t.code)"
                            >
                                {{ t.cateName }}
                            </div>
                        </ul>
                    </li>
                </ul>
                <hr class="line-top"/>
                <ul class="recommend-teacher-content">
                    <li
                            class="classify-content fl"
                            v-for="(a, index) in fieldListOne"
                            :key="index"
                            @click="goInstructorOne(a)"
                    >
                        <div class="cla-p">
                            <img style="height: 270px;  object-fit: cover;" :src="a.picture" alt=""/>
                        </div>
                        <h4 class="fl">{{ a.name }}</h4>
                        <div class="text">{{ a.securityField ? a.securityField.split(";").join("、") : '' }}</div>
                        <div class="red">
                            <p>{{ a.greatField ? a.greatField.split(";").join("、") : '' }}</p>
                        </div>
                        <!--            <em v-if="a.agType === 0">智库专家</em>-->
                        <!--            <em v-if="a.agType === 1">智库专家</em>-->
                        <em v-if="a.honorLabel === '0'" style="opacity: 0"></em>
                        <em v-if="a.honorLabel === '1'">智库专家</em>
                        <em v-if="a.honorLabel === '2'">中安讲师</em>
                        <em v-if="a.honorLabel === '3'">安全总监</em>
                        <em v-if="a.honorLabel === '4'">注册安工</em>
                        <em v-if="a.honorLabel === '5'">EHS专家</em>
                    </li>
                </ul>
            </div>
            <div class="detailBox1">
                <ul class="tatol">
                    <li
                            v-for="(item, index) in classifyList.slice(3, 6)"
                            :class="{ totalActive: activeThree == index }"
                            @click.stop="showThree(index, item.code)"
                            :key="index"
                    >
                        {{ item.cateName }}
                        <ul class="tatol1" v-if="activeThree === index">
                            <div
                                    :class="{ totalActive1: activeFour === index }"
                                    v-for="(t, index) in item.children"
                                    :key="'child-node' + index"
                                    @click.stop="showFour(index, t.code)"
                            >
                                {{ t.cateName }}
                            </div>
                        </ul>
                    </li>
                </ul>
                <hr class="line-top"/>
                <ul class="recommend-teacher-content">
                    <li
                            class="classify-content fl"
                            v-for="(b, index) in fieldListTwo"
                            :key="index"
                            @click="goInstructorTwo(b)"
                    >
                        <div class="cla-p">
                            <img style="height: 270px;  object-fit: cover;" :src="b.picture" alt=""/>
                        </div>
                        <h4 class="fl">{{ b.name }}</h4>
                        <div class="text">{{ b.securityField ? b.securityField.split(";").join("、") : '' }}</div>
                        <div class="red">
                            <p>{{ b.greatField ? b.greatField.split(";").join("、") : '' }}</p>
                        </div>
                        <!--            <em v-if="b.agType === 0">智库专家</em>-->
                        <!--            <em v-if="b.agType === 1">智库专家</em>-->
                        <em v-if="b.honorLabel === '0'" style="opacity: 0"></em>
                        <em v-if="b.honorLabel === '1'">智库专家</em>
                        <em v-if="b.honorLabel === '2'">中安讲师</em>
                        <em v-if="b.honorLabel === '3'">安全总监</em>
                        <em v-if="b.honorLabel === '4'">注册安工</em>
                        <em v-if="b.honorLabel === '5'">EHS专家</em>
                    </li>
                </ul>
            </div>
            <div class="detailBox1">
                <ul class="tatol">
                    <li
                            v-for="(item, index) in classifyList.slice(6, 10)"
                            :class="{ totalActive: activeFive === index }"
                            @click.stop="showFive(index, item.code)"
                            :key="index"
                    >
                        {{ item.cateName }}
                        <ul class="tatol1" v-if="activeFive === index">
                            <div
                                    :class="{ totalActive1: activeSix === index }"
                                    v-for="(t, index) in item.children"
                                    :key="'child-node' + index"
                                    @click.stop="showSix(index, t.code)"
                            >
                                {{ t.cateName }}
                            </div>
                        </ul>
                    </li>
                </ul>
                <hr class="line-top"/>
                <ul class="recommend-teacher-content">
                    <!-- <ul class="recommendTeacher"> -->

                    <li
                            class="classify-content fl"
                            v-for="(c, index) in fieldListThree"
                            :key="index"
                            @click="goInstructorThree(c)"
                    >
                        <div class="cla-p">
                            <img style="height: 270px;  object-fit: cover;" :src="c.picture" alt=""/>
                        </div>
                        <h4 class="fl">{{ c.name }}</h4>
                        <div class="text">{{ c.securityField ? c.securityField.split(";").join("、") : '' }}</div>
                        <div class="red">
                            <p>{{ c.greatField ? c.greatField.split(";").join("、") : '' }}</p>
                        </div>
                        <!--            <em v-if="c.agType === 0">智库专家</em>-->
                        <!--            <em v-if="c.agType === 1">智库专家</em>-->
                        <em v-if="c.honorLabel === '0'" style="opacity: 0"></em>
                        <em v-if="c.honorLabel === '1'">智库专家</em>
                        <em v-if="c.honorLabel === '2'">中安讲师</em>
                        <em v-if="c.honorLabel === '3'">安全总监</em>
                        <em v-if="c.honorLabel === '4'">注册安工</em>
                        <em v-if="c.honorLabel === '5'">EHS专家</em>
                    </li>
                </ul>
            </div>
            <!-- 分类区域 end -->
        </div>
    </div>
</template>

<script>
import {
    getDocumentPicture,
    professionClassify,
    teacherInfo,
} from "@/api/common";
import {Recommend, sameResearchField} from "@/api/teacher";

export default {
    name: "ConsultingExperts",
    data() {
        return {
            searchResults: "",
            rotationList: [], // 轮播图列表
            teacherList: [], //讲师列表
            bannerList: [], //banner图列表
            classifyList: [], //行业分类
            fieldListOne: [], //分类下请求数据的名称
            fieldListTwo: [], //分类下请求数据的名称
            fieldListThree: [], //分类下请求数据的名称
            activeOne: 2, //一级分类名称显示
            activeTwo: 0, // 二级分类名称显示
            activeThree: 1, // 第二行
            activeFour: 0,
            activeFive: 3, // 第三行
            activeSix: 0,
        };
    },
    mounted() {
        // 获取图片
        getDocumentPicture(3, 27).then((res) => {
            if (res.code === 200) {
                this.rotationList = res.data;
                // console.log(this.rotationList, "this.");
            }
        });
        getDocumentPicture(1, 27).then((res) => {
            if (res.code === 200) {
                this.bannerList = res.data;
            }
        });
        professionClassify().then((res) => {
            if (res.code === 200) {
                this.classifyList = res.data;
            }
        });
        const data = {
            count: 6,
            unit: 13,
            id: 0,
        };

        Recommend(data).then((res) => {
            if (res.code === 200) {
                // console.log(res.data)
                // console.log(res.data)
                res.data.map(item => {
                    item.greatField = item.greatField.replace(/;([^;]*)$/, '$1');
                })
                this.teacherList = res.data;
            }
        });
        // 二级分类下面的内容
        sameResearchField(data).then((res) => {
            if (res.code === 200) {
                res.data.map(item => {
                    item.greatField = item.greatField.replace(/;([^;]*)$/, '$1');
                })
                this.fieldListOne = res.data;
            }
        });
        const data1 = {
            unit: 15,
            id: 0,
        };
        sameResearchField(data1).then((res) => {
            if (res.code === 200) {
                res.data.map(item => {
                    item.greatField = item.greatField.replace(/;([^;]*)$/, '$1');
                })
                this.fieldListTwo = res.data;
            }
        });
        const data2 = {
            unit: 20,
            id: 0,
        };
        sameResearchField(data2).then((res) => {
            if (res.code === 200) {
                res.data.map(item => {
                    item.greatField = item.greatField.replace(/;([^;]*)$/, '$1');
                })
                this.fieldListThree = res.data;
            }
        });
    },
    methods: {
        goCon() {
            this.$router.push({
                path: "/instructorsearch",
            });
        },
        showOne(index, code) {
            this.activeOne = index;
            // this.activeTwo = 0;
            const data = {
                unit: code,
                id: 0,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code === 200) {
                    this.fieldListOne = res.data;
                }
            });
        },
        showTwo(index2, code) {
            this.activeTwo = index2;
            const data = {
                unit: code,
                id: 0,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code == 200) {
                    this.fieldListOne = res.data;
                }
            });
        },
        showThree(index3, code) {
            this.activeThree = index3;
            // this.activeFour = 0;
            const data = {
                unit: code,
                id: 0,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code == 200) {
                    this.fieldListTwo = res.data;
                }
            });
        },
        showFour(index4, code) {
            this.activeFour = index4;
            const data = {
                field: code,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code == 200) {
                    this.fieldListTwo = res.data;
                }
            });
        },
        showFive(index5, code) {
            this.activeFive = index5;
            this.activeSix = 0;
            const data = {
                unit: code,
                id: 0,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code == 200) {
                    this.fieldListThree = res.data;
                }
            });
        },
        showSix(index2, code) {
            this.activeSix = index2;
            const data = {
                unit: code,
                id: 0,
            };
            sameResearchField(data, code).then((res) => {
                if (res.code == 200) {
                    this.fieldListThree = res.data;
                }
            });
        },
        goInstructorSearch() {
            this.$router.push({
                path: "/instructorsearch/?keyword=" + this.searchResults,
            });
        },
        goInstructorDetails(teacher) {
            teacherInfo(teacher.id).then((res) => {
                if (res.code == 200) {
                    // console.log(teacher);
                    this.$router.push({
                        path:
                            "/instructordetails?id=" +
                            teacher.id +
                            "&industryType=" +
                            teacher.industryType +
                            "&workUnit=" +
                            teacher.workUnit,
                    });
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
        goLecturer() {
            this.$router.push({
                path: "/user/Securityworkerssettlingin",
            });
        },
        goInstructorOne(a) {
            teacherInfo(a.id).then((res) => {
                if (res.code == 200) {
                    this.$router.push({
                        path:
                            "/instructordetails?id=" +
                            a.id +
                            "&industryType=" +
                            a.industryType +
                            "&workUnit=" +
                            a.workUnit,
                    });
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
        goInstructorTwo(b) {
            teacherInfo(b.id).then((res) => {
                if (res.code == 200) {
                    this.$router.push({
                        path:
                            "/instructordetails?id=" +
                            b.id +
                            "&industryType=" +
                            b.industryType +
                            "&workUnit=" +
                            b.workUnit,
                    });
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
        goInstructorThree(c) {
            teacherInfo(c.id).then((res) => {
                if (res.code == 200) {
                    this.$router.push({
                        path:
                            "/instructordetails?id=" +
                            c.id +
                            "&industryType=" +
                            c.industryType +
                            "&workUnit=" +
                            c.workUnit,
                    });
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.expert {
//margin-top: 20px;
}

.inputP {
    font-size: 16px;
}

/* 版心 */
.consulting-experts-container {
    background: #fff;
    width: 1200px;
    height: auto;
    padding: 20px;
    margin-bottom: 31px;
}

/* 轮播图 start */
.consulting-experts-rotation img {
    width: 100%;
    height: 300px;
}

/* element轮播图的小圆点 */
.consulting-experts-rotation >>> .el-carousel__indicator--horizontal {
    padding: 30px 5px;
}

.consulting-experts-rotation >>> .el-carousel__indicators {
    bottom: 30px !important;
}

.consulting-experts-rotation >>> .el-carousel__button {
    width: 52px;
    height: 6px;
    background: #fff;
}

/* 小圆点选中颜色 */
.consulting-experts-rotation >>> .is-active .el-carousel__button {
    background-color: #eb5139;
}

/* elementUI提供的 el-carousel 的默认样式*/
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

/* 轮播图 end */

/* 搜索框 start */
.consulting-experts-search {
    position: relative;
    top: -46px;
    left: 5px;
    width: 698px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    z-index: 2;
    border: 1px solid #eb5139;
}

.consulting-experts-search >>> .el-input__inner {
    padding-right: 0;
    width: 596px;
    height: 50px;
    border: 0;
    color: #333;
}

.consulting-experts-search >>> .el-input {
    width: 596px;
    height: 50px;
}

.consulting-experts-search >>> .el-button {
    width: 102px;
    height: 51px;
    color: #fff;
    border: 0px;
    border-radius: 0px;
    background: #eb5139;
    font-size: 16px;
}

/* 搜索框 end */

/* 名师推荐 start */
.consulting-experts-recommend {
    width: 100%;
}

/* 头部 */
.recommend-teacher {
    position: relative;
    height: 25px;
    line-height: 39px;
}

/* 名师推荐文字 */
.recommend-teacher h3 {
    position: absolute;
    top: -17px;
    left: 4px;
    width: 114px;
    height: 25px;
    font-size: 28px;
//font-weight: 600; color: #000;
}

/* 名师推荐文字下面的横线 */
.recommend-teacher strong {
    display: inline-block;
    width: 114px;
    height: 6px;
    background: #eb5139;
}

.recommend-teacher >>> .el-button {
    float: right;
    width: 100px;
    height: 30px;
    color: #fff;
    background: #eb5139;
    border-radius: 15px;
}

.el-button span {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.recommend-teacher >>> .el-button--small {
    padding: 0 15px;
}

/* 内容 */
.recommend-teacher-content {
    margin-top: 31px;
    width: 100%;
    height: 332.5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.recommend-teacher-content > li {
    position: relative;
    width: 180px;
    height: 332.5px;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    float: none;
    margin: 0 20px 0 0;
}

.recommend-teacher-content > li:last-child {
    margin-right: 0px;
}

.recommend-teacher-content li .cla-p {
    width: 100%;
    height: 238px;
    border-radius: 6px;
    background-size: cover;
    background-position: center top !important;
    overflow: hidden;
    margin: 0 auto;
    background: #89898d;
}

.recommend-teacher-content li .cla-p > img {
    width: 100%;
    cursor: pointer;
    transition: all 0.6s;
}

.recommend-teacher-content li:hover .cla-p > img {
    transform: scale(1.2);
}

.recommend-teacher-content li h4 {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #666;
    line-height: 30px;
    font-weight: 500;
    font-size: 18px !important;
}

.recommend-teacher-content li > .text {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 0px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #333;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
}

.recommend-teacher-content li .red {
    padding: 0 12px;
}

.recommend-teacher-content li p {
    text-align: center;
    color: #fff;
    background-color: #dc3545;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* margin-top: 0.5rem !important; */
    margin: 3px 0 13px 0;
}

/* 左上角的定位 */
.recommend-teacher-content li em {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background: linear-gradient(143deg, #e98038 0%, #efa13f 100%);
    border-radius: 8px 0px 10px 0;
}

/* 名师推荐 end */

/* banner区域 start */
.consulting-experts-banner {
    margin-top: 30px;
    margin-bottom: 10px;
}

.consulting-experts-banner .cla-p {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center top !important;
}

/* banner区域 end */
.recommendTeacher {
    margin-top: 51px;
    width: 1200px;
    min-height: 322px;
}

/* 下面的内容 */
.recommendTeacher .classify-content {
    position: relative;
    margin-right: 18px;
    margin-bottom: 20px;
    width: 186px;
    height: 276px;
    background: #fff;
    cursor: pointer;
}

.recommendTeacher .classify-content .cla-p {
    width: 100%;
    height: 190px;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    background-position: center top !important;
}

/* 图片下面的文字 */
.recommendTeacher .classify-content h4 {
    margin: 7px 0 5px 10px;
    padding: 12px;
    text-align: center;
    width: 50px;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recommendTeacher .classify-content span {
    margin-top: 22px;
    display: inline-block;
    text-align: center;
    min-width: 40px;
    max-width: 90px;
    min-height: 18px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recommendTeacher .classify-content p {
    margin-top: 10px;
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #676c71;
    text-align: center;
}

/* 左上角的定位 */
.recommendTeacher .classify-content em {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background: linear-gradient(143deg, #e98038 0%, #efa13f 100%);
    border-radius: 8px 0px 10px 0;
}

.tatol {
    position: relative;
    display: flex;
    width: 50%;
    margin-left: 0px;
}

.tatol > li {
    margin-left: 0px;
    width: 90px;
    height: 36px;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}

.tatol li:nth-child(2) {
    color: #3f4245;
}

.tatol li:nth-child(3) {
    color: #3f4245;
}

.tatol li:nth-child(4) {
    color: #3f4245;
}

.tatol .totalActive:nth-child(2) {
    border-bottom: 2px solid #eb5139;
}

.tatol .totalActive:nth-child(3) {
    border-bottom: 2px solid #eb5139;
}

.tatol .totalActive:nth-child(4) {
    border-bottom: 2px solid #eb5139;
}

.totalActive {
    color: #eb5139 !important;
    font-weight: 600;
    border-bottom: 2px solid #eb5139;
}

.tatol1 {
    display: flex;
    position: absolute;
    left: 0;
    margin-left: -7px;
    width: 200px;
}

.tatol1 > div {
    width: 80px;
    margin-top: 10px;
    font-size: 14px;
    color: #676c71;
    height: 28px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.tatol1 .totalActive1 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.totalActive1 {
    width: 80px;
    height: 28px;
    border-radius: 4px 4px 4px 4px;
    background: #eb5139 !important;
}

.line-top {
    border: none;
    height: 2px;
    background-color: #e5e5e5;
    margin-top: -1px;
    /* border: 1px solid #e5e5e5; */
}

.detailBox1 {
    margin-top: 20px;
}

.cla-p > p {
    margin: 0 10px;
}

/* 分类区域 end  */
</style>
