import request from '@/utils/request'

// 给新邮箱发送验证码
export function sendCode(email) {
  return request({
    url:'api/institutionInfo/updateEmail/code?email=' + email,
    method:'post',
  })
}

// 验证验证码并修改邮箱
export function updateEmail(email,code) {
  return request({
    url:'api/institutionInfo/updateEmail?email='+ email + '&code=' + code,
    method:'post',
  })
}

// 修改密码
export function updatePassword(password,newPassword) {
  const data = {
    'password':password,
    'newPassword':newPassword,   
  }
  return request({
    url:`api/institutionInfo/updatePassword`,
    method:'post',
    data
  })
}

// 机构认证
export function institutionInfo(data) {
  return request({
    url:'api/institutionInfo/audit',
    method:'post',
    data
  })
}
// 修改机构名称
export function institutionName(data) {
  return request({
    url:'api/institutionInfo/updateIName',
    method:'post',
    data
  })
}
// 机构中心资料 - 头像
export function editPic(data) {
  return request({
    url:'api/institutionInfo/updatePicture',
    method: 'post',
    data
  })    
}
// 上传文件到OSS
export function sourceUpload(type) {
  return request({
    url:'api/source/upload?type=' + type,
    method: 'post' 
  })
}

// 个人中心我的资料-上传文档
export function documentUpload(data) {
  return request({
    url:'api/personalUser/documentUpload',
    method:'post',
    data
  })
}

// 个人中心资讯 - 从列表发布
export function saveDocument(data) {
  return request({
    url:'api/personalUser/saveDocument?id='+ data,
    method: 'post',
   data
  })
}

// 个人中心资料 -数据回显
export function documentEcho(id) {
  return request({
    url:`api/personalUser/documentEcho`,
    method: 'get', 
    params:{
      'id':id   
    }
  })
}

// 个人中心资料 - 编辑
export function editInfo(data) {
  return request({
    url:'api/personalUser/documentUpdate',
    method: 'post',
    data
  })    
}
