import request from '@/utils/request'

export function getUserVIPTime() {
    return request({
        url: `api/personalUser/vipTime`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}
export function getUserPurchaseCourse(current,size) {
    return request({
        url: `api/personalUser/purchaseCourse`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data:{
            'current':current,
           'size': size,
            
        }
    })
}