<template>
    <div class="main">
        <a href="http://wpa.qq.com/msgrd?v=3&uin=3294192620&site=qq&menu=yes" target="blank">
            <el-popover style="margin-top:5px" :disabled="true" popper-class="popStyle">
                <el-button slot="reference">Q Q客服</el-button>
            </el-popover>
        </a>

        <el-popover style="margin-top:5px" placement="left" width="100" trigger="click">
<!--            <vue-qr style="width:100%;" :text="config.value" :size="200" :margin="0"></vue-qr>-->
            <img style="width: 150px;height: 150px" src="@/assets/images/weixinerweima.png" alt="">
            <el-button slot="reference">微信咨询</el-button>
        </el-popover>
        <el-popover
                style="margin-top:5px"
                placement="left"
                title="咨询电话"
                width="100"
                trigger="click"
                content="010-82965088"
        >
            <el-button slot="reference">服务热线</el-button>
        </el-popover>
        <el-popover
            v-model="visible"
                style="margin-top:5px"
                placement="left"
                title="需求提交"
                width="800"
                trigger="click"
                @hide="clearText"

        >
            <div class="form-box">
                <p class="title-tabs">
                    <i>*</i>需求主题
                </p>
                <el-input type="text" placeholder="请输入内容" v-model="title" maxlength="10"
                          show-word-limit></el-input>
                <p class="title-tabs">
                    <i>*</i>需求类型
                </p>
                <el-radio v-model="radio" label="1">bug反馈</el-radio>
                <el-radio v-model="radio" label="2">账号问题</el-radio>
                <el-radio v-model="radio" label="3">安全培训需求</el-radio>
                <el-radio v-model="radio" label="4">安全咨询需求</el-radio>
                <!--        <el-radio v-model="radio" label="5">其他</el-radio>-->
                <p class="title-tabs">
                    <i>*</i>需求描述
                </p>
            </div>
            <el-button slot="reference">需求提交</el-button>
            <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                    resize="none"
            ></el-input>
            <p class="title-tabs">
                <i>*</i>联系方式
                <span>（我们会及时回复您）</span>
            </p>
            <el-input v-model="phoneNum" v-number-only maxlength="11" @keyup.enter.native="handleQuery"></el-input>
            <br/>
            <br/>
            <button class="commit" @click="commitFeedback">确认提交</button>
        </el-popover>
        <el-popover
                style="margin-top:5px"
                :disabled="true"
                placement="left"
                width="100"
                trigger="click"
        >
            <el-button slot="reference" @click="toTop">返回顶部</el-button>
        </el-popover>
    </div>
</template>

<script>
import {userFeedback} from "@/api/feedback";
import VueQr from "vue-qr";

export default {
    name: "Popover",
    components: {
        VueQr
    },
    methods: {
        toTop() {
            document.documentElement.scrollTop = 0;
        },
        clearText() {
            (this.title = ""), (this.textarea = ""), (this.phoneNum = "");
        },
        commitFeedback() {
            var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
            const data = {
                title: this.title,
                description: this.textarea,
                contacts: this.phoneNum,
                isSolve: false,
                type: this.radio
            };
            if (this.title == "") {
                this.$notify.error({
                    title: "错误",
                    message: "标题未填写"
                });
            } else if (this.textarea == "") {
                this.$notify.error({
                    title: "错误",
                    message: "问题描述未填写"
                });
            } else if (!reg.test(this.phoneNum)) {
                this.$notify.error({
                    title: "错误",
                    message: "请正确填写手机号"
                });
            } else {
                console.log(data)
                userFeedback(data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            title: "成功",
                            message: "您的反馈已经提交成功",
                            type: "success"
                        });
                        this.visible=false
                        // this.textarea = ""
                        // this.title = ""
                        // this.phoneNum = ""
                    } else {
                        this.$notify.error({
                            title: "错误",
                            message: res.message
                        });
                    }
                });
            }
        }
    },
    data() {
        return {
            visible:false,
            config: {
                value: "www.baidu.com" //显示的值、跳转的地址
            },
            title: "",
            radio: "1",
            textarea: "",
            phoneNum: ""
        };
    }
};
</script>

<style scoped>
.commit {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #ff4a35;
    color: #fff;
    cursor: pointer;
}

.title-tabs {
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
}

.title-tabs > i {
    color: red;
}

.wechatCode {
    width: 100%;
}

.popStyle {
    color: black;
}

.main {
    display: flex;
    flex-direction: column;
    width: 66px;
    z-index: 999;
}

.cards {
}

>>> .el-button {
    border: none;
    white-space: normal;
}

>>> .el-button:hover {
    background-color: red;
    color: #fff;
}

>>> .el-button > span {
    display: block;
    height: 36px;
    font-size: 18px;
    width: 36px;
}

.QQ {
    display: block;
    height: 52px;
    font-size: 18px;
    width: 52px;
    background-color: #fff;
}
</style>
