import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";
import App from './App.vue'
import store from './store'


import "./assets/css/common.scss"  // 全局样式
import "./assets/fonts/iconfont.css" // 字体图标
import FileList from "./components/FileList/FileList.vue";
import Recommend from './views/Home/Recommend/index.vue'
import RightTemplate from './components/RightTemplate/index.vue'
import CourseTemplate from './components/CourseTemplate/index.vue'
import InstructorTemplate from './components/InstructorTemplate'
import DotTemplate from './components/DotTemplate/index.vue'

//import './rem'

//使用全局过滤器
// Vue.filter('timeFormat', function(data){
//   const date = new Date(data)
//   return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()} ${date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()}:${date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds()}`
// })

import VideoPlayer from 'vue-video-player'
window.videojs = VideoPlayer.videojs //不设置这个，引用语言会报错
import VueLazyload from 'vue-lazyload'
import { getToken } from './utils/auth';
import VDistpicker from 'v-distpicker'
export default {
  component: { VDistpicker }
}

Vue.use(VueLazyload, {
  loading: require('./assets/uploads/upload data 1.png'),
  error: require('./assets/uploads/course 9.png'),
  try: 3
})

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

require('vue-video-player/src/custom-theme.css')
require('video.js/dist/video-js.min.css')
require('video.js/dist/lang/zh-CN.js') //必须引用，后面设置语言才会生效
Vue.use(VideoPlayer)
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.component('FileList', FileList)
Vue.component('Recommend', Recommend)
Vue.component('RightTemplate', RightTemplate)
Vue.component('CourseTemplate', CourseTemplate)
Vue.component('InstructorTemplate', InstructorTemplate)
Vue.component('DotTemplate', DotTemplate)

router.afterEach(() => {
  window.scrollTo(0, 0);
});
Vue.directive('numberOnly', {
  bind: function (el) {
    el.handler = function () {
      el.childNodes[1].value = el.childNodes[1].value.replace(/\D+/g, '');
    }
    el.addEventListener('keyup', el.handler);
  },
  unbind: function (el) {
    el.removeEventListener('keyup', el.handler);
  }
})


// //机构认证  未登录跳转到登录页
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//       //这里判断用户是否登录，验证本地存储是否有token
//       if (!getToken()) { // 判断当前的token是否存在
//         ElementUI.Notification({
//           message: '仅限机构用户登录后进入！',
//           type: 'error'
//         });
//           // next({
//           //     path: '/personallogin',
//           //     query: { redirect: to.fullPath }
//           // })
//       } else {
//           next()
//       }
//   }
//   else {
//       next() // 确保一定要调用 next()
//   }
// })




Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
  router,
  //在入口文件这里注册store,在每一个组件的身上都拥有一个$store这个属性
  store,
  beforeCreate() { //生命周期钩子，组件创建之前
    Vue.prototype.$bus = this//往原型上放一个VM(this、当前组件的实例对象)
  },

}).$mount("#app");



// 加入百度统计
// router.beforeEach((to, from, next) => {
//   if (to.path) {
//     if (window._hmt) {
//       window._hmt.push(['_trackPageview', '/#' + to.fullPath])
//     }
//   }
//   next()
// })


Vue.config.productionTip = false


//刷新保存状态
if (localStorage.getItem("store")) {
  store.replaceState(
    Object.assign(
      {},
      store.state,
      JSON.parse(localStorage.getItem("store"))
    )
  );
  localStorage.removeItem("store")
}

//监听，在页面刷新时将vuex里的信息保存到localStorage里
window.addEventListener("beforeunload", () => {
  localStorage.setItem("store", JSON.stringify(store.state));
});

