<template>
    <div class="regulationsList">
        <div class="top">
            <div class="top_1" v-for="(it, pi) in rulemaking" :key="it.id">
                <div class="title_z">
                    <div class="text">{{ it.title }}</div>
                    :
                </div>
                <div class="text_r">
                    <ul
                            ref="wrap"
                            class="regulations_list"
                            :style="{ height: it.trggleBtn ? 'auto' : '40px' }"
                    >
                        <li
                                ref="wrapLi"
                                v-for="(i, index) in it.list"
                                :key="'c' + i.id"
                                :class="{
                active:
                  activeProperty === index &&
                  property === pi &&
                  activelis === true,
              }"
                                @click="activeLi(i, index, it, pi)"
                                :style="{ width: liWidth + 'px' }"
                                class="classSty"
                        >
                            {{ i.relusName }}
                        </li>
                    </ul>
                    <el-button
                            type="text"
                            size="mini"
                            @click="trggleHandle(pi)"
                            class="el-icon--right but"
                            :class="rulemaking[pi].butText === '收起' ? 'zs' : 'zk'"
                            v-if="showBtn(pi)"
                    >{{ it.butText }}<i class="el-icon--right" :class="it.icon"></i
                    ></el-button>
                </div>
            </div>
            <div class="top_1" v-if="childlist.length">
                <div class="title_z">
                    <div class="text">城市</div>
                    :
                </div>
                <div class="text_r">
                    <ul class="regulations_list re_list">
                        <li
                                class="classSty"
                                v-for="i in childlist"
                                :key="'d' + i.id"
                                :class="relusID === i.id ? 'active' : ''"
                                @click="cityclick(i)"
                        >
                            {{ i.relusName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getCate} from "@/api/regulations";
import {regulationsData, ZK, SQ} from "./data";
import bus from "./eventBus";

export default {
    data() {
        return {
            liWidth: 100,
            rulemaking: regulationsData,
            ul_width: "",
            activeIndex: null,
            property: "",
            activeProperty: "",
            activelis: false,
            childlist: "",
            economize: "",
            relusID: "",
        };
    },
    computed: {
        showBtn() {
            return function (i) {
                return this.liWidth * regulationsData[i].list.length > this.ul_width;
            };
        },
    },
    mounted() {
        this.cate();
        this.$nextTick(() => {
            this.ul_width = this.$refs.wrap[0].offsetWidth;
        });
        bus.$on("activelis", (data) => {
            this.activelis = data;
        });
    },
    methods: {
        cityclick(i) {
            this.relusID = i.id;
            bus.$emit("city_id", this.relusID);
        },
        cate() {
            getCate(1).then((res) => {
                this.rulemaking[0].list = res.data;
            });
            getCate(2).then((res) => {
                this.rulemaking[1].list = res.data;
            });
        },
        trggleHandle(i) {
            this.rulemaking[i].trggleBtn = !this.rulemaking[i].trggleBtn;
            this.rulemaking[i].butText = this.rulemaking[i].trggleBtn ? SQ : ZK;
            this.rulemaking[i].icon = this.rulemaking[i].trggleBtn
                ? "el-icon-caret-top"
                : "el-icon-caret-bottom";
        },
        activeLi(i, index, it, pi) {
            // console.log(i, index, it, pi, "ppppppppppppppp");
            this.childlist = i.children.length ? i.children : [];
            bus.$emit("ind", it.id, i);
            setTimeout(() => {
                this.activelis = true;
            });
            this.activeProperty = index;
            this.property = pi;
        },
    },
};
</script>

<style lang='less' scoped>
.regulations_list {
  height: 40px;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.regulationsList {
  user-select: none;
  border-radius: 12px;
  overflow: hidden;

  .top {
    background-color: #fff;
    padding: 10px 10px 10px 20px;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 12px !important;
    overflow: hidden;

    .top_1 {
      margin-top: 15px;
      display: flex;

      .title_z {
        width: 115px;
        padding: 0 10px;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        color: #505050;
        font-family: "microsoft yahei";
        background: rgba(255, 63, 41, 0.1);
        color: #eb5139;
        border-radius: 5px;
        display: flex;

        .text {
          width: 110px;
          text-align: justify;
          text-align-last: justify;
          text-justify: distribute-all-lines;
          margin-right: 5px;
        }
      }

      .text_r {
        flex: 1;
        display: flex;

        .zk {
          padding-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #eb5139;
          line-height: 20px;
          z-index: 1;
        }

        .zs {
          padding-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #eb5139;
          line-height: 20px;
          z-index: 1;
        }

        .ulHeight {
          height: 31px;
          overflow: hidden;
        }

        ul {
          flex: 1;

          .classSty {
            text-align: center;
            padding: 0 6px;
            box-sizing: border-box;
            height: 40px;
            display: inline-block;
            line-height: 40px;
            margin-left: 10px;
            color: #444141;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // padding-left: 10px;
            cursor: pointer;

            &:hover {
              color: #ff3f29;
            }
          }

          .active {
            text-align: center;

            background: rgba(255, 63, 41, 0.1);
            border-radius: 5px;
            color: #eb5139;
          }
        }

        .re_list {
          height: auto;
        }
      }
    }
  }
}
</style>
