<template>
    <div>
        <!-- 搜索 -->
        <div class="consulting-experts-search">
            <el-input
                    placeholder="请输入你想找的规章"
                    class="inputP"
                    v-model="searchInputText"
            ></el-input>
            <el-button @click="getSearch" :loading="loading">搜索</el-button>
        </div>
        <!-- 条件 -->
        <div class="condition">
            <div v-for="(i, pi) in SearchCondition" :key="'b' + i.id" class="module">
                <div class="title">
                    <h5>{{ i.title }}</h5>
                    :
                </div>
                <ul>
                    <li
                            :class="{
              'condition-active':
                conditionActiveList[pi].pIndex === pi &&
                conditionActiveList[pi].iIndex === ci,
            }"
                            v-for="(item, ci) in i.list"
                            :key="item.id"
                            @click="conditionActive(i, item, ci, pi)"
                    >
                        {{ item.cateName }}
                    </li>
                </ul>
            </div>
        </div>
        <!-- 规章 -->
        <ul class="regulation">
            <li
                    v-for="(item, index) in regulationsData"
                    :class="{ active: regulationActiveIndex === index }"
                    :key="index"
                    @click="changeRegulation(index, item)"
            >
                {{ item }}
            </li>
        </ul>
        <!-- 内容 -->
        <div class="content_list">
            <ul class="content">
                <li
                        v-for="item in contentData"
                        :key="item.articleId"
                        class="depart_m"
                        @click.prevent="goDeatils(item)"
                >
                    <a href="javascript:"><h5 v-html="item.relusTitle"></h5></a>
                    <p class="content-t" v-html="item.messageAbstract"></p>

                    <p class="de_part">
                        <span>{{ item.keyword }}</span>
                        <span v-if="item.source"> 来源 : {{ item.source }}</span>
                    </p>
                </li>
                <el-pagination
                        @current-change="handleCurrentChange"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="pieces"
                >
                </el-pagination>
            </ul>

        </div>
    </div>
</template>

<script>
import {hylx, SearchCondition} from "./data";
import {getPageByTypeOrRelus} from "@/api/regulations";
import bus from "./eventBus";

export default {
    data() {
        return {
            pieces: 10,
            currentpage: 1,
            total: 0,
            SearchCondition: SearchCondition,
            hylx: hylx,
            regulationsData: ["全部", "部门规章", "地方政府规章"],
            activeName: "second",
            searchInputText: "",
            contentData: "",
            loading: false,
            regulationActiveIndex: 0,
            conditionActiveList: [
                {pIndex: 0, iIndex: 0},
                {pIndex: 1, iIndex: 0},
                {pIndex: 2, iIndex: 0},
                {pIndex: 3, iIndex: 0},
            ],
            btnInd: "",
            time: "",
            type: "",
            content: "",
            relusID: "",
        };
    },
    watch: {
        relusID() {
            this.trggleRegulation(this.btnInd);
        },
    },
    mounted() {
        // console.log(this.$route.query.keyword)
        if (this.$route.query.keyword !== undefined) {
            this.searchInputText = this.$route.query.keyword
            this.trggleRegulation(0);
            console.log("不空")
        } else {
            this.trggleRegulation(0);
            console.log("空")
        }
        bus.$on("ind", (data, item) => {
            this.relusID = item.id;
            this.btnInd = data;
            this.regulationActiveIndex = this.btnInd;
        });
        bus.$on("city_id", (id) => {
            this.relusID = id;
        });

    },

    methods: {
        changeRegulation(i, item) {
            this.currentpage = 1
            // console.log(this.currentpage)
            this.relusID = ""
            // console.log(this.relusID)
            let rslus;
            // console.log(item === undefined)
            if (item !== undefined) {
                rslus = "";
            } else {
                rslus = this.relusID;
            }
            this.regulationActiveIndex = i;
            bus.$emit("activelis", false);
            let data = {
                relusType: i >= 1 ? i : "",
                size: this.pieces,
                current: this.currentpage,
                time: this.time,
                type: this.type,
                titleOrText: this.searchInputText,
                content: this.content,
                relusID: rslus,
            };
            getPageByTypeOrRelus(data).then((res) => {
                this.contentData = res.data.records;
                this.total = res.data.total;
                this.replaceDataText(this.contentData);
            });

        },
        trggleRegulation(i, item) {
            // console.log(i)
            // console.log(i, item, "item");
            let rslus;
            // console.log(item === undefined)
            if (item !== undefined) {
                rslus = "";
            } else {
                rslus = this.relusID;
            }
            this.regulationActiveIndex = i;
            bus.$emit("activelis", false);
            let data = {
                relusType: i >= 1 ? i : "",
                size: this.pieces,
                current: this.currentpage,
                time: this.time,
                type: this.type,
                titleOrText: this.searchInputText,
                content: this.content,
                relusID: rslus,
            };
            getPageByTypeOrRelus(data).then((res) => {
                this.contentData = res.data.records;
                this.total = res.data.total;
                this.replaceDataText(this.contentData);
            });
        },
        goDeatils(item) {
            this.$router.push(
                //path: "/selectregulationsDetails?id=" + item.relusId,
                `/selectregulationsDetails/${item.articleId}`
            );
        },
        // cateList() {
        //   getCateList().then((res) => {
        //     const { data } = res;
        //
        //     // this.SearchCondition[3].list.push(...data);
        //   });
        // },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        conditionActive(it, item, i, j, title) {
            if (it.title === "日期筛选") {
                this.time = item.code;
            } else if (it.title === "行业类型") {
                this.type = item.code;
            } else if (it.title === "搜索位置") {
                this.content = item.code;
            }
            let c = {pIndex: j, iIndex: i};
            this.conditionActiveList.splice(j, 1, c);
            this.trggleRegulation();
        },


        replaceDataText(contents) {
            let copyData = JSON.parse(JSON.stringify(contents));
            let text = this.searchInputText;
            for (let i = 0; i < copyData.length; i++) {
                let {messageAbstract, relusTitle} = copyData[i];
                copyData[i].relusTitle = relusTitle.replace(
                    text,
                    `<span style="color:#ff2832">${text}</span>`
                );
                copyData[i].messageAbstract = messageAbstract.replace(
                    text,
                    `<span style="color:#ff2832">${text}</span>`
                );
            }
            this.loading = false;
            this.contentData = copyData;
        },
        //搜索
        getSearch() {
            this.loading = true;
            this.relusID = ""
            this.trggleRegulation(0);
            // this.replaceDataText(this.contentData);
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentpage = val;
            this.trggleRegulation(this.regulationActiveIndex);
        },
    },
    destroyed() {
        this.SearchCondition[3].list = [{id: 0, code: "", cateName: "不限"}];
    },
};
</script>

<style lang='less' scoped>
/* 搜索 */
.consulting-experts-search {
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #eb5139;
  margin-top: 30px;
}

.consulting-experts-search /deep/ .el-input__inner {
  padding-right: 0px;
  width: 100%;
  height: 98%;
  border: 0px;
  color: #333;
}

.consulting-experts-search /deep/ .el-input {
  width: 85%;
  height: 100%;
}

.consulting-experts-search /deep/ .el-button {
  width: 15%;
  height: 51px;
  color: #fff;
  border: 0px;
  border-radius: 0px;
  background: #eb5139;
  font-size: 16px;
}

/* 条件 */
.condition {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  border: #eb5139 1px dashed;
  font-family: "microsoft yahei";
  /* padding-top: 15px; */
  padding: 15px;
  box-sizing: border-box;

  &-active {
    border-bottom: 1px solid #eb5139;
  }

  .module {
    display: flex;
    margin-left: 0;

    .title {
      width: 80px;
      display: flex;
      margin-right: 19px;

      h5 {
        font-weight: 600;
        width: 69px;
        text-align: right;
        font-size: 15px;
        color: #545454;
        text-align: justify;
        text-align-last: justify;
        /*兼容ie*/
        text-justify: distribute-all-lines;
        margin-right: 10px;
      }
    }

    ul {
      /* margin-bottom: 15px; */
      flex: 1;

      li {
        display: inline-block;
        margin-right: 32px;
        font-size: 14px;
        color: #545454;
        cursor: pointer;

        /* padding-bottom: 4px; */
      }

      .active {
        border-bottom: 1px solid #eb5139;
      }
    }
  }

  .module:nth-child(4) {
    width: 100%;
    padding-top: 15px;
    border-top: #eb5139 1px dashed;
    margin-top: 15px;
  }
}

/* 规章 */
.regulation {
  display: flex;
  border: #eb5139 1px solid;
  height: 60px;
  /* line-height: 60px; */
  font-family: "microsoft yahei";
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;

  .active {
    background: rgba(255, 63, 41, 0.1);
    color: #eb5139;
  }

  li {
    flex: 1;
    line-height: 38px;
    text-align: center;
  }
}

/* 内容 */
.content {
  /* background: #eb5139; */
  min-height: 200px;
  margin-top: 30px;
  box-sizing: border-box;

  .depart_m {
    border-bottom: 1px solid #edb0b0;
    padding-bottom: 20px;
  }

  li {
    a > h5 {
      font-weight: bold;
      font-size: 16px;
      color: #464646;
      margin-top: 15px;
      line-height: 28px;
      margin-bottom: 9px;
      font-family: "microsoft yahei";
    }

    .content-t {
      font-size: 14px;
      color: #838383;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-family: "microsoft yahei";
      line-height: 25px;
    }

    .de_part {
      height: auto;
      display: flex;
      justify-content: first baseline;

      span {
        margin: 0;
        font-family: "microsoft yahei";
        padding: 0 14px;
        font-size: 12px;
        color: #196eaa;
        line-height: 30px;
        height: 30px;
        text-align: center;
        border: 1px solid #c2c2c2;
        margin-right: 19px;
        display: inline-block;
        margin-top: 8px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span:nth-child(1) {
        color: #eb5139;
        border: 1px solid #eb5139;
      }

      span:nth-child(2) {
        border: 1px solid #c2c2c2;
        color: #828282;
      }
    }
  }
}

.content_list /deep/ .el-pagination {
  width: fit-content;
  margin: 31px auto;
}
</style>
