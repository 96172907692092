<template>
  <!-- 新闻资讯页面 -->
    <div class="news">
        <el-row class="container">
            <div class="news-box fl">
                <!-- 新闻分类 start-->
                <div class="classify">
                    <div class="fl">资讯分类：</div>
                    <div class="detailBox fr">
                        <ul class="tatol">
                            <li
                                    :class="{ totalActive1: active }"
                                    style="
                  width: 80px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                "
                                    @click="allFn"
                            >
                                全部
                            </li>
                            <li
                                    v-for="(item, index) in classifyList"
                                    :class="{ totalActive: activeOne == index }"
                                    @click="showOne(index, item.code)"
                                    :key="index"
                            >
                                {{ item.cateName }}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 新闻分类 end -->
                <!-- 下面的内容 -->
                <!-- 资讯详情 start -->
                <ul class="news-all">
                    <li
                            v-for="(news, index) in newsList.records"
                            :key="index"
                            @click="goNewsDeatils(news)"
                            class="informationnews"
                    >
                        <div class="left_time">
                            <div class="left_top">
                                <span class="sun">{{ news.releaseTime.date }}/</span>
                                <span class="themoon">{{ news.releaseTime.month }}月</span>
                            </div>
                            <div class="year">{{ news.releaseTime.year }}年</div>
                        </div>
                        <div class="right_text">
                            <div class="title">{{ news.messageTitle }}</div>
                            <div class="information">
                                {{
                                news.messageDigest
                                    ? news.messageDigest.trim()
                                    : content.trim()
                                }}
                            </div>
                            <div class="tail">
                                <div class="source">
                                    <i class="el-icon-link"></i>
                                    <span>{{ news.source }}</span>
                                </div>
<!--                                <div class="readingvolume">-->
<!--                                    <i class="el-icon-edit"></i>-->
<!--                                    <span-->
<!--                                    >阅读量:<span>{{ news.pageviews }}</span></span-->
<!--                                    >-->
<!--                                </div>-->
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="footer-pagination">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="queryInfo.pagenum"
                            :page-size="queryInfo.pagesize"
                            layout="prev, pager, next, jumper"
                            :total="total"
                            background
                    ></el-pagination>
                </div>

                <!-- 资讯详情 end -->
            </div>

            <!-- 热门课程 -->
            <div class="hotest fr">
                <!-- 热门会议 start -->
                <div class="hot_box">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-notebook-2"></i>
                            <h4>最新会议</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="goMeetingSearch"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="news-list">
                        <li v-for="(n, index) in meetingList" :key="index">
                            <a href="javascript:;" @click="goMeetingDetail(n)">{{
                                n.title
                                }}</a>
                        </li>
                    </ul>
                </div>
                <!-- 热门会议 end -->
                <!-- 推荐课程 start -->
                <div class="hot_box">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-reading"></i>
                            <h4>推荐课程</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="goCourseSearch"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="hot-courses-pic fl">
                        <!-- 图片 -->
                        <li
                                v-for="(c, index) in courseList"
                                :key="c.id"
                                @click="goCourseDetail(c.id)"
                        >
                            <img :src="c.surfacePlotUrl"/>
                            <div class="hot-activities-member" v-if="c.vipCurriculum === 1">
                                会员免费
                            </div>
                            <p>{{ c.headline }}</p>
                        </li>
                    </ul>
                </div>
                <!-- 推荐课程 end -->
                <!-- 热门资讯 start -->
                <div class="hot_box">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-document-copy"></i>
                            <h4>热门资讯</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="goNewsSearch"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="news-list">
                        <li v-for="(item, index) in newsList.records" :key="index">
              <span
                      :class="
                  index >= 3
                    ? 'sequence'
                    : index == 2
                    ? 'three'
                    : index == 1
                    ? 'two'
                    : 'one'
                "
                      class="sequence"
              >{{ (index += 1) }}</span
              >
                            <a href="javascript:;" @click="goNewsDeatils(item)">{{
                                item.messageTitle
                                }}</a>
                        </li>
                    </ul>
                </div>
                <!-- 热门资讯 end -->
                <!-- 热门招聘 start -->

<!--                <div class="hot_box hot-occupation fl">-->
<!--                    <div class="nav_hot">-->
<!--                        <div class="ico_h">-->
<!--                            <i class="icon_t el-icon-position"></i>-->
<!--                            <h4>热门职位</h4>-->
<!--                        </div>-->
<!--                        <a class="more" href="javascript:;" @click="industryposition"-->
<!--                        >更多 &gt;</a-->
<!--                        >-->
<!--                    </div>-->

<!--                    <ul class="hot-occupation-message">-->
<!--                        <li-->
<!--                                v-for="work in workList.slice(0, 8)"-->
<!--                                :key="work.id"-->
<!--                                @click="goJobDetail(work.id)"-->
<!--                        >-->
<!--                            <p class="fl">{{ work.title }}</p>-->
<!--                            <p class="fl" style="width: 80px; margin-left: 53px">-->
<!--                                <em>{{ work.minSalary }}k - {{ work.maxSalary }}k</em>-->
<!--                            </p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->

                <!-- 热门招聘 end -->
            </div>
        </el-row>
    </div>
</template>

<script>
import {getPageByType} from "@/api/news/index";
import {allHotCourse, allHotRecruitment, topCates} from "@/api/common";
import {hotTopic, latestRelease} from "@/api/meeting/index";
import {ceHotRecruitment} from "@/api/hotposition";

export default {
    name: "News",
    data() {
        return {
            activeName: "two",
            queryInfo: {
                pagenum: 1,
                pagesize: 10,
            },
            queryInfoAll: {
                pagenum: 1,
                pagesize: 10,
            },
            total: 0,
            classifyList: [], // 行业分类
            newsList: [], // 资讯列表
            courseList: [], //热门课程
            informationList: [], // 热门资讯
            jobList: [], //热门招聘
            meetingList: [], // 热门会议
            activeOne: null, //一级分类名称显示
            active: true,
            searchResults: "",
            type: "",
            workList: [], // 热门职位
            content:
                "关于印发金属非金属矿山领域 “机械化换人、自动化减人” 试点示范实施方案的函 规划函〔2015〕35号 各试点示范单位： 为推动“机械化换人、自动化减人”科技强安专项行动示范企业试点工作深入开展，充分发挥典型引领作用，现印发总局金属非金属矿山领域示范企业试点实施方案。 附件： 1.首钢矿业公司杏山铁矿实施方案 2.中钢集团山东富全矿业实施方案 3.五矿邯邢矿业安徽开发矿业实施方案 安全监管总局规划科技司 2015年12月11日",
        };
    },
    mounted() {
        // 热门职位
        ceHotRecruitment().then((res) => {
            this.workList = res.data;
        });
        // 顶级行业列表
        topCates().then((res) => {
            if (res.code == 200) {
                this.classifyList = res.data;
            }
        });
        const data = {
            current: this.queryInfoAll.pagenum,
            size: this.queryInfoAll.pagesize,
            type: this.type,
            count: 3,
            industryType: 11,
        };
        this.information(data);

        // 热门课程
        allHotCourse(data).then((res) => {
            if (res.code == 200) {
                this.courseList = res.data;
            }
        });
        // 热门资讯
        // 热门招聘
        allHotRecruitment().then((res) => {
            if (res.code == 200) {
                this.jobList = res.data;
            }
        });
        // 热门会议
        latestRelease("all").then((res) => {
            // console.log(data, "热门会议");
            if (res.code == 200) {
                this.meetingList = res.data;
            }
        });
    },

    methods: {
        industryposition() {
            this.$router.push({
                path: "/findtalent",
            });
        },
        information(data) {
            getPageByType(data).then((res) => {
                // console.log(res, "ggggg");
                if (res.code == 200) {
                    res.data.records.map((item) => {
                        var date1 = new Date(item.releaseTime);
                        return (item.releaseTime = {
                            year: date1.getFullYear(),
                            month: date1.getMonth() + 1,
                            date: date1.getDate(),
                        });
                    });
                    this.newsList = res.data;
                    // console.log(this.newsList);
                    this.total = res.data.total;
                }
            });
        },
        allFn() {
            this.active = true;
            this.activeOne = null;
            this.type = "";
            this.queryInfo.pagenum = 1;
            this.getPageData();
        },
        showOne(index, code) {
            this.activeOne = index;
            this.active = false;
            this.type = code;
            this.queryInfo.pagenum = 1;
            this.getPageData();
        },
        getPageData() {
            const data = {
                current: this.queryInfo.pagenum,
                size: this.queryInfo.pagesize,
                type: this.type,
            };
            this.information(data);
        },
        handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize;
            this.getPageData();
        },
        // 监听page页码值改变的事件
        handleCurrentChange(newPage) {
            this.queryInfo.pagenum = newPage;
            this.getPageData();
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        goNewsDeatils(item) {
            this.$router.push({
                path: "/newsdetails?id=" + item.id,
            });
        },
        goNewsSearch() {
            this.$router.push({
                path: "/newssearch",
            });
        },
        goCourseDetail(id) {
            this.$router.push({
                path: "/coursedetails?id=" + id,
            });
        },
        goCourseSearch() {
            this.$router.push({
                path: "/study",
            });
        },
        goCompanySearch() {
            this.$router.push({
                path: "/jobsearch",
            });
        },
        goJobDetail(n) {
            this.$router.push({
                path: "/jobdetails?id=" + n,
            });
        },
        goMeetingDetail(n) {
            this.$router.push({
                path: "/meetingdetails?id=" + n.id,
            });
        },
        goMeetingSearch() {
            this.$router.push({
                path: "/meetingsearch" + this.searchResults,
            });
        },

        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
    },
};
</script>

<style scoped>
/* .sequence {
  display: inline-block;
} */
/* 热门职业 start */
.hot-occupation {
    width: 100%;
    position: relative;
    padding: 20px;
    height: 330px;
    overflow: hidden;
}

/* 下面的橙色线 */
.hot-occupation span {
    display: inline-block;
    width: 65px;
    height: 7px;
    background: #eb5139;
}

/* 文字信息 */
.hot-occupation-message {
    display: flex;
    flex-direction: column;
    padding: 0 26px;
    box-sizing: border-box;
}

.hot-occupation-message li {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding-top: 10px;
    cursor: pointer;
}

.hot-occupation-message li p {
    width: 200px;
    font-size: 14px;
    color: #666c72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hot-occupation-message li p:nth-child(1) {
    margin: 0;
    width: 164px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hot-occupation-message li p:nth-child(2) {
    flex: 1;
    text-align: right;
}

.hot-occupation-message li p em {
    font-size: 14px;
    color: #fe574a;
    line-height: 22px;
    flex: 1;
    text-align: right;
    max-width: none;
    font-weight: 500;
}

.hot-courses {
    margin-top: 10px;
    width: 391px;
    height: 1600px;
    border-radius: 4px;
    background-color: #fff;
}

.news {
    padding-bottom: 120px;
    margin-top: -75px;
    /* margin-bottom: 31px; */
}

.container {
    width: 1200px;
    margin: 0 auto;
}

.news-box {
    width: 820px;
    height: auto;
    overflow: hidden;
}

.news-box .classify {
    width: calc(100% - 40px);
    background: #fff;
}

/* 课程分类 */
.classify {
    height: auto;
    margin-top: 96px;
    padding: 20px;
    overflow: hidden;
}

.detailBox {
    width: 680px;
    height: 80px;
    margin-top: -10px;
}

.tatol {
    width: 680px;
    height: 80px;
}

.tatol > li {
    float: left;
    white-space: normal;
    margin-left: 0px;
    width: 80px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.tatol > li:hover {
    color: #ff3f29;
    cursor: pointer;
}

.totalActive,
.totalActive1 {
    font-size: 14px;
    background: rgba(255, 63, 41, 0.05);
    border-radius: 0.5rem;
    color: #eb5139;
    border-radius: 4px;
}

.news-all {
    background: #fff;
}

.hotest {
    width: 371px;
    height: auto;
    margin-top: 96px;
    overflow: hidden;
    background: #ffffff;
}

/* 推荐课程 */
.hotest .hot_box {
    width: 100%;
    height: auto;
    padding: 16px;
    /* margin-bottom: 20px; */
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

/* .nav_hot h4 {
  position: absolute;
  top: 0;
  left: 3px;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #33353c;
} */
.nav_hot {
    position: relative;
    width: 100%;
    height: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid #edf0f5;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
}

.nav_hot a {
    /* float: right; */
    font-size: 12px;
    line-height: 30px;
    text-align: right;
    color: #909499;
    margin: 0;
}

.nav_hot b {
    margin-top: 14px;
    display: inline-block;
    width: 65px;
    height: 9px;
    background: #eb5139;
}

.nav_hot em {
    margin-top: 14px;
    display: inline-block;
    width: 65px;
    height: 9px;
    background: #eb5139;
}

.nav_hot span {
    margin-top: 14px;
    display: inline-block;
    width: 65px;
    height: 9px;
    background: #eb5139;
}

.nav_hot a:hover {
    color: #ff3f29;
}

/* 推荐课程 */
.hot-courses-pic {
    display: flex;
    flex-direction: column;
}

.hot-courses-pic li {
    position: relative;
    width: 288px;
    height: 197px;
    margin: 10px 26px;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 27px 26px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
}

/* 左上角会员免费的定位  */
.hot-courses-pic li .hot-activities-member {
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
    border-radius: 0px 0px 8px 0px;
}

.hot-courses-pic li img {
    width: 288px;
    height: 137px;
}

.hot-courses-pic li p {
    margin-top: 15px;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    color: #000;
}

/* 热门招聘 */
.hot_box .job-list {
    width: 100%;
    height: 335px;
    overflow: hidden;
}

.hot_box .job-list li {
    margin-top: 12px;
    list-style: disc;
    list-style-position: inside;
    color: #dddddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.hot_box .job-list li a {
    width: 340px;
    height: 20px;
    line-height: 20px;
    color: #666c72;
}

.hot_box .job-list li a:hover {
    color: #ff3f29;
}

.hot_box .job-list li h4 {
    float: left;
    width: 248px;
    height: 44px;
    margin: 12px 0 13px 14px;
    font-size: 14px;
    line-height: 22px;
    color: #666c72;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* 分页 */
.footer-pagination {
    box-sizing: border-box;
    margin-left: 0;
    position: absolute;
    top: 1900px;
    left: 50px;
    width: 789px;
    height: 55px;
}

.footer-pagination >>> .el-pagination {
    width: 510px;
    height: 24px;
    color: #555656;
}

/* 经过每一个没有选中的数字的颜色 */
.footer-pagination >>> .el-pager li.number {
    color: #555656;
}

/* 左箭头 */
.footer-pagination >>> .el-pagination.is-background .btn-prev {
    background-color: transparent;
}

/* 右箭头 */
.footer-pagination >>> .el-pagination.is-background .btn-next {
    background-color: transparent;
}

/* 数字框 */
.footer-pagination >>> .el-input__inner {
    width: 45px;
    height: 24px;
    border-radius: 2px 2px 2px 2px;
}

/*热门资讯*/
.hotest .news-list {
    width: 100%;
    height: auto;
    padding: 0 26px;
    box-sizing: border-box;
}

.hotest .news-list li {
    margin-top: 12px;
    display: flex;
}

.sequence {
    background: #9195a3;
    width: 20px;
    height: 20px;
    color: #000;
    margin-right: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
}

.one {
    background: red;
    color: #fff;
}

.two {
    background: #f60;
    color: #fff;
}

.three {
    background: #faa90e;
    color: #fff;
}

.hotest .news-list li a {
    color: #666c72;
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hotest .news-list li a:hover {
    color: #ff3f29;
}

.ico_h {
    display: flex;
    color: #eb5139;
    width: 100px;
    margin: 0;
}

.icon_t {
    /* color: #ff3f29; */
    font-size: 30px;
    margin: 0;
    margin-right: 10px;
}

.ico_h h4 {
    margin: 0;
    line-height: 30px;
    font-weight: 600;
}
</style>
<style lang='less' scoped>
.informationnews {
  &:nth-child(1) {
    border-top: 1px solid #e7e4e4;
    // margin-top: 20px;
  }

  width: 793px;
  display: flex;
  height: 165px;

  .left_time {
    padding-top: 38px;
    flex: 1;
    margin: 0;
    padding-right: 15px;
    text-align: right;
    color: #817c7c;
    border-right: 1px solid #e7e4e4;
    position: relative;

    &::after {
      position: absolute;
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      background: #e7e4e4;
      border-radius: 6px;
      top: 44px;
      right: -7px;
    }

    .left_top {
      .sun {
        font-size: 30px;
        color: #0e0d0d;
      }

      .themoon {
        font-size: 21px;
        font-family: math;
        margin-left: 2px;
      }
    }

    .year {
      font-size: 22px;
      font-family: math;
    }
  }

  .right_text {
    border-bottom: 1px solid #e7e4e4;
    margin: 0;
    padding: 15px;
    width: 650px;
    color: #514f4f;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      line-height: 30px;
      color: #131212;
      cursor: pointer;
    }

    .information {
      margin-top: 6px;
      font-size: 13px;
      line-height: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .tail {
      display: flex;
      font-size: 14px;
      margin-top: 12px;

      .source {
        line-height: 15px;
        margin: 0;

        i {
          font-size: 19px;
          margin-right: 1px;
        }
      }

      .readingvolume {
        line-height: 15px;
        margin: 0;
        margin-left: 70px;

        i {
          font-size: 17px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
