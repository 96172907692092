<template>
  <!-- 搜会议首页 -->
    <div class="metting">
        <div
                class="metting-background"
                v-for="(p, index) in bannerList"
                :key="index"
        >
            <a :href="p.targetAddress" target="blank">
                <el-image
                        style="width: 100%; height: 300px"
                        :src="p.pictureUrl"
                        fit="cover"
                ></el-image>
            </a>
        </div>
        <!-- 搜索框 -->
        <div class="metting-search">
            <div class="metting-search-box">
                <!-- 搜索框里的文字 -->
                <el-input
                        v-model="searchResults"
                        placeholder="搜索感兴趣的会议"
                        @keyup.enter.native="goSearch"
                ></el-input>
                <el-button @click="goSearch">搜索</el-button>
                <!-- 搜索框里的字体图标 -->
                <i class="iconfont icon-sousuo"></i>
            </div>
        </div>
        <!-- 版心 -->
        <div class="metting-container">
            <div class="hot-activities fl">
                <!-- 会议集锦start -->
                <!-- <div class="hot-activities-classify">
                  <el-row>
                    <div class="hot-activities-head fl">
                      <h4 class="fl">会议集锦</h4>
                      <div class="hot-activities-more fr">
                        <a href="javascript:;" style="line-height: 42px" @click="tonew"
                          >更多 &gt;</a
                        >
                      </div>
                      <div class="hot-activities-content fl">
                        <ul>
                          <li
                            class="fl hotActives-classfiy"
                            v-for="(collect, index) in collectionList"
                            :key="index"
                            @click="goNew(collect)"
                          >
                            <div class="hot-activities-member">
                              {{ collect.industryType }}
                            </div>
                            <el-image
                              v-if="collect.picture != ''"
                              :src="collect.picture"
                              class="fl imgs"
                              fit="cover"
                            ></el-image>
                            <div class="imgs" v-else>
                              <img
                                class="fl"
                                style="width: 220px; height: 150px"
                                src="@/assets/images/资讯.png"
                              />
                            </div>
                            <div class="hot-activities-FooterContent fl">
                              <h5>{{ collect.messageTitle }}</h5>
                              <div class="fl">
                                {{ timestampToTime(collect.releaseTime) }}
                              </div>
                              <p class="fr" v-html="collect.source"></p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </el-row>
                </div> -->
                <!-- 会议集锦end -->
                <div class="release">
                    <div class="title">最新发布</div>
                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="全部" name="all"></el-tab-pane>
                            <el-tab-pane
                                    :name="item.code"
                                    :label="item.cateName"
                                    v-for="(item, index) in classifyList"
                                    :key="index"
                            ></el-tab-pane>
                            <el-tab-pane name="more" label="更多"></el-tab-pane>
                        </el-tabs>

                        <ul class="hotActives-content">
                            <li
                                    class="hotActives-classfiy fl"
                                    v-for="(latest, index) in latestList"
                                    :key="index"
                                    @click="goMeetingDetail(latest)"
                            >
                                <div class="hot-activities-member">
                                    {{ latest.industryType }}
                                </div>
                                <div class="imgs">
                                    <img :src="latest.surfacePlotUrl" class="fl" />
                                </div>
                                <div class="hot-activities-FooterContent fl">
                                    <h5>{{ latest.title }}</h5>
                                    <div class="fl">
                                        {{ timestampToTime(latest.holdingTime) }}
                                    </div>
                                    <p class="fr">{{ latest.sponsor }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="release">
                    <div class="title">热门会议</div>
                    <div>
                        <el-tabs v-model="activeName1" @tab-click="handleClick111">
                            <el-tab-pane label="全部" name="all"></el-tab-pane>
                            <el-tab-pane
                                    :name="item.code"
                                    :label="item.cateName"
                                    v-for="(item, index) in classifyList"
                                    :key="index + 1"
                            ></el-tab-pane>
                            <el-tab-pane name="more" label="更多"></el-tab-pane>
                        </el-tabs>

                        <ul class="hotActives-content">
                            <li
                                    class="hotActives-classfiy fl"
                                    v-for="(active, index) in hotActive"
                                    :key="index"
                                    @click="goMeetingDetails(active)"
                            >
                                <div class="hot-activities-member">
                                    {{ active.industryType }}
                                </div>
                                <el-image
                                        :src="active.surfacePlotUrl"
                                        class="fl"
                                        fit="fit"
                                        style="width: 220px; height: 135px"
                                ></el-image>
                                <div class="hot-activities-FooterContent fl">
                                    <h5>{{ active.title }}</h5>
                                    <div class="fl">
                                        {{ timestampToTime(active.holdingTime) }}
                                    </div>
                                    <p class="fr">
                                        {{ active.sponsor ? active.sponsor : content }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 左侧的热门活动 end -->
            <!-- 右侧的热门课程 start -->
            <div class="hot-courses fr">
                <!-- 热门课程的信息 start -->
                <div class="hot-courses-information">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-reading"></i>
                            <h4 class="title">热门课程</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="goCourseSearch"
                        >更多 &gt;</a
                        >
                    </div>
                    <CourseTemplate />
                </div>
                <!-- 热门课程的信息 end -->
                <!-- 热门讲师 start -->
                <div class="hot-teacher fl">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-trophy"></i>
                            <h4 class="title">热门工匠</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="lecturer">更多 &gt;</a>
                    </div>
                    <InstructorTemplate />
                </div>
                <!-- 热门讲师 end -->
                <!-- 热门职业 start -->
<!--                <div class="hot_box hot-occupation fl">-->
<!--                    <div class="nav_hot">-->
<!--                        <div class="ico_h">-->
<!--                            <i class="icon_t el-icon-position"></i>-->
<!--                            <h4 class="title">热门职位</h4>-->
<!--                        </div>-->
<!--                        <a class="more" href="javascript:;" @click="popularpositions"-->
<!--                        >更多 &gt;</a-->
<!--                        >-->
<!--                    </div>-->
<!--                    <ul class="hot-occupation-message">-->
<!--                        <li-->
<!--                                v-for="work in workList.slice(0, 8)"-->
<!--                                :key="work.id"-->
<!--                                @click="goJobDetail(work.id)"-->
<!--                        >-->
<!--                            <p class="fl">{{ work.title }}</p>-->
<!--                            <p class="fl" style="width: 80px; margin-left: 45px">-->
<!--                                <em>{{ work.minSalary }}k - {{ work.maxSalary }}k</em>-->
<!--                            </p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
                <!-- 热门职业 end -->
            </div>
            <!-- 右侧的热门课程 end-->
        </div>
        <!-- 底部 -->
        <div class="metting-footer">
            <div class="metting-footer-head">
                <h4 class="fl">合作伙伴</h4>
                <span></span>
            </div>
            <ul class="metting-footer-content">
                <li
                        v-for="(part, index) in partnerList"
                        :key="index"
                        class="footer-img fl"
                >
                    <a  target="blank">
                        <img :src="part.pictureUrl" alt />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { ceHotRecruitment } from "@/api/hotposition";
import { getDocumentPicture, topCates } from "@/api/common";
import {hotTopic, meetingCollection, latestRelease} from "@/api/meeting";

export default {
    name: "SearchConference",
    data() {
        return {
            activeName1: "all",
            tabsData: "111",
            activeName: "all",
            searchResults: "",
            bannerList: [], // banner 图
            hotActive: [], // 热门会议
            latestList: [], // 最新发布
            workList: [], // 热门职位
            partnerList: [], // 合作伙伴
            classifyList: [], // 行业分类切换
            activeOne: 0, //热门活动分类名称显示
            activeTwo: 0, // 最新发布分类名称显示
            collectionList: [], // 会议集锦
            content: "ngfkd9nT48",
        };
    },

    mounted() {
        // 热门职位
        ceHotRecruitment().then((res) => {
            this.workList = res.data;
        });
        this.showOne("all"); //热门会议
        this.getLatestRelease("all"); //最新发布
        // 行业分类切换
        topCates().then((res) => {
            if (res.code === 200) {
                this.classifyList = res.data;
            }
        });
        // banner图
        getDocumentPicture(1, 37).then((res) => {
            if (res.code === 200) {
                this.bannerList = res.data;
            }
        });
        // 合作伙伴
        getDocumentPicture(5, 30).then((res) => {
            if (res.code === 200) {
                this.partnerList = res.data;
            }
        });
        const data = {
            industryType: 11,
        };

        // 代码为0的时候获取全部的最新发布数据数据
        // hotTopicAll(2).then((res) => {
        //     if (res.code == 200) {
        //         this.hotActive = res.data;
        //     }
        // });

        // 会议集锦
        meetingCollection().then((res) => {
            if (res.code == 200) {
                this.collectionList = res.data;
            }
        });
    },
    methods: {
        //
        handleClick(tab) {
            if (tab.name == "more") {
                // console.log("more");
                this.goMeetingSearch();
                return;
            }
            this.getLatestRelease(tab.name);
        },
        getLatestRelease(type) {
            latestRelease(type).then((res) => {
                if (res.code == 200) {
                    this.latestList = res.data;
                }
            });
        },
        handleClick111(tab) {
            if (tab.name == "more") {
                // console.log("more");
                this.goMeetingSearch();
                return;
            }
            this.showOne(tab.name);
        },
        async showOne(tab) {
            hotTopic(tab).then((res) => {
                this.hotActive = res.data;
            });
        },
        lecturer() {
            this.$router.push({
                path: "/consultingexperts",
            });
        },
        popularpositions() {
            this.$router.push({
                path: "/findtalent",
            });
        },
        goCourseSearch() {
            this.$router.push({
                path: "/study",
            });
        },
        goNew(collect) {
            this.$router.push({
                path: "/newsdetails?id=" + collect.id,
            });
        },
        tonew() {
            this.$router.push({
                path: "/newssearch",
            });
        },
        // 热门会议
        goMeetingDetails(active) {
            this.$router.push({
                path:
                    "/meetingdetails?id=" +
                    active.id +
                    "&industryType=" +
                    active.industryType,
            });
        },
        // 最新发布
        goMeetingDetail(latest) {
            this.$router.push({
                path: "/meetingdetails?id=" + latest.id,
            });
        },
        // 搜会议首页输入框
        goSearch() {
            this.$router.push({
                path: "/meetingsearch/?keyword=" + this.searchResults,
            });
        },
        goMeetingSearch() {
            this.$router.push({
                path: "/meetingsearch/?keyword=" + this.searchResults,
            });
        },
        goJobDetail(id) {
            this.$router.push({
                path: "/jobdetails?id=" + id,
            });
        },
        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
    },
};
</script>

<style  scoped>
.release {
    height: 840px;
}
.release > .title {
    text-align: center;
    margin: 10px auto;
    font-size: 25px;
    font-weight: 600;
    color: #222;
    line-height: 2.8125rem;
    font-family: arial, verdana, helvetica, "PingFang SC", "HanHei SC",
    STHeitiSC-Light, Microsoft Yahei, sans-serif !important;
}
.metting {
    //margin-top: 20px;
}
/* 背景图 */
.metting-background {
    position: relative;
}
.metting-background img {
    width: 100%;
    height: 300px;
}
/* 搜索框 */
.metting-search .metting-search-box {
    position: relative;
    left: 0;
    top: -30px;
    width: 698px;
    height: 50px;
    color: #676c71;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eb5139;
}
/* 搜索框里的字体图标 */
.metting-search-box i {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 28px;
}
/deep/ .el-tabs__item{
    padding: 0 0.49rem;
}
    /* element的搜索框 */
.metting-search-box >>> .el-input__inner {
    border: 0;
    width: 596px;
    font-size: 16px;
    padding-left: 76px;
    color: #676c71;
}
.metting-search-box >>> .el-button {
    width: 102px;
    height: 51px;
    color: #fff;
    border: 0px;
    border-radius: 0px;
    background: #eb5139;
    font-size: 16px;
    position: relative;
    right: -46px;
    border-radius: 0 3px 3px 0;
}
/* 版心 */
.metting-container {
    display: flex;
    width: 1210px;
    background-color: #f5f7fa;
    margin: 0 auto;
}
/* 左侧的热门活动 start*/
.hot-activities {
    background: #fff;
    width: 829px;
    padding: 20px;
    padding-top: 10px;
    box-sizing: border-box;
}

/*每一个模块的高度 */
.hot-activities-classify {
    height: 580px;
}

/* 热门会议 */
.hotActives {
    height: 560px;
}
.hotActives-head {
    position: relative;
    width: 100%;
    height: 40px;
}
.hotActives-head h4 {
    line-height: 35px;
    font-size: 18px;
    font-weight: 600;
    color: #eb5139;
}
/* 下面的橙色横线 */
.hotActives-head h4::after {
    content: "";
    position: absolute;
    top: 35px;
    left: 10px;
    width: 43px;
    height: 0px;
    border-bottom: 4px solid #eb5139;
}
/* 更多 */
.hotActives-head .hotActives-more {
    line-height: 50px;
    width: 70px;
    height: 46px;
    text-align: right;
}
.hotActives-head .hotActives-more a {
    width: 100%;
    color: #666;
    font-size: 16px;
}
.hotActives-content {
    margin-top: 20px;
    width: 789px;
    height: 495px;
}
/* 热门活动内容的每一块区域 */
.hotActives-content .hotActives-classfiy {
    position: relative;
    margin: 0 21px;
    width: 220px;
    height: 210px;
    margin-bottom: 30px;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 1.6875rem 1.625rem 0 rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    overflow: hidden;
}

/* 左上角会员免费的定位 */
.hotActives-content .hotActives-classfiy .hot-activities-member {
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    z-index: 1;
    background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
    border-radius: 0 0 8px 0;
}
/* 热门活动内容的每一块区域的图片 */
.imgs {
    width: 220px;
    height: 135px;
    background: #666c72;
    overflow: hidden;
}
.hotActives-content .hotActives-classfiy img {
    width: 220px;
}
.detailBox {
    margin-top: 15px;
    width: 600px;
    height: 580px;
}
.tatol {
    display: flex;
}
.tatol > li {
    margin-left: 0px;
    width: 60px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}
.totalActive {
    color: #fff;
    background: #eb5139;
    border-radius: 4px;
}
/* 会议集锦的头部 */
.hot-activities-head {
    position: relative;
    width: 789px;
    height: 40px;
}
.hot-activities-head h4 {
    line-height: 35px;
    font-size: 18px;
    font-weight: 600;
    color: #eb5139;
}
/* 下面的橙色横线 */
.hot-activities-head h4::after {
    content: "";
    position: absolute;
    top: 35px;
    left: 13px;
    width: 43px;
    height: 0px;
    border-bottom: 4px solid #eb5139;
}
/* 会议集锦的更多 */
.hot-activities-head .hot-activities-more {
    width: 70px;
    height: 46px;
}
.hot-activities-head .hot-activities-more a {
    display: inline-block;
    color: #666;
    font-size: 16px;
}
/* 分类的头部 start*/
.activities-classification {
    height: 40px;
}
/* 分类内容 start */
/* 整个选项的头部 */
.hot-activities-classify >>> .el-tabs__header {
    height: 50px;
    line-height: 50px;
}
/* 整个选项的头部的位置 */
.hot-activities-classify >>> .el-tabs__nav-wrap.is-top {
    margin-left: 0px;
    width: 600px;
}
/* el-tabs默认的下划线 */
.hot-activities-classify >>> .el-tabs__nav-wrap::after {
    width: 0;
    height: 0;
}
/* 每一个选项下面的横线 */
.hot-activities-classify >>> .el-tabs__active-bar {
    width: 0;
    height: 0;
}
/* 第一个选项 的距离*/
.hot-activities-classify >>> #tab-one {
    margin-left: 34px;
}
/* 用来控制每一个选项 */
.hot-activities-classify >>> .el-tabs__item {
    padding: 0px;
    margin: 0px 15px;
    width: 55px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #676c71;
}
/* 选中的样式 */
.hot-activities-classify >>> .el-tabs__item.is-active {
    width: 55px;
    height: 20px;
    text-align: center;
    color: #fff;
    background: #eb5139;
    border-radius: 4px;
}
/* 分类的头部 end */

/* 热门活动的内容 */
.hot-activities-classify >>> .el-tabs__content {
    height: 495px;
}
/* 热门活动内容的每一块区域 */
.hot-activities-content {
    padding-left: 6px;
    box-sizing: border-box;
}
.hot-activities-content ul li {
    position: relative;
    margin: 0 20px;
    margin-bottom: 30px;
    width: 220px;
    height: 212px;
    cursor: pointer;
}
/* 左上角会员免费的定位 */
.hot-activities-content ul li .hot-activities-member {
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
    border-radius: 0px 0px 8px 0px;
}
/* 热门活动内容的每一块区域的图片 */
.hot-activities-content ul li img {
    width: 220px;
    height: 117px;
}
/* 分类内容 end */
/* 左侧的热门活动 end */

/* 右侧的热门课程 start */
.hot-courses {
    margin-left: 10px;
    width: 371px;
    /* height: 1600px; */
    border-radius: 4px;
    background-color: #fff;
}
/* 热门课程的信息 start */
.hot-courses .hot-courses-information {
    position: relative;
    padding: 20px;
    height: 685px;
    overflow: hidden;
}

/* 下面的橙色横线 */
.hot-courses-information span {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 12px;
    width: 65px;
    height: 7px;
    background: #eb5139;
}
/* 热门课程的信息 end */

/* 热门讲师 start */
.hot-teacher {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
/* 下面的橙色横线  */
.hot-teacher span {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 65px;
    height: 7px;
    background: #eb5139;
}
/* 图片信息区域 */

/* 热门讲师 end */

/* 热门职业 start */
.hot-occupation {
    width: 100%;
    position: relative;
    padding: 20px;
    overflow: hidden;
    height: 330px;
    box-sizing: border-box;
}
/* 下面的橙色线 */
.hot-occupation span {
    display: inline-block;
    width: 65px;
    height: 7px;
    background: #eb5139;
}
/* 文字信息 */
.hot-occupation-message {
    display: flex;
    flex-direction: column;
    padding: 0 26px;
}
.hot-occupation-message li {
    padding-top: 10px;
    cursor: pointer;
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.hot-occupation-message li p {
    width: 200px;
    font-size: 14px;
    color: #666c72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hot-occupation-message li p:nth-child(1) {
    margin: 0;
    width: 164px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hot-occupation-message li p:nth-child(2) {
    flex: 1;
    text-align: right;
}
.hot-occupation-message li p em {
    font-size: 14px;
    color: #fe574a;
    line-height: 22px;
    flex: 1;
    text-align: right;
    max-width: none;
    font-weight: 500;
}
/* 热门职业 end */
/* 右侧的热门课程 end */

/* 会议集锦 */
.hot-highlights {
    position: relative;
    width: 1069px;
}
.hot-highlights h4 {
    font-size: 18px;
    font-weight: 600;
    color: #eb5139;
}
.hot-highlights .hot-highlights-more {
    font-size: 18px;
    color: #676c71;
}
/* 下面橙色线 */
.hot-highlights span {
    position: absolute;
    left: 10px;
    top: 28px;
    width: 43px;
    height: 0px;
    border: 4px solid #eb5139;
}
/* 所有的 图片下面的文字区域 */
.hot-activities-FooterContent {
    padding: 0 10px;
    height: 70px;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
}
.hot-activities-FooterContent h5 {
    margin: 5px 0;
    width: 200px;
    font-size: 12px;
    font-weight: 600;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hot-activities-FooterContent div {
    width: 100%;
    font-size: 12px;
    color: #919498;
    margin-bottom: 5px;
}
.hot-activities-FooterContent p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: 12px;
}

/* 底部 */
.metting .metting-footer {
    position: relative;
    width: 1210px;
    height: auto;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 31px;
    padding: 20px;
    box-sizing: border-box;
}
/* 头部文字区域 */
.metting-footer-head {
    height: 30px;
}
.metting-footer-head h4 {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    color: #eb5139;
}
/* 下面的橙色横线 */
.metting-footer-head h4::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 12px;
    width: 43px;
    height: 0px;
    border-bottom: 4px solid #eb5139;
}
.metting-footer-content {
    width: 1200px;
    height: 120px;
}
.metting-footer-content .footer-img {
    margin: 17px 0;
    margin-right: 22px;
    cursor: pointer;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
}
.metting-footer-content .footer-img:nth-child(1) {
    margin-left: 20px;
}
.metting-footer-content .footer-img:nth-last-child() {
    margin-right: 0px;
}
.footer-img img {
    width: 211px;
    height: 82px;
}
.nav_hot {
    position: relative;
    width: 100%;
    height: 22px;
    padding-bottom: 12px;
    border-bottom: 1px solid #edf0f5;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
}
.nav_hot a {
    /* float: right; */
    font-size: 12px;
    line-height: 30px;
    text-align: right;
    color: #666;
    margin: 0;
}
.ico_h {
    display: flex;
    color: #eb5139;
    width: 100px;
    margin: 0;
}
.icon_t {
    /* color: #ff3f29; */
    font-size: 30px;
    margin: 0;
    margin-right: 10px;
}
.ico_h .title {
    margin: 0;
    line-height: 30px;
    font-weight: 600;
}
</style>
