import request from '@/utils/request'

export function setUserNickName(newName) {
    return request({
        url: `api/personalUser/updateNickName?nickName=` + newName,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
    })
}
export function setUserPhoneNum(phone, code) {
    const data = {
        'phone': phone,
        'code': code
    }
    return request({
        url: 'api/personalUser/changePhone',
        method: 'post',
        data

    })
}

export function setUserAvatar(url) {
    return request({
        url: `api/personalUser/updatePicture?url=` + url,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
    })
}