<template>
    <div class="enterprise">
        <div class="gradient"></div>
        <div class="image">
            <img :src="bannerImage" style="width: 100%;height: 100%;" alt=""/>
        </div>
        <div class="hotClass">
            <div>
                <!-- 新课上线 -->
                <div class="index-content-box">
                    <div class="index-course">
                        <div class="index-content-tit" :style="{ display: header }">
                            <h3 style="font-size: 24px;font-weight: bold">热门课程</h3>
                            <!--                            <span @click="goStudy">更多 &gt;</span>-->
                        </div>
                        <div style="border-top: 1px solid #DDDDDD"></div>
                        <ul class="classItem" @mouseenter="upMore" @mouseleave="downMore">
                            <li
                                    style="position: relative"
                                    v-for="item in classList"
                                    :key="item.id"
                                    @click="goCourseDetails(item.id)"
                            >
                                <div class="imgs">
                                    <img :src="item.surfacePlotUrl" alt="" class="cla-p"/>
                                </div>
                                <!-- <div
                                  :style="{
                                    'background-image': 'url(' + item.surfacePlotUrl + ')',
                                  }"
                                  class="cla-p"
                                ></div> -->
                                <p v-if="item.isPay === 3" class="tag">免费观看</p>
                                <p v-if="item.isPay === 2" class="tag">会员免费</p>
                                <p>{{ item.headline }}</p>
                                <div class="classContent">
                                    {{ item.courseDescription }}
                                </div>
                                <!--                                <div class="information">-->
                                <!--                                    <div>{{ item.institutionName }}</div>-->
                                <!--                                </div>-->
                                <!--                                <div class="information">-->
                                <!--                                    <div>{{ timestampToTime(item.onlineTime) }}</div>-->
                                <!--                                </div>-->
                                <div class="follow">
                                    <i class="el-icon-view" style="color: #999999"></i>
                                    已有<span style="color: #E97F37">{{ item.learnNumber }}</span>人学习
                                </div>
                            </li>
                            <span v-if="moreFalse"
                                  style="position: absolute;right: 0;top: 8px;cursor: pointer"
                                  @click="goStudy">更多 &gt;</span>
                        </ul>
                    </div>
                </div>

                <!-- 新课上线 End -->
            </div>
        </div>
        <div class="company-tab-box company-list">
            <div class="hotCompany">
                <h3 style="font-size: 24px;font-weight: bold">热门机构</h3>
            </div>
            <div style="border-top: 1px solid #DDDDDD"></div>
            <ul class="company" @mouseenter="upMoreInstitution" @mouseleave="downMoreInstitution">
                <li
                        class="company-independence"
                        v-for="item in companylist.slice(0,8)"
                        :key="item.id"
                >
                    <div class="sub-li-top">
                        <img :src="item.logo" style="object-fit: contain" alt=""/>
                        <div class="text">
                            <div v-html="item.institutionName" class="title"></div>
                            <div v-if="item.area" class="hotrecruitment">
                                <i class="el-icon-location-information"></i>
                                {{ item.area }}
                            </div>
                        </div>
                    </div>
                    <div class="sub-li-bottom">
                        <!--                        <div class="line"></div>-->
                        <div class="centerhotrecruitment">
                            <div style="font-size: 16px;color: #333;">{{ item.level }}</div>
                            <div>机构规模</div>
                        </div>
                        <div class="line"></div>
                        <div class="hotrecruitment">
                            <div style="font-size: 16px;color: #333;">{{ item.industry }}</div>
                            <div>机构类型</div>
                        </div>
                    </div>
                    <div class="button">
                        <button @click="specificposition(item.id)">
                            查看详情
                        </button>
                    </div>
                </li>
                <span v-if="moreFalseInstitution"
                      style="position: absolute;right: 20px;top: 8px;cursor: pointer"
                      @click="goInstitution">更多 &gt;</span>
            </ul>
        </div>
        <!--        <div class="page">-->
        <!--            <el-pagination-->
        <!--                    @size-change="handleSizeChange"-->
        <!--                    @current-change="handleCurrentChange"-->
        <!--                    :current-page.sync="queryInfo.pagenum"-->
        <!--                    :page-size="queryInfo.pagesize"-->
        <!--                    layout="prev, pager, next, jumper"-->
        <!--                    :total="total"-->
        <!--                    background-->
        <!--            ></el-pagination>-->
        <!--        </div>-->
    </div>
</template>

<script>
import {hotEnterpriseMore} from "@/views/Findtalent/api";
import {getDocumentPicture} from "@/api/common";
import Cla from "@/views/Home/Cla/index.vue";
import {getInstitutionCoures} from "@/api/institution/course";

export default {
    components: {Cla},
    data() {
        return {
            moreFalse: false,
            moreFalseInstitution: false,
            classList: [],
            bannerImage: '',
            inputcompany: "",
            total: 100,
            queryInfo: {
                pagenum: 1,
                pagesize: 12,
            },
            companylist: "",
            levelMapping: {
                1: "小于15人",
                2: "15-50人",
                3: "50-150人",
                4: "150-500人",
                5: "500-2000人",
            },
            industryMapping: {
                "11": "化工医药",
                "12": "电力能源",
                "13": "建筑建材",
                "14": "交通物流",
                "15": "冶金矿山",
                "16": "电子军工",
                "17": "机械机电",
                "18": "轻工环保",
                "19": "信息通信",
            }
        };
    },
    mounted() {
        this.searchlist();
        getDocumentPicture(1, 36).then((res) => {
            if (res.code === 200) {
                this.bannerImage = res.data[0].pictureUrl
            }
        })
        getInstitutionCoures(5).then((res) => {
            if (res.code === 200) {
                this.classList = res.data;
            }
        });
    },
    methods: {
        timestampToTime(date) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(date);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
        upMore() {
            this.moreFalse = true
        },
        downMore() {
            this.moreFalse = false
        },
        upMoreInstitution() {
            this.moreFalseInstitution = true
        },
        downMoreInstitution() {
            this.moreFalseInstitution = false
        },
        goCourseDetails(id) {
            console.log(id);
            this.$router.push("/agencyDetail?id=" + id);
        },
        goStudy() {
            this.$router.push("/allInstitution");
        },
        hotmore() {
            let data = {
                current: this.queryInfo.pagenum,
                size: this.queryInfo.pagesize,
                title: this.inputcompany,
            };
            hotEnterpriseMore().then((res) => {
                console.log(res)
                if (res.code === 200) {
                    res.data.forEach((item) => {
                        item.industry = this.industryMapping[item.industry] || "其他综合";
                        item.level = this.levelMapping[item.level] || "2000人以上";
                        if (item.institutionName.includes(this.inputcompany)) {
                            item.institutionName = item.institutionName.replace(
                                this.inputcompany,
                                `<span style="color:#ff2832">${this.inputcompany}</span>`
                            );
                        }
                    });
                    this.companylist = res.data;
                    this.total = res.data.total;
                }

            });

        },
        handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize;
            this.searchlist();
        },
        handleCurrentChange(newPage) {
            this.queryInfo.pagenum = newPage;
            this.searchlist();
        },
        searchlist() {
            this.hotmore();
        },
        specificposition(code) {
            // this.$router.push(`/morejob/1/0/${code}`);
            this.$router.push({
                path: "/companydetails?id=" + code,
            });
        },
        searchcompany() {
            // this.$router.push(`/morejob/1/${inputcompany}/0`);
            this.hotmore();
        },
        goInstitution() {
            console.log(111)
            this.$router.push({
                path: "/enterprise"
            })
        }
    },
};
</script>

<style lang='less' scoped>
.index-content-box {
  width: 1184px;
  margin: auto;
  margin-top: 20px !important;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}

.tag {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  width: 80px;
  //font-size: 0.75rem;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
  //background-color: #e97f37;
  background-color: rgba(233, 127, 55, 0.8);
  border-radius: 0.5625rem 0 0.625rem 0;

}

.imgs {
  width: 100%;
  height: 134px;
  overflow: hidden;
}

.cla-p {
  width: 100%;
  height: 100%;
  //object-fit: contain;
  transition: all 1s;
}

//.classItem > li:hover > .imgs > .cla-p {
//  transform: scale(1.1);
//}

.classItem > li:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 5px 5px #dad8d8;
}

.classItem > li {
  transition: transform 0.5s ease;
}

.index-content-tit > h3 {
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 28px;
  border-left: #eb5139 4px solid;
  border-radius: 4px;
  padding-left: 13px;
}

.index-content-tit {
  position: relative;
}

.index-content-tit > span {
  position: absolute;
  right: 5%;
  top: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
}

.classItem {
  display: flex;
  //background: #ffffff;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-top: 35px;
  position: relative;
}

.classItem > li {
  background-color: #ffffff;
  border-radius: 9px;
  width: 228px;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 10px;
  box-sizing: border-box;
  margin: 0 0 0 10px;
  box-shadow: 0 0 5px 2px #e6e6e6;
}

.classItem > li:nth-child(1) {
  margin: 0;
}

.classItem > li > img {
  width: 100%;
}

.classItem > li > p:nth-child(3) {
  font-size: 14px;
  width: 220px;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  margin: 6px 4px 5px;
}

.classContent {
  color: #999999;
  padding: 2px 10px;
  //width: 200px;
  font-size: 12px;
  height: 32px;
  //word-break: break-all;
  //overflow: hidden;
  //text-overflow: ellipsis;
  overflow: hidden; /* 超出容器高度的内容进行隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置行数为三行，兼容 WebKit 浏览器，如 Safari 和 Chrome */
  -webkit-box-orient: vertical;
}

//.classContent::after {
//  content: '';
//  display: block;
//  top: -20px;
//  height: 150%; /*隐藏部分的高度*/
//  background-color: white; /* 背景色，可以根据需要调整 */
//  opacity: 1; /* 透明度为1，使其完全隐藏 */
//}

.follow {
  padding: 0 20px;
  //width: 200px;
  height: 20px;
  font-size: 12px;
  text-align: end;
  line-height: 20px;
  margin: 5px 0 0;
}


.information {
  padding: 2px 10px;
}

.information > span,
.information > em,
.information > div {
  font-size: 14px;
  color: gray;
}

.course ul {
  width: 890px;
}

.course ul > li {
  margin-bottom: 20px;
}

.metting-container .classItem > li {
  margin-right: 12px;
}

.hot-activities .classItem > li {
  margin-right: 12px;
}

.hot-activities .classItem > li:nth-child(3n) {
  margin-right: 0 !important;
}

.mainbody .index-content-tit {
  margin-left: 10px;
}

.mainbody .classItem {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.hot-activities .classItem {
  width: 100%;
}

.hotCompany {
  display: flex;
  justify-content: flex-start;
  //padding: 20px 0 10px 0;

  > h3 {
    margin: 20px 0 10px 0;
    font-size: 28px;
    border-left: #eb5139 4px solid;
    border-radius: 4px;
    padding-left: 13px;
  }
}


.enterprise {
  background: #F5F5F5;
  //   height: 1000px;
  position: relative;
  padding-bottom: 20px;

  .gradient {
    width: 100%;
    height: 400px;
    //background-color: white;
    //background-image: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(119, 217, 236));
    position: absolute;
    top: 100px;
    left: 0;
  }

  .image {
    //margin-top: 20px;
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;

    img {
      width: 100%;
      //   height: 100%;
    }
  }

  .headsearch {
    position: absolute;
    margin-top: 350px;
    margin-left: 480px;

    .search {
      margin: 0 auto;
      width: 900px;
      position: relative;
      height: 90px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 12px;

      .searchinput {
        border: 1px solid #eb5139;
        border-radius: 12px;
        overflow: hidden;
      }
    }

    .search /deep/ .searchinput {
      width: 100%;
      height: 50px;
    }

    .searchinput /deep/ .el-input__inner {
      border: none;
      height: 100%;
    }

    .searchinput /deep/ .el-input-group__append {
      border: none;
      width: 150px;
      background: #eb5139;
      color: #fff;
      text-align: center;
      border-radius: 0;
      font-size: 18px;

      .el-input-group__append button.el-button {
        width: 100%;
        height: 100%;
      }
    }
  }

  .company-list {
    position: relative;
    width: 1184px;
    overflow: hidden;
    margin: 20px auto 0;
    background-color: white;
    padding: 20px;
    border-radius: 20px;

    .company {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-top: 35px;
      position: relative;

      .company-independence {
        //height: 270px;
        padding-bottom: 20px;
        width: 268px;
        border-radius: 12px;
        overflow: hidden;
        margin: 0 23px 23px 0;
        transition: transform 0.3s ease;
        border: 1px solid #eeeeee;

        .sub-li-top {
          padding: 30px 20px 10px 20px;
          //background: #fff;
          display: flex;
          background: linear-gradient(to bottom, #F2FAFA, white);

          img {
            width: 50px;
            height: 50px;
            margin: 0 10px 0 0;
            border-radius: 50%;
            background-color: white;
            object-fit: scale-down;
          }

          .text {
            //flex: 1;
            margin: 0;
            width: 148px;

            .title {
              width: 100%;
              font-size: 16px;
              margin-bottom: 9px;
              color: #222;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              //font-weight: 600;
            }

            .conpany-text {
              display: flex;

              li {
                display: inline-block;
                color: #666;
                padding: 0 8px;
                background: #f8f8f8;
                font-size: 13px;
                border-radius: 4px;
                max-width: 66px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 22px;
                margin: 0 5px 0 0;
              }
            }

            .hotrecruitment {
              width: 40%;
              text-align: center;
              color: #999999;
              margin: 0;
            }
          }
        }

        .sub-li-bottom {
          width: 100%;
          height: 48px;
          line-height: 48px;
          font-size: 14px;
          color: #666;
          //background: linear-gradient(90deg, #f5fcfc 0, #fcfbfa 100%);
          padding: 0 22px;
          box-sizing: border-box;
          display: flex;
          margin-top: 20px;

          .centerhotrecruitment {
            width: 50%;
            margin: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            > div {
              //height: 10px;
              line-height: 0;
              width: 100%;
            }

            > div:nth-child(1) {
              margin-bottom: 10px;
            }
          }

          .hotrecruitment {
            width: 50%;
            margin: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            > div {
              line-height: 0;
              width: 100%;
            }

            > div:nth-child(1) {
              margin-bottom: 10px;
            }
          }

          .line {
            height: 25px;
            width: 1px;
            font-size: 14px;
            color: #666;
            background: #ccc;
            position: relative;
            right: 10px;
            top: 18px;
            margin: 0 -10px 0 10px;
          }

          .salary {
            margin: 0;
          }
        }

        .button {
          text-align: center;
          margin: 34px auto auto;

          > button {
            cursor: pointer;
            margin: auto;
            width: 160px;
            height: 34px;
            border-radius: 30px;
            background-color: white;
            border: 1px solid #FF3F29;
            color: #FF3F29;
          }

          > button:hover {
            color: white;
            background-color: #FF3F29;
          }
        }
      }

      .company-independence:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 10px 5px #eee7e7;
      }

    }
  }

  .page {
    width: 412px;
    margin: 30px auto 31px;
  }
}
</style>
