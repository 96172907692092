<template>
    <div>
        <span class="detail-head">我的已购</span>
        <hr
                style="
        margin-top: 10px;
        background-color: #e5e5e5;
        height: 1px;
        border: none;
      "
        />
        <button
                :class="['head-btn', { active: this.boxChecked == 1 }]"
                @click="showClass"
        >
            课程
        </button>
        <button
                :class="['head-btn', { active: this.boxChecked == 0 }]"
                @click="showCard"
        >
            会员卡
        </button>
        <div class="cla" v-if="this.boxChecked == 0">
            <ul>
                <el-empty
                        v-if="myClassData.records && !myClassData.records.length"
                        description="暂无数据"
                ></el-empty>
                <li v-for="item in myClassData.records" :key="item.id">
                    <div class="box-content">
                        <!-- <img :src="item.surfacePlotUrl" alt /> -->
                        <div style="height: 159px; width: 100%">
                            <div
                                    :style="{
                  'background-image': 'url(' + item.surfacePlotUrl + ')',
                }"
                                    class="cla-p"
                            ></div>
                        </div>
                        <span>{{ item.headline }}</span>
                        <div class="box-btn">
                            <button @click="goCourseDetails(item.id, item.cause)">
                                继续学习
                            </button>
                        </div>
                        <p class="study">已学习</p>
                    </div>
                </li>
            </ul>
            <div class="Pga" v-if="myClassData.total > 8">
                <el-pagination
                        background
                        layout="prev, pager, next, jumper"
                        :page-size="8"
                        :current-page.sync="currentPage"
                        :total="myClassData.total"
                        @current-change="handleCurrentChange"
                ></el-pagination>
            </div>
        </div>
        <div v-if="this.boxChecked == 1">
            <div class="vip-card" v-if="this.isVip == true">
                <img src="@/assets/images/Grop233.png"/>
                <i class="vip">
                    会员卡
                    <i class="vip-icon"></i>
                </i>
                <i class="vip-star iconfont icon-star"></i>
                <span class="vip-date"
                >会员到期时间:{{ timestampToTime(vipDate) }}</span
                >
                <button class="vip-pay" @click="goJoinvip">续费会员</button>
            </div>
            <!-- <div v-if="this.isVip==false">暂无会员信息！</div> -->
            <el-empty v-if="!this.isVip" description="暂无会员信息！"></el-empty>
        </div>
    </div>
</template>

<script>
import {getUserVIPTime, getUserPurchaseCourse} from "@/api/user/purchased";
import Config from "@/settings";

export default {
    data() {
        return {
            boxChecked: 0,
            isVip: true,
            vipDate: 0,
            currentPage: 1,
            myClassData: {},
            picUrl: "",
        };
    },
    methods: {
        showCard() {
            this.boxChecked = 1;
        },
        showClass() {
            this.boxChecked = 0;
        },
        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
        goCourseDetails(id, cause) {
            console.log(id)
            //  console.log(cause);
            //  if (cause == "通过") {
            //    this.$router.push({
            //  //    path: "/coursedetails",
            //      name: "coursedetails",
            //      params: {
            //        id
            //      }
            //    });
            //  } else {
            //    this.$notify.error({
            //      title: "错误",
            //      message: cause
            //    });
            //  }
            //console.log(id);
            this.$router.push({
                path: `/coursedetails?id=${id}`,
            });
        },

        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
            getUserPurchaseCourse(val, 8).then((res) => {
                if (res.code === 200) {
                    this.myClassData = res.data;
                }
            });
        },
        goJoinvip() {
            this.$router.push({
                path: "/joinvip",
            });
        },
    },
    mounted() {
        console.log(1111)
        this.picUrl = Config.aliyunBaseUrl;
        this.handleCurrentChange(1);
        getUserVIPTime().then((res) => {
            //console.log(res);
            if (res.code == 400) {
                this.isVip = false;
            } else if (res.code == 200) {
                this.vipDate = res.data.expire;
            }
        });
    },
};
</script>

<style scoped>
.cla-p {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center top;
}

.Pga {
    display: flex;
}

.study {
    background-color: #eb5139;
    width: 48px;
    height: 18px;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    border-radius: 0 20px 20px 0;
    position: absolute;
    z-index: 999;
    left: 10px;
    top: 19px;
}

.cla > ul > li {
    width: 22%;
    margin: 10px;
}

.cla > ul {
    display: flex;
    flex-wrap: wrap;
}

.cla {
    min-height: 588px;
}

.box-content {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    position: relative;
}

.box-content > img {
    margin: 10px;
    height: 139px;
}

.box-content > span {
    width: 90%;
    height: 22px;
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-btn {
    width: 100%;
}

.box-btn > button {
    width: 90%;
    height: 42px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #e5e5e5;
    margin: 13px;
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFang SC-Semibold;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}

.vip-pay {
    width: 80px;
    height: 23px;
    border-radius: 69px 69px 69px 69px;
    border: 1px solid #333333;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    position: absolute;
    right: 136px;
    bottom: 39px;
    background-color: #ece0c0;
}

.vip-date {
    width: 226px;
    height: 20px;
    display: inline-block;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    position: absolute;
    left: 40px;
    bottom: 40px;
}

.vip-star {
    color: #ffdb77;
    font-size: 25px;
    position: absolute;
    top: 83px;
    left: 148px;
}

.vip {
    width: 150px;
    height: 45px;
    font-size: 32px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 38px;
    position: absolute;
    top: 76px;
    left: 40px;
}

.vip-icon {
    width: 30px;
    height: 30px;
    background-color: #0f0f0f;
    display: inline-block;
    border-radius: 50%;
    vertical-align: -10%;
}

.vip-card > img {
    width: 494px;
    height: 223px;
}

.vip-card {
    width: 494px;
    height: 223px;
    border-radius: 4px 4px 4px 4px;
    margin-left: 5px;
    margin-bottom: 55px;
    position: relative;
}

.detail-head {
    width: 64px;
    height: 22px;
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 19px;
}

.head-btn {
    display: inline-block;
    width: 68px;
    height: 26px;
    background: #eb5139;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin: 10px 5px;
    font-size: calc(100vw * 16 / 1920);
    color: white;
    cursor: pointer;
    padding-bottom: 2px;
}

.active {
    color: black;
    background-color: #f4f4f4;
}
</style>
