import request from '@/utils/request'

export function getHomeProfessionCate() {
    return request({
        url: "api/professionCate/list",
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}
export function getHomeRecommend(count, locationId) {
    return request({
        url: `api/advertising/indexSliderShowList`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            locationId: locationId
            ,
            count: count,
        }
    })
}

export function getSearchDetails(current, keyWord, size, type) {
    const data = {
        current: current,
        keyWord: keyWord,
        size: size,
        type: type
    }
    return request({
        url: `api/advertising/indexSearch`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data
    })
}

export function getHomeTeacher(count) {
    return request({
        url: `api/puserAuthentication/indexList`,
        // url: "api/meeting/searchMeeting",
        // url:"api/puserAuthentication/indexListnew",
        method: 'get',
        // data
        params: {
            count: count,
        }
    })
}

export function getHomeClass(count) {
    return request({
        url: `api/courseInfo/indexRecommend`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            count: count,
        }
    })
}

export function getHomeDetails(industryType) {
    return request({
        url: `api/index/show`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            industryType: industryType,
        }
    })
}

export function getBindingPhone(phone){
    return request({
        url:`api/auth/user/binding/${phone}`,
        method:'get'
    })
}

export function wxPhone(data){
    return request({
        url:'api/auth/bingding/wxPhone',
        method:'post',
        data
    })
}
