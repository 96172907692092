import request from '@/utils/request'


export function userUploadVideo(courseId, videoId, surfacePlotUrl, videoDuration, sequence, hourHeadline, tryAndSee, state) {
    return request({
        url: `api/personalUser/uploadVideo`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data: {
            'courseId': courseId,
            'videoId': videoId,
            'surfacePlotUrl': surfacePlotUrl,
            'videoDuration': videoDuration,
            'sequence': sequence,
            'hourHeadline': hourHeadline,
            'tryAndSee': tryAndSee,
            'state': state

        }
    })
}

export function getTeacherInfo(id) {
    return request({
        url: 'api/courseInfo/info/byId?id=' + id,
        method: 'get'
    })
}