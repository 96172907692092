<template>
  <!-- 课程 -->
  <div>
    <ul class="hot-courses-pic fl">
      <!-- 图片 -->
      <li v-for="p in courseList" :key="p.id" @click="goCourseDetail(p.id)">
        <img :src="p.surfacePlotUrl" alt="" />
        <div class="hot-activities-member" v-if="p.vipCurriculum === 1">
          会员免费
        </div>
        <p>{{ p.headline }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { allHotCourse } from "@/api/common";
export default {
  name: "CourseTemplate",
  data() {
    return {
      courseList: [], //热门课程
    };
  },
  mounted() {
    const data = {
      count: 3,
    };
    // 热门课程
    allHotCourse(data).then((res) => {
      if (res.code == 200) {
        this.courseList = res.data;
      }
    });
  },
  methods: {
    goCourseDetail(id) {
      this.$router.push({
        path: "/coursedetails?id=" + id,
      });
    },
  },
};
</script>

<style scoped>
.hot-courses-pic {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
}
.hot-courses-pic li {
  position: relative;
  width: 288px;
  height: 197px;
  margin: 10px 0;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 27px 26px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}
/* 左上角会员免费的定位  */
.hot-courses-pic li .hot-activities-member {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
  border-radius: 0px 0px 8px 0px;
}
.hot-courses-pic li img {
  width: 288px;
  height: 137px;
}
.hot-courses-pic li p {
  margin-top: 15px;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  /* font-weight: 600; */
  text-align: center;
  color: #000;
}
</style>