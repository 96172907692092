<template>
  <div>
    <span class="detail-head">我的资料</span>
    <el-button
      class="new-btn"
      @click="goUploadData"
    >上传资料</el-button>
    <hr
      style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none"
    />
    <div class="file-box">
      <button
        :class="['head-btn', {active:isShow==0}]"
        @click="showUp"
      >上传明细</button>
      <button
        :class="['head-btn', {active:isShow==1}]"
        @click="showDown"
      >下载明细</button>
    </div>
    <!-- 下载 -->
    <FileList
      :fileList="fileList"
      v-if="isShow==1"
    />
    <!-- 上传 -->
    <div v-if="isShow==0">
      <div style="display:flex">
        <div
          style="margin:1px"
          v-for="(i,index) in tabsList"
          :key="index"
          @click="tabsCheck(index)"
        >
          <a>
            <span
              :class="['cla-tabs',{activeTabs:index==active}]">{{i.name}}</span>
          </a>&nbsp;&nbsp;
          <span class="cla-i">|</span>&nbsp;
        </div>
      </div>
      <div class="detail-box">
        <el-empty
          v-if="userUpLoad.records && !userUpLoad.records.length"
          description="暂无数据"
        ></el-empty>
        <div
          v-for="(item,index) in userUpLoad.records"
          :key="item.id"
        >
          <div class="cla-detail">
            <div
              class="img-box"
              @click="goVideoManagement(index)"
            >
              <img
                :src="item.cover"
                style="height:80px;width:80px"
                alt
              />
            </div>
            <div class="m-text">
              <p class="m-tatol">{{item.title}}</p>
              <p class="m-time">{{item.type}}文件</p>
              <div
                class="warning-box"
                v-show="item.status==3"
              >
                <div>
                  <span class="mini-1">|</span>
                </div>
                <span class="warning-taps">{{item.cause}}</span>
              </div>
            </div>
            <div class="crud-box">
              <button @click="publish(item)">
                <i class="iconfont icon-fabu"></i>
                发布
              </button>
              <button @click="edit(item)">
                <i class="iconfont icon-bianji"></i>
                编辑
              </button>
              <button
                class="delete-btn"
                @click="open(item)"
              >
                <i class="iconfont icon-shanchu"></i>
                删除
              </button>
            </div>
          </div>
          <hr class="b-line" />
        </div>
      </div>
      <div
        class="Pga"
        v-if="userUpLoad.total>10"
      >
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :page-size="10"
          :current-page.sync="currentPage"
          :total="userUpLoad.total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserdownload, getUserDocumentAll, delDetails } from "@/api/user/information";
import { saveDocument } from "@/api/institutional/user"

export default {
  data () {
    return {
      active: 0,
      isShow: 0,
      fileList: {},
      tabsList: [
        {
          name: "全部资料",
          color: ""
        },
        {
          name: "审核中",
          color: ""
        },
        {
          name: "已通过",
          color: ""
        },
        {
          name: "未通过",
          color: ""
        }
      ],
      surface:'',
      userUpLoad: {},
      currentPage: 1
    };
  },
  mounted () {
    getUserdownload(1, 10).then(res => {
      if (res.code == 200) {
        this.fileList = res.data;
        console.log(res,'getUserdownload')
      }
    });
    this.getUserDetails();
  },

  methods: {
    publish (item) {
      console.log(item,'item')
      if(item.status === 0) {
        saveDocument(item.id).then(res => {
          if (res.code == 200) {
            this.$message({
              title: "成功",
              message: "发布成功！",
              type: "success"
            });
          } else {
            this.$message({
              title: "错误",
              message: "发布失败！",
              type: "error"
            });

          }
        })
      }else{
        this.$notify({
          title: "仅支持未发布的资料可以发布！",
          type: "warning"
        });
      }
    },


    edit (item) {
      if(item.status === 0 || item.status === 3) {
        this.$router.push({
          path: '/user/editdata?id=' + item.id
        })
      }else{
        this.$notify({
          type: "error",
          title: "该资料审核中或，不能修改!"
        });
      }

    },
    open (item) {
      this.$confirm("内容即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delDetails(item.id).then(res => {
          if (res.code == 200) {
            this.getUserDetails();
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    showUp () {
      this.isShow = 0;
    },
    getUserDetails () {
      getUserDocumentAll(0, 1, 10).then(res => {
        if (res.code == 200) {
          this.userUpLoad = res.data;
          console.log(res,'getUserDocumentAll')
        }
      });
    },
    showDown () {
      this.$store.dispatch("setDonloadPageNum", 1);

      getUserdownload(this.$store.state.user.donloadPageNum, 10).then(res => {
        if (res.code == 200) {
          this.fileList = res.data;
        }
      });
      this.isShow = 1;
    },
    goUploadData () {
      this.$router.push({
        path: "uploaddata"
      });
    },
    tabsCheck (index) {
      this.active = index;
      //console.log(this.active);
      getUserDocumentAll(index, 1, 10).then(res => {
        if (res.code == 200) {
          this.userUpLoad = res.data;
        }
      });
    },
    handleCurrentChange (val) {
      getUserDocumentAll(this.active, val, 10).then(res => {
        if (res.code == 200) {
          this.userUpLoad = res.data;
        }
      });
    }
  },
  computed: {
    pageNum () {
      return this.$store.state.user.donloadPageNum;
    }
  },
  watch: {
    pageNum () {
      getUserdownload(this.$store.state.user.donloadPageNum, 10).then(res => {
        if (res.code == 200) {
          this.fileList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.Pga {
  display: flex;
}
.activeTabs {
  color: #eb5139;
}
.cla-tabs {
  margin: 20px;
  width: 56px;
  height: 20px;
  font-size: calc(100vw * 14 / 1920);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
}
.cla-i {
  color: #e5e5e5;
}
.crud-box {
  display: flex;
  width: 27%;

  margin-left: 1px;
}
.crud-box > button {
  width: 23%;
  height: 40px;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  color: #676c71;
  font-size: calc(100vw * 14 / 1920);
}
.crud-box > button:hover {
  color: #47a7ff;
  border: 1px solid #47a7ff;
  cursor: pointer;
}
.delete-btn:hover {
  color: red !important;
  border: 1px solid red !important;
  cursor: pointer;
}
.warning-taps {
  width: 100%;
  height: 30px;
  font-size: calc(100vw * 14 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6767;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 20px;
}
.mini-1 {
  color: #e5e5e5;
}
.warning-box {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  position: absolute;
  top: 37px;
  left: 20%;
  width: 78%;
}
.m-play {
  width: 33px;
  height: 17px;
  font-size: calc(100vw * 12 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #e5e5e5;
  line-height: 14px;
  margin: 25px 0 1px 0;
}
.m-time {
  width: 144px;
  height: 20px;
  font-size: calc(100vw * 14 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 16px;
  margin: 19px 0;
}
.m-tatol {
  width: 669px;
  height: 20px;
  font-size: calc(100vw * 14 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-text {
  position: relative;
  margin: 10px 23px;
}
.cla-detail {
  display: flex;
  width: 100%;
}
.img-box {
  margin: 10px 0;
  width: 200px;
  display: flex;
}
.img-box > img {
  width: 100px;
  height: 71px;
}
.active {
  color: #ffffff;
  background-color: #eb5139;
}
.head-btn {
  display: inline-block;
  width: 68px;
  height: 26px;

  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 10px 5px;
  font-size: calc(100vw * 16 / 1920);
  cursor: pointer;
  padding-bottom: 2px;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: calc(100vw * 16 / 1920);
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
.new-btn {
  width: 95px;
  height: 29px;
  font-size: calc(100vw * 12 / 1920);
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  background-color: #eb5139;
  margin-left: 91%;
  border: 0;
}
</style>