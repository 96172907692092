//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
import ElementUI from 'element-ui';

import Home from '../views/Home/index.vue'
import Weixin from '../views/Login/weixin.vue'
import News from '../views/News/index.vue'
import NewsSearch from '../views/News/NewsSearch/index.vue'
import NewsDetails from '../views/News/NewsDetails/index.vue'
import Login from '../views/Login/Scan/index.vue'
import PersonalLogin from '../views/Login/PersonalLogin/index.vue'
import FindInformation from '../views/FindInformation/index.vue'
import Search from '../views/Search/index.vue'
import InstructionLogin from '../views/Login/InstitutionLogin/index.vue'
import InstructionRegister from '../views/Register/InstitutionalRegister/index.vue'
import Study from '../views/Study/index.vue'
import Findtalent from '../views/Findtalent/index.vue'
import User from '../views/User/index.vue'
import Userinfo from '../views/User/UserInfo/index.vue'
import Material from '../views/User/Material/index.vue'
import Purchased from '../views/User/Purchased/index.vue'
import Collection from '../views/User/Collection/index.vue'
import Focus from '../views/User/Focus/index.vue'
import SearchConference from '../views/SearchConference/index.vue'
import MeetingDetails from '../views/SearchConference/MeetingDetails/index.vue'
import MeetingSearch from '../views/SearchConference/MeetingSearch/index.vue'
import Information from '../views/User/Information/index.vue'
import InformationDetail from '../views/FindInformation/InformationDetail/index.vue'
import InformationSearch from '../views/FindInformation/InformationSearch/index.vue'
import Course from '../views/User/Course/index.vue'
import AuthenticationCertification from '../views/CraftsmanCertification/AuthenticationCertification/index.vue'
import SubmitCertification from '../views/CraftsmanCertification/SubmitCertification/index.vue'
import AddClass from '../views/User/AddClass/index.vue'
import EditClass from '../views/User/EditClass/index.vue'
import VideoManagement from '../views/User/VideoManagement/index.vue'

import SendCode from '../views/Institutional/AccountSettings/SendCode/index.vue'
import Name from '../views/Institutional/AccountSettings/Name/index.vue'
// import ReceivingCode from '../views/Institutional/AccountSettings/ReceivingCode/index.vue'
import ModifyPassword from '../views/Institutional/AccountSettings/ModifyPassword/index.vue'

import ConsultingExperts from '../views/ConsultingExperts/index.vue'
import InstructorDetails from "../views/ConsultingExperts/InstructorDetails/index.vue"
import InstructorSearch from '../views/ConsultingExperts/InstructorSearch/index.vue'
import VideoUp from '../views/User/VideoUp/index.vue'
import Integral from '../views/User/Integral/index.vue'
import CourseDetails from '../views/CourseDetails/index.vue'
import VideoPlayer from '../views/VideoPlayer/index.vue'
import Company from '../views/Company/index.vue'
import FeedBack from '../views/FeedBack/index.vue'
import SubmitFeedBack from '../views/FeedBack/SubmitFeedBack/index.vue'

import UserInformation from '../views/UserInformation/index.vue'
import Audit from '../views/User/Audit/index.vue'
import {getToken, isUser, isInstitution} from '@/utils/auth'; // getToken from cookie
import NProgress from 'nprogress'; // progress bar
import Config from '@/settings'
// 新增的查规章
import regulations from '@/views/regulations/index.vue'
// import InstitutionalRegister from "@/views/Register/InstitutionalRegister/index.vue";
import regPassword from "@/views/Register/instructionpassword/regPassword.vue";
import newPassword from "@/views/Register/newPasswordView/newPassword.vue";
// 寻找机构
import findAgency from "@/views/findAgency/findAgency.vue"
// 这三行代码是Header.vue页面里面，在导航切换的时候，控制台不报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push;

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}

//使用插件
Vue.use(VueRouter);
//重写VueRouter.prototype身上的push方法了
const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    // {
    //     path:'/passwordindex',
    //     // name:'',
    //     component: passwordIndex,
    //     meta: {
    //         headShow: false,
    //     }
    // },
    {
        path: '/newPassword',
        // name:'',
        component: newPassword,
        meta: {
            headShow: false,
        }
    },
    {
        path: '/regpassword',
        // name:'',
        component: regPassword,
        meta: {
            headShow: false,
        }
    },
    {
        path: '/wx',
        component: Weixin,
        meta: {
            headShow: false,

        }
    },
    {
        path: '/home',
        component: Home,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/news',
        component: News,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    // 这个是新增的查规章
    {
        path: '/selectregulations',
        component: regulations,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/selectregulationsDetails/:id',
        component: () => import('../views/regulations/component/details'),
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/newssearch',
        component: NewsSearch,

        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/newsdetails',
        component: NewsDetails,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/login',
        component: Login,
        meta: {
            headShow: false
        }
    },
    {
        path: '/personallogin',
        component: PersonalLogin,
        meta: {
            headShow: false
        }
    },
    {
        path: '/userprivacy',
        name: 'userprivacy',
        component: () => import('@/views/Login/InstitutionLogin/useragreement/userprivacy.vue'),
        meta: {
            headShow: false
        }
    },
    {
        path: '/userservices',
        name: 'userservices',
        component: () => import('@/views/Login/InstitutionLogin/useragreement/userservices.vue'),
        meta: {
            headShow: false
        }
    },
    {
        path: '/dataUploadProtocol',
        name: 'dataUploadProtocol',
        component: () => import('@/views/User/Securityworkerssettlingin/dataUploadProtocol.vue'),
        meta: {
            headShow: false
        }
    },
    {
        path: '/allInstitution',
        name: 'allInstitution',
        component: () => import('@/views/findAgency/allInstitution.vue'),
        meta: {
            headShow: true,
            keepAlive: false,
            showPop: true
        }
    },
    {
        path: '/search',
        component: () => import('@/views/Search'),
        name: 'search',
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/study',
        name: 'Study',
        component: () => import('@/views/Study'),
        meta: {
            headShow: true,
            keepAlive: false,
            showPop: true

        }
    },
    {
        path: '/instructionlogin',
        component: InstructionLogin,
        meta: {
            headShow: false
        }
    },
    {
        path: '/instructionregister',
        component: InstructionRegister,
        meta: {
            headShow: false
        }
    },
    {
        path: '/findinformation',
        component: FindInformation,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/informationdetail',
        component: InformationDetail,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/informationsearch/:searchWord?',
        component: InformationSearch,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/userinformation',
        component: UserInformation,
        meta: {
            headShow: true,
            showPop: true

        }
    },

    {
        path: '/findtalent',
        component: Findtalent,
        meta: {
            headShow: true,
            showPop: true

        }
    },
    {
        path: '/user',
        component: () => import('@/views/User'),
        // meta: {
        //   headShow: true
        // },
        // redirect: 'user/purchased',
        children: [
            {
                path: '',
                redirect: 'purchased'
            },
            {
                path: 'purchased',
                component: () => import("@/views/User/Purchased"),
                meta: {
                    headShow: true,
                    // requiresAuth: "user",
                }
            },
            {
                path: 'userinfo',
                name: 'userinfo',
                component: () => import('@/views/User/UserInfo'),
                meta: {
                    headShow: true,
                    // requiresAuth: "user",
                }
            },
            {
                path: 'audit',
                name: 'audit',
                component: () => import('@/views/User/Audit'),
                meta: {
                    headShow: true,
                    // requiresAuth: "user",
                }
            },
            {
                path: 'material',
                name: 'material',
                component: () => import('@/views/User/Material'),
                meta: {
                    headShow: true,
                    // requiresAuth: "user",
                }
            },
            // {
            //     path: 'purchased',
            //     name: 'purchased',
            //     component: () => import('@/views/User/Purchased'),
            //     meta: {
            //         headShow: true,
            //         requiresAuth: "user",
            //     },
            //
            // },
            {
                path: 'collection',
                name: 'collection',
                component: () => import('@/views/User/Collection'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'focus',
                name: 'focus',
                component: () => import('@/views/User/Focus'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'information',
                name: 'information',
                component: () => import('@/views/User/Information'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'uploaddata',
                name: 'UploadData',
                component: () => import('@/views/User/UploadData'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'editdata',
                name: 'editData',
                component: () => import('@/views/User/EditData'),
                meta: {
                    keepAlive: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'sell',
                name: 'sell',
                component: () => import('@/views/User/Sell'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'sellPerson',
                name: 'sellPerson',
                component: () => import('@/views/User/SellPerson'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'course',
                name: 'course',
                component: () => import('@/views/User/Course'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },

            },
            {
                path: 'addclass',
                name: 'addclass',
                component: () => import('@/views/User/AddClass'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },
            },
            {
                path: 'videomanagement',
                name: 'videomanagement',
                component: () => import('@/views/User/VideoManagement'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },
            },
            {
                path: 'integral',
                name: 'integral',
                component: () => import('@/views/User/Integral'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'invitation',
                name: 'invitation',
                component: () => import('@/views/User/Invitation'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'editclass',
                name: 'editclass',
                component: () => import('@/views/User/EditClass'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'videoup',
                name: 'videoup',
                component: () => import('@/views/User/VideoUp'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'Securityworkerssettlingin',
                name: 'Securityworkerssettlingin',
                component: () => import('@/views/User/Securityworkerssettlingin'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },

        ]
    },
    {
        path: '/institutional',
        component: () => import('@/views/Institutional'),
        meta: {
            headShow: true
        },
        redirect: 'institutional/meeting',
        children: [
            // 产品
            {

                path: 'product',
                name: 'product',
                component: () => import('@/views/Institutional/Product'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            // 结束
            // 宣传
            {

                path: 'propagate',
                name: 'propagate',
                component: () => import('@/views/Institutional/Propagate'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {

                path: 'propagateIndex',
                name: 'propagateIndex',
                component: () => import('@/views/Institutional/PropagateIndex'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {

                path: 'propagateEditor',
                name: 'propagateEditor',
                component: () => import('@/views/Institutional/PropagateEditor'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            // 完
            {
                path: 'course',
                name: 'course',
                component: () => import('@/views/Institutional/Course'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },

            },
            {
                path: 'addclass',
                name: 'addclass',
                component: () => import('@/views/Institutional/AddClass'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },
            },
            {
                path: 'videomanagement',
                name: 'videomanagement',
                component: () => import('@/views/Institutional/VideoManagement'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                },
            },
            {
                path: 'editclass',
                name: 'editclass',
                component: () => import('@/views/Institutional/EditClass'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'videoup',
                name: 'videoup',
                component: () => import('@/views/Institutional/VideoUp'),
                meta: {
                    headShow: true,
                    requiresAuth: "user",
                }
            },
            {
                path: 'audit',
                name: 'audit',
                component: () => import('@/views/Institutional/Audit'),
                meta: {
                    headShow: true,
                    // requiresAuth: "user",
                }
            },
            {

                path: 'intro',
                name: 'Intro',
                component: () => import('@/views/Institutional/Intro'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'Company',
                name: 'Company',
                component: () => import('@/views/Company/index.vue'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },

            {

                path: 'meeting',
                name: 'Meeting',
                component: () => import('@/views/Institutional/Meeting'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'position',
                name: 'Position',
                component: () => import('@/views/Institutional/Position'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('@/views/Institutional/Account'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'meetingeditor',
                name: 'MeetingEditor',
                component: () => import('@/views/Institutional/MeetingEditor'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'meetingrelease',
                name: 'MeetingRelease',
                component: () => import('@/views/Institutional/MeetingRelease'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'positioneditor',
                name: 'PositionEditor',
                component: () => import('@/views/Institutional/PositionEditor'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {
                path: 'positionrelease',
                name: 'PositionRelease',
                component: () => import('@/views/Institutional/PositionRelease'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            //   视频
            {

                path: 'course',
                name: 'course',
                component: () => import('@/views/Institutional/Course'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },
            {

                path: 'meeting',
                name: 'Meeting',
                component: () => import('@/views/Institutional/Meeting'),
                meta: {
                    headShow: true,
                    requiresAuth: "Institution",
                }
            },

            {
                path: 'sendcode',
                component: SendCode,
                name: 'SendCode',
                meta: {
                    headShow: true
                }
            },
            {
                path: 'name',
                component: Name,
                name: 'Name',
                meta: {
                    headShow: true
                }
            },
            {
                path: 'modifypassword',
                name: 'ModifyPassword',
                component: ModifyPassword,
                meta: {
                    headShow: true
                }
            },

        ]
    },
    {
        path: '/findAgency',
        component: findAgency,
        meta: {
            headShow: true,
        }
    },

    {
        path: '/searchconference',
        component: SearchConference,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/meetingdetails',
        component: MeetingDetails,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/meetingsearch/:searchResults?',
        component: MeetingSearch,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/consultingexperts',
        component: ConsultingExperts,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/instructordetails',
        component: InstructorDetails,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/instructorsearch/:searchResults?',
        component: InstructorSearch,
        meta: {
            headShow: true,
            showPop: true
        }
    },
    {
        path: '/authenticationcertification',
        component: AuthenticationCertification,
        meta: {
            keepAlive: true,
            headShow: true,
            requiresAuth: 'user',
        }
    },
    {
        path: '/submitcertification',
        component: SubmitCertification,
        meta: {
            headShow: true
        }
    },

    {
        path: '/coursedetails',
        component: CourseDetails,
        name: 'coursedetails',
        meta: {
            headShow: true
        }
    },
    {
        path: '/agencyDetail',
        component: () => import('@/views/findAgency/agencyDetail.vue'),
        name: 'agencyDetail',
        meta: {
            headShow: true
        }
    },
    {
        path: '/vedioplayer',
        component: () => import('@/views/VideoPlayer'),
        name: 'videoplayer',
        meta: {
            headShow: false,
            requiresAuth: "user",
        }
    },
    {
        path: '/Agencyvedioplayer',
        component: () => import('@/views/findAgency/index.vue'),
        name: 'Agencyvedioplayer',
        meta: {
            headShow: false,
            requiresAuth: "user",
        }
    },
    {
        path: '/hometabsdetails',
        component: () => import('@/views/HomeTabsDetails'),
        name: 'hometabsdetails',
        meta: {
            headShow: true
        }
    },
    {
        path: '/footermore',
        component: () => import('@/views/FooterMore'),
        name: 'footermore',
        meta: {
            headShow: true
        }
    },
    {
        path: '/companydetails',
        component: () => import('@/views/Findtalent/CompanyDetails.vue'),
        name: 'companydetails',
        meta: {
            headShow: true
        }
    },
    {
        path: '/jobdetails',
        component: () => import('@/views/Findtalent/JobDetails.vue'),
        name: 'jobdetails',
        meta: {
            headShow: true
        }
    },
    {
        path: '/jobsearch',
        component: () => import('@/views/JobSearch/index.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/wechatpayment',
        component: () => import('@/views/WechatPayment/index.vue'),
        name: 'wechatpayment',
        meta: {
            headShow: true,
            requiresAuth: "user",
        }
    },
    {
        path: '/feedback',
        component: FeedBack,
        meta: {
            headShow: true
        }
    },
    {
        path: '/submitfeedback',
        component: SubmitFeedBack,
        meta: {
            headShow: true
        }
    },
    {
        path: '/company',
        component: Company,
        meta: {
            headShow: true,
            requiresAuth: "Institution",
        }
    },
    {
        path: '/joinvip',
        component: () => import('@/views/JoinVIP'),
        meta: {
            headShow: true,
            requiresAuth: 'user',
        }
    },
    {
        path: '/allcourses',
        component: () => import('@/views/AllCourses'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/allinformation',
        component: () => import('@/views/AllInformation'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/email-activation',
        component: () => import('@/views/Register/email-activation'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/activation-success',
        name: 'activationSuccess',
        component: () => import('@/views/Register/email-activation/activation-success'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/allteacher',
        name: 'allteacher',
        component: () => import('@/views/AllTeacher/index.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/alljob',
        name: 'alljob',
        component: () => import('@/views/AllJob/index.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: `/morejob/:id/:title/:code`,
        name: 'morejob',
        component: () => import('@/views/Findtalent/morejob/index.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/enterprise',
        name: 'enterprise',
        component: () => import('@/views/Findtalent/enterprise/index.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/userprivacy',
        name: 'userprivacy',
        component: () => import('@/views/Login/InstitutionLogin/useragreement/userprivacy.vue'),
        meta: {
            headShow: true
        }
    },
    {
        path: '/userservices',
        name: 'userservices',
        component: () => import('@/views/Login/InstitutionLogin/useragreement/userservices.vue'),
        meta: {
            headShow: true
        }
    },


]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    // 兼容firefox
    document.documentElement.scrollTop = 0
    // 兼容safari
    window.pageYOffset = 0
    // next()
    if (to.meta.requiresAuth) {
        if (!getToken()) {
            next('/personallogin?callback=' + encodeURIComponent(to.fullPath))
        } else if (to.meta.requiresAuth == 'Institution' && !isInstitution()) {

            if (from.path != "/login" && from.path != "/personallogin" && from.path != "/instructionlogin") {
                ElementUI.Notification({
                    title: '警告',
                    message: '仅限机构用户登录后进入！',
                    type: 'warning'
                });
                next(from.fullPath)
            } else {
                next("/")
            }
        } else if (to.meta.requiresAuth == 'user' && !isUser()) {

            // if (from.path != "/login" && from.path != "/personallogin" && from.path != "/instructionlogin") {
            //     ElementUI.Notification({
            //         title: '警告',
            //         message: '仅限个人用户登陆后进入！',
            //         type: 'warning'
            //     });
            //     next(from.fullPath)
            // } else {
            //     next("/")
            // }
        }
    }
    next()
})


export default router
