<template>
  <!-- 整体大背景图 -->
    <div class="scan-bg">
        <div class="scan-center">
            <div class="scan-img fl">
                <img src="../../../assets/images/login-bg.png" alt/>
            </div>
            <!-- 登录信息模块 start-->
            <div class="scan-info">
                <!-- 个人和机构 -->
                <div class="personal">
                    <span @click="personallogin" class="personal-login">个人登录</span>
                    <!-- 中间那条竖线 -->
                    <div class="personal-line"></div>
                    <span class="institution-login">机构登录</span>
                    <!-- 机构登录下面的线 -->
                    <div class="institution-login-line"></div>
                </div>
                <!-- 登录信息模块 end-->
                <!-- 验证部分 start -->
                <div class="verification">
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
                        <!-- 账号/邮箱 输入框 -->
                        <el-form-item prop="username">
                            <el-input
                                    v-model="loginForm.username"
                                    class="mobile-number"
                                    placeholder="请输入邮箱"
                            ></el-input>
                        </el-form-item>
                        <!-- 密码输入框 -->
                        <el-form-item prop="password">
                            <el-input
                                    v-model="loginForm.password"
                                    type="password"
                                    auto-complete="new-password"
                                    class="mobile-number"
                                    placeholder="请输入密码"
                            ></el-input>
                        </el-form-item>
                        <!-- 验证码输入框 -->
                        <el-form-item prop="VerificationCode">
                            <el-input
                                    v-model="loginForm.VerificationCode"
                                    class="verification-code fl"
                                    placeholder="请输入验证码"
                            ></el-input>
                            <!-- 验证码图片 -->
                            <div class="verification-code-obtain">
                                <img :src="codeUrl" @click="getCode"/>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 验证部分 end -->

                <!-- 注册账号 -->
                <div style="display: flex">
                    <div class="reg-account">
                        <router-link to="instructionregister">注册账号</router-link>
                    </div>
                    <div class="reg-password">
                        <router-link to="regpassword">找回密码</router-link>
                    </div>
                </div>
                <!-- 立即登录按钮 -->
                <el-button class="log common" @click="InstitutionLog"
                >立即登录
                </el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import {encrypt} from "@/utils/rsaEncrypt";
import {institutionLogin, getCodeImg} from "@/api/login/institution";
import Cookies from "js-cookie";
import {listMeeting} from "@/api/institutional/meeting";

export default {
    name: "InstitutionLogin",
    data() {
        //表单验证——自定义验证邮箱
        var checkEmail = (rule, value, callback) => {
            const regEmail =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (regEmail.test(value)) {
                return callback();
            } else {
                callback(new Error("请输入合法邮箱"));
            }
        };
        //表单验证——自定义验证密码
        var validPassword = (rule, value, callback) => {
            const reg =
                /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{6,18}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(
                    new Error(
                        "密码以字母开头 长度在6~18之间 包含字母、数字、以及特殊符号任意两种"
                    )
                );
            }
        };
        return {
            codeUrl: "",
            loginForm: {
                username: "",
                password: "",
                VerificationCode: "",
                uuid: "",
            },
            queryInfo: {
                pagenum: 1,
                pagesize: 5,
            },
            loginRules: {
                username: [
                    {required: true, message: "邮箱不能为空", trigger: "blur"},
                    {validator: checkEmail, trigger: "blur"},
                ],
                password: [
                    {required: true, message: "密码不能为空", trigger: "blur"},
                    {validator: validPassword, trigger: "blur"},
                ],
                VerificationCode: [
                    {required: true, message: "验证码不能为空", trigger: "change"},
                ],
            },
        };
    },

    created() {
        // 获取用户名密码等Cookie
        //this.getCookie();
        // 获取验证码
        this.getCode();
    },
    methods: {
        personallogin() {
            let url = "/personallogin";
            if (this.$route.query.callback) {
                url += "?callback=" + this.$route.query.callback;
            }
            this.$router.push({
                path: url,
            });
        },
        // 修改
        InstitutionLog() {
            // console.log(111)
            // console.log(this.loginForm.password)
            const password = encrypt(this.loginForm.password);
            // console.log(password)
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    institutionLogin(
                        this.loginForm.username,
                        password,
                        this.loginForm.VerificationCode,
                        this.loginForm.uuid
                    ).then((res) => {
                        // console.log(res);
                        if (res.code == 200) {
                            const h = this.$createElement;
                            this.$notify({
                                message: h("i", {style: "color: green"}, res.message),
                            });
                            // this.$store.dispatch("institutionLogin", res);
                            this.$store.dispatch("userLogin", res);
                            if (this.$route.query.callback) {
                                this.$router.push({
                                    path: decodeURIComponent(this.$route.query.callback),
                                });
                            } else {
                                const data = {
                                    type: 0,
                                    current: this.queryInfo.pagenum,
                                    size: this.queryInfo.pagesize,
                                };
                                listMeeting(data).then((res) => {
                                    if (res.data) {
                                        this.datalist = res.data.records;
                                        this.total = res.data.total;
                                    }
                                });
                                if (res.data.user.nickName == null) {
                                    this.$router.push({
                                        path: "/company",
                                    });
                                } else {
                                    this.$router.push({
                                        path: "/institutional/intro",
                                    });
                                }
                            }
                        } else {
                            const h = this.$createElement;
                            this.$notify({
                                message: h("i", {style: "color: red"}, res.message),
                            });
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getCode() {
            getCodeImg().then((res) => {
                this.codeUrl = res.data.img;
                this.loginForm.uuid = res.data.uuid;
            });
        },
        getCookie() {
            const username = Cookies.get("username");
            let password = Cookies.get("password");
            // 保存cookie里面的加密后的密码
            this.cookiePass = password === undefined ? "" : password;
            password = password === undefined ? this.loginForm.password : password;
            this.loginForm = {
                username: username === undefined ? this.loginForm.username : username,
                password: password,
                VerificationCode: "",
            };
        },
    },
};
</script>

<style scoped>
/* 公共颜色 */
.common {
    color: #fff;
    background-color: #eb5139;
}

/* 整体大背景图  */
.scan-bg {
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/login-background.png") center no-repeat;
    background-size: cover;
    padding-top: 100px;
    overflow: hidden;
}

/* 中间区域的模块 */
.scan-bg .scan-center {
    display: flex;
    align-items: flex-start;
    width: 1238px;
    height: 682px;
    margin: 0 auto 0;
    border-radius: 8px;
    overflow: hidden;
}

/* 左侧小背景图 */
.scan-bg .scan-center .scan-img {
    width: 49.99%;
    height: 100%;
    background: url("../../../assets/images/login-bg.png") no-repeat center center;
    background-size: contain;
}

/*  登录信息模块 start */
.scan-bg .scan-center .scan-info {
    position: relative;
    width: 49.99%;
    height: 100%;
    background-color: #fff;
}

/* 个人和机构 */
.personal {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 81px;
    width: 407px;
    height: 34px;
    font-size: 24px;
    color: #333;
}

/* 个人登录 */
.personal .personal-login {
    width: 96px;
    color: #666;
}

/* 中间那条竖线 */
.personal .personal-line {
    width: 0px;
    height: 54px;
    margin: 106px;
    border: 1px solid #d0d0d0;
}

/* 机构登录 */
.personal .institution-login {
    font-weight: 600;
    width: 96px;
    color: #333;
}

/* 机构登录下面的线 */
.institution-login-line {
    position: absolute;
    top: 36px;
    right: 0;
    width: 94px;
    height: 10px;
    background-color: #eb5139;
}

/*  登录信息模块 end */

/*  验证部分 start*/
/* 手机号输入框 */
.mobile-number >>> .el-input__inner {
    width: 499px;
    height: 44px;
    outline: none;
    color: #333;
    font-size: 16px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 验证码输入框 */
.verification {
    width: 499px;
}

.verification /deep/ .el-dialog {
    border-radius: 50px !important;
}

.verification /deep/ .verification-code {
    width: 321px;
    height: 44px;
    margin-right: 50px;
}

.verification-code >>> .el-input__inner {
    font-size: 16px;
    height: 44px;
    color: #333;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

/* 获取验证码图片 */
.verification-code-obtain img {
    float: left;
    width: 128px;
    height: 44px;
}

/*  验证部分 end*/

/* 注册账号 */
.reg-account {
    width: 64px;
    height: 22px;
    margin-top: 28px;
    margin-left: 65px;
}

.reg-account a {
    font-size: 16px;
    color: #eb5139;
}

.reg-password {
    width: 64px;
    height: 22px;
    margin-top: 28px;
    margin-left: 300px;
}

.reg-password a {
    font-size: 16px;
    color: #eb5139;
}

/* 登录按钮 */
.log {
    margin-left: 65px;
    width: 499px;
    height: 44px;
    border-radius: 44px;
    margin-top: 27px;
    border: 0;
}
</style>
