<template>
  <div>
    <span class="detail-head">积分明细</span>
    <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none" />
    <button :class="['head-btn', {active:this.boxChecked==0}]" @click="showAll">全部</button>
    <button :class="['head-btn', {active:this.boxChecked==1}]" @click="showConsumption">消费</button>
    <button :class="['head-btn', {active:this.boxChecked==2}]" @click="showObtain">获取</button>
    <div class="tatle-head">
      <span class="detail">积分详细</span>
      <span class="zt">状态</span>
      <span class="jf">积分</span>
      <span class="sj">时间</span>
    </div>
    <div class="detail-list">
      <ul>
        <el-empty v-if="integralList.records && !integralList.records.length" description="暂无数据"></el-empty>
        <li class="detail-eve" v-for="(item,index) in integralList.records" :key="item.id">
          <span class="tabs">{{item.title}}</span>
          <span v-show="item.operation==1" class="pay-get">消费</span>
          <span v-show="item.operation==0" class="pay-get">获取</span>
          <span class="pay-num">{{item.integral}}</span>
          <span class="time">{{timestampToTime(item.updateTime)}}</span>
        </li>
      </ul>
    </div>
    <div class="Pga" v-if="integralList.total>10">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :page-size="10"
        :current-page.sync="currentPage"
        :total="integralList.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getUserConsumptionIntegral,
  getUserAcquireIntegral,
  getUserIntegralAll
} from "@/api/user/integral";
export default {
  data() {
    return {
      boxChecked: 0,
      currentPage: 1,
      integralList: {}
    };
  },
  mounted() {
    this.showAll();
  },
  methods: {
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "年" + MM + "月" + d + "日";
    },

    showAll() {
      getUserIntegralAll(1, 10).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.integralList = res.data;
        }
      });
      this.boxChecked = 0;
    },
    showConsumption() {
      getUserConsumptionIntegral(1, 10).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.integralList = res.data;
        }
      });
      this.boxChecked = 1;
    },
    showObtain() {
      getUserAcquireIntegral(1, 10).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.integralList = res.data;
        }
      });
      this.boxChecked = 2;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleCurrentChange(val) {
      if (this.boxChecked == 1) {
        getUserConsumptionIntegral(val, 10).then(res => {
          if (res.code == 200) {
            this.integralList = res.data;
          }
        });
      } else if (this.boxChecked == 2) {
        getUserAcquireIntegral(val, 10).then(res => {
          if (res.code == 200) {
            this.integralList = res.data;
          }
        });
      } else if (this.boxChecked == 0) {
        getUserIntegralAll(val, 10).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.integralList = res.data;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.Pga {
  display: flex;
}
.tabs {
  width: 234px;
  font-size: 14px;
}
.time {
  position: absolute;
  right: 7%;
  font-size: calc(100vw * 16 / 1920);
}
.pay-num {
  color: green;
  position: absolute;
  width: 80px;
  left: 73%;
  font-size: calc(100vw * 16 / 1920);
}
.pay-get {
  color: #676c71;
  position: absolute;
  right: 33.2%;
  font-size: calc(100vw * 16 / 1920);
}
.detail-list {
  width: 100%;
  height: 300px;
}
.tatle-head {
  width: 100%;
  height: 36px;
  background: #f4f4f4;
  position: relative;
}
.detail {
  line-height: 36px;
  color: #676c71;
  font-size: 16px;
  margin-left: 5.8%;
}
.jf {
  line-height: 36px;
  color: #676c71;
  font-size: calc(100vw * 16 / 1920);
  position: absolute;
  right: 24.5%;
}
.zt {
  line-height: 36px;
  color: #676c71;
  font-size: calc(100vw * 16 / 1920);
  position: absolute;
  right: 33.2%;
}
.sj {
  line-height: 36px;
  color: #676c71;
  font-size: calc(100vw * 16 / 1920);
  position: absolute;
  right: 14%;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
.head-btn {
  display: inline-block;
  width: 68px;
  height: 26px;
  background: #f4f4f4;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 10px 5px;
  font-size: 16px;
  color: black;
  cursor: pointer;
  padding-bottom: 2px;
}
.active {
  color: white;
  background-color: #eb5139;
}
.detail-eve {
  position: relative;
  margin: 10px 0;
}
</style>
