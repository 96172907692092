import request from '@/utils/request'

export function vedioPlayer(vid, courseId) {
    return request({
        url: 'api/aliyun/GetVideoPlayAuth',
        method: 'get',
        params: {
            vid: vid,
            courseId: courseId
        }

    })
}

export function createUrl(url) {
    return request({
        url: 'api/aliyun/initVodClient',
        method: 'get',
        params: {
            title: url
        }

    })
}

export function playAuth(id) {
    return request({
        url: 'api/aliyun/GetVideoPlayAuth',
        method: 'get',
        params: {
            courseId: id,


        }

    })
}
