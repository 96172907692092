
import { getToken, setToken, removeToken } from '@/utils/auth'


const state = {
  token: JSON.parse(localStorage.getItem("COMPANY_AUTH_INFO")) || '',
  userInfo: JSON.parse(localStorage.getItem("USER_AUTH_INFO")) || {},
  teaPageNum: 1,
  donloadPageNum: 1,
  roles: JSON.parse(localStorage.getItem("USER_ROLES_INFO")) || [],
}

const mutations = {

  USERLOGIN(state, data) {
    state.userInfo = data.data.user;
    state.token = data.data.token;
    state.roles = data.data.roles;
    localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(data.data.token))
    localStorage.setItem("USER_AUTH_INFO", JSON.stringify(data.data.user))
    localStorage.setItem("USER_ROLES_INFO", JSON.stringify(data.data.roles))
  },
  USERLOGOUT(state) {
    state.userInfo = {}
    state.token = ''
    state.roles = []
    localStorage.removeItem('COMPANY_AUTH_INFO')
    localStorage.removeItem('USER_AUTH_INFO')
    localStorage.removeItem('USER_ROLES_INFO')
  },
  SETNAME(state, newName) {
    state.userInfo.nickName = newName
    localStorage.setItem("USER_AUTH_INFO", JSON.stringify(state.userInfo))
  },
  SETTEAPAGENUM(state, val) {
    state.teaPageNum = val
  },
  SETDONLOADPAGENUM(state, val) {
    state.donloadPageNum = val
  }


}

const actions = {
  //登录业务
  userLogin({ commit }, data) {

    //服务器下发token，用户唯一标识符(uuid)
    //将来经常通过带token找服务器要用户信息进行展示

    //用户已经登录成功且获取到token
    commit("USERLOGIN", data);
    //持久化存储token


  },
  userLogOut({ commit }) {
    commit("USERLOGOUT");
  },
  setName({ commit }, newName) {
    commit('SETNAME', newName)
  },
  setTeaPageNum({ commit }, val) {
    commit('SETTEAPAGENUM', val)
  },
  setDonloadPageNum({ commit }, val) {
    commit('SETDONLOADPAGENUM', val)
  }





}



export default {

  state,
  mutations,
  actions,
};
