<template>
    <div class="main">
        <div class="head">
            <div class="p-box">
                <el-image
                        style="width: 514px; height: 265px"
                        :src="courseInfo.surfacePlotUrl"
                        fit="cover"
                ></el-image>
            </div>
            <div class="text-box">
                <h3 class="v-name">{{ courseInfo.headline }}</h3>
                <div class="title">
                    <p class="study">{{ courseInfo.learnNumber }}学过</p>
                    <!-- 收藏按钮开始 -->
                    <div class="sc">
                        <img
                                v-show="courseInfo.isCollection == false"
                                @click="collection"
                                src="../../assets/images/Group216.png"
                                alt
                        />
                        <img
                                v-show="courseInfo.isCollection == true"
                                style="width: 21.5px; height: 21px"
                                src="@/assets/images/Star 13.png"
                                alt
                                @click="cancelCollection"
                        />
                        <span
                                v-show="courseInfo.isCollection == false"
                                @click="collection"
                                style="
                margin-right: 40px;
                margin-left: 3px;
                vertical-align: middle;
                cursor: pointer;
              "
                        >收藏</span
                        >
                        <span
                                v-show="courseInfo.isCollection == true"
                                @click="cancelCollection"
                                style="
                margin-left: 3px;
                margin-right: 40px;
                vertical-align: middle;
                cursor: pointer;
              "
                        >已收藏</span
                        >
                        <!-- 收藏按钮结束 -->
                        <div class="share fr" style="position: relative">
                            <div @click.stop="shareFn">
                                <i class="iconfont icon-fenxiang"></i>分享
                            </div>
                            <div
                                    style="position: fixed;top: 0;left: 0;width: 100vw;height: 100vh;opacity: 0;z-index: 0;"
                                    v-show="shareBox"
                                    @click.stop="shareBox = false"
                            ></div>
                            <div class="share_box" v-show="shareBox">
                                <ul class="share-ways">
                                    <li
                                            class="share-QQ"
                                            @mouseenter="qqFn1"
                                            @mouseleave="qqFn2"
                                            @click="qqUrl"
                                    >
                                        <img :src="img" class="qq-img" alt=""/>
                                    </li>
                                    <li
                                            class="share-wechat"
                                            @mouseenter="wechatFn1"
                                            @mouseleave="wechatFn2"
                                    >
                                        <img :src="pic" class="weixin-img" alt=""/>
                                        <div class="share_wechat_ercodebox" v-show="wechatBox">
                                            <div class="share-wechat-ercode" ref="qrCodeUrls">
                                                <!-- <div
                                                id="qrcode"
                                                ref="qrcode"
                                              ></div>
                                              <img
                                                src=""
                                                alt=""
                                            >-->
                                            </div>
                                            <div class="arrow-down arrow-down-out"></div>
                                            <div class="arrow arrow-out"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="price-box" >
                    <div class="price">
                        <span class="ft_14">价格</span>
                        <p class="ml-4" v-if="courseInfo.isPay !== 3">
                            <i class="ft_24"> ￥</i>{{ courseInfo.originalPrice.toFixed(2) }}<span  style="font-size: 14px;margin-left: 3px">元</span>
                        </p>
                        <p class="ml-4"  v-else>
                            <i class="ft_24"> ￥</i>0.00<span style="font-size: 14px;margin-left: 3px">元</span>
                        </p>
                    </div>

                    <!--                    <span class="vip-price" v-if="this.$route.query.ispay != 3"-->
                    <!--                    >会员价:￥{{-->
                    <!--                        (courseInfo.originalPrice * courseInfo.discountRate).toFixed(2)-->
                    <!--                        }}</span>-->
                   <span v-if="courseInfo.isPay===2">
                        <span
                            v-if="this.$store.state.user.userInfo.isVip == 1"
                            class="vip-price1"
                            @click="goVip"
                        >您已开通会员</span
                        >
                    <span v-else class="vip-price1" @click="goVip">会员免费立即开通</span>
                   </span>
                </div>
                <p class="z-tabs" style="display: none">赠品</p>
                <div class="wd-items" style="margin-top: 7px">
                    <p>{{ courseInfo.industryType }}</p>
                    <p>{{ courseInfo.special }}</p>
                </div>
                <!-- 普通付费课程 -->
                <div class="btn-box" v-if="courseInfo.isPay === 1">
                    <button
                            class="immediately"
                            v-if="courseInfo.isBuy === false"
                            style="color: #ffffff; cursor: pointer"
                            @click="buy()"
                    >
                        立即购买
                    </button>
<!--                    <button-->
<!--                            class="freeofcharge"-->
<!--                            disabled-->
<!--                            v-if="courseInfo.isBuy === true"-->
<!--                            @click="buy(courseInfo)"-->
<!--                    >-->
<!--                        已购买,立即观看-->
<!--                    </button>-->
                    <button
                        class="freeofcharge"

                        v-if="courseInfo.isBuy === true"
                        @click="goPlayVedio()"
                    >
                        已购买,立即观看
                    </button>
                    <el-dialog
                            title="确定订单信息"
                            :visible.sync="dialogVisible"
                            width="40%"
                            :before-close="handleClose"
                            style
                    >
                        <div class="pay-body-box">
                            <div class="pay-head">
                                <el-image
                                        fit="cover"
                                        style="width: 100px; height: 60px"
                                        :src="courseInfo.surfacePlotUrl"
                                        alt
                                ></el-image>
                                <span>{{ courseInfo.headline }}</span>
                            </div>
                            <span class="payment-method">支付方式</span>
                            <div class="payment-method-box">
                                <p v-if="payShow === 0" class="wechat-pay">
                                    <img src="@/assets/images/Vector.png" alt/>
                                    <span>微信支付</span>
                                </p>
                                <p v-if="payShow === 1" class="wechat-pay">
                                    <img src="@/assets/images/Group 275.png" alt/>
                                    <span>支付宝支付</span>
                                </p>
                                <p class="wechat-pay1" @click="wechayPay">
                                    <i :class="{ payActive: payShow === 0 }"></i>
                                    <img src="@/assets/images/Vector.png" alt/>
                                    <span>微信支付</span>
                                </p>
                                <p class="wechat-pay1" @click="zhifubao">
                                    <i :class="{ payActive: payShow === 1 }"></i>
                                    <img src="@/assets/images/Group 275.png" alt/>
                                    <span>支付宝支付</span>
                                </p>
                            </div>
                            <i class="iconfont icon-chahao close" @click="closePay"></i>
                            <span class="payment-method">购买课程</span>
                            <div class="course-box">
                                <el-image
                                        fit="cover"
                                        style="width: 190px; height: 82px"
                                        :src="courseInfo.surfacePlotUrl"
                                        alt=""
                                ></el-image>
                                <span>{{ courseInfo.headline }}</span>
                                <i>￥ {{ courseInfo.originalPrice }}</i>
                                <!-- <p>永久可看</p> -->
                            </div>
                            <hr class="foot-line"/>
                            <div class="bottom-contect">
                <span style="color: #9f9f9f">
                  实付:
                  <i style="color: #ed702e"
                  >￥ {{ courseInfo.originalPrice }}</i
                  >
                </span>
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
              <i
              >提交订单则表示您同意《中安工匠用户服务协议》丶《中安工匠售后政策》等</i
              >
              <el-button
                      type="primary"
                      style="background-color: #ed702e; color: #fff"
                      @click="payment"
              >立即支付</el-button
              >
            </span>
                    </el-dialog>
<!--                    <button-->
<!--                            class="freeofcharge"-->
<!--                            v-if="courseInfo.isBuy == false"-->
<!--                            @click="goPlayVedio()"-->
<!--                    >-->
<!--                        免费试看-->
<!--                    </button>-->
                </div>
                <!-- 免费课程 -->
                <div class="btn-box" v-if="courseInfo.isPay === 3">
                    <button class="freeviewing" @click="goPlayVedio()">免费观看</button>
                </div>
                <!-- 会员免费课程 -->
                <div class="btn-box" v-if="courseInfo.isPay === 2">
<!--                    <button-->
<!--                            class="immediately"-->
<!--                            v-if="-->
<!--!this.$store.state.user.userInfo.mock ||-->
<!--              courseInfo.isBuy === false &&-->
<!--              this.$store.state.user.userInfo.isVip === 0-->
<!--            "-->
<!--                            style="color: #ffffff; cursor: pointer"-->
<!--                            @click="buy()"-->
<!--                    >-->
<!--                        立即购买-->
<!--                    </button>-->
                    <button
                            disabled
                            v-if="
              courseInfo.isBuy === true ||
              this.$store.state.user.userInfo.isVip === 1
            "
                            style="
              color: rgb(171 165 165);
              cursor: not-allowed;
              border: 1px solid #ccc;
            "
                            @click="buy(courseInfo)"
                    >
                        已购买或已经是会员
                    </button>
                    <button
                            v-else
                            class="immediately"
                            style="color: #ffffff; cursor: pointer"
                            @click="buy()"
                    >
                        立即购买
                    </button>
                    <el-dialog
                            title="确定订单信息1"
                            :visible.sync="dialogVisible"
                            width="40%"
                            :before-close="handleClose"
                            style
                    >
                        <div class="pay-body-box">
                            <div class="pay-head">
                                <el-image
                                        fit="cover"
                                        style="width: 100px; height: 60px"
                                        :src="courseInfo.surfacePlotUrl"
                                        alt=""
                                ></el-image>
                                <span>{{ courseInfo.headline }}</span>
                            </div>
                            <span class="payment-method">支付方式</span>
                            <div class="payment-method-box">
                                <p v-if="payShow === 0" class="wechat-pay">
                                    <img src="@/assets/images/Vector.png" alt/>
                                    <span>微信支付</span>
                                </p>
                                <p v-if="payShow === 1" class="wechat-pay">
                                    <img src="@/assets/images/Group 275.png" alt/>
                                    <span>支付宝支付</span>
                                </p>
                                <p class="wechat-pay1" @click="wechayPay">
                                    <i :class="{ payActive: payShow === 0 }"></i>
                                    <img src="@/assets/images/Vector.png" alt/>
                                    <span>微信支付</span>
                                </p>
                                <p class="wechat-pay1" @click="zhifubao">
                                    <i :class="{ payActive: payShow === 1 }"></i>
                                    <img src="@/assets/images/Group 275.png" alt/>
                                    <span>支付宝支付</span>
                                </p>
                            </div>
                            <i class="iconfont icon-chahao close" @click="closePay"></i>
                            <span class="payment-method">购买课程</span>
                            <div class="course-box">
                                <el-image
                                        fit="cover"
                                        style="width: 190px; height: 82px"
                                        :src="courseInfo.surfacePlotUrl"
                                        alt=""
                                ></el-image>
                                <span>{{ courseInfo.headline }}</span>
                                <i>￥ {{ courseInfo.originalPrice }}</i>
                                <!-- <p>永久可看</p> -->
                            </div>
                            <hr class="foot-line"/>
                            <div class="bottom-contect">
                <span style="color: #9f9f9f">
                  实付:
                  <i style="color: #ed702e"
                  >￥ {{ courseInfo.originalPrice }}</i
                  >
                </span>
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
              <i
              >提交订单则表示您同意《中安工匠用户服务协议》丶《中安工匠售后政策》等</i
              >
              <el-button
                      type="primary"
                      style="background-color: #ed702e; color: #fff"
                      @click="payment"
              >立即支付</el-button
              >
            </span>
                    </el-dialog>
                    <button
                            v-if="
              courseInfo.isBuy === true ||
              this.$store.state.user.userInfo.isVip === 1
            "
                            style="
              background-color: #ffffff;
              color: #eb5139;
              border: 1px solid #eb5139;
            "
                            @click="goPlayVedio()"
                    >
                        立即观看
                    </button>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="body-left">
                <div style="background-color: #f1f1f1">
          <span :class="['left-head', { active: isShow == 0 }]" @click="show0"
          >介绍</span
          >
                    <span :class="['left-head', { active: isShow == 1 }]" @click="show1"
                    >目录</span
                    >
                </div>
                <div v-if="isShow == 0">
                    <div class="left-detail">
                        <p>讲师姓名：{{ courseInfo.teacherName }}</p>
<!--                        <p>讲师单位：{{ courseInfo.affiliated }}</p>-->
                        <p>课程性质：{{ courseInfo.industryType }}</p>
                        <h3 style="line-height: 1.5;letter-spacing: 1px" >{{ courseInfo.courseDescription }}</h3>
                        <img
                                v-for="(p, index1) in courseInfo.urls"
                                :key="index1"
                                class="img-bottom"
                                :src="p"
                                alt
                        />
                    </div>
                </div>
                <div class="left-detail" v-else>
                    <ul>
                        <li
                                class="vedio-item"
                                v-for="(item, index) in vedioList.records"
                                @click="goPlay(item.videoId, item.hourHeadline, index)"
                                :key="item.id"
                        >
<!--                            <span class="tab-first">{{ item.sequence }}</span>-->
                            <span class="tab-first"><i class="el-icon-video-camera" style="margin-right: 5px"></i>视频:</span>
                            <span class="tab2">{{ item.hourHeadline }}</span>
                            <span class="tab3">时长:{{ item.videoDuration }}</span>
                        </li>
                    </ul>
                    <div class="Pga" v-if="vedioList.total > 10">
                        <el-pagination
                                background
                                layout="prev, pager, next, jumper"
                                :page-size="10"
                                :current-page.sync="currentPage"
                                :total="vedioList.total"
                                @current-change="handleCurrentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
            <div class="body-right">
                <div class="postcard" v-if="this.teacherInfo.name">
                    <div class="header">
                        <img :src="this.teacherInfo.picture" alt=""/>
                    </div>
                    <ul>
                        <li class="name">{{ this.teacherInfo.name }}</li>
                        <li class="title1">{{ this.teacherInfo.title }}</li>
                        <li class="field">
                            {{ this.teacherInfo.greatField }}
                        </li>
                        <li class="personalintroduction">
                            {{ this.teacherInfo.introduce }}
                        </li>
                    </ul>
                </div>
                <div class="hot_box fl">
                    <div class="nav_hot">
                        <div class="ico_h">
                            <i class="icon_t el-icon-reading"></i>
                            <h4>推荐课程</h4>
                        </div>
                        <a class="more" href="javascript:;" @click="goCourseSearch"
                        >更多 &gt;</a
                        >
                    </div>
                    <ul class="hot-courses-pic fl">
                        <!-- 图片 -->
                        <li
                                v-for="(c, index) in courseList"
                                :key="c.id"
                                @click="goCourseDetail(c.id)"
                        >
                            <img :src="c.surfacePlotUrl" alt=""/>
                            <div class="hot-activities-member" v-if="c.vipCurriculum === 1">
                                会员免费
                            </div>
                            <p>{{ c.headline }}</p>
                        </li>
                    </ul>
                    <!-- 推荐课程 end -->
                </div>
<!--                <div class="hot_box hot-occupation fl">-->
<!--                    <div class="nav_hot">-->
<!--                        <div class="ico_h">-->
<!--                            <i class="icon_t el-icon-position"></i>-->
<!--                            <h4>热门职位</h4>-->
<!--                        </div>-->
<!--                        <a class="more" href="javascript:;" @click="popularpositions"-->
<!--                        >更多 &gt;</a-->
<!--                        >-->
<!--                    </div>-->

<!--                    <ul class="hot-occupation-message">-->
<!--                        <li-->
<!--                                v-for="work in workList"-->
<!--                                :key="work.id"-->
<!--                                @click="goJobDetail(work.id)"-->
<!--                        >-->
<!--                            <p class="fl">{{ work.title }}</p>-->
<!--                            <p class="fl">-->
<!--                                <em>{{ work.minSalary }}k - {{ work.maxSalary }}k</em>-->
<!--                            </p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {getCourseDetail, getCourseDirectory} from "@/api/coursedetails";
import {vedioPlayer} from "@/api/ailiplayer";
import {payVIPType, payAli} from "@/api/joinvip";
import {userCancelCollection, userCollection} from "@/api/collectionId";
import {getTeacherInfo} from "@/api/user/video";
import {toQQ} from "@/utils/share.js";
import QRCode from "qrcodejs2";
import {allHotCourse, allHotRecruitment, recommendHotInfo, topCates} from "@/api/common";
import {ceHotRecruitment} from "@/api/hotposition";
import {getUserInfo} from "@/api/user/userinfo";
import {getSpecialList} from "@/api/study";

export default {
    data() {
        return {
            special:'',
            specialId:'',
            isPay: "",
            workList: "",
            type: "",
            courseList: "",
            isShow: 0,
            currentPage: 1,
            courseInfo: this.$store.state.coursedetails.courseInfo,
            vedioList: this.$store.state.coursedetails.vedioList,
            videoId: "",
            PlayAuth: "",
            dialogVisible: false,
            payShow: 0,
            teacherInfo: {},
            shareBox: false, // 分享盒子
            wechatBox: false, // 微信二维码盒子
            imgObj: {
                imgOne: require("../../assets/uploads/qqOne.png"),
                imgTwo: require("../../assets/uploads/qqTwo.png"),
            },
            img: require("../../assets/uploads/qqOne.png"),
            picObj: {
                picOne: require("../../assets/uploads/wechatOne.png"),
                picTwo: require("../../assets/uploads/wechatTwo.png"),
            },
            pic: require("../../assets/uploads/wechatOne.png"),
        };
    },
    methods: {
        goCourseDetail(id) {
            this.$router.push({
                path: "/coursedetails?id=" + id,
            });
        },
        goJobDetail(id) {
            this.$router.push({
                path: "/jobdetails?id=" + id,
            });
        },
        popularpositions() {
            this.$router.push({
                path: "/findtalent",
            });
        },
        goCourseSearch() {
            this.$router.push({
                path: "/study",
            });
        },
        // 点击分享按钮
        shareFn() {
            this.shareBox = true;
        },
        // qq
        qqFn1() {
            this.img = this.imgObj.imgTwo;
        },
        qqFn2() {
            this.img = this.imgObj.imgOne;
        },
        // 分享到QQ
        qqUrl() {
            let url = location.href;
            let title = "我是标题";
            toQQ(url, title);
        },
        // 微信
        wechatFn1() {
            this.pic = this.picObj.picTwo;
            this.wechatBox = true;
            // 分享到微信
            this.$nextTick(() => {
                var qrcodes = new QRCode(this.$refs.qrCodeUrls, {
                    text: location.href, // 需要转换为二维码的内容
                    width: 85,
                    height: 85,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            });
        },
        wechatFn2() {
            this.pic = this.picObj.picOne;
            this.wechatBox = false;
            this.$refs.qrCodeUrls.innerHTML = "";
        },
        copyUrl() {
            let invitelink = location.href;
            this.$copyText(invitelink).then(
                (res) => {
                    this.$message({
                        title: "成功",
                        message: "链接已成功复制到剪切板！",
                        type: "success",
                    });
                },
                (err) => {
                    this.$message({
                        title: "错误",
                        message: err,
                        type: "error",
                    });
                }
            );
        },
        closePay() {
            this.dialogVisible = false;
        },
        // cancelCollection() {
        //   userCancelCollection(this.courseInfo.id, 1).then((res) => {
        //     if (res.code == 200) {
        //       this.$notify({
        //         title: "成功",
        //         message: "取消收藏成功",
        //         type: "success",
        //       });
        //       this.courseInfo.isCollection = false;
        //     } else {
        //       this.$notify.error({
        //         title: "错误",
        //         message: res.message,
        //       });
        //     }
        //   });
        // },
        // collection() {
        //   userCollection(this.courseInfo.id, 1).then((res) => {
        //     if (res.code == 200) {
        //       this.$notify({
        //         title: "成功",
        //         message: "收藏成功",
        //         type: "success",
        //       });
        //       this.courseInfo.isCollection = true;
        //     } else {
        //       this.$notify.error({
        //         title: "错误",
        //         message: res.message,
        //       });
        //     }
        //   });
        // },
        goVip() {
            this.$router.push("/joinvip");
        },
        cancelCollection() {
            userCancelCollection(this.courseInfo.id, 1).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        title: "成功",
                        message: "取消收藏成功",
                        type: "success",
                    });
                    this.courseInfo.isCollection = false;
                } else {
                    this.$message.error({
                        title: "错误",
                        message: res.message,
                    });
                }
            });
        },
        collection() {
            userCollection(this.courseInfo.id, 1).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        title: "成功",
                        message: "收藏成功",
                        type: "success",
                    });
                    this.courseInfo.isCollection = true;
                } else {
                    this.$message.error({
                        title: "错误",
                        message: res.message,
                    });
                }
            });
        },

        payment() {
            // console.log(this.payShow)
            this.dialogVisible = false;
            if (this.payShow === 0) {
                // console.log('微信', this.courseInfo.productId)
                this.$router.push(
                    {path:"/wechatpayment?id=" +
                            this.courseInfo.productId +
                            "&price=" +
                            this.courseInfo.originalPrice+
                            "&courseId=" +
                            this.$route.query.id}
                );

                // payVIPType(this.courseInfo.productId).then((res) => {
                //     console.log(res);
                // });
            }
            if (this.payShow === 1) {
                payAli(this.courseInfo.productId).then((res) => {
                    if (res.code === 200) {
                        // console.log('支付宝', this.courseInfo.productId)
                        const div = document.createElement("div");
                        div.innerHTML = res.data.formStr;
                        document.body.appendChild(div);
                        // console.log("插入的表单字符串：", res.data.formStr);
                        const formElement = div.querySelector("form");
                        if (formElement) {
                            formElement.setAttribute("target", "_blank");
                            formElement.submit();
                        } else {
                            console.error("无法找到表单元素");
                        }
                    } else {
                        this.$message.error({
                            title: "错误",
                            message: res.message,
                        });
                    }
                });
            }
        },
        show0() {
            this.isShow = 0;
        },
        // 请求视频的数据1
        show1() {
            this.isShow = 1;
            return new Promise((resolve) => {
                getCourseDirectory(this.courseInfo.id, 1, 10).then((res) => {
                    if (res.code === 200) {
                        this.vedioList = res.data;
                        this.$store.dispatch("courseDirectory", res.data);
                    }
                    resolve(res);
                });
            });
        },
        // 请求视频的数据2
        handleCurrentChange(val) {
            getCourseDirectory(this.courseInfo.id, val, 10).then((res) => {
                if (res.code === 200) {
                    this.vedioList = res.data;
                    this.$store.dispatch("courseDirectory", res.data);
                }
            });
            // console.log(this.vedioList);
        },

        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },

        timeConversion(time) {
            //time是传进来的分钟数
            let timeData = "";
            let h = Math.floor(time / 60);
            time -= h * 60;
            if (time == 0) {
                if (h < 10) {
                    timeData = h ? "0" + h + ":00" : "";
                } else {
                    timeData = h ? h + ":00" : "";
                }
            } else {
                if (time < 10) {
                    time = "0" + time;
                }
                if (h < 10) {
                    h = "0" + h;
                }
                timeData = (h ? h + ":" : "00:") + (time ? time + "" : "");
            }
            return timeData;
        },
        goPlay(vid, name, index) {
            // console.log( this.vedioList.records)
            let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                : "";
            if (token && token.nickName) {
                // this.dialogVisible = true;
                vedioPlayer(vid, this.vedioList.records[index].id).then((res) => {
                    // console.log(res)
                    const playAuth = res.PlayAuth;
                    // console.log("测试-凭证" + playAuth);
                    this.videoId = vid;
                    this.PlayAuth = playAuth;

                    this.$router.push(
                        "/vedioplayer?videoId=" +
                        this.videoId +
                        "&PlayAuth=" +
                        this.PlayAuth +
                        "&courseId=" +
                        this.courseInfo.id +
                        "&id=" +
                        this.$route.query.id +
                        "&name=" +
                        name +
                        "&classId=" +
                        this.vedioList.records[index].id
                    );
                    // if (res.status == 401) {
                    //     console.log(123456);
                    //     this.$message.error("请先登录再尝试观看");
                    // }
                });
            } else {
                this.$message.error({
                    title: "错误",
                    message: "请先登录再尝试观看！",
                });
            }

            // console.log(this.vedioList.records[index])
            // console.log(vid,name,index)
            // vedioPlayer(vid, this.vedioList.records[index].id).then((res) => {
            //     console.log(res)
            //     const playAuth = res.PlayAuth;
            //     // console.log("测试-凭证" + playAuth);
            //     this.videoId = vid;
            //     this.PlayAuth = playAuth;
            //
            //     this.$router.push(
            //         "/vedioplayer?videoId=" +
            //         this.videoId +
            //         "&PlayAuth=" +
            //         this.PlayAuth +
            //         "&courseId=" +
            //         this.courseInfo.id +
            //         "&id=" +
            //         this.$route.query.id +
            //         "&name=" +
            //         name +
            //         "&classId=" +
            //         this.vedioList.records[index].id
            //     );
            //     if (res.status == 401) {
            //         console.log(123456);
            //         this.$message.error("请先登录再尝试观看");
            //     }
            // });
        },
        buy() {
            let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                : "";
            if (token && token.nickName) {
                this.dialogVisible = true;
            } else {
                this.$message.error({
                    title: "错误",
                    message: "请先登录再购买！",
                });
            }
        },
        async goPlayVedio() {
            // console.log(1111)
            let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                : "";
            if (token && token.nickName) {
                await this.show1();
                if (this.vedioList.records.length <= 0) {
                    this.$message.error({
                        title: "错误",
                        message: "视频播放列表无数据，不能试看！",
                    });
                } else {

                    const videoId = this.vedioList.records[0].videoId;
                    const id = this.vedioList.records[0].id;
                    console.log(videoId,id)
                    vedioPlayer(videoId, id).then((res) => {
                        // console.log(res)
                        const playAuth = res.PlayAuth;
                        // console.log("测试-凭证" + playAuth);
                        this.videoId = videoId;
                        this.PlayAuth = playAuth;
                        this.$router.push(
                            "/vedioplayer?videoId=" +
                            this.videoId +
                            "&PlayAuth=" +
                            this.PlayAuth +
                            "&courseId=" +
                            this.courseInfo.id +
                            "&id=" +
                            this.$route.query.id +
                            "&classId=" +
                            id
                        );
                        if (res.status == 401) {
                            this.$message.error("请先登录再尝试观看");
                        }
                    });
                }
            } else {
                this.$message.error({
                    title: "错误",
                    message: "请先登录再尝试观看！",
                });
            }
        },
        wechayPay() {
            this.payShow = 0;
        },
        zhifubao() {
            this.payShow = 1;
        },
    },
    computed: {
        timeList(videoDuration) {
            return this.timeConversion(videoDuration);
        },
    },
    mounted() {
        // console.log(this.$store.state.user.userInfo)
        // console.log(this.$store.state.coursedetails.courseInfo)
        // console.log(this.$store.state)
        // console.log(this.$store.state.coursedetails.vedioList)
        // console.log(this.$store.state.user.userInfo.mock)
        getUserInfo().then((res)=>{
            if(res.data===200){
                this.$store.state.user.userInfo.isVip = res.data.isVip
            }
        })
        // console.log(this.$store.state.user.userInfo)
        ceHotRecruitment().then((res) => {
            this.workList = res.data;
        });
        const data = {
            current: 1,
            size: 6,
            type: this.type,
            count: 3,
            industryType: 11,
        };
        // 热门课程
        // allHotCourse(data).then((res) => {
        //     if (res.code == 200) {
        //         this.courseList = res.data;
        //     }
        // });

        if (this.$route.query.id) {
            getCourseDetail(this.$route.query.id).then((res) => {
                if (res.code == 200) {
                    this.special = res.data.special
                    getSpecialList().then((res)=>{
                        if(res.code===200){
                            res.data.forEach((item)=>{
                                if(item.name === this.special){
                                    recommendHotInfo(item.id).then((res)=>{
                                        this.courseList = res.data;
                                    })
                                }
                            })
                        }
                    })
                    this.courseInfo = res.data;
                    this.$store.dispatch("courseInfo", res.data);
                } else {
                    // console.log(res.message);
                    this.$notify.error({
                        title: "错误",
                        message: res.message,
                    });
                    this.$router.go(-1);
                }
            });
        }
        getTeacherInfo(this.$route.query.id).then((res) => {
            if (res.code == 200) {
                this.teacherInfo = res.data;
                // console.log(this.teacherInfo, "12345678");
            }
        });
    },
};
</script>

<style scoped>
* {
    font-family: "微软雅黑", Arial;
}

.main {
    margin-top: 20px;
    overflow-x: hidden;
}

.share {
    margin-left: 5px;
    top: 3px;
    right: 3px;
}

/* 分享盒子 */
.share_box {
    padding-left: 14px;
    width: 100px;
    height: 40px;
    line-height: 55px;
    border: 1px solid #eb5139;
    position: absolute;
    top: 0;
    left: 70px;
}

.share_box .share-ways {
    height: 40px;
}

.share-ways li {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    cursor: pointer;
}

.share-ways li .qq-img,
.share-ways li .weixin-img {
    display: block;
    width: 28px;
    height: 28px;
}

.share-wechat {
    position: relative;
}

.share_wechat_ercodebox {
    position: absolute;
    top: -35px;
    right: -115px;
    width: 93px;
    height: 110px;
    background-color: transparent;
}

.share-wechat-ercode {
    position: relative;
    box-sizing: border-box;
    padding-top: 3px;
    width: 93px;
    height: 93px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 2px;
    vertical-align: top;
}

.share_box .arrow-down {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ccc;
    position: absolute;
}

.share_box .arrow-down-out {
    bottom: 52px;
    right: 93px;
}

.share_box .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 11px solid #fff;
    position: absolute;
}

.share_box .arrow-out {
    bottom: 52px;
    right: 92px;
}

.close {
    position: absolute;
    right: 5px;
    top: 10px;
    font-size: 26px;
    color: #fff;
    cursor: pointer;
}

.dialog-footer > i {
    font-size: 12px;
}

.bottom-contect {
    width: 100%;
    height: 33px;
    position: relative;
    float: right;
}

.bottom-contect > span {
    line-height: 33px;
    position: absolute;
    right: 0;
}

.foot-line {
    margin-top: 12px;
    width: 100%;
    height: 1px;
    border: none;
    background-color: #e5e5e5;
}

.payActive {
    background-color: #eb5139 !important;
}

.course-box > span {
    color: #333333;
    margin-left: 5px;
    font-size: 14px;
}

.course-box > i {
    color: #333333;
    float: right;
    font-size: 14px;
}

.course-box {
    position: relative;
    margin-top: 28px;
    font-size: 14px;
    color: #999999;
}

.course-box > p {
    position: absolute;
    top: 21px;
    left: 191px;
}

.course-box > img {
    width: 187px;
    height: 80px;
    vertical-align: text-top;
}

.wechat-pay1 > i {
    margin-right: 10px;
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #d9d9d9;
    vertical-align: middle;
}

.wechat-pay {
    border-bottom: 1px solid #e5e5e5 !important;
    padding: 5px 0;
    margin: 0 10px;
}

.wechat-pay1 {
    padding: 5px 0;
    margin: 0 10px;
}

.wechat-pay > span {
    vertical-align: middle;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.wechat-pay > img {
    width: 16px;
    height: 14px;
    vertical-align: middle;
}

.wechat-pay1 > span {
    vertical-align: middle;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.wechat-pay1 > img {
    width: 16px;
    height: 14px;
    vertical-align: middle;
}

.payment-method-box {
    width: 100%;
    margin-top: 28px;
    background-color: #f5f6f7;
    height: 95px;
}

.payment-method {
    position: relative;
    top: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.pay-head > img {
    width: 60px;
    height: 60px;
    vertical-align: middle;
}

.pay-head > span {
    vertical-align: middle;
    margin-left: 15px;
}

.pay-body-box {
    width: 100%;
}

>>> .el-dialog__title {
    color: #debb85;
    font-size: 16px;
    position: relative;
    top: -8px;
}

>>> .el-dialog__header {
    background-color: #424244;
    height: 15px;
}

>>> .el-dialog__header > button {
    display: none;
}

>>> .el-dialog__body {
    height: 363px;
}

.img-bottom {
    width: 100%;
}

.Pga {
    display: flex;
}

.tab3 {
    color: #676c71;
    position: absolute;
    right: 5px;
}

.tab2 {
    margin-left: 10px;
    width: 617px;
    height: 20px;
    font-size: calc(100vw * 14 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
}

.tab-first {
    color: #676c71;
    font-size: 14px;
}

.vedio-item {
    cursor: pointer;
    margin: 20px 0;
    position: relative;
}

.right-text {
    margin-top: 15px;
    width: 90%;
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #676c71;
    line-height: 21px;
}

.line-right {
    margin-top: 45px;
}

.img-box > img {
    margin-top: 55px;
    width: 112px;
    height: 112px;
}

.cla-p {
    margin-top: 55px;
    width: 112px;
    height: 112px;
    background-size: cover;
    background-position: center top !important;
}

.img-box {
    width: 100%;

    display: flex;
}

.left-detail > h3 {
    margin-top: 20px;
    font-size: 14px;
}

.left-detail > p {
    margin-bottom: 10px;
    font-size: 14px;
}

.left-detail {
    padding: 30px;
}

.active {
    background-color: #eb5139 !important;
    color: #ffffff;
}

.left-head {
    display: inline-block;
    width: 86px;
    height: 45px;
    background: #f1f1f1;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
}

.body {
    width: 1520px;
    margin-top: 10px;
    margin-bottom: 31px;
    display: flex;
}

.body-right {
    width: 371px;
    background-color: #ffffff;
    margin: 0;
    margin-left: 10px;
    box-sizing: border-box;
    height: fit-content;
}

.body-left {
    flex: 1;
    background-color: #ffffff;
    margin: 0;
}

.btn-box {
    position: absolute;
    bottom: 0;
    left: auto;
}

.btn-box > button {
    width: 171px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    margin-right: 20px;
}

.btn-box > .immediately {
    background: #d2000a;
    color: #fff;
}

.freeofcharge,
.freeviewing {
    background-color: #ff7800 !important;
    color: #fff;
}

.wd-items > p {
    color: #666;
    margin-bottom: 5px;
}

.z-tabs {
    width: 50px;
    height: 23px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eb5139;
    text-align: center;
    padding-top: 2px;
    margin-left: 1px;
    margin-top: 3px;
    color: #eb5139;
}

.price-box {
    height: 74px;
    background: #f5f5f5;
    color: #666;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    box-sizing: border-box;
}

.vip-price {
    width: 132px;
    height: 20px;
    display: inline-block;
    background-color: #ffe7e3;
    color: #eb6353;
    margin-left: 4px;
    border-radius: 4px 0 0 4px;
    text-align: center;
    font-size: calc(100vw * 14 / 1920);
}

.vip-price1 {
    width: 116px;
    height: 20px;
    /* margin-left: -68px; */
    display: inline-block;
    background-color: #eb5139;
    color: white;
    font-size: calc(100vw * 14 / 1920);
    border-radius:  4px 4px ;
    text-align: center;
    cursor: pointer;
}

.price {
    align-items: center;
    color: #eb6353;
    font-weight: 600;
    font-size: 18px;
    /* width: 77px; */
    margin: 0;
    display: flex;
}

.study {
    width: 92px;
    height: 20px;
    font-size: 14px;
    margin-left: 1px;
    color: #bbb6b8;
}

.head {
    width: 1520px;
    background-color: #ffffff;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
}

.body {
    display: flex;
}

.head-p {
    width: 514px;
    height: 235px;
    border-radius: 4px;
    margin-top: 30px;
}

.v-name {
    margin: 0;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: bold;
    color: #4d4d4d;
}

.text-box {
    height: 265px;
    width: 100%;
    margin-left: 20px;
    margin-right: 0;
    padding-right: 20px;
    box-sizing: border-box;
    position: relative;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-box > .title > div > img {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    vertical-align: middle;
}

.title > .sc {
    margin-right: 20px;
    line-height: 35px;
}

.ft_14 {
    color: #666;
    font-size: 14px;
}

.ml-4 {
    font-size: 36px;
    color: #ff2832;
    font-weight: bold;
    line-height: 74px;
    margin-left: 20px;
}

.ft_24 {
    color: #ff2832;
    font-weight: normal;
    font-size: 24px;
}
</style>
<style lang='less' scoped>
.body-right {
  padding: 20px;

  .postcard {
    margin-top: 50px;
    width: 90%;
    background: #fff;
    border-top: 1px solid #f4f3f3;
    box-shadow: 0 1.6875rem 1.625rem 0 rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .header {
      width: 100px;
      height: 100px;
      border: 5px solid #f8f6f6;
      border-radius: 50%;
      overflow: hidden;
      margin-top: -67px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    ul {
      li {
        padding: 0 20px;
        text-align: center;
        color: #9d9c9c;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #2e2e2e;
        margin-top: 15px;
      }

      .title1 {
        color: rgb(227, 90, 16);
        margin: 10px 0;
      }

      .field {
        max-height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }

      .personalintroduction {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        margin: 15px 0;
        padding: 0 20px;
        height: 40px;
        box-sizing: border-box;
      }
    }
  }
}

.hot-occupation {
  width: 100% !important;
  position: relative;
  height: 330px;
  overflow: hidden;
}

/* 文字信息 */
.hot-occupation-message {
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  box-sizing: border-box;
}

.hot-occupation-message li {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-top: 10px;
  cursor: pointer;
}

.hot-occupation-message li p:nth-child(1) {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hot-occupation-message li p:nth-child(2) {
  width: 100%;
  margin-left: 15px !important;
  flex: 1;
  text-align: right;
}

.hot-occupation-message li p em {
  width: 100px;
  font-size: 14px;
  color: #fe574a;
  line-height: 22px;
  flex: 1;
  text-align: right;
  font-weight: 500;
}

.tatol {
  width: 680px;
  height: 80px;
}

.tatol > li {
  float: left;
  white-space: normal;
  margin-left: 0px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.tatol > li:hover {
  color: #ff3f29;
  cursor: pointer;
}

.totalActive,
.totalActive1 {
  font-size: 14px;
  background: rgba(255, 63, 41, 0.05);
  border-radius: 0.5rem;
  color: #eb5139;
  border-radius: 4px;
}

.news-all {
  background: #fff;
}

.hotest {
  width: 371px;
  height: auto;
  margin-top: 96px;
  overflow: hidden;
  background: #ffffff;
}

/* 推荐课程 */
.hot_box {
  width: 100%;
  height: auto;
  padding: 16px;
  /* margin-bottom: 20px; */
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.nav_hot {
  position: relative;
  width: 100%;
  height: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #edf0f5;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
}

.nav_hot a {
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  color: #909499;
  margin: 0;
}

/* 推荐课程 */
.hot-courses-pic {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hot-courses-pic li {
  position: relative;
  height: 197px;
  margin: 10px 26px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 27px 26px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}

.hot-courses-pic li img {
  width: 100%;
  height: 137px;
}

.hot-courses-pic li p {
  margin-top: 15px;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
  color: #000;
}

/* 热门招聘 */
.hot_box .job-list {
  width: 100%;
  height: 335px;
  overflow: hidden;
}

.hot_box .job-list li {
  margin-top: 12px;
  list-style: disc;
  list-style-position: inside;
  color: #dddddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.hot_box .job-list li a {
  width: 340px;
  height: 20px;
  line-height: 20px;
  color: #666c72;
}

.hot_box .job-list li a:hover {
  color: #ff3f29;
}

.hot_box .job-list li h4 {
  float: left;
  width: 248px;
  height: 44px;
  margin: 12px 0 13px 14px;
  font-size: 14px;
  line-height: 22px;
  color: #666c72;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 分页 */
.footer-pagination {
  box-sizing: border-box;
  margin-left: 0;
  position: absolute;
  top: 1900px;
  left: 50px;
  width: 789px;
  height: 55px;
}

.footer-pagination /deep/ .el-pagination {
  width: 510px;
  height: 24px;
  color: #555656;
}

/* 经过每一个没有选中的数字的颜色 */
.footer-pagination /deep/ .el-pager li.number {
  color: #555656;
}

/* 左箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-prev {
  background-color: transparent;
}

/* 右箭头 */
.footer-pagination /deep/ .el-pagination.is-background .btn-next {
  background-color: transparent;
}

/* 数字框 */
.footer-pagination /deep/ .el-input__inner {
  width: 45px;
  height: 24px;
  border-radius: 2px 2px 2px 2px;
}

.ico_h {
  display: flex;
  color: #eb5139;
  width: 100px;
  margin: 0;
}

.icon_t {
  font-size: 30px;
  margin: 0;
  margin-right: 10px;
}

.ico_h h4 {
  margin: 0;
  line-height: 30px;
  font-weight: 600;
}

@media (min-width: 1024px) and (max-width: 1981px) {
  .body {
    width: 1200px;
  }

  .head {
    width: 1200px;
  }
}

@media (min-width: 1982px) {
  .body {
    width: 1520px;
  }

  .head {
    width: 1520px;
  }
}
</style>
