import request from '@/utils/request'

// 通用

// 获取广告位信息
export function getDocumentPicture(count,locationId) {
  return request({
    url:'api/advertising/indexSliderShowList?count=' + count + '&locationId=' + locationId,
    method: 'get',
  })
}

// 多级行业分类
export function professionClassify() {
  return request({
    url:'api/professionCate/list',
    method: 'get'
  })
}

// 顶级行业列表
export function topCates() {
  return request({
    url:'api/professionCate/topCates',
    method:'get'
  })
}

// 热门职位总榜
export function allHotRecruitment() {
  return request({
    url:'api/recruitment/allHotRecruitment',
    method: 'get'
  })
}

// 热门课程列表
export function allHotCourse(data) {
  return request({
    url:'api/courseInfo/allHotCourse?count=' + data.count,
    method: 'get'
  })
}

// 根据专项得到课程列表
export function recommendHotInfo(specialId){
  return request({
    url:`api/courseInfo/recommendHotInfo?specialId=${specialId}`,
    method:'get'
  })
}


// 热门讲师
export function allHotTeacher() {
  return request({
    url:'api/puserAuthentication/allHotTeacher',
    method:'get'
  })
}

// 根据资料id查询详细信息
export function detailMessage(id) {
  return request({
    url:`api/document/search`,
    method:'get',
    params:{
      'id':id
    }
  })
}

//  获取讲师信息
export function courseInfo(id) {
  return request({
    url:  `/api/document/info/byId`,
    method: 'get',
    params: {
      'id':id
    }
  })
}



// 讲师详情
export function teacherInfo(id) {
  return request({
    url:`api/puserAuthentication/info/${id}`,
    method:'get'
  })
}

// 会议详情
export function meetingInfo(id) {
  return request({
    url:`api/meeting/info/${id}`,
    method:'get'
  })
}

// 会议详情 - 作者最新文章
export function newArticle(id) {
  return request({
    url:`api/meeting/newArticle/${id}`,
    method:'get'
  })
}

// 会议详情 - 相关推荐
export function relatedArticle(data) {
  return request({
    url: 'api/meeting/relatedArticle?industryType=' + data.industryType,
    method: 'get'
  })
}


// 查询职位详细信息
export function recruitmentDetail(id) {
  return request({
    url:`api/recruitment/detail/${id}`,
    method: 'get'
  })
}
