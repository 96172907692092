<template>
  <!-- 安工认证页面 -->
    <div class="main">
        <div class="form-box">
            <div class="headers">
                <p>安工入驻</p>
            </div>
            <div class="top-head">
                <!--                <p>填写或上传您的个人信息/工作单位/获得过的荣誉/主讲课程等信息</p>-->
                <p>以下信息用于平台审核，部分信息用于对外宣传和对接项目!</p>
            </div>
            <!-- 内容区域的表单 -->

            <div class="authentication-form">
                <el-form
                        :label-position="labelPosition"
                        :model="formLabelAlign"
                        :rules="rules"
                        ref="formName"
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input
                                        placeholder="请填写您的姓名"
                                        v-model="formLabelAlign.name"
                                        class="name-input"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="出生年月" prop="value1">
                                <el-date-picker
                                        type="date"
                                        placeholder="选择日期"
                                        v-model="formLabelAlign.value1"
                                        class="date-input"
                                        style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                            <el-form-item label="学历" prop="qualifications">
                                <el-select
                                        v-model="formLabelAlign.qualifications"
                                        placeholder="请选择"
                                        class="rank-input"
                                >
                                    <el-option
                                            v-for="item in options2"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                    label="行业选择"
                                    prop="selectedOptions"
                                    class="ptions"
                            >
                                <!--                                <el-col>-->
                                <!--                                    <el-cascader-->
                                <!--                                            :options="IndustryList"-->
                                <!--                                            :props="{-->
                                <!--                      expandTrigger: 'hover',-->
                                <!--                      value: 'code',-->
                                <!--                      label: 'cateName',-->
                                <!--                      checkStrictly: true,-->
                                <!--                    }"-->
                                <!--                                            v-model="formLabelAlign.selectedOptions"-->
                                <!--                                            @change="selecetH"-->
                                <!--                                            clearable-->
                                <!--                                            size="big"-->
                                <!--                                            class="industry-input"-->
                                <!--                                    ></el-cascader>-->
                                <!--                                </el-col>-->
                                <el-select v-model="formLabelAlign.selectedOptions" placeholder="请选择">
                                    <el-option
                                            v-for="item in IndustryList"
                                            :key="item.id"
                                            :label="item.cateName"
                                            :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div class="commercialaffairs">
                                <span class="check">*</span>
                                <el-form-item
                                        label="商务照片"
                                        class="business-upload"
                                        prop="picArrayList"
                                >
                                    <p style="color: #333333;font-weight: 400;">(建议上传360*540的JPG丶PNG图片)</p>
                                    <el-upload
                                            action
                                            list-type="picture-card"
                                            :on-preview="handlePictureCardPreview"
                                            :limit="1"
                                            :file-list="formLabelAlign.picArrayList"
                                            :on-change="handleVideo"
                                            :before-upload="beforeUploadVideo"
                                            style="overflow: hidden"
                                    >
                                        <!--                                                                                <img-->
                                        <!--                                                                                        style="width: 146px; height: 146px"-->
                                        <!--                                                                                        v-if="imageUrl"-->
                                        <!--                                                                                        :src="imageUrl"-->
                                        <!--                                                                                        class="avatar"-->
                                        <!--                                                                                 alt=""/>-->
                                        <img
                                                style="width: 146px; height: 146px"
                                                v-if="formLabelAlign.picArrayList"
                                                :src="formLabelAlign.picArrayList"
                                                class="avatar"
                                                alt=""/>
                                        <!--                                        <img v-else  style="width: 146px; height: 146px" class="avatar" :src="formLabelAlign.picArrayList"  alt="">-->
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="职位" prop="position">
                                <el-input
                                        v-model="formLabelAlign.position"
                                        class="name-input"
                                        placeholder="请填写您的职位信息"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="职称" prop="value">
                                <el-select
                                        v-model="formLabelAlign.value"
                                        placeholder="请选择"
                                        class="rank-input"
                                >
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <div class="province">
                                <span class="check">*</span>
                                <el-form-item label="常驻城市" prop="city">
                                    <v-distpicker
                                            hide-area
                                            v-if="cityCommit === true"
                                            @province="onChangeProv"
                                            @city="onChangeCity"
                                    ></v-distpicker>
                                    <!--                                    <p v-else>{{ this.cityText }}</p>-->
                                    <el-input v-else
                                              v-model="this.cityText"
                                              class="name-input"
                                              placeholder="请填写您的职位信息"
                                    ></el-input>
                                </el-form-item>
                            </div>

                            <el-form-item label="联系方式" prop="phoneNum">
                                <el-input v-model="formLabelAlign.phoneNum"></el-input>
                            </el-form-item>
                            <div class="certificate">
                                <span class="check">*</span>
                                <el-form-item
                                        label="相关证书"
                                        class="picture-certificate"
                                        prop="picArrayList1"
                                >
                                    <p style="color: #333333;font-weight: 400">
                                        (请上传职业相关证书（证明）的JPG丶PNG图片)</p>
                                    <el-upload
                                            action
                                            list-type="picture-card"
                                            :on-preview="handlePictureCardPreview"
                                            :on-remove="handleRemove"
                                            :file-list="formLabelAlign.picArrayList1"
                                            :http-request="handleVideo2"
                                            :on-change="fileListChange"
                                            :before-upload="beforeUploadVideoList"
                                            :multiple="true"
                                    ></el-upload>
                                    <!--                                    <el-upload-->
                                    <!--                                            action-->
                                    <!--                                            list-type="picture-card"-->
                                    <!--                                            :on-preview="handlePictureCardPreview"-->
                                    <!--                                            :on-remove="handleRemove"-->
                                    <!--                                            :limit="3"-->
                                    <!--                                            :file-list="formLabelAlign.picArrayList1"-->
                                    <!--                                            :http-request="handleVideo2"-->
                                    <!--                                            :on-exceed="execedFunction"-->
                                    <!--                                            :on-change="fileListChange"-->
                                    <!--                                            :before-upload="beforeUploadVideoList"-->
                                    <!--                                            :multiple="true"-->
                                    <!--                                    ></el-upload>-->
                                    <!--                                    <img v-if="picString" v-for="(item,index) in picString" :key="index" :src="item" alt="">-->
                                    <el-dialog :visible.sync="dialogVisible">
                                        <!--                                        <img v-if="formLabelAlign.picArrayList1" v-for="(item,index) in formLabelAlign.picArrayList1" :key="index" :src="item" alt="">-->
                                        <img width="100%" :src="dialogImageUrl" alt/>
                                    </el-dialog>
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>
                    <el-form-item label="报价" prop="quotation" class="quotation">
                        <p>(元/天)(对外培训的课时费报价，用于帮您对接企业培训或会议需求)</p>
                        <el-input
                                v-model="formLabelAlign.quotation"
                                class="teacher-input"
                                type="text"
                                resize="none"
                                placeholder="请输入价格"
                                @input="validateNumericInput(this)"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="个人简介" prop="teacher">
                        <p>(用于对外宣传展示和协助您对接项目，请您认真填写)</p>
                        <el-input
                                v-model="formLabelAlign.teacher"
                                class="teacher-input"
                                type="textarea"
                                resize="none"
                                placeholder="向大家介绍一下你自己~"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="您是安全生产领域的：" prop="ResearchField">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 180px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            可多选，对外宣传，用于帮您对接项目
                        </p>
                        <el-checkbox-group v-model="formLabelAlign.ResearchField">
                            <el-checkbox label="学术专家"></el-checkbox>
                            <el-checkbox label="科研专家"></el-checkbox>
                            <el-checkbox label="培训专家"></el-checkbox>
                            <el-checkbox label="咨询专家"></el-checkbox>
                            <el-checkbox label="管理专家"></el-checkbox>
                            <el-checkbox label="信息化专家"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="擅长领域 :" prop="checkList2">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 100px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            可多选，对外宣传，用于帮您对接项目
                        </p>
                        <el-checkbox-group class="checkBox" v-model="formLabelAlign.checkList2">
                            <el-checkbox label="安全文化"></el-checkbox>
                            <el-checkbox label="安全管理"></el-checkbox>
                            <el-checkbox label="法律法规"></el-checkbox>
                            <el-checkbox label="行业标准"></el-checkbox>
                            <el-checkbox label="应急管理"></el-checkbox>
                            <el-checkbox label="安全评价"></el-checkbox>
                            <el-checkbox label="职业健康"></el-checkbox>
                            <el-checkbox label="资质培训"></el-checkbox>
                            <el-checkbox label="隐患排查"></el-checkbox>
                            <el-checkbox label="特种设备"></el-checkbox>
                            <el-checkbox label="风险管理"></el-checkbox>
                            <el-checkbox label="化学品管理" style="margin-right: 16px"></el-checkbox>
                            <el-checkbox label="仓储运输"></el-checkbox>
                            <el-checkbox label="作业管理"></el-checkbox>
                            <el-checkbox label="事故案例"></el-checkbox>
                            <el-checkbox label="监管监察"></el-checkbox>
                            <el-checkbox label="防爆电气"></el-checkbox>
                            <el-checkbox label="应急预案"></el-checkbox>
                            <el-checkbox label="消防管理"></el-checkbox>
                            <el-checkbox label="综合安全"></el-checkbox>
                        </el-checkbox-group>
                        <span>其他：</span>
                        <el-input
                                type="text"
                                placeholder="请输入"
                                v-model="checkList2Others"
                                maxlength="20"
                                show-word-limit
                                class="dd"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="荣誉资质 :" prop="honor">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 100px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            用于对外宣传展示和协助您对接项目，请您认真填写。
                        </p>
                        <el-input
                                v-model="formLabelAlign.honor"
                                class="teacher-input"
                                type="textarea"
                                resize="none"
                                placeholder="您在擅长的领域获得的荣誉。"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="项目 :" prop="project">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 66px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            用于对外宣传展示和协助您对接项目，请您认真填写。
                        </p>
                        <el-input
                                v-model="formLabelAlign.project"
                                class="teacher-input"
                                type="textarea"
                                resize="none"
                                placeholder="您在擅长的领域做过的项目。"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="主讲课程 :" prop="course">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 100px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            用于对外宣传
                        </p>
                        <el-input
                                v-model="formLabelAlign.course"
                                class="teacher-input"
                                type="textarea"
                                resize="none"
                                placeholder="您主要讲的课程。"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="工作经历 :" prop="consulting">
                        <p
                                style="
                position: absolute;
                top: -36px;
                left: 100px;
                color: #333333;
                font-weight: 400;
                font-size: 12px;
              "
                        >
                            用于对外宣传
                        </p>
                        <el-input
                                v-model="formLabelAlign.consulting"
                                class="teacher-input"
                                type="textarea"
                                resize="none"
                                placeholder="请填写您的咨询方向。"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <!-- 同意协议 -->
                <div class="">
                    <i
                            @click="showIcon"
                            :class="[
              'iconfont',
              { iconActive: iconIsActive === true },
              'icon-kongxinduigou',
            ]"
                    ></i>
                    <span class="">我已同意</span>
                    <!--                    <a href="/dataUploadProtocol">资料上传协议</a>-->
                    <span style="color: red;cursor: pointer" @click="profile">《中安工匠平台专家入驻合作协议》</span>
                    <!--                    <router-link to="/dataUploadProtocol"></router-link>-->
                </div>
            </div>

            <!-- 提交按钮 -->
            <div class="submit">
                <!--                <el-button class="btn1" @click="forBack">上一步</el-button>-->
                <el-button v-if="canCommit === true" class="btn2" @click="postInfo"
                >提交
                </el-button>
                <el-button v-if="againCommit === true" class="btn2" @click="againPostInfo"
                >再次提交
                </el-button>
                <!--                <el-button v-else class="btn2" disabled>等待审核</el-button>-->
            </div>
        </div>
        <div>
            <el-dialog
                    :close-on-click-modal="false"
                    :showClose="false"
                    title="提示"
                    :visible.sync="examineVisible"
                    width="30%"
            >
                <div v-if="examineNum===1" style="text-align: center;font-size: 20px"><span>正在审核中请您等待。。。</span>
                </div>
                <div v-if="examineNum===2" style="text-align: center;font-size: 20px"><span>恭喜您通过审核</span></div>
                <div v-if="examineNum===3" style="text-align: center">
                    <span style="font-size: 18px">抱歉审核未通过</span>
                    <div style="margin-top: 4px;text-align: center"><span
                            style="font-size: 18px;margin-right: 3px">原因:</span><span
                            style="font-size: 18px;font-weight: 600">{{ cause }}</span></div>
                </div>
                <span slot="footer" class="dialog-footer" style="text-align: center">
                    <el-button v-if="examineNum===1" type="primary" @click="goBack" class="btn2">确 定</el-button>
                    <el-button v-if="examineNum===2" type="primary" @click="ok" class="btn2">确定</el-button>
                    <el-button v-if="examineNum===3" type="primary" @click="upBack" class="btn2">确 定</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>


<script>
import {userPuserAuthentication} from "@/api/authenticationcertification/authenticationcertification";
import {direct, sts} from "@/api/direct.js";
import {ossDirectVideo, uuid} from "@/utils/ossDirect.js";
import {getHomeProfessionCate} from "@/api/home/home";
import {isAuthentication} from "@/api/login";
import VDistpicker from "v-distpicker";
import {validatePhoneTwo} from "@/utils/validate";

export default {
    name: "AuthenticationCertification",
    components: {VDistpicker},
    data() {
        return {
            beforeBool: false,
            num: 1,
            examineVisible: false,
            examineNum: 0,
            againCommit: false,
            status: 0,
            labelPosition: "top",
            cause: "",
            cityCommit: true,
            formLabelAlign: {
                name: "",
                //学历
                qualifications: "",
                position: "",
                //个人简介
                teacher: "",
                //常驻地址（暂定）
                city: "",
                //安全生产领域
                ResearchField: "",
                honor: "",
                project: "",
                course: "",
                //工作经历
                consulting: "",
                //所属安全领域
                ResearchField: [],
                //擅长领域
                checkList2: [],
                value1: "",
                value: "",
                selectedOptions: "",
                phoneNum: "",
                picArrayList: "",
                picArrayList1: [],
                quotation: "",
            },

            //安全生产擅长领域
            //checkList: [],

            //擅长领域内其他
            checkList2Others: "",
            options: [
                {
                    value: "初级工程师",
                    label: "初级工程师",
                },
                {
                    value: "中级工程师",
                    label: "中级工程师",
                },
                {
                    value: "副高级工程师",
                    label: "副高级工程师",
                },
                {
                    value: "高级工程师",
                    label: "高级工程师",
                },
                {
                    value: "其他",
                    label: "其他",
                },
            ],
            options2: [
                {
                    value: "高中及以下",
                    label: "高中及以下",
                },
                {
                    value: "大专",
                    label: "大专",
                },
                {
                    value: "本科",
                    label: "本科",
                },
                {
                    value: "硕士",
                    label: "硕士",
                },
                {
                    value: "博士及以上",
                    label: "博士及以上",
                },
            ],
            attachedName: '',
            imageUrl: "", //封面图片地址
            dialogImageUrl: "",
            dialogVisible: false,
            imageUrl2: "",
            dialogImageUrl2: "",
            dialogVisible2: false,
            picArray: [],

            picString: "",
            iconIsActive: false,
            IndustryList: [],

            selectCity1: "",
            selectCity2: "",
            selectCity3: "",
            //提交状态标记
            canCommit: true,
            //城市回显
            cityText: "",
            key: "",
            key2: "",
            rules: {
                quotation: [
                    {
                        required: true,
                        message: "请输入价格",
                        trigger: "blur",
                    },
                ],
                name: [
                    {required: true, message: "请输入姓名", trigger: "blur"},
                    {min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur"},
                ],
                value1: [
                    {
                        type: "date",
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
                qualifications: [
                    {
                        required: true,
                        message: "请选择学历",
                        trigger: "change",
                    },
                ],
                selectedOptions: [
                    {
                        required: true,
                        message: "请选择行业类型",
                        trigger: "change",
                    },
                ],
                position: [{required: true, message: "请输入职位", trigger: "blur"}],
                value: [{required: true, message: "请选择职称", trigger: "change"}],
                phoneNum: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {
                        pattern: /^[1]([2-9])[0-9]{9}$/,
                        message: "请输入正确的手机号码",
                    },
                    {validator: validatePhoneTwo, trigger: "blur"},
                ],
                teacher: [{required: true, message: "请输入内容", trigger: "blur"}],
                honor: [
                    {required: true, message: "请输入您的荣誉资质", trigger: "blur"},
                ],
                project: [
                    {required: true, message: "请介绍您的项目", trigger: "blur"},
                ],
                course: [
                    {required: true, message: "请输入您的主讲课程", trigger: "blur"},
                ],
                consulting: [
                    {required: true, message: "请输入您的工作经历", trigger: "blur"},
                ],
                picArrayList: [
                    {validator: this.bb, message: "请上传商务照片", trigger: "change"},
                ],
                picArrayList1: [
                    {
                        validator: this.aa,
                        message: "请上传相关证书",
                        trigger: "change",
                    },
                ],
                adder: [{required: true, message: "请输入省市", trigger: "blur"}],
                checkList2: [
                    {
                        type: "array",
                        required: true,
                        message: "请至少选择一个擅长领域",
                        trigger: "change",
                    },
                ],
                ResearchField: [
                    {
                        type: "array",
                        required: true,
                        message: "请至少选择一个安全领域",
                        trigger: "change",
                    },
                ],
                city: [
                    {
                        validator: this.validateFileList,
                        message: "请输入省市",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        // 点击资料上传保存信息
        // console.log(this.formLabelAlign)
        // console.log(JSON.parse(localStorage.getItem("url")))
        // console.log(JSON.parse(localStorage.getItem("pic")))
        if (localStorage.getItem('info') !== null) {
            getHomeProfessionCate().then((res) => {
                if (res.code === 200) {
                    this.IndustryList = res.data
                }
            });
            this.formLabelAlign = JSON.parse(localStorage.getItem('info'))
            this.formLabelAlign.picArrayList = JSON.parse(localStorage.getItem("url"))
            this.formLabelAlign.picArrayList1 = JSON.parse(localStorage.getItem("pic"))
            this.cityCommit = false
            this.cityText = this.formLabelAlign.city
        } else {
            this.num = localStorage.getItem("num") + 1
            localStorage.setItem("num", this.num)
            this.formLabelAlign.phoneNum = this.$store.state.user.userInfo.phone;
            getHomeProfessionCate().then((res) => {
                // console.log(res)
                if (res.code === 200) {
                    // this.IndustryList = this.getTreeData(res.data);
                    this.IndustryList = res.data
                }
            });
            isAuthentication().then((res) => {
                if (res.code === 200 && res.data) {
                    this.cause = res.data.cause
                    // localStorage.setItem("status", res.data.status)
                    // console.log(res.data.status === 3)
                    // console.log(localStorage.getItem("num").length)
                    // if (res.data.city != "") {
                    //     this.canCommit = false;
                    // }
                    if (res.data.status === 3) {
                        // console.log("失败")
                        // this.canCommit = true;
                        this.canCommit = false
                        this.againCommit = true
                        this.examineVisible = true
                        this.examineNum = 3
                        this.status = 3

                    }
                    if (res.data.status === 2) {
                        this.cityCommit = false
                        //     积分
                        //     getIntegralCalculation(0,3,0,JSON.parse(localStorage.getItem("USER_AUTH_INFO")).id).then((res)=>{
                        //         console.log(res)
                        //     })
                    }
                    if (res.data.status === 2 && localStorage.getItem("num").length === 2) {
                        // console.log("审核成功")
                        this.canCommit = true;
                        this.examineVisible = true
                        this.examineNum = 2
                        this.cityCommit = false

                    }
                    if (res.data.status === 1) {
                        // console.log("审核中")
                        this.canCommit = true;
                        this.examineVisible = true
                        this.examineNum = 1
                        this.num = 1
                        localStorage.setItem("num", this.num)
                        // console.log(1111,localStorage.getItem("num").length)
                    }
                    this.formLabelAlign.value1 = res.data.birthday;
                    this.formLabelAlign.consulting = res.data.consultingDirection;
                    // this.imageUrl = res.data.picture;
                    this.formLabelAlign.picArrayList = res.data.picture
                    //this.picArray = res.data.credential.split(";");
                    this.formLabelAlign.honor = res.data.honor;
                    this.formLabelAlign.teacher = res.data.introduce;
                    //常驻城市
                    this.formLabelAlign.city = res.data.city;
                    // console.log(res.data.city);
                    if (res.data.credential.indexOf(";")) {
                        let parr = res.data.credential.split(";");
                        parr.forEach((value, index) => {
                            //  value["url"] = parr[index];
                            this.formLabelAlign.picArrayList1.push({url: value});
                        });
                    } else {
                        let parr = res.data.credential
                        this.formLabelAlign.picArrayList1.push({url: value});
                    }


                    //职称
                    this.formLabelAlign.value = res.data.title;
                    this.formLabelAlign.course = res.data.mainCourse;
                    this.formLabelAlign.name = res.data.name;
                    this.formLabelAlign.position = res.data.position;
                    this.formLabelAlign.project = res.data.project;
                    //安全生产领域
                    this.formLabelAlign.ResearchField =
                        res.data.securityField.split(";") || [];
                    //擅长领域
                    let newArr = res.data.greatField.split(";") || [];
                    let newArr2 = [];
                    newArr.forEach((item) => {
                        if (item !== "") {
                            newArr2.push(item);
                        }
                    });
                    this.formLabelAlign.checkList2 = newArr2 || [];
                    // 报价
                    this.formLabelAlign.quotation = res.data.quotation;
                    //学历
                    this.formLabelAlign.qualifications = res.data.qualifications;
                    this.formLabelAlign.selectedOptions = res.data.industryType;
                    //城市回显
                    this.cityText = res.data.city;
                }
            });
            console.log(this.formLabelAlign)
        }
    },
    computed: {},
    methods: {
        profile() {
            // console.log(this.formLabelAlign)
            // console.log(this.imageUrl)
            // console.log(this.picString)
            localStorage.setItem("info", JSON.stringify(this.formLabelAlign))
            localStorage.setItem("url", JSON.stringify(this.imageUrl) ? JSON.stringify(this.imageUrl) : JSON.stringify(this.formLabelAlign.picArrayList))
            localStorage.setItem("pic", JSON.stringify(this.picString) ? JSON.stringify(this.picString) : JSON.stringify(this.formLabelAlign.picArrayList1))
            this.$router.push({path: '/dataUploadProtocol', query: {name: this.formLabelAlign.name}})
        },
        // 只准输入数字
        validateNumericInput() {
            this.formLabelAlign.quotation = this.formLabelAlign.quotation.replace(/\D/g, ''); // 使用正则表达式替换非数字字符为空字符串
        },
        ok() {
            this.examineVisible = false
        },
        upBack() {
            // console.log(this.formLabelAlign)
            // this.canCommit = false
            // this.formLabelAlign.value1 = "";
            // this.formLabelAlign.consulting = "";
            // this.imageUrl = "";
            // this.formLabelAlign.picArrayList1 = ""
            // this.formLabelAlign.picArrayList = ""
            // //this.picArray = res.data.credential.split(";");
            // this.formLabelAlign.honor = "";
            // this.formLabelAlign.teacher = "";
            // //常驻城市
            // this.formLabelAlign.city = "";
            //
            // this.formLabelAlign.value = "";
            // this.formLabelAlign.course = "";
            // this.formLabelAlign.name = "";
            // this.formLabelAlign.position = "";
            // this.formLabelAlign.project = "";
            // this.formLabelAlign.ResearchField = []
            // this.formLabelAlign.checkList2 = []
            // this.formLabelAlign.quotation = ""
            // this.formLabelAlign.qualifications = "";
            // this.formLabelAlign.selectedOptions = "";
            // this.cityText = "";
            // this.formLabelAlign.honor = "";
            // this.formLabelAlign.picArrayList = [];
            // this.formLabelAlign.phoneNum = ""

            this.examineVisible = false

        },
        goBack() {
            this.$router.go(-1)
        },
        aa(rule, value, callback) {
            if (this.key === "") {
                callback(new Error("111111"));
            } else {
                callback();
            }
        },
        bb(rule, value, callback) {
            if (this.picArray.length === 0) {
                callback(new Error("111111"));
            } else {
                callback();
            }
        },
        validateFileList(rule, value, callback) {
            if (
                this.formLabelAlign.city === "" ||
                this.selectCity1 === "省" ||
                this.selectCity1 === "市"
            ) {
                callback(new Error("请输入省"));
            } else {
                callback();
            }
        },
        onChangeProv(e) {
            this.$refs.formName.clearValidate("city");
            // console.log("省", e);
            this.selectCity1 = e.value;
        },
        onChangeCity(e) {
            this.$refs.formName.clearValidate("city");
            this.selectCity2 = e.value;
            this.formLabelAlign.city = this.selectCity1 + this.selectCity2;
        },

        forBack() {
            this.$router.go(-1);
        },
        fileListChange(file, fileList) {
            console.log(file, fileList);

        },
        getTreeData(data) {
            for (var i = 0; i < data.length > 0; i++) {
                if (data[i].children == null || data[i].children.length <= 0) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        selecetH(value) {
            this.formLabelAlign.selectedOptions = value[value.length - 1];
            // console.log(this.selectedOptions);
        },
        showIcon() {
            console.log(this.iconIsActive)
            this.iconIsActive = !this.iconIsActive;
            if (this.iconIsActive === false) {
                console.log(this.formLabelAlign.name)
            }
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeUploadVideoList(file) {
            console.log(file.type, 11111)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            // const isJPG = imageType === "image/jpeg" || imageType === "image/png";
            // console.log(isJPG)
            if (!isJPG) {
                this.$message.error("上传头像图片只能是 jpg/png 格式!");
                this.beforeBool = false
                console.log(this.formLabelAlign.picArrayList1, "所有")
                // this.formLabelAlign.picArrayList1 = []
                return isJPG
            } else {
                this.beforeBool = true
                // console.log(this.formLabelAlign.picArrayList1)
            }

        },
        handleVideo(file) {
            console.log(file)
            if (file.status === "ready") {
                this.$refs.formName.clearValidate("picArrayList1");
                let beforeBool = true;
                if (beforeBool) {
                    this.videoLoading = true;
                    direct().then((res) => {
                        // res = res.data;
                        if (res.code === 200) {
                            // 文件名，重新写一个文件名
                            const pathArr = file.name.split(".");
                            let suffix = pathArr[pathArr.length - 1];

                            if (!suffix) {
                                this.$message({
                                    type: "error",
                                    message: this.i18n.scsb,
                                });
                                return;
                            }
                            // 重新赋值文件名字
                            suffix = "." + suffix;
                            let key =
                                res.data.dir +
                                uuid(36, 36) +
                                new Date().getTime() +
                                Math.floor(Math.random() * 100000) +
                                suffix;
                            // 获取sts
                            sts().then((con) => {
                                // con = con.data;
                                if (con.code === 200) {
                                    // console.log(file);
                                    ossDirectVideo(
                                        con.data,
                                        key,
                                        file.raw,
                                        (suc) => {
                                            // this.$message({
                                            //   type: "success",
                                            //   message: this.i18n.schcg
                                            // });
                                            // this.videoLoading = false;
                                            // this.videoUrl = URL.createObjectURL(file.raw);
                                            // this.form.video = key;

                                            // console.log(
                                            //     "url",
                                            //     "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            //     key
                                            // );
                                            this.imageUrl =
                                                "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                                key;
                                            this.key = key;
                                            this.formLabelAlign.picArrayList = this.imageUrl
                                        },

                                        (err) => {
                                            this.videoLoading = false;
                                            this.$message({
                                                type: "error",
                                                message: this.i18n.scsb,
                                            });
                                        }
                                    );
                                }
                            });
                        }
                    });
                }
            }
            // this.formLabelAlign.picArrayList1.push(file);

        },

        // handleVideo(file) {
        //     if (file.status === "ready") return;
        //     // this.formLabelAlign.picArrayList1.push(file);
        //     this.$refs.formName.clearValidate("picArrayList1");
        //     let beforeBool = true;
        //     if (beforeBool) {
        //         this.videoLoading = true;
        //         direct().then((res) => {
        //             // res = res.data;
        //             if (res.code === 200) {
        //                 // 文件名，重新写一个文件名
        //                 const pathArr = file.name.split(".");
        //                 let suffix = pathArr[pathArr.length - 1];
        //
        //                 if (!suffix) {
        //                     this.$message({
        //                         type: "error",
        //                         message: this.i18n.scsb,
        //                     });
        //                     return;
        //                 }
        //                 // 重新赋值文件名字
        //                 suffix = "." + suffix;
        //                 let key =
        //                     res.data.dir +
        //                     uuid(36, 36) +
        //                     new Date().getTime() +
        //                     Math.floor(Math.random() * 100000) +
        //                     suffix;
        //                 // 获取sts
        //                 sts().then((con) => {
        //                     // con = con.data;
        //                     if (con.code === 200) {
        //                         // console.log(file);
        //                         ossDirectVideo(
        //                             con.data,
        //                             key,
        //                             file.raw,
        //                             (suc) => {
        //                                 // this.$message({
        //                                 //   type: "success",
        //                                 //   message: this.i18n.schcg
        //                                 // });
        //                                 // this.videoLoading = false;
        //                                 // this.videoUrl = URL.createObjectURL(file.raw);
        //                                 // this.form.video = key;
        //
        //                                 console.log(
        //                                     "url",
        //                                     "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
        //                                     key
        //                                 );
        //                                 this.imageUrl =
        //                                     "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
        //                                     key;
        //                                 this.key = key;
        //                                 this.formLabelAlign.picArrayList = this.imageUrl
        //                             },
        //
        //                             (err) => {
        //                                 this.videoLoading = false;
        //                                 this.$message({
        //                                     type: "error",
        //                                     message: this.i18n.scsb,
        //                                 });
        //                             }
        //                         );
        //                     }
        //                 });
        //             }
        //         });
        //     }
        // },
        // execedFunction(files, fileList) {
        //     this.$message.warning(
        //         `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
        //             files.length + fileList.length
        //         } 个文件`
        //     );
        // },
        handleVideo2(file, fileList) {
            if (file.status === "ready") return;
            // this.formLabelAlign.picArrayList.push(file);
            this.$refs.formName.clearValidate("picArrayList");
            // let beforeBool = true;
            console.log(typeof this.beforeBool)
            if (this.beforeBool) {
                this.videoLoading = true;
                direct().then((res) => {
                    // res = res.data;
                    if (res.code === 200) {
                        // 文件名，重新写一个文件名
                        const pathArr = file.file.name.split(".");
                        let suffix = pathArr[pathArr.length - 1];

                        if (!suffix) {
                            this.$message({
                                type: "error",
                                message: this.i18n.scsb,
                            });
                            return;
                        }

                        // 重新赋值文件名字
                        suffix = "." + suffix;
                        let key =
                            res.data.dir +
                            uuid(36, 36) +
                            new Date().getTime() +
                            Math.floor(Math.random() * 100000) +
                            suffix;
                        // 获取sts
                        sts().then((con) => {
                            // con = con.data;
                            if (con.code === 200) {
                                // console.log(file);

                                ossDirectVideo(
                                    con.data,
                                    key,
                                    file.file,
                                    (suc) => {
                                        // this.$message({
                                        //   type: "success",
                                        //   message: this.i18n.schcg
                                        // });
                                        // this.videoLoading = false;
                                        // this.videoUrl = URL.createObjectURL(file.raw);
                                        // this.form.video = key;

                                        // console.log(
                                        //     "url",
                                        //     "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                        //     key
                                        // );
                                        this.imageUrl2 =
                                            "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                                            key;

                                        // console.log(this);

                                        this.picArray.push(this.imageUrl2);
                                        this.picString = this.picArray.join(";");
                                        // console.log(this.picString,"打印的地址")
                                        this.formLabelAlign.picArrayList1 = this.picString
                                        // console.log(this.formLabelAlign.picArrayList1)
                                        // if (this.picString.includes("pdf")) {
                                        //     this.formLabelAlign.picArrayList1 = []
                                        // } else {
                                        //
                                        // }

                                    },
                                    (err) => {
                                        this.videoLoading = false;
                                        this.$message({
                                            type: "error",
                                            message: this.i18n.scsb,
                                        });
                                    }
                                );
                            }
                        });
                    }
                });
            }
        },
        beforeUploadVideo() {
            this.$refs.formName.clearValidate("fileList");
            return true;
        },
        beforeUploadVideo2() {
            return true;
        },
        handlePictureCardPreview(file, fileList) {
            console.log(file)
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            // console.log(fileList);
        },
        handlePictureCardPreview2(file) {
            this.dialogImageUrl2 = file.url;
            if (isShowUpload <= 3) {
                this.dialogVisible2 = true;
            } else {
                this.dialogVisible2 = false;
            }
            this.dialogVisible2 = true;
            // console.log(dialogImageUrl2);
        },
        handleRemove(file, fileList) {
            // console.log(file)
            console.log(fileList)
            this.picArray = fileList.reduce((arr, item) => {
                arr.push(item.url);
                return arr;
            }, []);
            this.picString = this.picArray.join(";");

        },
        handleRemove2(file, fileList) {
            // console.log(file, fileList);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        postInfo() {
            console.log(this.formLabelAlign.phoneNum.length)
            this.num = 1
            localStorage.setItem("num", this.num)
            // console.log(this.$refs.formName.validate)
            // console.log(this.formLabelAlign)
            // this.$refs.formName.validate((valid) => {
            //     if (valid) {
            //         console.log(111)
            //         this.submit();
            //     } else {
            //         // console.log(111)
            //         this.$message.error("请完善信息后，再提交");
            //         return false;
            //     }
            // });
            if (this.formLabelAlign.ResearchField.length !== 0 && this.formLabelAlign.checkList2.length !== 0 && this.formLabelAlign.city !== "" && this.formLabelAlign.consulting !== ""
                && this.formLabelAlign.course !== "" && this.formLabelAlign.honor !== "" && this.formLabelAlign.name !== "" && this.formLabelAlign.phoneNum.length === 11 && this.formLabelAlign.picArrayList.length !== 0
                && this.formLabelAlign.picArrayList1.length !== 0 && this.formLabelAlign.position !== "" && this.project !== "" && this.formLabelAlign.qualifications !== "" && this.formLabelAlign.quotation !== ""
                && this.formLabelAlign.selectedOptions !== "" && this.formLabelAlign.teacher !== "" && this.formLabelAlign.value !== "" && this.formLabelAlign.value1 !== ""
            ) {
                this.submit()
            } else {
                this.$message.error("请完善信息后，再提交");
                return false;
            }
        },
        submit() {
            // console.log(this.formLabelAlign.picArrayList)
            // console.log(this.formLabelAlign.picArrayList1)
            if (this.iconIsActive !== true) {
                this.$message.error({
                    title: "错误",
                    message: "请先阅读上传资料协议!",
                });
                return;
            }
            this.formLabelAlign.picArrayList1 = typeof this.formLabelAlign.picArrayList1 === "string"
                ? this.formLabelAlign.picArrayList1
                : this.formLabelAlign.picArrayList1.map(item => item.url).join(";");

            let checkList2BoxAfter =
                this.formLabelAlign.checkList2.join(";") + ";" + this.checkList2Others;
            let checkListBox = this.formLabelAlign.ResearchField.join(";");
            userPuserAuthentication(
                this.formLabelAlign.name,
                this.formLabelAlign.value1,
                // this.imageUrl,
                this.formLabelAlign.picArrayList,
                this.formLabelAlign.qualifications,
                this.formLabelAlign.position,
                this.formLabelAlign.value,
                //常住地址

                this.formLabelAlign.city,
                //相关证书
                // this.picString,
                this.formLabelAlign.picArrayList1,
                //个人简介
                this.formLabelAlign.teacher,

                //安全生产领域
                checkListBox,
                //擅长领域
                checkList2BoxAfter,
                this.formLabelAlign.honor,
                this.formLabelAlign.project,
                this.formLabelAlign.course,
                //工作经历
                this.formLabelAlign.consulting,
                this.formLabelAlign.selectedOptions,
                this.formLabelAlign.quotation,
                //擅长领域
                this.formLabelAlign.phoneNum
            ).then((res) => {
                if (res.code === 200) {
                    localStorage.removeItem("info")
                    localStorage.removeItem("url")
                    localStorage.removeItem("pic")
                    this.$router.push("/submitcertification");
                } else {
                    this.$message.warning(res.message);
                }
            });
        },
        againSubmit() {
            if (this.iconIsActive !== true) {
                this.$message.error({
                    title: "错误",
                    message: "请先同意上传资料协议!",
                });
                return;
            }
            let checkList2BoxAfter =
                this.formLabelAlign.checkList2.join(";") + ";" + this.checkList2Others;
            let checkListBox = this.formLabelAlign.ResearchField.join(";");
            userPuserAuthentication(
                this.formLabelAlign.name,
                this.formLabelAlign.value1,
                this.imageUrl,
                this.formLabelAlign.qualifications,
                this.formLabelAlign.position,
                this.formLabelAlign.value,
                //常住地址

                this.formLabelAlign.city,
                //相关证书
                this.formLabelAlign.picArrayList1,
                //个人简介
                this.formLabelAlign.teacher,

                //安全生产领域
                checkListBox,
                //擅长领域
                checkList2BoxAfter,
                this.formLabelAlign.honor,
                this.formLabelAlign.project,
                this.formLabelAlign.course,
                //工作经历
                this.formLabelAlign.consulting,
                this.formLabelAlign.selectedOptions,
                this.formLabelAlign.quotation,
                //擅长领域
                this.status
            ).then((res) => {
                if (res.code === 200) {
                    this.$router.push("/submitcertification");
                } else {
                    this.$message.warning(res.message);
                }
            });
        },
        againPostInfo() {
            this.num = 1
            localStorage.setItem("num", this.num)
            console.log(this.formLabelAlign.phoneNum.length)
            if (this.formLabelAlign.ResearchField.length !== 0 && this.formLabelAlign.checkList2.length !== 0 && this.formLabelAlign.city !== "" && this.formLabelAlign.consulting !== ""
                && this.formLabelAlign.course !== "" && this.formLabelAlign.honor !== "" && this.formLabelAlign.name !== "" && this.formLabelAlign.phoneNum.length === 11 && this.formLabelAlign.picArrayList.length !== 0
                && this.formLabelAlign.picArrayList1.length !== 0 && this.formLabelAlign.position !== "" && this.project !== "" && this.formLabelAlign.qualifications !== "" && this.formLabelAlign.quotation !== ""
                && this.formLabelAlign.selectedOptions !== "" && this.formLabelAlign.teacher !== "" && this.formLabelAlign.value !== "" && this.formLabelAlign.value1 !== ""
            ) {
                this.submit()
            } else {
                this.$message.error("请完善信息后，再提交");
                return false;
            }
            // this.$refs.formName.validate((valid) => {
            //     console.log(valid)
            //     if (valid) {
            //         this.againSubmit();
            //     } else {
            //         // console.log(111)
            //         this.$message.error("请完善信息后，再提交");
            //         return false;
            //     }
            // });
        }
        // postInfo() {
        //   console.log(this.formLabelAlign.city);
        //   let checkListBox = this.formLabelAlign.ResearchField.join(";");
        //   //console.log(checkListBox);
        //   //擅长领域处理

        //   let checkList2BoxAfter =
        //     this.checkList2.join(";") + ";" + this.checkList2Others;
        //   if (!this.formLabelAlign.name) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "姓名不能为空！",
        //     });
        //   } else if (!this.value1) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "出生年月不能为空！",
        //     });
        //   } else if (this.checkList2BoxAfter == "") {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "擅长领域不能为空！",
        //     });
        //   } else if (!this.formLabelAlign.qualifications) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "学历不能为空！",
        //     });
        //   } else if (!this.formLabelAlign.position) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "职位不能为空！",
        //     });
        //   } else if (!this.value) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "职称不能为空！",
        //     });
        //   } else if (this.picString == "" && this.picArrayList.length == 0) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "证书不能为空！",
        //     });
        //   } else if (!this.formLabelAlign.teacher) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "讲师简介不能为空！",
        //     });
        //   } else if (this.formLabelAlign.city == "") {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "常住地址不能为空！",
        //     });
        //   } else if (checkListBox == "") {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "安全生产领域不能为空！",
        //     });
        //   } else if (!this.formLabelAlign.consulting) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "工作经历不能为空！",
        //     });
        //   } else if (!this.formLabelAlign.course) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "主讲课程不能为空!",
        //     });
        //   } else if (this.iconIsActive != 1) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "请先阅读上传资料协议!",
        //     });
        //   } else if (!checkList2BoxAfter) {
        //     this.$notify.error({
        //       title: "错误",
        //       message: "擅长领域不能为空!",
        //     });
        //   } else {
        //     userPuserAuthentication(证书
        //       this.formLabelAlign.name,
        //       this.value1,
        //       this.imageUrl,
        //       this.formLabelAlign.qualifications,
        //       this.formLabelAlign.position,
        //       this.value,
        //       //常住地址

        //       this.formLabelAlign.city,
        //       //相关证书
        //       this.picString,
        //       //个人简介
        //       this.formLabelAlign.teacher,

        //       //安全生产领域
        //       checkListBox,
        //       //擅长领域
        //       checkList2BoxAfter,
        //       this.formLabelAlign.honor,
        //       this.formLabelAlign.project,
        //       this.formLabelAlign.course,
        //       //工作经历
        //       this.formLabelAlign.consulting,
        //       this.selectedOptions
        //       //擅长领域
        //     ).then((res) => {
        //       if (res.code == 200) {
        //         this.$router.push("/submitcertification");
        //       } else {
        //         this.$message.warning(res.message);
        //       }
        //     });
        //   }
        // },
    },
};
</script>

<style>


.commercialaffairs {
    position: relative;
}

.certificate {
    position: relative;
}

.province {
    position: relative;
}

.check {
    font-weight: 600;
    color: #f56c6c;
    position: absolute;
    top: 5px;
    margin-left: 10px;
}

.commercialaffairs .el-form-item__label,
.certificate .el-form-item__label {
    margin-left: 10px;
}

.el-message__content {
    display: inline-block !important;
    width: 222px !important;
    margin: 5px !important;
    height: 33px !important;
    overflow: hidden;
}

.el-message > p {
    margin: 0 auto !important;
    text-align: center;
    line-height: 33px;
}
</style>
<style scoped>
.head-btn {
    display: inline-block;
    width: 100px;
    height: 20px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
    margin-top: 20px;
}

.iconActive {
    color: #eb5139 !important;
    cursor: pointer !important;
}

/**上传部分样式 */

.form-box >>> .el-upload--picture-card {
    background-image: url(../../../assets/images/fangtouxiang.png);
    overflow: hidden;
    background-size: 100% 100%;
}

.form-box >>> .el-upload {
    width: 146px;
    height: 146px;
    position: relative;
}

.form-box >>> #p-load {
    background-image: url(../../../assets/images/Group255.png);
    background-repeat: no-repeat;
    background-size: 19% 100%;
}

.avatar-uploader .el-upload {
    border: 1px dashed black;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.form-box >>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #eb5139;
    background: #eb5139;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 238px;
    height: 125px;
    line-height: 125px;
    text-align: center;
    border: 1px dashed gray;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/**上传部分样式结束 */

.main {
    background-size: 100%;
    height: 2800px;
}

/* 内容区域的头部 */
.top-head {
    margin-top: 10px;
    display: flex;
}

.top-head > p {
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
}

.headers {
    width: 96px;
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
}

.headers > p {
    padding-top: 60px;
}

.form-box {
    background-color: #ffffff;
    position: relative;
    top: 20px;
    width: 1200px;
    padding: 0 110px;
    box-sizing: border-box;
}

/* 内容区域的表单 */
.authentication-form {
    margin: 24px 60px 0;
}

.authentication-form >>> .el-form-item__label {
    margin-bottom: 5px;
    padding: 0 0 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

/* 所有的* 号 */
.el-form-item u {
    position: absolute;
    left: 0;
    top: -33px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    color: #ff5740;
}

/* 所有的竖线1 */
.el-form-item b {
    position: absolute;
    top: 9px;
    left: 92px;
    width: 0px;
    height: 19px;
    opacity: 1;
    border: 1px solid #cccccc;
}

/* 所有的竖线2 */
.el-form-item strong {
    position: absolute;
    top: 10px;
    left: 72px;
    width: 0px;
    height: 19px;
    opacity: 1;
    border: 1px solid #cccccc;
}

/* 所有的input 框 */
.el-form-item >>> .el-input__inner {
    background-color: #fafafb;
    border: 1px solid #efefee;
    border-radius: 4px;
}

/* 姓名、职位的el-input框 */


.name-input >>> .el-input__inner {
    width: 300px;
    height: 40px;
    outline: none;
    font-size: 14px;
}

/* 出生年月的el-input框 */
.date-input >>> .el-input__inner {
    padding-left: 10px;
    width: 150px;
    height: 30px;
}

.date-input >>> .el-form-item__content {
    width: 300px;
    height: 40px;
    outline: none;
    font-size: 14px;
}

/* 出生年月的图标 */
.date-input >>> .el-icon-date:before {
    content: "";
}

/* 职称的el-input框 */
.rank-input >>> .el-input__inner {
    width: 150px;
    height: 40px;
}

.rank-input >>> .el-input--small {
    width: 120px;
}

.rank-input >>> .el-input__suffix {
    right: -25px;
}

/* 去掉el-select的下拉框默认蓝色框 */
.rank-input >>> .el-input .el-input__inner {
    border-color: #efefee;
}

.industry-input >>> .el-input__inner {
    border-color: #efefee !important;
}

/* 商务照片start */
.business-upload {
    position: relative;
    margin-left: 0;
}

/* 建议上传的文字 */
.business-upload p {
    position: absolute;
    top: -36px;
    left: 90px;
    color: #ccc;
    font-size: 12px;
}

/* 加号图标 */
.business-upload >>> .avatar-uploader-icon {
    position: absolute;
    left: 10%;
    top: 45%;
    font-size: 24px;
    color: #fafafb;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 50%;
    z-index: 1;
}

.avatar {
    text-align: center;
    width: 178px;
    height: 178px;
    display: block;
}

/* 商务照片 end */
/* 工作单位、职称描述、研究领域的el-input框 */
.work-input >>> .el-input__inner {
    padding-left: 10px;
    width: 697px;
    height: 40px;
    outline: none;
    font-size: 14px;
}

/* 讲师简介、荣誉、项目、主讲课程、咨询方向 */

/* 讲师简介、荣誉、项目、主讲课程、咨询方向的el-input框 */
.teacher-input >>> .el-textarea__inner {
    width: 697px;
    height: 150px;
    outline: none;
    font-size: 14px;
    vertical-align: top;
    text-align: left;
    background-color: #fafafb;
    border: 1px solid #efefee;
    border-radius: 4px;
}

/* 相关证书 start*/
.picture-certificate {
    position: relative;
    margin-left: 0;
}

/* 左边的星号 */
.picture-certificate u {
    position: absolute;
    left: 0;
    top: -33px;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    color: #ff5740;
}

.picture-certificate p {
    position: absolute;
    left: 90px;
    top: -36px;
    font-size: 12px;
    color: #ccc;
}

.quotation p {
    position: absolute;
    left: 60px;
    top: -36px;
    font-size: 12px;
    color: #ccc;
}

/* 相同的两张图片 */
.picture-certificate .picture-three {
    margin-right: 20px;
    width: 300px;
    height: 150px;
}

/* 需要定位的叉号图片 */
.picture-two,
.picture-four {
    width: 16px;
    height: 16px;
}

.picture-certificate .picture-two {
    position: absolute;
    left: 280px;
    top: 3px;
}

.picture-certificate .picture-four {
    position: absolute;
    left: 600px;
    top: 3px;
}

/* 需要定位的加号图片 */
.picture-certificate .picture-five {
    position: absolute;
    left: 745px;
    top: 40px;
    width: 90px;
    height: 77px;
}

/* 上传照片的宽高 */
.avatar-uploader {
    margin-left: 0px;
    width: 300px;
    height: 150px;
    background: #fafafb;
    border: 1px solid #efefee;
}

/* 上传照片的宽高 */
.avatar-uploader {
    margin-left: 0px;
    width: 300px;
    height: 150px;
    background: #fafafb;
    border: 1px solid #efefee;
}

/* element提供的上传照片样式 */
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.dd {
    width: 300px;
}

/* 加号图标 */
.picture-certificate >>> .avatar-uploader-icon {
    position: absolute;
    left: 780px;
    top: 45%;
    font-size: 24px;
    color: #fafafb;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-radius: 50%;
    z-index: 1;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/* 相关证书 end */
/* 同意协议 start*/
.agreement {
    position: absolute;
    left: 30px;
    top: 2610px;
    width: 160px;
    height: 20px;
    font-size: 14px;
    color: gray;
}

/* 同意协议里面的图标 */
.agreement-icon {
    position: absolute;
    left: -19px;
    top: 1px;
    display: inline-block;
    width: 16px;
    height: 16px;
    color: gray;
}

.agreement a {
    color: gray;
    border-bottom: 1px solid #ffffff;
}

/* 同意协议 end */
/* 上一步按钮 */

.btn1 {
    margin-left: 0;
    margin-right: 60px;
    width: 200px;
    height: 40px;
    border: 0;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eb5139;
    color: #eb5139;

    background-color: transparent;
}

.btn2 {
    width: 200px;
    height: 40px;
    background: #eb5139;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #ffffff;
    margin-right: 0;
    border: 0;
}

.submit {
    position: relative;
    top: 55px;
    display: flex;
    margin-top: 40px;
    width: 469px;
}

.box {
    display: flex;
}

.date-input >>> .el-input__inner {
    width: 300px;
    height: 40px;
}

.rank-input >>> .el-input__inner {
    width: 300px;
    height: 40px;
}

.rank-input >>> .el-input--small {
    width: 270px;
}

.industry-input {
    width: 300px;
}

.distpicker-address-wrapper >>> select {
    width: 149px;
}

.el-form-item >>> .el-input__inner {
    width: 300px;
    height: 40px;
}

.teacher-input >>> .el-textarea__inner {
    width: 740px;
}

.el-form-item__content .el-form-item >>> .el-input__inner {
    width: 500px;
}

.el-input__suffix {
    right: 200px !important;
}

.el-form-item__content >>> .el-col-24 {
    padding-left: 0;
}

.ptions {
//margin-left: -10px;
}

.ptions >>> .el-form-item__label {
    margin-left: 10px;
}

.province .check {
    margin-left: 10px;
}

.province .el-form-item--small >>> .el-form-item__label {
    margin-left: 10px;
}
</style>
