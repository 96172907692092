<template>
    <div class="main">
        <div class="head-search" :class="{ fixed: fixed }">
            <img :src="banner" alt @click="goPublishPosition"/>
            <div class="searchArea">
                <input
                        class="searchInput"
                        v-model="keyword"
                        placeholder="搜索职位"
                        type="text"
                        style="padding-left: 15px"
                />
                <button class="searchBtn" @click="goSearch">搜索</button>
            </div>
            <div class="hot">
                <a>热门推荐:</a>
                <button v-for="i in hotDetail" :key="i.id" @click="jobDetail(i.id)">
                    {{ i.title }}
                </button>
            </div>
        </div>
        <div :class="{ overAuto: fixed }">
            <div class="giftedperson" ref="giftedperson">
                <div>
                    <div class="hurry">
                        <span>热招职位</span>
                        <!-- <button class="job-release" @click="goPublishPosition">
                          发布职位
                        </button> -->
                    </div>
                    <div class="nav-item-box">
                        <el-menu
                                :default-active="activeIndex"
                                class="el-menu-demo"
                                mode="horizontal"
                                @select="handleSelect"
                                active-text-color="#FF3F29"
                                text-color="#666C72"
                        >
                            <el-menu-item
                                    :index="index.toString()"
                                    v-for="(item, index) in ProfessionCateList"
                                    :key="item.id"
                                    @click="DifJobList(item.code)"
                            >{{ item.cateName }}
                            </el-menu-item
                            >
                        </el-menu>
                    </div>
                </div>
                <div class="position-card">
                    <ul>
                        <el-empty
                                v-if="hotjob && !hotjob.length"
                                description="暂无数据"
                        ></el-empty>

                        <li
                                v-for="(item1, index5) in hotjob"
                                :key="item1.id"
                                class="card-box"
                                @click="goJobDetail(item1.id)"
                        >
                            <div class="box-top">
                                <div class="box-detail-1">
                                    <span>{{ item1.title }}</span>
                                    <i class="iconfont icon-Subtract" style="color: #e5e5e5"></i>
                                    <i v-if="item1.minSalary && item1.maxSalary" class="money"
                                    >{{ Math.floor(item1.minSalary) }}k-{{
                                        Math.floor(item1.maxSalary)
                                        }}k</i
                                    >
                                    <i class="money2" v-else>面议</i>
                                </div>
                                <div class="box-detail-2">
                                    <button>{{ item1.ageLimit }}</button>
                                    <button>{{ gender2(item1.educationalRequirements) }}</button>
                                </div>
                            </div>
                            <div class="box-bottom">
                                <!-- <span class="box-bottom-1">{{
                                  item1.releaseOrganizationName
                                }}</span>
                                <span class="hurry-tabs">{{
                                  item1.isHotJob ? "急聘" : ""
                                }}</span> -->
                                <p class="company">
                                    <img :src="item1.logo" alt=""/>
                                    <span>{{ item1.releaseOrganizationName }}</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <p class="common-tab-more">
                        <a href="javascript:void(0);" @click="morejob"> 查看更多 </a>
                    </p>
                </div>
                <div class="hurry">
                    <span>热门企业</span>
                    <div class="sp-bottom"></div>
                </div>
                <div class="position-card">
                    <ul>
                        <li
                                v-for="(item2, index2) in hotEnterprise"
                                :key="index2"
                                class="card-box1"
                        >
                            <div class="head-tabs">
                                <div class="left-news1">
                                    <img :src="item2.enterprise.logo" alt=""/>
                                </div>
                                <div class="left-news" @click="goEnterprise(item2)">
                                    <p>{{ item2.enterprise.institutionName }}</p>
                                    <span
                                            v-show="item2.enterprise.level || item2.enterprise.industry"
                                            style="color: gray"
                                    >{{ gender(item2.enterprise.level) }} |
                    {{ item2.enterprise.industry }}</span
                                    >
                                </div>
                            </div>
                            <el-empty
                                    v-if="item2.job && !item2.job.length"
                                    description="暂无数据"
                            ></el-empty>
                            <ul class="company-job-list">
                                <li
                                        class="company-job-item"
                                        v-for="i1 in item2.job.slice(0,3)"
                                        :key="i1.id"
                                        @click="goJobDetail(i1.id)"
                                >
                                    <div class="box-detail-1">
                                        <span style="margin-right: 5px">{{ i1.title }}</span>
                                        <i
                                                class="iconfont icon-Subtract"
                                                style="color: #e5e5e5"
                                        ></i>
                                        <i v-if="i1.minSalary && i1.maxSalary" class="money"
                                        >{{ i1.minSalary }}k-{{ i1.maxSalary }}k</i
                                        >
                                        <i class="money2" v-else>面议</i>
                                    </div>
                                    <div class="box-detail-2">
                                        <button>{{ i1.ageLimit }}</button>
                                        <button>{{ i1.careerArea }}</button>
                                        <button>{{ gender2(i1.educationalRequirements) }}</button>
                                    </div>
                                </li>
                            </ul>

                            <a class="more-job-btn" @click="goEnterprise(item2)">
                                查看更多职位</a
                            >
                        </li>
                    </ul>
                    <p class="common-tab-more">
                        <a href="javascript:void(0);" @click="moreenterprise"> 查看更多 </a>
                    </p>
                </div>
                <!--        <div class="hurry">-->
                <!--          <span>企业招聘</span>-->
                <!--          <div class="sp-bottom"></div>-->
                <!--        </div>-->
                <!--        <div class="position-card">-->
                <!--          <ul>-->
                <!--            <el-empty-->
                <!--              v-if="companyList && !companyList.length"-->
                <!--              description="暂无数据"-->
                <!--            ></el-empty>-->
                <!--            <li v-for="item3 in companyList" :key="item3.id" class="card-box2">-->
                <!--              <a href="#/companydetails" target="blank">-->
                <!--                <img :src="item3.pictureUrl" alt />-->
                <!--              </a>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import {getHotJobList, getEnterpriseList, hotJod} from "@/api/job";
import {getHomeProfessionCate, getHomeRecommend} from "@/api/home/home";

export default {
    data() {
        return {
            options1: [
                {
                    value: "Chengdu",
                    label: "职位",
                },
            ],
            value: "职位",
            activeIndex: "0",
            hotList: [],
            ProfessionCateList: [],
            hotjob: [],
            hotEnterprise: [],
            peoploNum: "",
            keyword: "",
            companyList: [],
            banner: "",
            fixed: false,
        };
    },
    methods: {
        moreenterprise() {
            this.$router.push("/enterprise");
        },
        morejob() {
            this.$router.push(`/morejob/0/0/-1`);
        },
        seekingtalents(data = "") {
            getHotJobList(data).then((res) => {
                this.hotjob = res.data;
            });
        },

        goPublishPosition() {
            if (this.$store.state.user.token == "") {
                let url = "/instructionlogin";
                if (this.$route.query.callback) {
                    url += "?callback=" + this.$route.query.callback;
                }
                this.$router.push({
                    path: url,
                });
            } else {
                this.$router.push({
                    path: "/institutional/positionrelease",
                });
            }
        },
        jobDetail(id) {
            this.$router.push({
                path: "/jobdetails?id=" + id,
            });
        },
        goEnterprise(item2) {
            this.$router.push({
                path: "/companydetails?id=" + item2.enterprise.id,
            });
        },
        goJobDetail(id) {
            this.$router.push({
                path: "/jobdetails?id=" + id,
            });
        },
        goSearch() {
            this.$router.push(`/morejob/0/${this.keyword}/-1`);
        },
        handleSelect(key, keyPath) {
        },
        DifJobList(code) {
            if (code == 0) {
                this.seekingtalents();
            } else {
                this.seekingtalents(code);
            }
        },
    },
    beforeMount() {
        getHomeProfessionCate().then((res) => {
            if (res.code == 200) {
                this.ProfessionCateList = res.data;
                this.ProfessionCateList.unshift({
                    id: 0,
                    cateName: "全部",
                    code: 0,
                    parentId: 0,
                    children: [],
                });
                // this.seekingtalents(this.ProfessionCateList[0].code);
                // getHotJobList(this.ProfessionCateList[0].code).then((res) => {
                //   this.hotjob = res.data?.slice(0, 10);
                // });
            } else {
                alert("分类请求失败");
            }
        });
    },
    mounted() {
        this.seekingtalents();
        // getHotJobList().then((res) => {
        //   this.hotjob = res.data?.slice(0, 10);
        // });

        hotJod().then((res) => {
            if (res.code == 200) {
                this.hotList = res.data;
            }
        });
        getHomeRecommend(12, 19).then((res) => {
            if (res.code == 200) {
                this.companyList = res.data;
            }
        });
        getHomeRecommend(10, 33).then((res) => {
            if (res.code == 200) {
                // console.log(res, "res");
                this.banner = res.data[0].pictureUrl;
            }
        });
        getEnterpriseList(9).then((res) => {
            if (res.code == 200) {
                this.hotEnterprise = res.data;
                if (res.data.level == 1) {
                    this.peoploNum = "少于15人";
                } else if (res.data.level == 2) {
                    this.peoploNum = "15-50人";
                } else if (res.data.level == 3) {
                    this.peoploNum = "50-150人";
                } else if (res.data.level == 4) {
                    this.peoploNum = "150-500人";
                } else if (res.data.level == 5) {
                    this.peoploNum = "500-2000人";
                } else if (res.data.level == 6) {
                    this.peoploNum = "2000人以上";
                }
            }
        });
    },
    computed: {
        gender() {
            return (value) => {
                if (value == 1) {
                    return "少于15人";
                } else if (value == 2) {
                    return "15-50人";
                } else if (value == 3) {
                    return "50-150人";
                } else if (value == 4) {
                    return "150-500人";
                } else if (value == 5) {
                    return "500-2000人";
                } else if (value == 6) {
                    return "2000人以上";
                }
            };
        },
        gender2() {
            return (value) => {
                if (value == 1) {
                    return "不限";
                } else if (value == 2) {
                    return "初中及以下";
                } else if (value == 3) {
                    return "中专/技校";
                } else if (value == 4) {
                    return "高中";
                } else if (value == 5) {
                    return "大专";
                } else if (value == 6) {
                    return "本科";
                } else if (value == 6) {
                    return "硕士";
                } else if (value == 6) {
                    return "博士";
                }
            };
        },
        hotDetail() {
            return this.hotList.slice(0, 8);
        },
    },
};
</script>


<style scoped>
.main {
//margin-top: 20px;
}

.giftedperson {
    width: 1184px;
    min-width: 1184px;
    margin: 0 auto;
    background: #f6f6f8;
    margin-bottom: 31px;
}

.box-bottom-1 {
    display: inline-block;
    width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.job-release {
    position: absolute;
    right: 20%;
    top: 0;
    background-color: #eb5139;
    color: #fff;
    padding: 3px 6px;
    border-radius: 5px;
    cursor: pointer;
}

.left-news {
    float: left;
    margin-left: 16px;
    width: 282px;
}

.left-news > span {
    /* font-size: calc(100vw * 14 / 1920);
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #999;
    line-height: 18px;
}

.left-news > p {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 22px;
    margin: 0 0 4px 0;
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.left-news1 {
    height: 44px;
    width: 44px;
    line-height: 44px;
    overflow: hidden;
    float: left;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: white;
}

.left-news1 > img {
    max-width: 100%;
    min-height: 100%;
    display: block;
    border-radius: 4px;
    object-fit: scale-down
}

.head-tabs {
    height: 44px;
    padding: 16px 20px;
    overflow: hidden;
    background: linear-gradient(90deg, #f2fafa 0, #fcfbfa 100%);
    display: flex;
}

.qiye-img {
    width: 43px;
    height: 40px;
}

.card-box2 {
    width: 190px;
    height: 80px;
    position: relative;
    background-color: #ffffff;
    margin: 3px;
    overflow: hidden;
    border-radius: 8px;
}

.card-box2 > a > img {
    width: 100%;
}

.box-detail-2 {
    white-space: normal;
    padding-right: 0;
    height: 22px;
    word-break: break-all;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-detail-2 > button {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    height: 18px;
    line-height: 18px;
    padding: 0 10px;
    border-radius: 4px;
    background: #f8f8f8;
    margin-left: 8px;
    box-sizing: border-box;
}

.box-detail-2 > button:nth-child(1) {
    margin-left: 0;
}

.money {
    position: absolute;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fe574a;
    line-height: 22px;
    flex: none;
}

.money2 {
    color: #eb6353;
    position: absolute;
    right: 5px;
    font-size: calc(100vw * 14 / 1920);
}

.box-detail-1 {
    height: 20px;
    width: 100%;
    position: relative;
    font-size: calc(100vw * 14 / 1920);
    margin-bottom: 12px;
}

.box-detail-1 > span {
    display: inline-block;
    margin-right: 8px;
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-top {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 20px;
    height: 88px;
    background-color: #ffffff;
}

.box-bottom {
    height: 48px;
    /* background-color: #fff1ef; */
    background: linear-gradient(90deg, #f5fcfc 0, #fcfbfa 100%);
    margin-top: 0;
    display: block;
    border: none;
    padding: 12px 20px;
    box-sizing: border-box;
}

.company {
    line-height: 24px;
    height: 24px;
}

.company > img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border-radius: 2px;
}

.company > span {
    display: inline-block;
    margin-left: 8px;
    font-size: 13px;
    height: 24px;
    line-height: 24px;
    color: #666;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}

.common-tab-more {
    height: 40px;
    margin-top: 8px;
    text-align: center;
}

.common-tab-more > a {
    display: inline-block;
    min-width: 112px;
    box-sizing: content-box;
    width: 184px;
    text-align: center;
    background: rgba(0, 190, 189, 0.1);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #00a6a7;
    height: 40px;
    line-height: 40px;
    border: none;
}

.common-tab-more > a:hover {
    color: #fff;
    background: #00a6a7;
}

.box-bottom2 {
    height: 32px;
    text-align: center;
    line-height: 50px;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.box-bottom2 > button {
    width: 89px;
    height: 27px;
    background-color: #ffffff;
    border: 1px solid #eb5139;
    border-radius: 4px;
}

.box-bottom > span {
    line-height: 40px;
    margin-left: 10px;
}

.hurry-tabs {
    float: right;
    margin-right: 5px;
    font-weight: 600;
    color: #eb6353;
}

.card-box {
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
    width: 384px;
    height: 136px;
    border-radius: 12px;
    margin: 0 5px;
    margin-bottom: 16px;
}

.card-box1 {
    width: 384px;
    height: 388px;
    background-color: #ffffff;
    margin: 0;
    margin: 5px;
    margin-bottom: 16px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.position-card > ul {
    display: flex;
    flex-flow: wrap;
    /* justify-content: space-between; */
}

.position-card1 > ul {
    display: flex;
    flex-flow: wrap;
    width: 90%;
    padding-left: 1%;
}

.position-card {
    /* width: 97%; */
}

.position-card1 {
    width: 1600px;
    position: relative;
    left: -23px;
}

>>> .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
    user-select: none;
    padding-bottom: 40px;
}

.main >>> .el-menu-item .is-active,
.main >>> .el-menu-item:hover,
.main >>> .el-menu-item:focus {
    background: transparent;
}

.main >>> .is-active::after {
    content: "";
    display: block;
    width: 28px;
    height: 3px;
    position: absolute;
    left: 50%;
    margin-left: -14px;
    bottom: -2px;
    background: #ff3f29;
    border-radius: 5px;
}

.el-menu {
    background: #f6f6f8;
}

.nav-item-box {
    margin-bottom: 20px;
}

.el-menu-item {
    font-size: 16px;
    position: relative;
}

.el-menu--horizontal > .el-menu-item {
    height: 25px;
    line-height: 25px;
}

.hurry {
    margin-top: 64px;
    margin-bottom: 25px;
    text-align: center;
}

.hurry > span {
    font-size: 32px;
    font-weight: 600;
    color: #222;
    line-height: 45px;
    font-family: arial, verdana, helvetica, "PingFang SC", "HanHei SC",
    STHeitiSC-Light, Microsoft Yahei, sans-serif !important;
}

.hurry1 {
    display: flex;
    margin-top: 150px;
    position: relative;
}

.hurry1 > span {
    z-index: 1;
    width: 72px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 21px;
}

.hot {
    width: 700px;
    margin-top: 6px;
}

.hot > a {
    font-size: 16px;
}

.hot > button {
    background-color: #ffffff;
    font-size: 12px;
    color: #ff826f;
    min-width: 63px;
    height: 21px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    white-space: nowrap;
    margin-left: 8px;
}

.main {
    width: 100%;
}

.head-search {
    width: 100%;
}

.head-search > img {
    width: 100%;
    height: 300px;
}

.searchArea {
    display: flex;
    width: 698px;
    height: 50px;
    border: 1px solid #ff3f29;
    z-index: 1;
    margin-top: -25px;
    border-radius: 7px;
    position: relative;
}

.searchInput {
    display: inline-block;
    width: 610px;
    border: none;
    border-radius: 6px 0 0 6px;
    outline: none;
    font-size: 16px;
}

.searchBtn {
    display: inline-block;
    width: 102px;
    border-radius: 0 5px 5px 0;
    background-color: #ff3f29;
    font-size: 18px;
    color: #ffffff;
    border: none;
}

>>> .el-input__inner {
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 6px 0 6px 6px;
}

.company-job-list {
    padding: 4px 20px 12px;
    height: 256px;
    box-sizing: border-box;
}

.company-job-item {
    display: block;
    height: auto;
    width: 344px;
    font-size: 0;
    padding: 12px 0;
    box-sizing: border-box;
    margin: 0;
}

.more-job-btn {
    display: block;
    margin: 0 auto;
    width: 122px;
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid rgba(0, 190, 189, 0.6);
    font-size: 14px;
    font-weight: 400;
    color: #00a6a7;
    transition: all 0.2s linear;
}

.more-job-btn:hover {
    color: #fff !important;
    background: #00a6a7 !important;
}
</style>
