<template>
    <div class="mainbody">
        <!-- 分类 焦点图 -->
        <div class="nav" style="width: 1205px">
            <ul class="left-box">
                <li
                        class="left-content"
                        @mouseenter="showNew(index)"
                        @mouseleave="showClose"
                        v-for="(item, index) in ProfessionCateList"
                        @click="goSearch(item.code, '', item.cateName)"
                        :key="item.cateId"
                >
                    <div class="contect" @click="goSearch(item.code, '', item.cateName)">
                        <h3>
                            <i
                                    class="iconfont icon-zonghexingye"
                                    style="
                  font-size: 22px;
                  position: relative;
                  top: 2px;
                  color: #1cafff;
                "
                            ></i>
                            {{ item.cateName }}
                        </h3>
                        <span
                                v-if="item.children.length"
                                :class="['contect-mini', { onShow: isShow === index }]"
                        >
              <span :class="['contect-mini', { onShow: isShow === index }]">{{
                  item.children[0].cateName
                  }}</span>
            </span>
                        <i
                                v-else
                                style="position: relative; top: 13px"
                                class="el-icon-arrow-right"
                        ></i>
                    </div>
                    <div class="mini-tabs">
                        <ul>
                            <li
                                    :class="{ onShow2: isShow === index }"
                                    @click.stop="goSearch(item.code, item2.code, item.cateName)"
                                    v-for="item2 in item.children"
                                    :key="item2.id"
                            >
                                {{ item2.cateName }}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bannerbox">
            <div class="carousel" style="height: 100%">
                <el-carousel :interval="5000" arrow="always" height="100%">
                    <el-carousel-item
                            v-for="(item, index) in caouselList"
                            :key="index"
                            height="100%"
                    >
                        <a :href="item.targetAddress" target="blank">
                            <img
                                    style="width: 100%"
                                    class="carouselImg"
                                    :src="item.pictureUrl"
                                    @click="carousel(item)"
                                    alt=""/>
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="index-content-box">
            <ul class="index-hot">
                <li v-for="(p, index) in RecommendList" :key="index">
                    <a :href="p.targetAddress" target="blank">
                        <img :src="p.pictureUrl" alt=""/>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tea">
            <Recommend :UserAttentionList="teacherList"/>
        </div>
        <div class="tea">
            <Cla :classList="classList"/>
        </div>
        <Detail :ProfessionCateList="ProfessionCateList"/>
        <div class="qiye" v-show="this.othersList !== []">
            <div class="hezuo">
                <ul>
                    <li v-for="p in othersList" :key="p.id">
                        <a>
                            <!--                            <img :src="p.pictureUrl" alt/>-->
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!--        绑定手机号-->
        <div class="verification">
            <el-dialog
                    title="请绑定手机号"
                    :visible.sync="dialogVisible"
                    :show-close="false"
                    :close-on-click-modal="false"
                    center
                    width="30%"
                    style="border-radius: 100px"
            >
                <div>
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
                        <!-- 手机号输入框 -->
                        <el-form-item prop="username">
                            <el-input
                                    v-model="loginForm.username"
                                    class="mobile-number"
                                    placeholder="请输入手机号"
                            ></el-input>
                        </el-form-item>
                        <!-- 验证码输入框 -->
                        <el-form-item prop="VerificationCode">
                            <el-input
                                    v-model="loginForm.VerificationCode"
                                    class="verification-code fl"
                                    placeholder="请输入验证码"
                            ></el-input>
                            <!-- 获取验证码按钮 -->
                            <button
                                    v-show="show"
                                    style="cursor: pointer"
                                    class="verification-code-obtain common"
                                    type="button"
                                    @click="bindCode"
                            >
                                获取验证码
                            </button>
                            <button
                                    v-show="!show"
                                    class="verification-code-obtain common"
                                    style="background-color: gray;cursor: no-drop"
                                    type="button"
                            >
                                获取验证码{{ count }}
                            </button>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button class="verification-code-obtain common" style="width: 40%;margin: auto" @click="binding">确 定</el-button>

                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Recommend from "./Recommend/index.vue";
import Cla from "./Cla/index.vue";
import Detail from "./Detail/index.vue";
import {
    getHomeProfessionCate,
    getHomeRecommend,
    getHomeTeacher,
    getHomeClass, getBindingPhone, wxPhone,
} from "@/api/home/home";
import {validatePhoneTwo} from "@/utils/validate";
import {getCode} from "@/api/login/personal";

export default {
    name: "",
    components: {Recommend, Cla, Detail},
    data() {
        return {
            // user: {
            //     phone: null
            // },
            count: '',
            show: true,
            dialogVisible: false,
            loginForm: {
                username: "",
                VerificationCode: "",
            },

            caouselList: [],
            hdgd: 0,
            ProfessionCateList: [],
            //推荐5图地址
            RecommendList: [],
            teacherList: [],
            classList: [],
            isShow: -1,
            first: "",
            othersList: [],
            loginRules: {
                username: [
                    {required: true, trigger: "blur", message: "手机号不能为空"},
                    {
                        pattern: /^[1]([3-9])[0-9]{9}$/,
                        message: "请输入正确的手机号码",
                    },
                    {validator: validatePhoneTwo, trigger: "blur"},
                ],
                VerificationCode: [
                    {required: true, message: "验证码不能为空", trigger: "blur"},
                ],
            },
        };
    },
    mounted() {
        const storedValue = localStorage.getItem("bindPhone");
        if (storedValue){
            this.dialogVisible = JSON.parse(localStorage.getItem("bindPhone"))
        }
        getHomeProfessionCate().then((res) => {
            if (res.code === 200) {
                this.ProfessionCateList = res.data;
            } else {
                alert("分类请求失败");
            }
        });
        getHomeRecommend(5, 1).then((res) => {
            if (res.code === 200) {
                this.RecommendList = res.data;
            }
        });
        getHomeRecommend(1, 31).then((res) => {
            if (res.code === 200) {
                console.log(res)
                this.othersList = res.data;
            } else {
            }
        });
        getHomeRecommend(4, 2).then((res) => {
            if (res.code === 200) {
                this.caouselList = res.data;
            }
        });
        getHomeTeacher(6).then((res) => {
            if (res.code === 200) {
                let reg = /[;；]/g;
                res.data.map((item) => {
                    if (item.securityField !== null) {
                        item.securityField = item.securityField.replace(reg, "、");
                    }
                    if (item.greatField !== null) {
                        item.greatField = item.greatField.slice(0, -1).replace(reg, "、");
                    }
                });
                this.teacherList = res.data;
            }
        });
        getHomeClass(4).then((res) => {
            if (res.code === 200) {
                this.classList = res.data;
            }
        });
    },
    methods: {
        // 绑定手机获取验证码
        bindCode() {
            this.$refs["loginForm"].validateField("username", (errorMessage) => {
                let valid = errorMessage === "";
                if (errorMessage !== "") {
                    this.$message.error({
                        title: "错误",
                        message: "请输入正确的手机号",
                    });
                } else {
                    if (valid === true) {
                        getBindingPhone(this.loginForm.username).then((res) => {
                            if (res.code === 200) {
                                this.show = false;
                                const TIME_COUNT = 60;
                                if (!this.timer) {
                                    this.count = TIME_COUNT;
                                    this.timer = setInterval(() => {
                                        if (this.count > 0 && this.count <= TIME_COUNT) {
                                            this.count--;
                                        } else {
                                            this.show = true;
                                            clearInterval(this.timer);
                                            this.timer = null;
                                        }
                                    }, 1000);
                                }
                                this.$message({
                                    title: "成功",
                                    message: res.data,
                                    type: "success",
                                });
                            } else {
                                this.$message.error({
                                    title: "错误",
                                    message: res.message,
                                });
                            }
                        });
                    }
                }
            });
        },

        binding() {
            wxPhone({phone: this.loginForm.username, code: this.loginForm.VerificationCode}).then((res) => {
                console.log(res)
                if (res.code === 200) {
                    this.$message({
                        title: "成功",
                        message: "绑定成功",
                        type: "success",
                    });
                    this.dialogVisible = false
                    localStorage.removeItem("bindPhone");
                } else {
                    this.$message.error({
                        title: "错误",
                        message: res.message,
                    });
                }
            })
        },
        showNew(index) {
            this.isShow = index;
        },
        showClose() {
            this.isShow = -1;
        },
        goSearch(item, item2 = "", item3) {
            this.$router.push(
                "/hometabsdetails?id=" +
                item +
                "&childrenId=" +
                item2 +
                "&totalName=" +
                item3
            );
        },
    },
    computed: {
        watchInputData() {
            return (value) => {
                if (value) {
                    return value;
                } else {
                    return "";
                }
            };
        },
    },
};
</script>

<style scoped>
.verification-code-obtain {
    width: 25%;
    height: 44px;
    margin-left: 8%;
    background: #eb5139;
    border-radius: 44px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 0;
}

.verification /deep/ .verification-code {
    width: 55%;
    height: 44px;
//margin-right: 50px;
}

.verification-code >>> .el-input__inner {
    margin-left: 20px;
    font-size: 16px;
    height: 44px;
    color: #333;
    width: 100%;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

.mobile-number {
    width: 85%;
    margin-left: 20px;
}

.mobile-number >>> .el-input__inner {
    width: 100%;
    margin: auto auto 10px;
    height: 44px;
    outline: none;
    color: #333;
    font-size: 16px;
    border-radius: 44px;
    border: 1px solid #d0d0d0;
}

.common {
    color: #fff;
    background-color: #eb5139;
}

.mainbody > .index-content-box {
    margin-top: 20px !important;
    width: 1200px;
}

.tea {
    background: #fff !important;
    width: 1200px;
    padding: 0 10px;
    box-sizing: border-box;
}

.qiye {
    width: 1200px;
}

.tea-head {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 28px;
}

.tea-head > i {
    font-size: 26px;
    color: #1079cc;
    margin-right: 5px;
}

.hezuo {
    background: #fff;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
}

.hezuo > ul > li > a > img {
    align-items: center;
    width: 100%;
    height: 178px;
}

.hezuo > ul > li > a {
    display: block;
    width: 100%;
    text-align: center;
}

.hezuo > ul > li {
    width: 100%;
    text-align: center;
}

.hezuo > button {
    width: 40px;
    font-size: 14px;
    padding: 3px 7px;
    background-color: #1079cc;
    color: #ffffff;
    position: absolute;
    top: 0;
}

.hezuo > ul {
    display: flex;
    position: relative;
    margin-top: 35px;
    margin-bottom: 25px;
}

.contect {
    display: flex;
    line-height: 40.5px;
}

.contect > h3 {
    font-size: 16px;
    margin-left: 25px;
}

.contect-mini {
    color: #666c72;
}

.left-content {
    position: relative;
    height: 50px;
    width: 100%;
}

.left-content:hover {
    color: #333;
    background-color: #f2f2f2;
    cursor: pointer;
}

.left-box {
    color: #fff;
    width: 183px;
    position: absolute;
    top: 74px;
    width: 206px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    height: 500px;
    margin-top: 2px;
}

.mini-tabs {
    position: absolute;
    right: -301px;
    top: 0;
    z-index: 996;
    width: 300px;
    background-color: #ffffff;
}

.mini-tabs > ul {
    display: flex;
}

.mini-tabs > ul > li {
    margin: 20px;
    color: #000000;
    display: none;
}

.onShow2 {
    display: block !important;
}

.onShow {
    color: #ff3f29;
}

.index-hot > li > a > img {
    width: 100%;
    height: 100%;
}

.tea .index-content-box {
    display: flex;
    box-sizing: border-box;
}

.index-hot {
    display: flex;
    justify-content: space-between;
}

.index-hot > li {
    width: 225px;
    height: 107px;
    margin: 0;
}

.mainbody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bannerbox {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 500px;
//margin-top: 20px; position: relative; overflow: hidden;
}

.bannerbox .nav {
    width: 183px;
    height: 366px;
    padding: 20px 0;
    margin-right: 20px;
    background: #ffffff;
    border-radius: 8px;
}

.bannerbox .nav .fir-content {
    width: 159px;
    height: 32px;
    margin-bottom: 6px;
    padding: 0 12px;
    cursor: pointer;
    position: relative;
    background-color: red;
}

.bannerbox .nav .fir-content:hover {
    background: #f2f2f2;
}

.bannerbox .nav .fir-content h3 {
    float: left;
    padding-right: 8px;
    color: #33353c;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}

.bannerbox .nav .fir-content span {
    float: left;
    color: #666c72;
    font-size: 14px;
    line-height: 32px;
}

.bannerbox .nav .fir-content:hover h3 {
    color: #ff3f29;
}

.bannerbox .nav .fir-content:hover span {
    color: #ff3f29;
}

.bannerbox .nav .fir-content i {
    display: inline-block;
    width: 12px;
    height: 13px;
    margin: 9.5px 0;
}

.bannerbox .nav .sub-content {
    display: none;
    position: absolute;
    left: 183px;
    top: -20px;
    z-index: 97;
    width: 416px;
    height: 96px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(82, 78, 78, 0.18);
    border-radius: 4px;
}

.bannerbox .nav .fir-content:hover .sub-content {
    display: block;
}

.bannerbox .nav .sub-content h4 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}

.nav {
    overflow: hidden;
}

.bannerbox .nav .sub-content ul {
    width: auto;
    height: auto;
    margin-top: 12px;
}

.bannerbox .nav .sub-content ul li {
    float: left;
    width: auto;
    height: 20px;
    margin: 0 24px 8px 0;
}

.carousel {
    width: 100%;
    height: 100%;
}

/* 轮播图 */
.el-carousel {
    height: 100%;
}

.el-carousel__container {
    position: relative;
    height: 100%;
}

.carouselImg {
    width: 100%;
    height: 100%;
}

>>> .el-carousel {
    overflow: hidden;
}

.nav {
    width: 1205px;
}

.mainbody .tea {
    padding: 0;
}
</style>
