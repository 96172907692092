const state = {
    courseInfo: {},
    vedioList: {}
}

const mutations = {
    COURSEINFO(state, data) {
        state.courseInfo = data

    },
    COURSEDIRECTORY(state, data1) {
        state.vedioList = data1

    },
    // USERLOGOUT(state) {
    //   state.token = ''
    //   sessionStorage.removeItem('COMPANY_AUTH_INFO')

    // }


}

const actions = {
    //登录业务
    courseInfo({ commit }, data) {
        //服务器下发token，用户唯一标识符(uuid)
        //将来经常通过带token找服务器要用户信息进行展示

        //用户已经登录成功且获取到token
        commit("COURSEINFO", data);
        //持久化存储token
    },
    // userLogOut({ commit }) {
    //   commit("USERLOGOUT", data);
    // }
    courseDirectory({ commit }, data1) {
        //服务器下发token，用户唯一标识符(uuid)
        //将来经常通过带token找服务器要用户信息进行展示
        //用户已经登录成功且获取到token
        commit("COURSEDIRECTORY", data1);
        //持久化存储token
    },


}

export default {
    state,
    mutations,
    actions,

};
