import request from '@/utils/request'
export function getCourseInfo(current, size, code, specialId, key, vipCurriculum) {
    return request({
        url: `api/courseInfo/list`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'current': current,
            'size': size,
            'industryType': code,
            'specialId': specialId,
            'key': key,
            'vipCurriculum': vipCurriculum
        }
    })
}

export function getCourseInfoVip(current, size, code, specialId) {
    return request({
        url: `api/courseInfo/vipList`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'current': current,
            'size': size,
            'code': code,
            'specialId': specialId
        }
    })
}

export function getUserSearch(current, key, size) {
    return request({
        url: `api/courseInfo/selKey`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            'current': current,
            'key': key,
            'size': size
        }
    })
}

export function getSpecialList() {
    return request({
        url: `api/professionCate/specialList`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data

    })
}