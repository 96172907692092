import request from '@/utils/request'

export function getCourseDetail(params) {
    return request({
        url: `api/courseInfo/info/${params}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function getCourseDirectory(id, current, size) {
    return request({
        url: `api/courseInfo/info/directory`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            id: id,
            current: current,
            size: size
        }
    })
}

export function getCourseDirectoryAll(id) {
    return request({
        url: `api/courseInfo/info/directoryAll`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            id: id,
        }
    })
}