<template>
  <div>
    <span class="detail-head">编辑信息</span>
    <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none" />
    <span class="head-btn">
      <i style="color:#FF6767">*</i>封面上传
    </span>
    <span class="head-tabs">(格式jpeg丶png，文件大小<5MB，建议尺寸>1146*717,最低尺寸>960*600)</span>
    <br />
    <el-upload
      action
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :limit="5"
      :on-change="handleVideo"
      :before-upload="beforeUploadVideo"
    >
      <img style="width:100%" v-if="imageUrl" :src="imageUrl" class="avatar" />
    </el-upload>
    <span class="head-btn">
      <i style="color:#FF6767">*</i>视频上传
    </span>
    <span class="head-tabs">((推荐采用mp4丶flv丶avi丶mpg4格式，可有效缩短审核转码耗时))</span>
    <br />
    <el-upload
      action
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :limit="5"
      :on-change="handleVideo"
      :before-upload="beforeUploadVideo"
    ></el-upload>
    <!-- :http-request="uploadURL" -->

    <el-dialog :visible.sync="dialogVisible">
      <video width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
    <span class="head-btn">
      <i style="color:#FF6767">*</i>视频时长
    </span>
    <br />
    <el-col :span="2">
      <el-input type="time" v-model="input" placeholder="请输入内容"></el-input>
    </el-col>
    <br />
    <br />
    <span class="head-btn">
      <i style="color:#FF6767">*</i>视频显示顺序
    </span>
    <br />
    <el-col>
      <el-input-number
        v-model="num"
        controls-position="right"
        @change="handleChange"
        :min="1"
        :max="10"
      ></el-input-number>
    </el-col>
    <br />
    <br />
    <span class="head-btn">
      <i style="color:#FF6767">*</i>是否为试看视频
    </span>
    <div style="margin:5px 0">
      <el-radio v-model="radio" label="1">会员免费</el-radio>
      <el-radio v-model="radio" label="2">免费课程</el-radio>
    </div>
    <br />
    <span class="head-btn">
      <i style="color:#FF6767">*</i>课程标题
    </span>
    <br />
    <el-col :span="17">
      <el-input type="text" placeholder="请输入内容" v-model="text" maxlength="41" show-word-limit></el-input>
    </el-col>
    <br />
    <br />
    <button style=" background-color: #47A7FF;" class="commit-btn">保存为草稿</button>
    <button style=" background-color: #eb5139;" class="commit-btn">发布</button>
  </div>
</template>

<script>
import { direct, sts } from "@/api/direct.js";
import { client, getFileNameUUID } from "./alioss.js";
import { ossDirect, ossDirectVideo, uuid } from "@/utils/ossDirect.js";
export default {
  data() {
    return {
      imageUrl: "", //封面图片地址,
      dialogImageUrl: "",
      dialogVisible: false,
      //imageUrlList: [],
      input: 0,
      num: 1,
      radio: "1", //单选按钮状态
      text: "",
      videoUrl: "",
      // 视频上传中
      videoLoading: false,
      // 回顾图片正在上传中
      imgLoading: false,
      // 回顾图片
      img: ""
    };
  },
  methods: {
    uploadURL(file) {
      //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = "pic/" + "banner" + `${Date.parse(new Date())}` + ".jpg";
      //定义唯一的文件名，打印出来的uid其实就是时间戳
      client()
        .multipartUpload(fileName, file.file, {
          progress: function(percentage, cpt) {
            // console.log("打印进度", percentage);
          }
        })
        .then(res => {
          //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
          this.Addfrom.url =
            "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
            fileName;
        });
    },

    beforeUploadVideo() {
      return true;
    },
    /*
				视频
			*/
    handleVideo(file) {
      let beforeBool = true;
      if (beforeBool) {
        this.videoLoading = true;
        direct().then(res => {
          // res = res.data;
          if (res.code == 200) {
            // 文件名，重新写一个文件名
            const pathArr = file.name.split(".");
            let suffix = pathArr[pathArr.length - 1];

            if (!suffix) {
              this.$message({
                type: "error",
                message: this.i18n.scsb
              });
              return;
            }
            // 重新赋值文件名字
            suffix = "." + suffix;
            let key =
              res.data.dir +
              uuid(36, 36) +
              new Date().getTime() +
              Math.floor(Math.random() * 100000) +
              suffix;
            // 获取sts
            sts().then(con => {
              // con = con.data;
              if (con.code == 200) {
                ossDirectVideo(
                  con.data,
                  key,
                  file.raw,
                  suc => {
                    // this.$message({
                    //   type: "success",
                    //   message: this.i18n.schcg
                    // });
                    // this.videoLoading = false;
                    // this.videoUrl = URL.createObjectURL(file.raw);
                    // this.form.video = key;

                    // console.log(
                    //   "url",
                    //   "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                    //     key
                    // );
                    this.imageUrl =
                      "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                      key;
                  },
                  err => {
                    this.videoLoading = false;
                    this.$message({
                      type: "error",
                      message: this.i18n.scsb
                    });
                  }
                );
              }
            });
          }
        });
      }
    },
    handleChange(value) {
      console.log(value);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(dialogImageUrl);
    },
    handleAvatarSuccess1(res, file) {
      this.imageUrlList.push(URL.createObjectURL(file.raw));

      console.log(this.imageUrlList);
    }
  }
};
</script>

<style scoped>
.commit-btn {
  margin-top: 42px;
  width: 134px;
  height: 33px;

  text-align: center;
  line-height: 33px;
  font-size: 14px !important;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 33px;
  margin-bottom: 33px;
}
>>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #eb5139;
  background: #eb5139;
}
/**上传部分样式 */

>>> .el-upload--picture-card {
  background-image: url(../../../assets/images/Group259.png);
  overflow: hidden;
  background-size: 100% 100%;
}
>>> .el-upload {
  width: 238px;
  height: 125px;
  position: relative;
}
>>> #p-load {
  background-image: url(../../../assets/images/Group255.png);
  background-repeat: no-repeat;
  background-size: 19% 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
>>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #eb5139;
  background: #eb5139;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 238px;
  height: 125px;
  line-height: 125px;
  text-align: center;
  border: 1px dashed gray;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/**上传部分样式结束 */
.head-tabs {
  width: 394px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #e5e5e5;
  line-height: 14px;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
.head-btn {
  display: inline-block;
  width: 155px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  margin-top: 10px;
}
</style>
