<template>
  <div class="main">
    <div class="header-card">
      <div>
        <span class="header-left">分类 :</span>
        <el-button type="warning" size="mini" plain>全部</el-button>
      </div>
      <div class="tabs-box">
        <button class="first-btn">按性质</button>
        <button class="o-btn" v-for="o in 5" :key="o">123</button>
      </div>
      <div class="tabs-box">
        <button class="first-btn">按行业</button>
        <button class="o-btn" v-for="(item,index) in industry" :key="index">{{item.cateName}}</button>
      </div>
    </div>

    <span style="color:gray">
      共有
      <i style="color:black">{{searchDetails.total}}</i> 门包含“
      <i style="color:black">PS</i> ”的课程
    </span>
    <br />

    <span>专业课/系列课</span>
    <!-- 课程详情 -->
    <div class="cla-detail">
      <ul class="classItem">
        <li v-for="(item,index) in searchDetails.records" :key="item.id">
          <img :src="item.surfacePlotUrl" alt title />
          <h4>{{item.headline}}</h4>
          <div class="information">
            <span class="fl">导师：{{item.teacherName}}</span>
          </div>
          <br />
          <div class="times-card">
            <div class="left">
              <span>{{timestampToTime(item.updateTime)}}</span>
            </div>
            <div class="center"></div>
            <div class="right">
              <span>￥{{item.originalPrice}}</span>
            </div>
          </div>
          <!-- <p class="public">公开课</p> -->
        </li>
      </ul>
    </div>
    <div class="Pga">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :page-size="10"
        :current-page.sync="currentPage"
        :total="searchDetails.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <Recommend :tea="tea" />
    <!-- 资料推荐 -->
    <!-- <div class="material">
      <h3 class="m-head">推荐资料</h3>
      <h3 class="head-bottom"></h3>
      <div>
        <ul class="m-detail">
          <li>
            <img src="../../assets/2021/course 4.png" alt />
            <div class="m-text">
              <span>土木工程挖沟技术1</span>
            </div>
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
          <li>
            <img src="../../assets/2021/course 4.png" alt />
          </li>
        </ul>
      </div>
    </div>-->
  </div>
</template>

<script>
import Recommend from "../Home/Recommend/index.vue";
import { getSearchDetails, getHomeProfessionCate } from "@/api/home/home";

export default {
  components: { Recommend },
  data() {
    return {
      tea: "hidden",
      liWidth: 276,
      currentPage: 1,
      searchDetails: {},

      industry: {}
    };
  },
  methods: {
    handleCurrentChange(val) {
      getSearchDetails(
        val,
        this.$route.params.searchValue,
        10,
        this.$route.params.value
      ).then(res => {
        if (res.code == 200) {
          this.searchDetails = res.data;
          this.$store.dispatch("userSearchDetails", res.data);
        }
      });
    },
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "年" + MM + "月" + d + "日";
    }
  },

  mounted() {
    // console.log(this.$store.state.search.userSearch);
    getSearchDetails(
      1,
      this.$route.params.searchValue,
      10,
      this.$route.params.value
    ).then(res => {
      if (res.code == 200) {
        this.searchDetails = res.data;
        this.$store.dispatch("userSearchDetails", res.data);
      }
    });
    getHomeProfessionCate().then(res => {
      if (res.code == 200) {
        this.industry = res.data;
      }
    });
  }
};
</script> 
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #444554ff;
  color: #fff;
}
</style>

<style scoped>
.cla-detail {
  width: 100%;
}
.m-text {
  display: flex;
}
.m-text > span {
  width: 106px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-detail {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}
.m-detail > li {
  margin: 24px 34px !important;
}
.m-detail > li > img {
  width: 185px;
  height: 215px;
}
.head-bottom {
  width: 34px;
  height: 0px;
  border: 3px solid #eb5139;
  margin-left: 20px;
}
.material {
  width: 1520px;
}
.m-head {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #eb5139;
  line-height: 21px;
  margin-top: 20px;
  margin-left: 5px;
}
.header-card {
  margin: 30px 0 10px 0;
  height: 127px;
  background-color: #ffffff;
}
.header-left {
  display: inline-block;
  width: 52px;
  height: 22px;
  font-size: 18px;
  margin: 20px 0 0 20px;
  font-weight: bold;
}
.tabs-box {
  margin-left: 72px;
  margin-top: 10px;
}
.first-btn {
  width: 65px;
  height: 22px;
  border-radius: 10px 0 10px 10px;
}
.o-btn {
  width: 65px;
  height: 22px;
  margin-left: 30px;
  background-color: #ffffff;
}
button {
  cursor: pointer;
}
.classItem {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.classItem > li {
  background-color: #ffffff;
  border-radius: 9px;
  float: left;
  width: 18.6%;
  margin: 10px 9px !important;
  box-shadow: 0 27px 26px #00000017;
  position: relative;
}
.classItem > li > img {
  width: 100%;
}
.classItem > li > h4 {
  font-size: 14px;
  margin: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  width: 106px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.times-card {
  display: flex;
  margin: 10px;
  color: gray;
}
.center {
  width: 100%;
  text-align: center;
}
.information {
  margin: 10px;
}
.left .right {
  margin: 10px;
}
.right > span {
  color: #eb5139;
}
.left > span {
  display: inline-block;
  width: 135px;
}
.main {
  width: 92%;
  margin: 0 auto;
}
.Pga {
  display: flex;
}
.public {
  position: absolute;
  top: 172px;
  right: 10px;
  width: 42px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #eb5139;
  line-height: 16px;
}
</style>