<template>
    <div>
        <span class="detail-head">课程管理</span>
        <button class="new-btn" @click="goAdd">新增课程</button>
        <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none"/>
        <div class="file-box">
            <button class="head-btn">管理</button>
            <!--            <input-->
            <!--                    class="head-search"-->
            <!--                    type="text"-->
            <!--                    v-model="searchDetails"-->
            <!--                    @keyup.enter="searchCourse"-->
            <!--                    placeholder="按课程名搜索"-->
            <!--            />-->
        </div>
        <div style="display:flex">
            <div style="margin:1px" v-for="(i,index) in tabsList" :key="index" @click="tabsCheck(index)">
                <a>
                    <span :class="['cla-tabs',{active:index==active}]">{{ i.name }}</span>
                </a>&nbsp;&nbsp;
                <span class="cla-i">|</span>&nbsp;
            </div>
        </div>
        <!--空数据状态-->
        <!-- 每个信息 -->

        <div>
            <el-empty v-if="userCourse.records && !userCourse.records.length" description="暂无数据"></el-empty>
            <div v-for="(item,index) in userCourse.records" :key="index">
                <div class="cla-detail">
                    <div style="position: relative" class="img-box" @click="goVideoManagement(item.id)">
                        <img :src="item.surfacePlotUrl" alt/>
                        <img style="position: absolute;bottom:-10px;right:-10px;width: 60px;height: 60px"
                             v-if="item.auditState===3" src="@/assets/images/nogo.png" alt="">
                    </div>
                    <div class="m-text">
                        <p class="m-tatol">{{ item.headline }}</p>
                        <p class="m-time">{{ timestampToTime(item.createTime) }}</p>
                        <p class="m-play">
                            <i class="iconfont icon-a-shipin1"></i>
                            {{ item.watchNumber }}
                        </p>
                        <div class="warning-box" v-if="item.auditState==3">
                            <div>
                                <span class="mini-1">|</span>
                            </div>
                            <span class="warning-taps">{{ item.cause }}</span>
                        </div>
                        <div class="warning-box" v-if="item.auditState===2">
                            <div>
                                <span class="mini-1">|</span>
                            </div>
                            <span class="succeed-taps">审核通过</span>
                        </div>
                        <div class="warning-box" v-if="item.auditState===0">
                            <div>
                                <span class="mini-1">|</span>
                            </div>
                            <span class="Unpublished-taps">未发布</span>
                        </div>
                        <div class="warning-box" v-if="item.auditState===1">
                            <div>
                                <span class="mini-1">|</span>
                            </div>
                            <span class="Unpublished-taps">待审核</span>
                        </div>
                        <!--                        <div class="warning-box" v-if="item.auditState===3">-->
                        <!--                            <div>-->
                        <!--                                <span class="mini-1">|</span>-->
                        <!--                            </div>-->
                        <!--                            <span class="Notpass-taps">未通过</span>-->
                        <!--                        </div>-->
                    </div>
                    <div class="crud-box">
                        <!--                        <button v-if="item.auditState===0" @click="distributedUpload">-->
                        <!--                            <i class="iconfont icon-shangchuan"></i>-->
                        <!--                            上传-->
                        <!--                        </button>-->
                        <button v-if="item.auditState===1" @click="uploadReminder">
                            <i class="iconfont icon-shangchuan"></i>
                            上传
                        </button>
                        <button v-if="item.auditState === 2" @click="goVideoManagement(item.id)">
                            <i class="iconfont icon-shangchuan"></i>
                            上传
                        </button>

                        <!--                        <button v-if="item.auditState===2" @click="uploadOK(item.id)">-->
                        <!--                            <i class="iconfont icon-fabu"></i>-->
                        <!--                            发布-->
                        <!--                        </button>-->
                        <!--                        <button v-else @click="uploadError" >-->
                        <!--                            <i class="iconfont icon-fabu"></i>-->
                        <!--                            发布-->
                        <!--                        </button>-->
                        <button v-if="item.auditState===1" @click="setClassError">
                            <i class="iconfont icon-bianji"></i>
                            编辑
                        </button>
                        <button @click="goSetClass(item.id)" v-if="item.auditState !==1">
                            <i class="iconfont icon-bianji"></i>
                            编辑
                        </button>

                        <button class="delete-btn" @click="open(item.id)">
                            <i class="iconfont icon-shanchu"></i>
                            删除
                        </button>
                    </div>
                </div>
                <hr class="b-line"/>
            </div>
        </div>
        <div class="Pga" v-if="userCourse.total>5">
            <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :page-size="5"
                    :current-page.sync="currentPage"
                    :total="userCourse.total"
                    @current-change="handleCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import {getUserSelectAll, delCourse} from "@/api/user/course";
import {listSaveCourseInfo} from "@/api/user/addclass";
import {getIntegralCalculation} from "@/api/login/personal";

export default {
    data() {
        return {
            active: 0,
            searchDetails: "",
            tabsList: [
                {
                    name: "全部课程",
                    color: ""
                },
                {
                    name: "审核中",
                    color: ""
                },
                {
                    name: "已通过",
                    color: ""
                },
                {
                    name: "未通过",
                    color: ""
                }
            ],
            userCourse: {},
            currentPage: 1,
            errormessage: false
        };
    },
    methods: {
        searchCourse() {
            // console.log(this.searchDetails);
        },
        timestampToTime(date) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(date);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日" + h + ":" + m;
        },
        setClassError() {
            this.$message.error({
                title: "错误",
                message: "课程已提交审核，不支持修改"
            });
        },
        uploadError() {
            this.$message.error({
                title: "错误",
                message: "课程已发布，等待审核中！"
            });
        },
        uploadOK(id) {
            listSaveCourseInfo(id).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "发布成功!"
                    });
                } else {
                    this.$message.error({
                        title: "错误",
                        message: res.message
                    });
                }
            });
            this.$router.go(0);
        },
        open(id) {
            this.$confirm("内容即将删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                delCourse(id).then(res => {
                    if (res.code === 200) {
                        this.getUserCourseList();
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    }
                    if (res.code === 400) {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    message: "取消删除"
                });
            });
        },
        tabsCheck(index) {
            getUserSelectAll(index, 1, 5).then(res => {
                if (res.code === 200) {
                    this.userCourse = res.data;
                }
            });

            this.active = index;
        },
        goAdd() {
            if (this.errormessage === false) {
                this.$message.error({
                    title: "错误",
                    message: "您还未认证成功讲师，不能上传课程！"
                });
            } else {
                this.$router.push({
                    path: "/user/addclass"
                });
            }
        },
        goVideoManagement(id) {
            this.$router.push(`/user/videomanagement?id=` + id);
        },
        uploadReminder() {
            this.$message.error({
                title: "错误",
                message: "课程已提交审核，不能上传"
            });
        },
        // distributedUpload(){
        //     this.$message.error({
        //         title: "错误",
        //         message: "课程未发布，不能上传"
        //     });
        // },
        goSetClass(id) {
            // console.log(id);
            this.$router.push({
                path: `/user/addclass`,
                name: "addclass",
                query: {
                    id: id
                }
            });
        },
        getUserCourseList() {
            getUserSelectAll(0, 1, 5).then(res => {
                if (res.code === 200) {
                    this.userCourse = res.data;
                    this.active = 0;
                    this.errormessage = true;
                } else {
                    this.$message.error({
                        title: "错误",
                        message: res.message
                    });
                    this.errormessage = false;
                }
            });
        },
        handleCurrentChange(val) {
            getUserSelectAll(this.active, val, 5).then(res => {
                if (res.code == 200) {
                    this.userCourse = res.data;
                }
            });
        }
    },
    mounted() {
        this.getUserCourseList();
        // if (Object.keys(this.$route.query).length === 0) {
        //     console.log("没有id")
        // }else {
        //     getUserSelectAll(0, 1, 5).then(res => {
        //         if (res.code === 200) {
        //             this.userCourse = res.data;
        //             console.log(111,this.userCourse.records)
        //             this.userCourse.records.forEach((item,index)=>{
        //                 if(item.id===Number(this.$route.query.id)){
        //                     if(item.auditState===2){
        //                         getIntegralCalculation(Number(this.$route.query.id),4,0,JSON.parse(localStorage.getItem("USER_AUTH_INFO")).id).then((res)=>{
        //                             console.log(res)
        //                         })
        //                     }
        //                 }
        //             })
        //             this.active = 0;
        //             this.errormessage = true;
        //         } else {
        //             this.$notify.error({
        //                 title: "错误",
        //                 message: res.message
        //             });
        //             this.errormessage = false;
        //         }
        //     });
        // }
    }
};
</script>

<style scoped>
.Pga {
    display: flex;
}

.file-box {
    position: relative;
}

.new-btn {
    width: 95px;
    height: 29px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    background-color: #eb5139;
    margin-left: 91%;
}

.head-search {
    border: 1px solid #e5e5e5;
    height: 25px;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
}

.active {
    color: #eb5139;
}

.b-line {
    border: none;
    height: 1px;
    background-color: #e5e5e5;
    margin-bottom: 4px;
}

.crud-box {
    display: flex;
    width: 27%;
    margin-left: 1px;
}

.crud-box > button {
    width: 28%;
    height: 40px;
    margin: auto;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    color: #676c71;
    font-size: calc(100vw * 14 / 1920);
}

.crud-box > button:hover {
    color: #47a7ff;
    border: 1px solid #47a7ff;
    cursor: pointer;
}

.delete-btn:hover {
    color: red !important;
    border: 1px solid red !important;
    cursor: pointer;
}

.mini-1 {
    color: #e5e5e5;
}

.m-time {
    width: 88%;
    height: 20px;
    font-size: calc(100vw * 14 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 16px;
    margin: 19px 0;
}

.m-play {
    width: 55px;
    height: 17px;
    font-size: calc(100vw * 12 / 1920);
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #e5e5e5;
    line-height: 14px;
    margin: 25px 0 1px 0;
}

.m-tatol {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.warning-box {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    position: absolute;
    top: 37px;
    left: 154px;
}

.warning-taps {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff6767;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 20px;
}

.Unpublished-taps {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ee9e0a;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 20px;
}

.succeed-taps {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #188a27;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 20px;
}

.m-text {
    position: relative;
    margin: 10px 23px;
    width: 56%;
}

.cla-detail {
    display: flex;
    width: 98%;
}

.img-box {
    margin: 10px 0;
    width: 17%;
}

.img-box > img {
    width: 100%;
}

.cla-tabs {
    margin: 20px;
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.cla-i {
    color: #e5e5e5;
}

.detail-head {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #676c71;
    line-height: 19px;
}

.head-btn {
    display: block;
    width: 100px;
    height: 26px;
    background: #eb5139;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin: 10px 5px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    padding-bottom: 2px;
}
</style>
