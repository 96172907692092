<template>
    <div
            id="app"
            style="min-width: 1440px; display: none"
            :style="{ display: 'block' }"
    >
        <div v-if="$route.meta.headShow">
            <Header></Header>
            <keep-alive>
                <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
            <PopUp v-if="!this.$store.state.user.token"/>
            <!--            <Popover-->
            <!--                    class="pop"-->
            <!--                    v-show="showPop === true && $route.meta.showPop"-->
            <!--            ></Popover>-->
            <Popover
                    class="pop"
            ></Popover>
            <Footer></Footer>
        </div>
        <router-view v-if="!$route.meta.headShow"></router-view>
    </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import Popover from "@/components/Popover/index.vue";
// import PopUp from "@/views/Login/PopUp/index.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        Popover,
        // PopUp
    },
    data() {
        return {
            showPop: true,
            screenWidth: document.body.clientWidth,
        };
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.showPop = val >= 1400;
            if (!this.timer) {
                // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                this.screenWidth = val;
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    // 打印screenWidth变化的值
                    //console.log(that.screenWidth);
                    that.timer = false;
                }, 400);
            }
        },
    },
    mounted() {
        console.log(this.screenWidth)
        if (this.screenWidth < 1400) {
            this.showPop = false;
        }
        const that = this;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth;
                that.screenWidth = window.screenWidth;
            })();
        };
    },
    created() {
        this.woCanSee = document.body.clientWidth;
        // 在页面加载时读取localStorage
        if (localStorage.getItem("store")) {
            this.$store.replaceState(
                Object.assign(
                    {},
                    this.$store.state,
                    JSON.parse(localStorage.getItem("store"))
                )
            );
        }
        // 在页面刷新时将store保存到localStorage里
        window.addEventListener("beforeunload", () => {
            localStorage.setItem("store", JSON.stringify(this.$store.state));
        });
    },
};

// 在created钩子函数中判断当前路由地址是否在首页home，如果不在则替换为home页面
// created () {
//   if (this.$router.path !== "/home")
//     this.$router.push("home")
// }
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

* {
    font-family: "微软雅黑", Arial;
}

.pop {
    position: fixed;
    top: 25%;
    right: 0.5%;
}

[v-cloak] {
    display: none;
}

#app,
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    user-select: auto;
}

.el-message {
    min-width: 100px !important;
}

.el-message__content {
    margin: 0 !important;
    padding: 0 !important;
}

.el-notification {
    top: 30px !important;
}

@media (min-width: 1024px) {
    body {
        font-size: 18px;
    }
}

/*>=1024的设备*/

@media (min-width: 1100px) {
    body {
        font-size: 20px;
    }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
    body {
        font-size: 22px;
    }
}

/*>=1280的设备*/

@media (min-width: 1366px) {
    body {
        font-size: 24px;
    }
}

@media (min-width: 1420px) {
    body {
        font-size: 14px !important;
    }
}

@media (min-width: 1680px) {
    body {
        font-size: 28px;
    }
}

@media (min-width: 1920px) {
    body {
        font-size: 22px;
    }
}
</style>
