import request from "@/utils/request";

export function getInstitutionCoures(count) {
    return request({
        url: `api/InstitutionCouresInfo/indexInstitutionRecommend`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            count: count,
        }
    })
}

export function getInstitutionCourseInfo(current, size, code, specialId, key, vipCurriculum,institutiolnId,institutiolnName) {
    return request({
        url: `api/InstitutionCouresInfo/list`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'current': current,
            'size': size,
            'industryType': code,
            'specialId': specialId,
            'key': key,
            'vipCurriculum': vipCurriculum,
            'institutiolnId':institutiolnId,
            'institutiolnName':institutiolnName
        }
    })
}

export function getInstitutionCourseDetail(params) {
    return request({
        url: `api/InstitutionCouresInfo/info/${params}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function InstitutionDirectory(params) {
    return request({
        url: `api/admin/institutionCourseInfo/info/directory`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params
    })
}

export function GetVideoPlayAuthInstitution(vid, courseId) {
    return request({
        url: 'api/aliyun/GetVideoPlayAuthInstitution',
        method: 'get',
        params: {
            vid: vid,
            courseId: courseId
        }

    })
}


export function getInstitutionDirectoryAll(id) {
    return request({
        url: `api/InstitutionCouresInfo/info/directoryAll`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            id: id,
        }
    })
}

export function getInstitutionInfo(id) {
    return request({
        url: 'api/InstitutionCouresInfo/info/byId?id=' + id,
        method: 'get'
    })
}

//回显数据
export function selCourseInfo(id) {
    return request({
        url: `api/InstitutionCouresInfo/selCourseInfo?id=${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}


//回显编辑提交
export function saveUpdateInstitutionInfo(affiliated,catena,courseDescription,freeLessons,headline,id,industryType,isPay,originalPrice,propagandaPicture,specialId,state,surfacePlotUrl,teacherName) {
    return request({
        url: 'api/InstitutionCouresInfo/saveUpdateCourseInfo',
        method: 'post',
        data:{
            affiliated: affiliated,
            catena:catena,
            courseDescription: courseDescription,
            freeLessons: freeLessons,
            headline: headline,
            id: id,
            industryType: industryType,
            isPay: isPay,
            originalPrice: originalPrice,
            propagandaPicture: propagandaPicture,
            specialId: specialId,
            state: state,
            surfacePlotUrl: surfacePlotUrl,
            teacherName: teacherName
        }

    })
}
