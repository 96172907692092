import request from '@/utils/request'

export function getCodeAndEmail(code,email) {
    return request({
        url: `api/email/forget/user/email/getCode`,
        method: 'post',
        data:{
            "code": code,
            "email": email
    }
    })
}