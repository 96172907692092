import JSEncrypt from 'jsencrypt/bin/jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

// const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh/ardHuWnLjhjmoIEqtGPqG0AB+iR46zrq/SLCILuXeh0WUj+qIFuLcRHPsA+7RFp7F8vWC1wvkdtb7UwiWaEcd1SKLWhiEY/lmUG4X8dKi68ZOHtURO0iiARXgSGxgBCjNC2qe26wW9qfaT0FewlNYMfTrzMr1oeq+Fd9bERpwIDAQAB'
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCNqIbOPyh/+gBLcrpXV76qvWpdwLgoCv1mzDSsYh5D/hzVUZDEVTRcDIfjjl+YQCVlAmCMEjvhLA2hudj/aAjbZ3igScLnIW4VcTxbZ11dwH4R8U8sDF02zchHXQ83iyN4AtAlc9E0aycaJz1Smj458bjcN41/jPTuLlqVTK++CwIDAQAB"


const privateKey = ''

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密

}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(txt)
}

