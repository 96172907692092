import request from '@/utils/request'

export function getUserConsumptionIntegral(current, size) {
    return request({
        url: `api/personalUser/consumptionIntegral`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            'current': current,
            'size': size,

        }
    })
}
export function getUserAcquireIntegral(current, size) {
    return request({
        url: `api/personalUser/acquireIntegral`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            'current': current,
            'size': size,

        }
    })
}
export function getUserIntegralAll(current, size) {
    return request({
        url: `api/personalUser/integralAll`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            'current': current,
            'size': size,

        }
    })
}