<template>
    <div style="padding-bottom: 10px">
        <div class="imgAndSearch">
            <!-- 轮播图 start-->
            <div class="data-rotation">
                <div style="height:300px">
                    <img src="@/assets/images/profileBanner.jpg" alt="">
                </div>
                <div class="search">
                    <div>
                        <el-input type="text" v-model="keyName" placeholder="请输入想要查找的案例"
                                  :placeholder-style="{ 'font-size': '17px' }"></el-input>
                        <button @click="goSearch">搜索</button>
                    </div>
                </div>
            </div>
            <!-- 轮播图 end-->
        </div>
        <div>
            <div class="contentBox">
                <div class="latest">
                    <div>
                        <div><span>最新</span>事故案例</div>
                        <div @click="getMore">更多></div>
                    </div>
                    <div>
                        <div v-for="item in latestAccident" :key="item.id" @click="getDetail(item.id,item.isType)">
                            <div>
                                <img :src="item.indexUrl" alt="">
                                <img v-if="item.isType===1" src="@/assets/images/word.png"
                                     alt="">
                                <img v-if="item.isType===0" src="@/assets/images/pdf3.png"
                                     alt="">
                                <img v-if="item.isType===2" src="@/assets/images/jpg-1.png"
                                     alt="">
                            </div>
                            <p>{{ item.accidentName }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sortBox">
                <div class="Recommended">
                    <div>
                        <div><span>精选</span>事故案例</div>
                        <div @click="getMore">更多></div>
                    </div>
                    <div>
                        <ul>
                            <li :style="{ 'border-bottom': typeCode === item.code ? '2px solid #FF6330' : '', 'color': typeCode === item.code ? '#FF6330' : '' }"
                                v-for="item in typeList" :key="item.code" @click="getTypeCode(item.code)">
                                {{ item.cateName }}
                            </li>
                        </ul>
                    </div>
                    <el-empty style="margin: 30px 0" v-if="sameAccident.length===0" description="暂无案例"></el-empty>
                    <div>
                        <div v-for="item in sameAccident" :key="item.id" @click="getDetail(item.id,item.isType)">
                            <div>
                                <img :src="item.indexUrl" alt="">
                                <img v-if="item.isType===1" src="@/assets/images/word.png"
                                     alt="">
                                <img v-if="item.isType===0" src="@/assets/images/pdf3.png"
                                     alt="">
                                <img v-if="item.isType===2" src="@/assets/images/jpg-1.png"
                                     alt="">
                            </div>
                            <p>{{ item.accidentName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {topCates} from "@/api/common";
import {getPopular, latestAccidents} from "@/api/case/caseList";

export default {
    name: "FindInformation",
    data() {
        return {
            keyName: '',//关键字
            rotationList: [], // 轮播图列表
            typeList: [],//行业列表
            typeCode: '11',
            latestAccident: [],//最新事故
            sameAccident: []//行业分类事故
        };
    },

    mounted() {
        topCates().then(res => {
            if (res.code === 200) {
                this.typeList = res.data
            }
        })
        // 最新案例
        latestAccidents().then(res => {
            if (res.code === 200) {
                this.latestAccident = res.data
            }
        })
        // 相同行业案例
        this.getPopular(this.typeCode)
    },
    methods: {
        // 封装
        getPopular(id) {
            getPopular(id).then(res => {
                if (res.code === 200) {
                    this.sameAccident = res.data
                }
            })
        },
        getTypeCode(e) {
            this.typeCode = e
            this.getPopular(this.typeCode)
        },
        getMore() {
            this.$router.push("/informationsearch")
        },
        getDetail(id, type) {
            this.$router.push({path: '/informationdetail', query: {id: id, type: type}})
        },
        goSearch() {
            this.$router.push({path: '/informationsearch', query: {keyName: this.keyName}})
        }
    },
};
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
}

/* 轮播图 */
.data-rotation img {
    width: 100%;
    height: 300px;
}

/* 轮播图上面的横条 */
/* element轮播图的小圆点 */
.data-rotation >>> .el-carousel__indicator--horizontal {
    padding: 30px 5px;
}

.data-rotation >>> .el-carousel__button {
    width: 52px;
    height: 6px;
    background: #fff;
}

/* 小圆点选中颜色 */
.data-rotation >>> .is-active .el-carousel__button {
    background-color: #eb5139;
}

/* elementUI提供的 el-carousel 的默认样式*/
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    /* line-height: 150px; */
    margin: 0;
}

.imgAndSearch {

}

.search {
    position: relative;
    width: 100%;
    margin: -25px auto auto;
    z-index: 2;
}

.search > div {
    width: 700px;
    margin: auto;
}

.search > div >>> .el-input--small {
    width: 596px;
}

.search > div >>> .el-input__inner {
    height: 50px;
    border: 1px solid #EB5139;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 16px;
}


.search > div > button {
    padding: 13px 33px;
    font-size: 17px;
    background-color: #EB5139;
    border: 1px solid #EB5139;
    color: white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}


.contentBox {
    width: 1200px;
    margin: 20px auto auto;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
}


.latest > div:nth-child(1) {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.latest > div:nth-child(1) > div:nth-child(1) > span {
    color: #FF6330;
}

.latest > div:nth-child(1) > div:nth-child(2) {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
}

.latest > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.latest > div:nth-child(2) > div {
    padding-top: 20px;
    width: 144px;
    margin-right: 29px;
}

.latest > div:nth-child(2) > div:nth-child(7n) {
    margin-right: 0;
}

.latest > div:nth-child(2) > div > div:nth-child(1) {
    transition: 0.5s all;
    position: relative;
}

.latest > div:nth-child(2) > div > div:nth-child(1) > img:nth-child(1) {
    width: 144px;
    height: 200px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 4px 4px 2px #E0E0E0;
    cursor: pointer;
    object-fit: scale-down;
}

.latest > div:nth-child(2) > div > div:nth-child(1) > img:nth-child(2) {
    position: absolute;
    width: 22px;
    height: 22px;
    right: -2px;
    bottom: 4px;
}

.latest > div:nth-child(2) > div > div:nth-child(1):hover {
    transform: translateY(-8px);
}

.latest > div:nth-child(2) > div > p {
    margin-top: 8px;
    height: 38px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.latest > div:nth-child(2) > div > p:hover {
    color: #FF6330;
}

/*行业推荐*/
.sortBox {
    width: 1200px;
    margin: 20px auto auto;
    padding: 25px;
    background-color: white;
    border-radius: 10px;
}


.Recommended > div:nth-child(1) {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.Recommended > div:nth-child(1) > div:nth-child(1) > span {
    color: #FF6330;
}

.Recommended > div:nth-child(1) > div:nth-child(2) {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
}

.Recommended > div:nth-child(2) {
    margin-top: 10px;
    width: 100%;
}

.Recommended > div:nth-child(2):after {
    content: " ";
    display: block;
    width: 100%;
    height: 1px;
    position: relative;
    top: -1px;
    background-color: #ECECEC;
}

.Recommended > div:nth-child(2) > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.Recommended > div:nth-child(2) > ul > li {
    font-size: 16px;
    position: relative;
    cursor: pointer;
    padding: 10px 0 10px 0;
    z-index: 2;
}

.Recommended > div:nth-child(2) > ul > li:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 2px;
    background: #FF4304;
    transition: all 0.5s;
}

.Recommended > div:nth-child(2) > ul > li:hover:after {
    left: 0;
    width: 100%;
}

.Recommended > div:nth-child(2) > ul > li:hover {
    color: #FF4304;
}

.Recommended > div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.Recommended > div:nth-child(3) > div {
    padding-top: 20px;
    width: 144px;
    margin-right: 29px;
}

.Recommended > div:nth-child(3) > div:nth-child(7n) {

    margin-right: 0;
}

.Recommended > div:nth-child(3) > div > div:nth-child(1) {
    transition: 0.5s all;
    position: relative;
}

.Recommended > div:nth-child(3) > div > div:nth-child(1) > img:nth-child(1) {
    width: 144px;
    height: 200px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 4px 4px 2px #E0E0E0;
    cursor: pointer;
    object-fit: scale-down;
}

.Recommended > div:nth-child(3) > div > div:nth-child(1) > img:nth-child(2) {
    position: absolute;
    width: 22px;
    height: 22px;
    right: -2px;
    bottom: 4px;
}


.Recommended > div:nth-child(3) > div > div:nth-child(1):hover {
    transform: translateY(-8px);
}

.Recommended > div:nth-child(3) > div > p {
    margin-top: 8px;
    height: 38px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.Recommended > div:nth-child(3) > div > p:hover {
    color: #FF6330;
}
</style>
