import request from '@/utils/request'

export function getVerificationCode(params) {
    return request({
        url: `api/auth/user/code/${params}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function userLogin(data) {
    return request({
        url: `api/auth/user/login`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        data
    })
}

export function isAuthentication() {
    return request({
        url: `/api/puserAuthentication/user/echoAuthentication`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
    })
}

export function weixinLogin(data) {
    return request({
        url: `/api/auth/ticket`,
        method: 'post',
        data
    })
}