<template>
  <div class="main">
    <div v-if="this.pageShow==1">
      <span class="detail-head">个人资料</span>
      <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none" />
      <div class="userinfo-first">个人信息</div>
      <div v-if="userName==2">
        <i class="user-tab1">用户昵称：</i>
        <input class="user-name" v-model="afterNickName" type="text" />
        <button class="name-ybtn" @click="setName(afterNickName)">确定</button>
        <button class="name-fbtn" @click="backName">取消</button>
      </div>
      <div style="height:30px" v-if="userName==1">
        <span class="user-tab1">用户昵称：</span>
        <i style="font-size: 14px;">{{userNickName}}</i>

        <i style="font-size: 14px;" class="set-phone" @click="goNameSet">修改昵称</i>
      </div>
      <div class="userinfo-first">账号设置</div>

      <br />
      <i class="user-tab">手机：</i>
      <i v-if="userPhoneNum!==''" style="font-size: 14px;">{{userPhoneNum}}</i>
      <i v-else style="font-size: 14px;">未绑定</i>

<!--      <a class="user-modify1" @click="goPhone">修改手机</a>-->
<!--      <br />-->

      <div class="userinfo-first">更换头像</div>
      <el-upload
        action
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :limit="5"
        :on-change="handleVideo"
        :before-upload="beforeUploadVideo"
        style="overflow: hidden;"
      >
        <el-image style="height: 100%" v-if="imageUrl" :src="imageUrl" fit="cover"></el-image>
      </el-upload>
      <button class="p-commit" @click="changeAvatar">确定更换</button>
    </div>
    <div v-if="this.pageShow==2">
      <span class="detail-head">个人资料/修改密码</span>
      <hr style="margin-top:10px" />
      <i class="user-tab">原密码：</i>&nbsp;&nbsp;
      <input class="pw-input" type="password" />
      <br />
      <i class="user-tab">新密码：</i>&nbsp;&nbsp;
      <input class="pw-input" type="text" />
      <br />
      <i class="user-tab">确认密码：</i>
      <input class="pw-input" type="text" />
      <br />
      <button class="submit" @click="backMaterial">确定</button>
    </div>

    <div v-if="this.pageShow==3">
      <span class="detail-head">个人资料/修改手机号</span>
      <hr style="margin-top:10px" />
      <i class="user-tab">请输入新手机号以更换号码：</i>&nbsp;&nbsp;
      <el-input v-model="newPhoneNum"></el-input>
      <br />
      <i class="user-tab">验证码：</i>&nbsp;&nbsp;
      <input class="phone-code" v-model="phoneCode" type="text" />
      <i @click="countDown(newPhoneNum)" :style="{  pointerEvents: none}" class="yz-btn">{{content}}</i>
      <br />
      <button class="submit" @click="goPhoneNext(newPhoneNum,phoneCode)">确定</button>
      <button class="back-btn" @click="forBack">取消</button>
    </div>
    <div v-if="this.pageShow==4">
      <span class="detail-head">个人资料/修改手机号</span>
      <hr style="margin-top:10px" />
      <br />
      <i class="user-tab">输入新手机号：</i>&nbsp;&nbsp;
      <input class="phone-code" type="text" />
      <br />
      <i class="user-tab">输入验证码：</i>&nbsp;&nbsp;
      <input class="phone-code" type="text" />
      <i @click="countDown" :style="{  pointerEvents: none}" class="yz-btn1">{{content}}</i>
      <br />
      <button class="submit" @click="backMaterial">确定</button>
    </div>
  </div>
</template>

<script>
import {
  setUserNickName,
  setUserPhoneNum,
  setUserAvatar
} from "@/api/user/material";
import { getCode } from "@/api/login/personal";
import { direct, sts } from "@/api/direct.js";
import { ossDirect, ossDirectVideo, uuid } from "@/utils/ossDirect.js";
export default {
  data() {
    return {
      userName: 1,
      pageShow: 1,
      content: "获取验证码", // 获取验证码按钮文字
      totalTime: 60, // 获取验证码按钮失效时间
      none: "",
      userNickName: "",
      userPhoneNum: "",
      afterNickName: "",
      phoneCode: "",
      imageUrl: this.$store.state.user.userInfo.avatarPath, //封面图片地址
      dialogImageUrl: "",
      newPhoneNum: ""
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(dialogImageUrl);
    },
    handleVideo(file) {
        console.log(file)
      if (file.status == "ready") return;

      let beforeBool = true;
      if (beforeBool) {
        this.videoLoading = true;
        direct().then(res => {
          // res = res.data;
          if (res.code == 200) {
            // 文件名，重新写一个文件名
            const pathArr = file.name.split(".");
            let suffix = pathArr[pathArr.length - 1];

            if (!suffix) {
              this.$message({
                type: "error",
                message: this.i18n.scsb
              });
              return;
            }
            // 重新赋值文件名字
            suffix = "." + suffix;
            let key =
              res.data.dir +
              uuid(36, 36) +
              new Date().getTime() +
              Math.floor(Math.random() * 100000) +
              suffix;
            // 获取sts
            sts().then(con => {
              // con = con.data;
              if (con.code == 200) {
                  console.log(con)
                ossDirectVideo(
                  con.data,
                  key,
                  file.raw,
                  suc => {
                    // this.$message({
                    //   type: "success",
                    //   message: this.i18n.schcg
                    // });
                    // this.videoLoading = false;
                    // this.videoUrl = URL.createObjectURL(file.raw);
                    // this.form.video = key;

                    // console.log(
                    //   "url",
                    //   "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                    //     key
                    // );
                    this.imageUrl =
                      "http://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/" +
                      key;
                  },
                  err => {
                    this.videoLoading = false;
                    this.$message({
                      type: "error",
                      message: this.i18n.scsb
                    });
                  }
                );
              }
            });
          }
        });
      }
    },
    beforeUploadVideo() {
      return true;
    },
    backName() {
      this.userName = 1;
    },
    goNameSet() {
      this.userName = 2;
    },
    backMaterial() {
      this.pageShow = 1;
    },
    goPassword() {
      this.pageShow = 2;
    },
    goPhone() {
      this.pageShow = 3;
    },
    goPhoneNext(userPhoneNum, phoneCode) {
      setUserPhoneNum(userPhoneNum, phoneCode).then(res => {
        if (res.code == 200) {
          this.$alert("修改完成，再次登陆后生效！", "提示", {
            confirmButtonText: "确定"
          });
          this.pageShow = 1;
        } else {
          this.$alert(res.message, "警告", {
            confirmButtonText: "确定"
          });
          this.pageShow = 1;
        }
      });
    },
    countDown(userPhoneNum) {
      {
        this.$confirm("确认修改手机号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          getCode(userPhoneNum).then(res => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "验证码以发送!"
              });
              this.content = this.totalTime + "s后重发"; //这里解决60秒不见了的问题

              let clock = window.setInterval(() => {
                this.totalTime--;

                this.content = this.totalTime + "s后重发";

                if (this.totalTime < 0) {
                  //当倒计时小于0时清除定时器

                  window.clearInterval(clock);

                  this.content = "发送验证码";

                  this.totalTime = 60;
                }
                if (this.totalTime < 60) {
                  this.none = "none";
                } else {
                  this.none = "";
                }
              }, 1000);
            } else {
              this.$message.error({
                title: "错误",
                message: res.message
              });
            }
          });
        });
      }
    },
    //修改用户昵称
    setName(afterNickName) {
      setUserNickName(afterNickName).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功！"
          });

          this.$store.dispatch("setName", afterNickName);
          this.userNickName = this.$store.state.user.userInfo.nickName;
        } else {
          this.$message.error({
            title: "错误",
            message: res.message
          });
        }
      });
      this.userName = 1;
    },
    forBack() {
      this.pageShow = 1;
    },
    changeAvatar() {
      console.log(this.imageUrl);
      if (this.imageUrl) {
        setUserAvatar(this.imageUrl).then(res => {
          if (res.code == 200) {
            this.$message({
              title: "成功",
              message: "头像更换成功,下次登陆后生效！",
              type: "success"
            });
          } else {
            this.$message.error({
              title: "错误",
              message: res.message
            });
          }
        });
      } else {
        this.$message.error({
          title: "错误",
          message: "请选择正确的图片地址"
        });
      }
    }
  },
  mounted() {
    this.userNickName = this.$store.state.user.userInfo.nickName;
    this.userPhoneNum = this.$store.state.user.userInfo.phone;
  }
};
</script>

<style scoped>
.p-commit {
  margin-top: 33px;
  margin-left: 30px;
  margin-bottom: 55px;
  height: 44px;
  width: 88px;
  line-height: 44px;
  background-color: #eb5139;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.set-phone {
  color: #4187f2;
  margin-left: 41px;
}
.main {
  position: relative;
}
a {
  cursor: pointer;
}
.yz-btn1 {
  position: absolute;
  top: 98px;
  left: 170px;
  color: #5297e4;
  cursor: pointer;
}
.yz-btn {
  position: absolute;
  top: 82px;
  left: 140px;
  color: #5297e4;
  cursor: pointer;
}
.submit {
  width: 83px;
  height: 28px;
  background: #eb5139;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  color: #ffffff;
  margin-top: 21px;
}
.back-btn {
  width: 83px;
  height: 28px;
  background: gray;
  border-radius: 4px 4px 4px 4px;
  color: black;
  margin-top: 21px;
  margin-left: 22px;
}
.pw-input {
  display: inline-block;
  width: 209px;
  height: 28px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e5e5e5;
}
.phone-code {
  display: inline-block;
  width: 142px;
  height: 28px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e5e5e5;
}
.user-modify {
  position: absolute;
  color: #4187f2;
  bottom: 58px;
  left: 212px;
}
.user-modify1 {
  position: absolute;
  left: 212px;
  top: 192px;
  color: #4187f2;
  font-size: 14px;
}
.user-modify2 {
  position: absolute;
  left: 225px;
  bottom: 1px;
  color: #d14c3d;
}
.name-fbtn {
  width: 83px;
  height: 28px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #eb5139;
  margin-left: 20px;
  color: #eb5139;
}
.name-ybtn {
  width: 83px;
  height: 28px;
  background: #eb5139;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin-left: 20px;
  color: #ffffff;
}
.user-tab {
  margin-left: 3px;
  color: #969696;
  margin-top: 10px;
  display: inline-block;
  font-size: 14px;
}
.user-tab1 > i {
  font-size: 14px;
}
.user-tab1 {
  margin-left: 3px;
  color: #969696;
  font-size: 14px;
}
.user-name {
  width: 209px;
  height: 28px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e5e5e5;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
.userinfo-first {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 19px;
  margin-top: 20px;
  margin-left: 1px;
  margin-bottom: 10px;
}
</style>
