import request from '@/utils/request'

//行业类型
export function getCateList() {
    return request({
        url: '/api/admin/professionCate/cateList',
        method: 'get',
    })
}
export function getCate(data) {
    return request({
        url: `/api/relus/getCateList?relusType=${data}`,
        method: 'get',
    })
}
export function getPageByTypeOrRelus(data) {

    return request({
        url: `/api/relus/getPageByTypeOrRelus?relusType=${data.relusType}&size=${data.size}&current=${data.current}&time=${data.time}&type=${data.type}&titleOrText=${data.titleOrText}&content=${data.content}&relusID=${data.relusID}`,
        method: 'get',
    })
}
export function getById(data) {
    return request({
        url: `/api/relus/getById?articleId=${data}`,
        method: 'get',
    })
}
export function getByIdRelusId(data) {
    return request({
        url: `/api/relus/getByIdRelusId?relusId=${data}`,
        method: 'get',
    })
}
