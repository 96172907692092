<template>
  <div>
    <span class="detail-head">我的关注</span>
    <hr style="margin-top:10px;background-color:#E5E5E5; height:1px; border:none" />
    <div class="file-box">
      <button class="head-btn">讲师</button>
    </div>
    <el-empty v-if="UserAttentionList.records && !UserAttentionList.records.length" description="暂无数据"></el-empty>
    <Recommend
      :tea="tea"
      :liWidth="liWidth"
      :header="header"
      :UserAttentionList="UserAttentionList"
    />
  </div>
</template>

<script>
import { getUserAttention } from "@/api/user/focus";

export default {
  data() {
    return {
      tea: "hidden",
      liWidth: "17.7%",
      header: "none",
      UserAttentionList: {}
    };
  },
  methods: {},
  computed: {
    pageNum() {
      return this.$store.state.user.teaPageNum;
    }
  },
  mounted() {
    getUserAttention(1, 10).then(res => {
      if (res.code == 200) {
          console.log(res)
        this.UserAttentionList = res.data;
      }
    });
  },
  watch: {
    pageNum() {
      getUserAttention(this.$store.state.user.teaPageNum, 10).then(res => {
        if (res.code == 200) {
          this.UserAttentionList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.head-btn {
  display: inline-block;
  width: 68px;
  height: 26px;
  background: #eb5139;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 10px 5px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  padding-bottom: 2px;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
</style>