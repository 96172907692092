<template>
  <!-- 整体大背景图 -->
  <div class="scan-bg">
    <div class="scan-center">
      <!-- 左侧小背景图 -->
      <div class="scan-img"></div>
      <!-- 登录信息模块 start -->
      <div class="scan-info">
        <!-- 个人和机构 -->
        <div class="personal">
          <span class="personal-login">个人登录</span>
          <!-- 个人登录下面的线 -->
          <div class="personal-login-line"></div>
          <!-- 中间那条竖线 -->
          <div class="personal-line"></div>
          <span @click="instructionlogin" class="institution-login"
            >机构登录</span
          >
        </div>
        <!-- 验证部分 -->
        <div class="verification">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <!-- 手机号输入框 -->
            <el-form-item prop="username">
              <el-input
                v-model="loginForm.username"
                class="mobile-number"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <!-- 验证码输入框 -->
            <el-form-item prop="VerificationCode">
              <el-input
                v-model="loginForm.VerificationCode"
                class="verification-code fl"
                placeholder="请输入验证码"
                @keyup.enter.native="personalLog"
              ></el-input>
              <!-- 获取验证码按钮 -->
              <button
                v-show="show"
                class="verification-code-obtain common"
                @click="personalCode"
                type="button"
              >
                获取验证码
              </button>
              <button
                v-show="!show"
                class="verification-code-obtain common"
                style="background-color: gray; cursor: not-allowed"
                type="button"
              >
                获取验证码{{ count }}
              </button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 登录信息模块 end -->

        <!-- 注册账号 -->
        <!-- <div class="reg-account">
          <router-link to="personalregister">注册账号</router-link>
        </div>-->
        <!-- 登录即同意文字 -->
        <agreement />

        <!-- 立即登录按钮 -->
        <el-button class="log common" @click.enter="personalLog"
          >立即登录</el-button
        >
        <div class="scan-scope" @click="goScan">
          <img src="../../../assets/images/图标二维码.png" alt />
        </div>
      </div>
    </div>
  </div>
  <!-- 验证码弹出层 -->
  <!-- <div class="PersonalLogin-PopUp" v-if="isShow">
      <div class="PersonalLogin-mask"></div>
      <div class="PersonalLogin-inner">
        <div class="VerificationCode-content">
          <div class="VerificationCode-top-content">
            <img src="../images/verification-code-obtain.png" alt />
            <el-input v-model="input" placeholder="请输入验证码"></el-input>
            <el-button>确定</el-button>
            <div class="VerificationCode-top-wrong" @click="isShow=false">
              <a href="#">
                <img src="../images/CraftsmanCertification 4.png" alt />
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>-->
  <!-- </div> -->
</template>

<script>
import agreement from "@/components/agreement/index.vue";
import {login, getCode, getIntegralCalculation} from "@/api/login/personal";
import { validatePhoneTwo } from "@/utils/validate";

export default {
  name: "PersonalLogin",
  components: { agreement },
  data() {
    return {
      show: true,
      count: "",
      timer: null,
      loginForm: {
        username: "",
        VerificationCode: "",
      },
      isShow: false,
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          {
            pattern: /^[1]([2-9])[0-9]{9}$/,
            message: "请输入正确的手机号码",
          },
          { validator: validatePhoneTwo, trigger: "blur" },
        ],
        VerificationCode: [
          { required: true, trigger: "blur", message: "验证码不能为空" },
        ],
      },
    };
  },
  methods: {
    goScan() {
      let url = "/login";
      if (this.$route.query.callback) {
        url += "?callback=" + this.$route.query.callback;
      }
      this.$router.push({
        path: url,
      });
    },
    instructionlogin() {
      let url = "/instructionlogin";
      if (this.$route.query.callback) {
        url += "?callback=" + this.$route.query.callback;
      }
      this.$router.push({
        path: url,
      });
    },
    personalLog() {
      console.log(this.loginForm.username, this.loginForm.VerificationCode);
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          login(this.loginForm.username, this.loginForm.VerificationCode).then(
            (res) => {
              console.log(res);
              if (res.code == 200) {
                const h = this.$createElement;
                // this.$notify({
                //   message: h("i", { style: "color: green" }, res.message)
                // });
                this.$store.dispatch("userLogin", res);
                if (this.$route.query.callback) {
                  this.$router.push({
                    path: decodeURIComponent(this.$route.query.callback),
                  });
                } else {
                  this.$router.push({
                    path: "/home",
                  });
                }
              } else {
                this.$message({
                  title: "错误",
                  message: "验证码错误",
                  type: "error",
                });
              }
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    personalCode() {
      this.$refs["loginForm"].validateField("username", (errorMessage) => {
        let valid = errorMessage == "";
        if (errorMessage !== "") {
          this.$message.error({
            title: "错误",
            message: "请输入正确的手机号",
          });
        } else {
          console.log(valid); // true/false
          if (valid === true) {
            getCode(this.loginForm.username).then((res) => {
              if (res.code === 200) {
                this.show = false;
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.show = true;

                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
                this.$message({
                  title: "成功",
                  message: "验证码已发送",
                  type: "success",
                });
              } else {
                this.$message.error({
                  title: "错误",
                  message: res.message,
                });
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* 公共颜色 */
.common {
  color: #fff;
  background-color: #eb5139;
}
/* 整体大背景图 */
.scan-bg {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/login-background.png") center
    no-repeat;
  background-size: cover;
  padding-top: 100px;
  overflow: hidden;
}
/* 中间区域的模块 */
.scan-bg .scan-center {
  display: flex;
  align-items: flex-start;
  width: 1238px;
  height: 682px;
  margin: 0 auto 0;
  border-radius: 8px;
  overflow: hidden;
}
/* 左侧小背景图 */
.scan-bg .scan-center .scan-img {
  width: 49.99%;
  height: 100%;
  background: url("../../../assets/images/login-bg.png") no-repeat center center;
  background-size: contain;
}

/*  登录信息模块start  */
.scan-bg .scan-center .scan-info {
  position: relative;
  width: 49.99%;
  height: 100%;
  background-color: #fff;
}
/* 个人和机构 */
.personal {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 70px;
  width: 407px;
  height: 34px;
  font-size: 24px;
  color: #333;
}

/* 个人登录 */
.personal .personal-login {
  width: 96px;
  font-weight: 600;
  color: #333;
}
/* 个人登录下面的橙色线 */
.personal-login-line {
  position: absolute;
  top: 36px;
  left: 3px;
  width: 94px;
  height: 10px;
  background-color: #eb5139;
}
/* 中间那条竖线 */
.personal .personal-line {
  width: 0px;
  height: 54px;
  margin: 106px;
  border: 1px solid #d0d0d0;
}
/* 机构登录 */
.personal .institution-login {
  width: 96px;
  color: #666;
}
/*  登录信息模块end  */

/*  验证部分 start*/
.verification {
  width: 499px;
}
/* 手机号输入框 */
.mobile-number >>> .el-input__inner {
  margin-top: 20px;
  width: 499px;
  height: 44px;
  outline: none;
  color: #333;
  font-size: 16px;
  border-radius: 44px;
  border: 1px solid #d0d0d0;
}
/* 验证码输入框 */
.verification /deep/ .verification-code {
  width: 321px;
  height: 44px;
  margin-right: 50px;
}
.verification-code >>> .el-input__inner {
  font-size: 16px;
  height: 44px;
  color: #333;
  border-radius: 44px;
  border: 1px solid #d0d0d0;
}
/* 获取验证码按钮 */
.verification-code-obtain {
  width: 128px;
  height: 44px;
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
}
/*  验证部分 end*/
/* 登录按钮 */
.log {
  margin-left: 60px;
  width: 499px;
  height: 44px;
  border-radius: 44px;
  border: 0;
}

/* 二维码 */
.scan-scope img {
  position: absolute;
  top: 0;
  right: 0;
  width: 101px;
  height: 101px;
}
.scan-scope img:hover {
  cursor: pointer;
}

/* 验证码弹出层 */
/* 弹出层 */
.PersonalLogin-PopUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 遮罩层 */
.PersonalLogin-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(18, 18, 18, 0.2);
}
/* 内容区域 */
.PersonalLogin-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
}
.VerificationCode-content {
  position: relative;
}
/* 整个白色的弹出框 */
.VerificationCode-content {
  display: flex;
  flex-direction: column;
  width: 395px;
  height: 211px;
  background: #fff;
  border-radius: 4px;
}
/* 验证码图片 */
.VerificationCode-top-content img {
  margin: 21px 45px;
  width: 306px;
  height: 71px;
}
/* 输入框 */
.VerificationCode-top-content >>> .el-input__inner {
  margin: 0px 45px;
  margin-bottom: 5px;
  width: 306px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}
/* 确认按钮 */
.VerificationCode-top-content >>> .el-button {
  margin-top: 12px;
  width: 395px;
  height: 40px;
  color: #fff;
  background: #eb5139;
  border: 0px;
}
.VerificationCode-top-wrong {
  position: absolute;
  top: -13px;
  right: -35px;
}
.VerificationCode-top-wrong img {
  width: 16px;
  height: 16px;
}
</style>
