import request from '@/utils/request'

// 热门会议
export function hotTopic(data) {
  console.log(data, '1234');
  let url = '';
  if (data == 'all') {
    url = `api/meeting/classify/hotMeeting?allCount=2`
  } else {
    url = 'api/meeting/list/hotTopic?industryType=' + data
  }
  return request({
    url,
    method: 'get'
  })
}

// 最新发布
export function latestRelease(industryType) {
  let url = ''
  if (industryType == 'all') {
    url = 'api/meeting/list/latestRelease?&allCount=1'
  } else {
    url = 'api/meeting/list/latestRelease?industryType=' + industryType
  }
  return request({
    url,
    method: 'get'
  })
}
// 最新发布全部
export function latestReleaseAll(code) {
  return request({
    url: `api / meeting / list / latestRelease ?& allCount=${code} `,
    method: 'get'
  })
}
// 会议集锦
export function meetingCollection() {
  return request({
    url: 'api/meeting/meetingCollection',
    method: 'get'
  })
}


// 会议搜索结果
export function searchMetting(data) {
  return request({
    url: "api/meeting/searchMeeting?current=" + data.current + "&size=" + data.size + "&title=" + data.title,
    // url: "api/meeting/searchMeeting",
    method: 'get',
    // data
  })
}