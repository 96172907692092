<template>
    <div>
        <!-- 新课上线 -->
        <div class="index-content-box">
            <div class="index-course">
                <div class="index-content-tit" :style="{ display: header }">
                    <h3>热门课程</h3>
                    <span @click="goStudy">更多 &gt;</span>
                </div>
                <ul class="classItem">
                    <li
                            style="position: relative"
                            v-for="item in classList"
                            :key="item.id"
                            @click="goCourseDetails(item.id)"
                    >
                        <div class="imgs">
                            <img :src="item.surfacePlotUrl" alt="" class="cla-p"/>
                        </div>
                        <!-- <div
                          :style="{
                            'background-image': 'url(' + item.surfacePlotUrl + ')',
                          }"
                          class="cla-p"
                        ></div> -->
                        <p v-if="item.isPay === 3" class="tag">免费观看</p>
                        <p v-if="item.isPay === 2" class="tag">会员免费</p>
                        <h4>{{ item.headline }}</h4>
                        <div class="information">
                            <span class="fl">讲师：{{ item.teacherName }}</span>
                            <em v-if="item.isPay === 3" class="fr" style="font-size: 16px;color: red">免费</em>
                            <em v-if="item.isPay !== 3" class="fr"
                                style="font-size: 16px;color: red">&yen;{{ item.originalPrice }}</em>
                            <!--              <em class="fr">{{ item.classHour }}课时</em>-->
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 新课上线 End -->
    </div>
</template>

<script>
export default {
    props: ["classList", "header", "liWidth", "itemWidth"],
    methods: {
        goCourseDetails(id) {
            console.log(id);
            this.$router.push("/coursedetails?id=" + id);
        },
        goStudy() {
            this.$router.push("/study");
        },
    },
};
</script>

<style lang='less' scoped>
.index-content-box {
  margin-top: 20px !important;
}

.tag {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 60px;
  font-size: 0.75rem;
  color: white;
  text-align: center;
  line-height: 20px;
  background-color: #e97f37;
  border-radius: 0.5625rem 0 0.625rem 0;
}

.imgs {
  width: 100%;
  height: 141px;
  overflow: hidden;
}

.cla-p {
  width: 100%;
  height: 100%;
  //object-fit: contain;
  transition: all 1s;
}

.classItem > li:hover > .imgs > .cla-p {
  transform: scale(1.1);
}

.index-content-tit > h3 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 28px;
  border-left: #eb5139 7px solid;
  padding-left: 7px;
}

.index-content-tit {
  position: relative;
}

.index-content-tit > span {
  position: absolute;
  right: 5%;
  top: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #666;
}

.classItem {
  display: flex;
  background: #ffffff;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding-bottom: 20px;
}

.classItem > li {
  background-color: #ffffff;
  border-radius: 9px;
  width: 271.39px;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.classItem > li > img {
  width: 100%;
}

.classItem > li > h4 {
  font-size: 16px;
  margin: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.information {
  padding: 0 10px;
}

.information > span,
.information > em {
  font-size: 14px;
  color: gray;
}

.course ul {
  width: 890px;
}

.course ul > li {
  margin-bottom: 20px;
}

.metting-container .classItem > li {
  margin-right: 12px;
}

.hot-activities .classItem > li {
  margin-right: 12px;
}

.hot-activities .classItem > li:nth-child(3n) {
  margin-right: 0 !important;
}

.mainbody .index-content-tit {
  margin-left: 10px;
}

.mainbody .classItem {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.hot-activities .classItem {
  width: 100%;
}
</style>
