<template>
    <div class="lecturer">
        <div class="NewsSearch-RightData fr">
            <!-- 热门讲师 start -->
            <div class="hot-teacher fl">
                <div class="nav_hot">
                    <div class="ico_h">
                        <i class="icon_t el-icon-office-building"></i>
                        <h4>热门工匠</h4>
                    </div>
                    <a class="more" href="javascript:;" @click="lecturer">更多 &gt;</a>
                </div>
                <ul class="hot-teacher-pic">
                    <el-empty
                            v-if="teacherList && !teacherList.length"
                            description="暂无数据"
                    ></el-empty>
                    <li
                            v-for="(t, index) in teacherList.slice(0, 5)"
                            :key="index"
                            @click="goInstructorDetails(t)"
                    >
                        <div class="imgs">
                            <img :src="t.picture" alt class="fl"/>
                        </div>
                        <div class="r">
                            <ul>
                                <li class="title">{{ t.name }}</li>
                                <!-- <li class="imgs">
                                  <img src="../../assets/images/金牌讲师.jpg" alt="" />
                                </li> -->
                            </ul>
                            <p style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis" >{{ t.securityField }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 热门讲师 end -->
            <!-- 热门课程 start -->
            <div class="hot-courses-information fl">
                <div class="nav_hot">
                    <div class="ico_h">
                        <i class="icon_t el-icon-reading"></i>
                        <h4 class="title">热门课程</h4>
                    </div>
                    <a class="more" href="javascript:;" @click="popularcourses"
                    >更多 &gt;</a
                    >
                </div>
                <ul class="hot-courses-pic fl">
                    <li
                            v-for="(p, index) in courseList"
                            :key="p.id"
                            @click="goCourseDetail(p.id)"
                    >
                        <img :src="p.surfacePlotUrl" alt/>
                        <div class="hot-activities-member" v-if="p.vipCurriculum === 1">
                            会员免费
                        </div>
                        <p>{{ p.headline }}</p>
                    </li>
                </ul>
            </div>
            <!-- 热门课程 end -->
        </div>
    </div>
</template>

<script>
import {allHotTeacher, allHotCourse, teacherInfo} from "@/api/common";
import {hotDocument} from "@/api/data/index";

export default {
    name: "RightTemplate",
    data() {
        return {
            activeName: 0, // 默认展开第一个面板
            teacherList: [], //热门讲师
            courseList: [], //热门课程
            wordList: [], // 热门文档
        };
    },
    mounted() {
        const data = {
            count: 3,
        };
        // 热门讲师
        allHotTeacher().then((res) => {
            if (res.code == 200) {
                var reg = /[;；]/g;
                res.data.map((item) => {
                    item.securityField =
                        item.securityField && item.securityField.replace(reg, "、");
                });
                this.teacherList = res.data;
            }
        });
        // 热门课程
        allHotCourse(data).then((res) => {
            if (res.code == 200) {
                this.courseList = res.data;
            }
        });
        // 热门文档
        hotDocument().then((res) => {
            if (res.code == 200) {
                this.wordList = res.data;
            }
        });
    },
    methods: {
        goCourseDetail(id) {
            this.$router.push({
                path: "/coursedetails?id=" + id,
            });
        },
        popularcourses() {
            this.$router.push({
                path: "/study",
            });
        },
        lecturer() {
            this.$router.push({
                path: "/consultingexperts",
            });
        },
        goInstructorDetails(t) {
            teacherInfo(t.id).then((res) => {
                if (res.code == 200) {
                    this.$router.push({
                        path:
                            "/instructordetails?id=" +
                            t.id +
                            "&industryType=" +
                            t.industryType +
                            "&workUnit=" +
                            t.workUnit,
                    });
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
    },
    goCourseDetail(id) {
        this.$router.push({
            path: "/coursedetails?id=" + id,
        });
    },
    goInformationDetail(doc) {
        this.$router.push({
            path: "/informationdetail?id=" + doc.id,
        });
    },
};
</script>

<style lang='less' scoped>
.lecturer {
  width: 371px;
  margin: 0;
  margin-left: 10px;
}

/* 右侧 */
.NewsSearch-RightData {
  width: 100%;
  height: auto;
  background-color: #fff;
}

/* 热门讲师 start */
.hot-teacher {
  position: relative;
  padding: 20px;
  width: 351px;
}

/* 下面的橙色横线  */
.hot-teacher span {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 65px;
  height: 8px;
  background: #eb5139;
}

/* 图片信息区域 */
.hot-teacher-pic {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.hot-teacher-pic li {
  padding: 10px 0;
  cursor: pointer;
}

.hot-teacher-pic .hot-teacher-information {
  width: 340px;
  height: 43px;
}

.hot-teacher-information img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.hot-teacher-pic li .hot-teacher-information h5 {
  line-height: 30px;
  font-size: 14px;
  color: #333333;
}

.hot-teacher-pic li .hot-teacher-information h5 p {
  line-height: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #676c71;
}

/* 热门讲师 end */
/* 热门课程 start */
.hot-courses-information {
  width: 100%;
  position: relative;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

/* 下面的橙色横线 */
.hot-courses-information span {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 12px;
  width: 65px;
  height: 8px;
  background: #eb5139;
}

/* 图片信息区域 */
.hot-courses-pic {
  width: 100%;
  padding: 0px 22px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.hot-courses-pic li {
  position: relative;
  width: 288px;
  height: 197px;
  margin: 10px 0;
  background: #ffffff;
  box-shadow: 0px 27px 26px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  cursor: pointer;
}

/* 左上角会员免费的定位  */
.hot-courses-pic li .hot-activities-member {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.hot-courses-pic li img {
  width: 288px;
  height: 137px;
}

.hot-courses-pic li p {
  margin-top: 15px;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  // font-weight: 600;
  text-align: center;
  color: #000;
}

/* 热门课程 end */

/* 排序的数字 */
.hot-document .numberOrder {
  margin-right: 20px;
  color: #999aa9;
}

.hot-document /deep/ .el-collapse-item:nth-child(1) .numberOrder {
  color: #e88569;
}

.hot-document /deep/ .el-collapse-item:nth-child(2) .numberOrder {
  color: #efb543;
}

.hot-document /deep/ .el-collapse-item:nth-child(3) .numberOrder {
  color: #b57f30;
}

.hot-search {
  position: relative;
  width: 100%;
  padding: 15px;
  height: 22px;
}

.hot-search-word {
  position: absolute;
  top: 15px;
  left: 16px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

/* 热搜关键词文字下面的横条 */
.hot-search strong {
  margin-top: 15px;
  display: inline-block;
  width: 65px;
  height: 9px;
  background: #eb5139;
}

/* 整体 */
.hot-document /deep/ .el-collapse {
  margin-top: 50px;
  margin-left: 20px;
  width: 320px;
  border: 0;
  cursor: pointer;
}

.el-collapse-item-title {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 排序的数字 */
.hot-document /deep/ .el-collapse-item {
  color: #999aa9;
}

.hot-document /deep/ .el-collapse-item__header {
  width: 320px;
  border: 0;
}

.hot-document /deep/ .el-collapse-item__wrap {
  border: 0;
}

.hot-document .hot-word {
  height: 215px;
}

.hot-word-picture {
  position: relative;
  width: 267px;
  height: 215px;
  margin: 0 30px;
}

/* 数字1的定位 */
.hot-word-picture span {
  position: absolute;
  top: 7px;
  left: 12px;
}

/* 文档背景图 */
.hot-word-picture img {
  width: 143px;
  height: 189px;
}

.hot-word-picture h5 {
  margin-top: 43px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

/* 需要定位的 容量大小 */
.hot-word-picture .size {
  position: absolute;
  left: 17px;
  top: 60px;
  font-weight: 600;
}

/* 第一行文字 */
.hot-word-picture .size h6 {
  font-size: 14px;
  color: #000;
}

/* 第二行文字 */
.hot-word-picture .size p {
  color: #676c71;
  font-size: 12px;
}

/* 需要定位的 积分 */
.hot-word-picture .integral {
  position: absolute;
  left: 110px;
  top: 110px;
  font-weight: 600;
}

/* 文档图标 */
.hot-word-picture .integral img {
  width: 30px;
  height: 30px;
}

.hot-word-picture .integral p {
  color: #ff7d69;
  font-size: 12px;
  margin-left: -25px;
}

/* 需要定位的 下载量 */
.hot-word-picture .download {
  position: absolute;
  bottom: 40px;
  right: -50px;
  width: 115px;
  height: 20px;
  color: #a4a5b2;
  font-size: 14px;
}

/* 字体图标 */
.hot-word-picture .download i {
  margin-right: 5px;
}

/* 第二行的文档信息 */
.hot-search-word-msg {
  width: 300px;
  height: 390px;
  padding: 0 25px;
}

.hot-search-word-msg li {
  margin-bottom: 15px;
}

/* 排序的数字 */
.hot-search-word-msg li span {
  font-size: 14px;
  margin-right: 22px;
}

.hot-search-word-msg li em {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

/* 热门资料 end */
.nav_hot {
  position: relative;
  width: 100%;
  height: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #edf0f5;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
}

.nav_hot a {
  /* float: right; */
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  margin: 0;
  color: #666;
}

.ico_h {
  display: flex;
  color: #eb5139;
  width: 150px;
  margin: 0;
}

.icon_t {
  /* color: #ff3f29; */
  font-size: 30px;
  margin: 0;
  margin-right: 10px;
}

.ico_h h4 {
  margin: 0;
  line-height: 30px;
  font-weight: 600;
}

// 讲师
.hot-teacher {
  padding: 20px;
  padding-top: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.hot-teacher-pic {
  > li {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    border-bottom: 1px solid #ebebeb;

    > .imgs {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background: #666;

      img {
        width: 100%;
      }
    }

    .r {
      width: 200px;
      height: 45px;
      margin-left: 12px;

      ul {
        display: flex;

        li {
          margin: 0;
        }

        .title {
          color: #333;
          font-size: 16px;
          line-height: 22px;
        }

        .imgs {
          width: 70px;
          height: 20px;
          border-radius: 3px;
          margin-left: 10px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      p {
        line-height: 25px;
        color: #666;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        font-size: 14px;
        font-family: "微软雅黑", Arial;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }
}
</style>
