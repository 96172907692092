<template>
  <!-- 看资讯搜索结果页 -->
  <div>
    <!-- 版心 -->
    <div class="aNewsSearch-container">
      <!-- 左侧搜索结果区域 start -->
      <div class="aNewsSearch-LeftSearch fl">
        <!-- 左侧搜索结果的头部 start -->
        <div class="aNewsSearch-LeftSearch-top">
          <!-- 搜索框 -->
          <div class="aNewsSearch-LeftSearch-input fl">
            <el-input
              v-model="searchResults"
              placeholder="请输入要搜索的内容"
              @keyup.enter.native="searchBtn"
            ></el-input>
            <el-button @click="searchBtn">搜索</el-button>
          </div>
          <!-- 搜索结果 -->
          <p class="fl">
            共有
            <span>{{ searchList.total }}</span
            >条标题中包含 <span>"{{ this.searchResults }}"</span> 的资讯
          </p>
        </div>
        <!-- 左侧搜索结果的头部 end -->
        <!-- 左侧搜索结果的内容 start -->
        <ul class="aNewsSearch-LeftSearch-content">
          <el-empty
            v-if="searchList.records && !searchList.records.length"
            description="暂无数据"
          ></el-empty>
          <li
            v-for="(pages, index) in searchList.records"
            :key="index"
            @click="goDetails(pages)"
          >
            <div class="aNewsSearch-upload-time">
              <!-- display: inline-block; -->
              <div class="aNewsSearch-upload-time-left">
                <h3 class="fl">{{ pages.messageTitle }}</h3>
                <div class="timingsource">
                  <div class="laiyuan">
                    <!-- margin-right:4px; -->
                    <em style="color: #676c71">来源:</em>
                    <em style="color: #676c71">&nbsp&nbsp{{ pages.source }}</em>
                  </div>
                  <div class="aNewsSearch-upload-time-right">
                    <span class="fr">{{
                      timestampToTime(pages.releaseTime)
                    }}</span>
                    <span class="fr">上传时间:&nbsp&nbsp</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="content111">
              <p v-html="pages.messageDigest"></p>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <div class="footer-pagination" v-if="this.total != 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
        <!-- 左侧搜索结果的内容 end -->
      </div>
      <!-- 左侧搜索结果区域 end -->
      <!-- 右侧热门资料区域 start -->
      <RightTemplate />
      <!-- 右侧热门资料区域 end -->
    </div>
  </div>
</template>

<script>
import { newsSearch } from "@/api/news/index";
export default {
  name: "NewsSearch",
  data() {
    return {
      searchResults: "",
      // currentPage: 1,
      searchList: [], // 搜索结果列表
      teacherList: [], //热门讲师
      courseList: [], //热门课程
      total: "",
      queryInfo: {
        pagenum: 1,
        pagesize: 5,
      },
    };
  },
  mounted() {
    const data = {
      current: 1,
      size: 5,
      title: "",
    };
    newsSearch(data).then((res) => {
      this.searchList = res.data;
      this.total = res.data.total;
    });
  },
  watch: {
    searchResults: {
      // 监视的处理函数
      handler(val) {
        this.searchBtn(val);
      },
    },
  },
  methods: {
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.searchBtn();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.searchBtn();
    },
    searchBtn() {
      var param = this.searchResults;
      param = encodeURIComponent(param);
      const data = {
        current: this.queryInfo.pagenum,
        size: this.queryInfo.pagesize,
        title: param,
      };
      newsSearch(data).then((res) => {
        this.searchList = res.data;
        this.total = res.data.total;
      });
    },
    goDetails(pages) {
      this.$router.push({
        path: "/newsdetails?id=" + pages.id,
      });
    },
    // 时间戳转换为当前时间
    timestampToTime(date) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(date);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "年" + MM + "月" + d + "日" + h + ":" + m;
    },
  },
};
</script>

<style scope>
* {
  margin: 0;
  padding: 0;
}
.aNewsSearch-container {
  margin-top: 20px;
  width: 1200px;
  display: flex;
}
/* 左侧搜索结果区域 start */
.aNewsSearch-LeftSearch {
  position: relative;
  flex: 1;
}
/* 左侧搜索结果的头部 start */
.aNewsSearch-LeftSearch-top {
  flex: 1;
  height: 90px;
  background: #fff;
}
/* 搜索结果 */
.aNewsSearch-LeftSearch-top p {
  margin: 20px 0;
  font-size: 14px;
  color: #999;
}
.aNewsSearch-LeftSearch-top p span {
  color: #333;
}
/* 搜索框 */
.aNewsSearch-LeftSearch-input {
  margin: 20px;
  height: 50px;
}
.el-input--small {
  width: 550px;
}

.aNewsSearch-LeftSearch-input .el-input__inner {
  float: left;
  height: 50px;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #eb5139;
  border-right: 0;
}
/* button按钮 */
.aNewsSearch-LeftSearch-input .el-button {
  float: right;
  width: 97px;
  height: 50px;
  background: #eb5139;
  font-size: 16px;
  color: #fff;
  border: 0;
  border-radius: 0px;
}
/* 左侧搜索结果的头部 end */

/* 左侧搜索结果的内容 start */
.aNewsSearch-LeftSearch-content {
  margin-top: 50px;
  box-sizing: border-box;
  height: auto;
  background-color: #fff;
}
.aNewsSearch-LeftSearch-content li {
  position: relative;
  padding: 23px 20px 0px 20px;
  cursor: pointer;
}
.aNewsSearch-LeftSearch-content li p {
  font-size: 14px;
  color: #676c71;
}
.aNewsSearch-LeftSearch-content li .NewsSearch-upload-time {
  height: 33px;
  overflow: hidden;
}
.aNewsSearch-upload-time h3 {
  margin-right: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.aNewsSearch-upload-time span {
  font-size: 12px;
  color: #676c71;
}
.aNewsSearch-upload-time em {
  font-size: 12px;
  color: #333333;
}

.aNewsSearch-LeftSearch-content {
  margin-top: 50px;
  box-sizing: border-box;
  background-color: #fff;
}
.aNewsSearch-upload-time {
  display: flex;
  justify-content: space-between;
}
.aNewsSearch-upload-time-left {
  width: 100%;
  align-items: start;
  height: 43px;
  padding-bottom: 12px;
  margin-left: 5px;
}
.aNewsSearch-upload-time h3 {
  margin-left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.timingsource {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  align-items: center;
}
.laiyuan {
  margin: 0px;
  /* margin-right: 50px; */
}
.aNewsSearch-upload-time-right {
  margin: 0;
}
/* 分页 */
.footer-pagination {
  box-sizing: border-box;
  width: fit-content;
  margin: 31px auto;
}
.footer-pagination >>> .el-pagination {
  width: 510px;
  height: 24px;
  color: #555656;
}
/* 经过每一个没有选中的数字的颜色 */
.footer-pagination >>> .el-pager li.number {
  color: #555656;
}
/* 左箭头 */
.footer-pagination >>> .el-pagination.is-background .btn-prev {
  background-color: transparent;
}
/* 右箭头 */
.footer-pagination >>> .el-pagination.is-background .btn-next {
  background-color: transparent;
}
/* 数字框 */
.footer-pagination >>> .el-input__inner {
  width: 45px;
  height: 24px;
  border-radius: 2px 2px 2px 2px;
}
.content111 {
  padding-bottom: 25px;
  border-bottom: 2px solid #ccc;
}
.aNewsSearch-LeftSearch-content > li:last-child .content111 {
  border: none;
}
/* 左侧搜索结果的内容 end */
/* 左侧搜索结果区域 end */

/* 右侧 */
</style>