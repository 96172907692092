<template>
    <div class="head">
        <!-- top头部 -->
        <!--        <div style="width: 100%;background-color: white;position: fixed;z-index: 99;height: 77px">-->
        <div class="head-container">
            <div class="wrap">
                <!-- logo -->
                <!--          <div style="font-size: 18px">测试环境v1.0.0</div>-->
                <div>
                    <img
                            style="width: 195px"
                            class="logo"
                            src="./images/index-logo.png"
                            alt=""/>
                </div>
                <!-- logo End -->

                <!-- 网站导航 -->
                <div class="nav-item-box">
                    <el-menu
                            :default-active="defaultActivePath + ''"
                            class="el-menu-demo"
                            mode="horizontal"
                            @select="handleSelect"
                            active-text-color="#FF3F29"
                            text-color="#666C72"
                            router
                    >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/home"
                        >首页
                        </el-menu-item
                        >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/news"
                        >看资讯
                        </el-menu-item
                        >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/consultingexperts"
                        >询专家
                        </el-menu-item
                        >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/searchconference"
                        >搜会议
                        </el-menu-item
                        >
                        <!-- 查规章是新加的 -->
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/selectregulations"
                        >查规章
                        </el-menu-item
                        >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/findAgency"
                        >寻机构
                        </el-menu-item
                        >
                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/study"
                        >学课程
                        </el-menu-item
                        >
                        <!--            <el-menu-item-->
                        <!--              style="font-size: calc(100vw * 16 / 1920)"-->
                        <!--              index="/findtalent"-->
                        <!--              >找职位</el-menu-item-->
                        <!--            >-->

                        <el-menu-item
                                style="font-size: calc(100vw * 16 / 1920)"
                                index="/findinformation"
                        >案例库
                        </el-menu-item
                        >

                    </el-menu>

                </div>
                <!-- 查规章 -->
                <!-- 网站导航 End -->

                <!-- 课程搜索 -->
                <div class="searchArea">
                    <el-select
                            size="big"
                            v-model="value"
                            popper-class="select-popper"
                            :popper-append-to-body="false"
                            placeholder="请选择"
                    >
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>
                    <input
                            class="searchInput"
                            v-model="searchValue"
                            style="font-size: 14px"
                            placeholder="搜索课程/专家/会议..."
                            type="text"
                    />
                    <el-button class="searchBtn" @click="goSearch">搜索</el-button>
                </div>
                <!-- 课程搜索 End -->

                <!-- 入驻 -->
                <div class="join">
                    <a
                            href="javascript:"
                            style="color: #f06d23; font-size: 16px"
                            @click="goAuthenticationcertification"
                    >安工入驻</a
                    >
                    <a
                            href="javascript:"
                            style="color: #298bff; font-size: 16px; margin-left: 25px"
                            @click="goCompany"
                    >机构入驻</a
                    >
                </div>
                <!-- 入驻 End -->

                <!-- 登录/注册 -->

                <div class="log-box" v-if="!this.$store.state.user.token">
                    <div class="login fr">
                        <router-link
                                class="signup"
                                style="color: #909e99; font-size: 16px"
                                to="personallogin"
                        >登录/注册
                        </router-link
                        >
                    </div>
                </div>
                <div class="log-box" v-if="this.$store.state.user.token">
                    <div class="log-box" v-if="this.$store.state.user.token">
                        <div
                                class="avatar"
                                :style="'background-image:url(' + userList.avatarPath + ');'"
                        ></div>
                        <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="vertical-align: middle">
                <i
                        v-if="this.$store.state.user.userInfo.isVip === 1"
                        class="iconfont icon-vip"
                        style="font-size: 22px; color: red; vertical-align: middle"
                ></i>
                {{ userList.nickName ? userList.nickName : userList.username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                        v-if="$store.state.user.roles[0] === 'Institution'"
                                        @click.native="goUser"
                                >机构中心
                                </el-dropdown-item
                                >
                                <el-dropdown-item
                                        v-if="$store.state.user.roles[0] === 'user'"
                                        @click.native="goUser"
                                >个人中心
                                </el-dropdown-item
                                >
                                <!--                <el-dropdown-item @click.native="goUserMaterial"-->
                                <!--                  >账号设置</el-dropdown-item-->
                                <!--                >-->
                                <hr style="width: 75%"/>
                                <el-dropdown-item @click.native="logOut"
                                >退出账号
                                </el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button
                                class="msg"
                                @click="goUserinformation"
                                icon="el-icon-message-solid"
                        >
                            <i class="msg-red" :style="{ display: ma1 }">•</i>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <!--        </div>-->
        <!-- 登录/注册 End -->
    </div>
  <!-- top头部 End -->
</template>

<script>
import {getUserNotice,} from "@/api/userinformation";

export default {
    name: "Header",
    data() {
        return {
            pirUrl: "https://zhongan-resource-test.oss-cn-beijing.aliyuncs.com/",
            ma1: "block",
            options: [
                {
                    value: "课程",
                    label: "课程",
                },
                {
                    value: "专家",
                    label: "专家",
                },
                {
                    value: "会议",
                    label: "会议",
                },
                {
                    value: "规章",
                    label: "规章",
                },
                {
                    value: "案例",
                    label: "案例",
                },
            ],
            value: "专家",
            defaultActivePath: [],
            userList: {},
            searchValue: "",
            searchResults: "", // 会议关键词
        };
    },
    created() {
        if (this.$store.state.user.token) {
            getUserNotice(1, 10, 1).then((res1) => {
                if (res1.code === 200) {
                    // console.log(res.data)
                    getUserNotice(1, 10, 2).then((res2) => {
                        if (res2.code === 200) {
                            // console.log(res.data)
                            if (res1.data.total > 0 || res2.data.total > 0) {
                                this.ma1 = "block";
                            } else {
                                this.ma1 = "none";
                            }
                        }
                    });
                }
            });
            // getUserInfo().then((res)=>{
            //     console.log("信息",res)
            //     if(res.data===200){
            //         // this.$store.state.user.userInfo.isVip = res.data.isVip
            //         this.$store.state.user.userInfo.isVip =0
            //         console.log(this.$store.state.user.userInfo.isVip)
            //     }
            // })

            // console.log(this.type1, this.type2);
            if (this.type1 === 1 || this.type2 === 1) {
                this.ma1 = "block";
            } else {
                this.ma1 = "none";
            }
        }
        this.userList = this.$store.state.user.userInfo;
        //在导航栏创建时从localStorage中获取设置的activePath键的值
        this.defaultActivePath = window.localStorage.getItem("activePath");
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
            window.localStorage.setItem("activePath", keyPath);
            this.defaultActivePath = keyPath;
        },
        goSearch() {
            // console.log(this.value, this.searchValue);
            if (this.searchValue === "") {
                if (this.value === "课程") {
                    this.$router.push("/study");
                } else if (this.value === "专家") {
                    this.$router.push("/consultingexperts");
                } else if (this.value === "会议") {
                    this.$router.push("/searchconference");
                } else if (this.value === "规章") {
                    this.$router.push("/selectregulations")
                } else if (this.value === "案例") {
                    this.$router.push("/findinformation")
                }
                return;
            }
            if (this.value === "课程") {
                this.$router.push("/study?keyword=" + this.searchValue);
            } else if (this.value === "专家") {
                this.$router.push("/instructorsearch?keyword=" + this.searchValue);
            } else if (this.value === "会议") {
                this.$router.push("/meetingsearch?keyword=" + this.searchValue);
            } else if (this.value === "案例") {
                this.$router.push("/informationsearch?keyName=" + this.searchValue)
            } else if (this.value === "规章") {
                this.$router.push("/selectregulations?keyword=" + this.searchValue)
            }
        },

        goUser() {
            if (this.$store.state.user.roles.indexOf("user") >= 0) {
                this.$router.push({
                    path: "/user",
                });
            } else {
                this.$router.push({
                    path: "/institutional/intro",
                });
            }
            // this.$router.push({
            //   path: "/user"
            // });
        },
        goUserMaterial() {
            if (this.$store.state.user.roles.indexOf("user") >= 0) {
                this.$router.push({
                    path: "/user/material",
                });
            } else {
                this.$router.push({
                    path: "/institutional/account",
                });
            }
        },

        goCompany() {
            // console.log(11);
            this.$router.push({
                path: "/instructionlogin",
            });
        },
        logOut() {
            this.$store.dispatch("userLogOut");
            this.$message({
                title: "成功",
                message: "成功退出登录",
                type: "success",
            });
            this.$router.push({
                path: "/home",
            });
        },
        goUserinformation() {
            this.$router.push({
                path: "/userinformation",
            });
        },
        goAuthenticationcertification() {
            // if (this.$store.state.user.token) {
            // console.log(this.$store.state.user.userInfo.phone !== "");
            if (this.$store.state.user.userInfo.phone !== "") {
                this.$router.push("/user/Securityworkerssettlingin");
            } else {
                this.$message.error({
                    title: "警告",
                    message: "请先绑定手机号后再继续",
                });
            }
            // } else {
            //   this.$notify.error({
            //     title: "",
            //     message: "仅限个人用户登陆后进入"
            //   });
            // }
        },
    },
};
</script>

<style scoped>
.head >>> .el-menu-item {
    padding: 0 !important;
}
</style>
<style scoped lang="scss">


.msg-red {
  position: absolute;
  color: #ff3f29;
  top: 3px;
  right: 18px;
}

.msg {
  border: none;
  margin-left: 5px;
  position: relative;
  font-size: 18px;
}

.log-box {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.login a {
  text-decoration: none;
}

.join > a {
  text-decoration: none;
  margin: 0 5px 0 5px;
}

.searchInput {
  display: inline-block;
  margin-left: 88px;
  border: none;
  outline: none;
}

.searchBtn {
  display: inline-block;
  width: 80px;
  border-radius: 0 5px 5px 0;
  background-color: #ff3f29;
  font-size: 18px;
  color: #ffffff;
  border: none;
  height: 41px;
  position: absolute;
  right: -1px;
  top: -1px;
  cursor: pointer;
}

.searchArea {
  display: flex;
  width: 510px;
  height: 40px;
  border: 1px solid #ff3f29;
  z-index: 19999;
  margin-top: 11.5px;
  border-radius: 7px;
  position: relative;
  top: 6px;

  ::v-deep .el-select {
    width: 80px;
    position: absolute;
    top: -19px;
    left: 0.5px;
  }

  ::v-deep .el-input__inner {
    border: 0;
    height: 35px;
  }
}

.nav-item-box {
  width: 37%;
  min-width: 400px !important;
  margin-left: 15px;
}

.el-menu-item {
  width: 16%;
  font-size: 16px;
  line-height: 76px;
  margin-right: -16px;
}

.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-decoration: none;
}

.el-menu-demo {
  display: flex;
  flex-wrap: nowrap;
  border: none;
  height: 76px;
  overflow: hidden;
}

.head {
  height: 77px;
  width: 100%;
  display: flex;
  z-index: 99;
}


.head-container {
  height: 76px;
  //width: 1519px;
  width: 100%;
  flex-wrap: nowrap;
  position: fixed;
  z-index: 99;
}

.wrap {
  padding: 0 5%;
  line-height: 76px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #ffffff;
  white-space: nowrap;
  position: relative;
}

.logo {
  vertical-align: middle;
}

.login {
  margin-left: 15px;
}


</style>
