import request from "@/utils/request";

export function register(email,password,code,uuid) {
  const data = {
    'email':email,
    'password':password,
    'code':code,
    'uuid':uuid
  }
  return request({
    url: 'api/institutionInfo/register',
    method:'post',
    data
  })
}
// 机构注册，重新发送邮箱验证码
export function resend() {
  return request({
    url:'api/institutionInfo/resend',
    method:'post'
  
  })
}

// 机构用户激活
export function activate(data) {
  return request({
    url:'api/institutionInfo/activate',
    method:'post',
   data
  })
  
}