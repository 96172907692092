import request from '@/utils/request'

export function getVIPType() {
    return request({
        url: `api/personalUser/vipList`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}

export function payVIPType(id) {
    return request({
        url: `api/wxPay/native/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
    })
}


export function payVIPState(order) {
    return request({
        url: `api/wxPay/query/${order}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
    })
}
export function payAli(id) {
    return request({
        url: `api/aliPay/trade/page/pay/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
    })
}