<template></template>

<script>
import { weixinLogin } from "@/api/login.js";
export default {
  name: "Weixin",
  created() {
      console.log("Weixin component is created")
      console.log(this.$route.query.ticket)
    if (this.$route.query.ticket) {
      weixinLogin({ ticket: this.$route.query.ticket }).then((res) => {
        if (res.code === 200) {
            // console.log(res.data.user.phone)
          this.$store.dispatch("userLogin", res);
          if(res.data.user.phone === null){
            localStorage.setItem("bindPhone",'true')
              // console.log("true")
          }else{
              localStorage.setItem("bindPhone",'false')
              // console.log(false)
          }
        } else {
          this.$message.error({
            title: "错误",
            message: res.message,
          });
        }
        this.$router.push({
          path: "/",
        });
      });
    }
  },
};
</script>
