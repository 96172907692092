<template>
  <!-- 反馈提交成功页面 -->
  <div>
    <!-- 版心 -->
    <div class="submit-feedback-container">
      <!-- 内容区域 -->
      <div class="submit-feedback-content">
        <!-- 头部内容 start -->
        <div class="submit-feedback-TopContent">
          <h3>提交成功，感谢你对中安工匠的支持!</h3>
          <p>我们会认真处理您的意见或建议，由于反馈众多，可能无法逐一回复，请见谅·</p>
        </div>
        <!-- 头部内容 end -->
        <!-- 中间的图片 start -->
        <div class="submit-feedback-MiddleContent">
          <img
            src="../uploads/CraftsmanCertification 6.png"
            alt=""
          >
        </div>
        <!-- 中间的图片 end -->
        <!-- 下面的按钮 start -->
        <div class="submit-feedback-BottomContent">
          <el-button
            class="submit-feedback-button1"
            @click="goFeedBack"
          >返回首页</el-button>
          <el-button class="submit-feedback-button2">完成</el-button>
        </div>
        <!-- 下面的按钮 end  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmitFeedBack',
  methods: {
    goFeedBack () {
      this.$router.push({
        path: '/feedback'
      })
    }
  }
}
</script>

<style scoped>
.submit-feedback-container {
  margin-top: 30px;
  margin-bottom: 500px;
  width: 1520px;
  height: 528px;
  background: #fff;
  border-radius: 4px;
}
/* 内容区域 */
.submit-feedback-content {
  box-sizing: border-box;
  text-align: center;
}
/* 头部内容 start  */
.submit-feedback-TopContent h3 {
  padding-top: 61px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.submit-feedback-TopContent p {
  font-size: 14px;
  font-weight: 600;
  color: #676c71;
}
/* 头部内容 end */
/* 中间的图片 start */
.submit-feedback-MiddleContent {
  margin: 66px 0px 30px 0px;
}
.submit-feedback-MiddleContent img {
  width: 243px;
  height: 243px;
}
/* 中间的图片 end */
/* 下面的按钮 start */
/* 返回首页按钮 */
.submit-feedback-BottomContent .submit-feedback-button1 {
  margin-right: 59px;
  width: 200px;
  height: 40px;
  font-size: 14px;
  color: #eb5139;
  background: #fff;
  border-radius: 4px;
  border: 0;
  border: 1px solid #eb5139;
}
/* 完成按钮 */
.submit-feedback-BottomContent .submit-feedback-button2 {
  width: 200px;
  height: 40px;
  font-size: 14px;
  color: #fff;
  background: #eb5139;
  border-radius: 4px;
  border: 0;
}
/* 下面的按钮 end */
</style>