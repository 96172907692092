import request from '@/utils/request'

export function getUserAttention(current, size) {
    return request({
        url: `api/personalUser/UserAttention`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data: {
            'current': current,
            'size': size,

        }
    })
}