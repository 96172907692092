import request from '@/utils/request'

export function saveUpdateCourseInfo(affiliated,catena,courseDescription,freeLessons,headline,id,industryType,isPay,originalPrice,propagandaPicture,specialId,state,surfacePlotUrl,teacherName) {
    return request({
        url: 'api/personalUser/saveUpdateCourseInfo',
        method: 'post',
       data:{
           affiliated: affiliated,
           catena:catena,
           courseDescription: courseDescription,
           freeLessons: freeLessons,
           headline: headline,
           id: id,
           industryType: industryType,
           isPay: isPay,
           originalPrice: originalPrice,
           propagandaPicture: propagandaPicture,
           specialId: specialId,
           state: state,
           surfacePlotUrl: surfacePlotUrl,
           teacherName: teacherName
       }

    })
}