<template>
  <!-- 讲师 -->
  <!-- <div class='r'> -->
  <ul class="hot-teacher-pic">
    <li
      v-for="(hot, index) in teacherList.slice(0, 5)"
      :key="index"
      @click="goInstructorDetails(hot)"
    >
      <div class="imgs">
        <img :src="hot.picture" alt class="fl" />
      </div>
      <div class="r">
        <ul>
          <li class="title">{{ hot.name }}</li>
        </ul>
        <p style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{ hot.securityField.split(";").join("、") }}</p>
      </div>
    </li>
  </ul>
  <!-- </div> -->
</template>

<script>
import { allHotTeacher, teacherInfo } from "@/api/common";
export default {
  name: "InstructorTemplate",
  data() {
    return {
      teacherList: [],
    };
  },
  mounted() {
      console.log(1111)
    // 热门讲师
    allHotTeacher().then((res) => {
      if (res.code === 200) {
        this.teacherList = res.data;
      }
    });
  },
  methods: {
    goInstructorDetails(hot) {
      teacherInfo(hot.id).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            path:
              "/instructordetails?id=" +
              hot.id +
              "&industryType=" +
              hot.industryType +
              "&workUnit=" +
              hot.workUnit,
          });
        } else {
          const h = this.$createElement;
          this.$notify({
            message: h("i", { style: "color: red" }, res.message),
          });
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.hot-teacher-pic {
  > li {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    border-bottom: 1px solid #ebebeb;
    margin: 0;

    > .imgs {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background: #666;
      img {
        width: 100%;
      }
    }
    .r {
      width: 60%;
      height: 45px;
      margin-left: 12px;
      ul {
        display: flex;
        li {
          margin: 0;
        }
        .title {
          color: #333;
          font-size: 16px;
          line-height: 22px;
        }
        .imgs {
          width: 70px;
          height: 20px;
          border-radius: 3px;
          margin-left: 10px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
      }
      p {
        line-height: 25px;
        color: #666;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        font-size: 14px;
        font-family: "微软雅黑", Arial;
      }
    }
  }
  li:last-child {
    border-bottom: none;
  }
}
</style>
