import request from '@/utils/request'

export function getUserSelectAll(auditState, current, size) {
    return request({
        url: `api/personalUser/selectAll`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        params: {
            'auditState': auditState,
            'current': current,
            'size': size,

        }
    })
}
export function delCourse(id) {
    return request({
        url: `api/personalUser/delCourseInfo`,
        // url: "api/meeting/searchMeeting",
        method: 'delete',
        // data
        params: {
            'id': id,
        }
    })
}