import request from '@/utils/request'





export function direct() {
	return request({
		url: 'api/source/pic/policy',
		// url: "api/meeting/searchMeeting",
		method: 'get',
		// data
	})
}
// 获取STS
export function sts() {
	return request({
		url: 'api/source/sts',
		// url: "api/meeting/searchMeeting",
		method: 'get',
		// data
	})
}