<template>
  <!-- 意见反馈页面 -->
  <div>
    <!-- 版心 -->
    <div class="feedback-container">
      <!-- 内容区域 -->
      <div class="feedback-content">
        <!-- 头部内容 start-->
        <div class="feedback-TopContent">
          <h3>意见反馈</h3>
          <p>如果提交有关浏览速度、系统BUG、视觉显示问题，请注明您使用的操作系统、浏览器，以便我们尽快对应查找问题并解决。</p>
        </div>
        <!-- 头部内容 end -->
        <!-- 中间区域的表单 start-->
        <div class="feedback-MiddleContent">
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item label="问题类型">
              <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                class="select-questions"
              >
                <el-tab-pane
                  label="BUG反馈"
                  name="first"
                ></el-tab-pane>
                <el-tab-pane
                  label="账号问题"
                  name="second"
                ></el-tab-pane>
                <el-tab-pane
                  label="安全培训需求"
                  name="third"
                ></el-tab-pane>
                <el-tab-pane
                  label="安全咨询需求"
                  name="fourth"
                ></el-tab-pane>
              </el-tabs>
            </el-form-item>
            <el-form-item label="问题标题">
              <el-input
                v-model="formLabelAlign.title"
                class="TitleInput"
              ></el-input>
            </el-form-item>
            <el-form-item label="问题描述">
              <el-input
                v-model="formLabelAlign.describe"
                class="DescribeInput"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="附件上传"
              class="AttachmentInput"
            >
              <p>支持格式.png. jpg. gif. jpeg. pdf. doc, 不超过5M</p>
              <img
                src="./uploads/CraftsmanCertification 2.png"
                alt=""
              >
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="avatar"
                >
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>

            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="formLabelAlign.PhoneNumber"
                class="ContactInput"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 中间区域的表单 end-->
        <!-- 下面的按钮 start -->
        <div class="feedback-BottomContent">
          <!-- 返回首页按钮 -->
          <el-button class="feedback-button1">返回首页</el-button>
          <!-- 提交反馈按钮 -->
          <el-button
            @click="goSubmitFeedBack"
            class="feedback-button2"
          >提交反馈</el-button>
        </div>
        <!-- 下面的按钮 end -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedBack',
  data () {
    return {
      labelPosition: 'top',
      formLabelAlign: {
        title: '请填写简单阐述您遇到的问题',
        describe: '请填写描述您遇到的问题',
        PhoneNumber: '请填写您的邮箱或手机号'
      },
      activeName: 'first',
      imageUrl: ''
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    goSubmitFeedBack () {
      this.$router.push({
        path: '/submitfeedback'
      })
    }
  }
}
</script>

<style scoped>
/* 版心 */
.feedback-container {
  margin-top: 30px;
  margin-bottom: 500px;
  width: 1520px;
  height: 878px;
  background-color: #fff;
}
/* 内容区域 */
.feedback-content {
  box-sizing: border-box;
  width: 1520px;
  height: 878px;
}
/* 头部内容 start */
.feedback-TopContent {
  text-align: center;
}
.feedback-TopContent h3 {
  padding-top: 60px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.feedback-TopContent p {
  font-size: 14px;
  font-weight: 600;
  color: #676c71;
}
/* 头部内容 end */
/* 中间区域的表单 start */
.feedback-MiddleContent {
  padding: 0 60px;
}
/* element里面的label文字 */
.feedback-MiddleContent >>> .el-form--label-top .el-form-item__label {
  padding: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
/* 问题类型 start */
/* el-tabs的整个选项长度 */
.feedback-MiddleContent >>> .el-tabs__header {
  margin: 0;
}
/* el-tabs默认的长横线 */
.feedback-MiddleContent >>> .el-tabs__nav-wrap::after {
  width: 0;
  height: 0;
}
/* 第一个选项 */
.feedback-MiddleContent >>> #tab-first::before {
  content: "";
  position: absolute;
  left: 0;
}
/* 第四个选项 */
.feedback-MiddleContent >>> #tab-fourth {
  padding-right: 27px;
}
/* el-tabs不选中的样式 */
.feedback-MiddleContent >>> .el-tabs__item {
  padding: 0 10px;
  position: relative;
  text-align: center;
  width: 100px !important;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
/* el-tabs不选中的小圆点 */
.feedback-MiddleContent >>> .el-tabs__item::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d9d9d9;
}
/* el-tabs选中的样式 */
.feedback-MiddleContent >>> .el-tabs__item.is-top.is-active {
  position: relative;
  width: 100px !important;
  font-size: 14px;
  font-weight: 600;
  color: #eb5139;
}
/* el-tabs每个选项下面的横条  选中的小圆点*/
.feedback-MiddleContent >>> .el-tabs__active-bar {
  position: absolute;
  width: 10px !important;
  height: 10px;
  bottom: 15px;
  left: 0;
  border-radius: 50%;
  background-color: #eb5139;
}
/* 问题类型 end */

/* 问题标题 */
.TitleInput >>> .el-input__inner {
  width: 379px;
  height: 40px;
  font-size: 14px;
  color: #ccc;
  background: #fafafb;
  border-radius: 4px;
  border: 1px solid #efefee;
}
/* 问题标题 */
/* 问题描述 start */
.DescribeInput >>> .el-input__inner {
  width: 379px;
  height: 150px;
  font-size: 14px;
  color: #ccc;
  background: #fafafb;
  border-radius: 4px;
  border: 1px solid #efefee;
}
/* 问题描述 end */

/* 附件上传start */
.AttachmentInput {
  position: relative;
  margin-left: 0;
}
.AttachmentInput p {
  position: absolute;
  left: 72px;
  top: -32px;
  font-size: 12px;
  color: #969696;
}
.AttachmentInput img {
  position: absolute;
  left: 105px;
  top: 40px;
  width: 90px;
  height: 77px;
}
/* 上传照片的宽高 */
.avatar-uploader {
  margin-left: 0px;
  width: 300px;
  height: 150px;
  background: #fafafb;
  border: 1px solid #efefee;
}
/* element提供的上传照片样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/* 加号图标 */
.avatar-uploader-icon {
  position: absolute;
  left: 10%;
  top: 45%;
  font-size: 24px;
  color: #fafafb;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 50%;
  z-index: 1;
}
.avatar {
  text-align: center;
  width: 178px;
  height: 178px;
  display: block;
}
/* 附件上传end */
/* 联系方式 */
.ContactInput >>> .el-input__inner {
  width: 300px;
  height: 40px;
  font-size: 14px;
  color: #ccc;
  background: #fafafb;
  border-radius: 4px;
  border: 1px solid #efefee;
}
/* 中间区域的表单 end */
/* 下面的按钮 start */
.feedback-BottomContent {
  text-align: center;
}
/* 返回首页按钮 */
.feedback-BottomContent .feedback-button1 {
  margin-right: 59px;
  width: 200px;
  height: 40px;
  font-size: 14px;
  color: #eb5139;
  background: #fff;
  border-radius: 4px;
  border: 0;
  border: 1px solid #eb5139;
}
/* 提交反馈按钮 */
.feedback-BottomContent .feedback-button2 {
  width: 200px;
  height: 40px;
  font-size: 14px;
  color: #fff;
  background: #eb5139;
  border-radius: 4px;
  border: 0;
}
/* 下面的按钮 end */
</style>