<template>
    <div class="detailBox">
        <div>
            <ul class="tatol">
                <li
                        :class="{ totalActive: activeIndex == index }"
                        v-for="(item, index) in DetailList1"
                        @click="showHomeDetails(index, item)"
                        :key="item.id"
                >
                    {{ item.cateName }}
                </li>
            </ul>
        </div>

        <hr class="line-top"/>
        <div>
            <!-- 三级卡片详情 -->
            <div class="main-detail">
                <!-- 左侧卡片区 -->
                <el-row class="left-card">
                    <el-col
                            :span="8"
                            v-for="o in detailTotal.teacher"
                            :key="o.id"
                            @click.native="goDetail(o)"
                            height="250px"
                    >
                        <el-card class="tea-box" :body-style="{ padding: '0px' }">
                            <!-- <el-image :src="o.picture" class="image" fit="cover"></el-image> -->
                            <div
                                    :style="{ 'background-image': 'url(' + o.picture + ')' }"
                                    class="cla-p"
                            ></div>
                            <div class="card-text">
                                <h3>{{ o.name }}</h3>
                                <i class="greatField">{{ o.greatField ? o.greatField.split(";").join("、") : '' }}</i>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <div class="right-list">
                    <el-card class="box-card">
                        <div class="card-header">
                            <p>看资讯</p>
                            <a @click="goNews">更多 &gt;</a>
                        </div>
                        <div class="card-body">
                            <ul class="num num1">
                                <li
                                        v-for="news in detailTotal.news"
                                        :key="news.id"
                                        @click="goNewsDetail(news)"
                                >
                                    {{ news.title }}
                                </li>
                            </ul>
                            <hr/>
                        </div>
                        <!--                        <div>-->
                        <!--                            <div class="card-header">-->
                        <!--                                <p>搜会议</p>-->
                        <!--                                <a @click="goMeeting">更多 &gt;</a>-->
                        <!--                            </div>-->
                        <!--                            <div class="card-body">-->
                        <!--                                <ul class="num num2">-->
                        <!--                                    <li-->
                        <!--                                            v-for="meet in detailTotal.meeting"-->
                        <!--                                            @click="goMeetDetail(meet)"-->
                        <!--                                            :key="meet.id"-->
                        <!--                                    >-->
                        <!--                                        {{ meet.title }}-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                                <hr/>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </el-card>
                    <el-card class="box-card">
                        <!--                        <div>-->
                        <!--                            <div class="card-header">-->
                        <!--                                <p>找职位</p>-->
                        <!--                                <a @click="goJob">更多 &gt;</a>-->
                        <!--                            </div>-->
                        <!--                            <div class="card-body">-->
                        <!--                                <ul class="num num1">-->
                        <!--                                    <li-->
                        <!--                                            v-for="j in detailTotal.job"-->
                        <!--                                            @click="goJobDetail(j)"-->
                        <!--                                            :key="j.id"-->
                        <!--                                    >-->
                        <!--                                        {{ j.title }}-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                                <hr/>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div>
                            <div class="card-header">
                                <p>查规章</p>
                                <a @click="goDocument">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num3">
                                    <li
                                            v-for="d in detailTotal.relus"
                                            @click="goDocumentDetail(d)"
                                            :key="d.articleId"
                                    >
                                        {{ d.relusTitle }}
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                        <div>
                            <div class="card-header">
                                <p>搜会议</p>
                                <a @click="goMeeting">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num2">
                                    <li
                                            v-for="meet in detailTotal.meeting"
                                            @click="goMeetDetail(meet)"
                                            :key="meet.id"
                                    >
                                        {{ meet.title }}
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                        <div v-if="1 != 1">
                            <div class="card-header">
                                <p>找职位</p>
                                <a>更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num2">
                                    <li>111111111111111111111111</li>
                                    <li>222222222222222222222222222222</li>
                                    <li>333333333333333333333333333333333</li>
                                    <hr/>
                                </ul>
                            </div>
                        </div>
                    </el-card>
                    <div class="course-card">
                        <div class="card-header">
                            <p class="stu">案例库</p>
                            <a @click="goCourse" class="add">更多 &gt;</a>
                        </div>
                        <div>
                            <ul style="display: flex;justify-content: flex-start" class="ulList">
                                <li
                                        class="lesson"
                                        v-for="c in  detailTotal.case"
                                        :key="c.id"
                                        @click="goInformation(c)"
                                >
                                    <div class="course-box" style="position: relative">
                                        <el-image
                                                :src="c.indexUrl"
                                                style="
                        height: 129px;
                        width: 100px;
                        border: 1px solid #e0e0e0;
                        border-radius: 4px;
                        display: inline-block;
                        vertical-align: text-top;
                      "
                                                fit="scale-down"
                                        ></el-image>
                                        <img v-if="c.isType===1"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/word.png"
                                             alt="">
                                        <img v-if="c.isType===0"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/pdf3.png"
                                             alt="">
                                        <img v-if="c.isType===2"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/jpg-1.png"
                                             alt="">
                                        <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;margin-top: 5px;cursor: pointer">{{
                                            c.accidentName
                                            }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--第二个框框-->
        <div>
            <ul class="tatol">
                <li
                        :class="{ totalActive: activeIndex2 == index }"
                        v-for="(item, index) in DetailList2"
                        @click="showHomeDetails2(index, item)"
                        :key="item.id"
                >
                    {{ item.cateName }}
                </li>
            </ul>
        </div>

        <hr class="line-top"/>
        <div>
            <!-- 三级卡片详情 -->
            <div class="main-detail">
                <!-- 左侧卡片区 -->
                <el-row class="left-card">
                    <el-col
                            :span="8"
                            v-for="o in detailTotal2.teacher"
                            :key="o.id"
                            @click.native="goDetail(o)"
                    >
                        <el-card class="tea-box" :body-style="{ padding: '0px' }">
                            <!-- <el-image :src="o.picture" class="image" fit="cover"></el-image> -->
                            <div
                                    :style="{ 'background-image': 'url(' + o.picture + ')' }"
                                    class="cla-p"
                            ></div>
                            <div class="card-text">
                                <h3>{{ o.name }}</h3>
                                <!--                <i>{{ o.position }}</i>-->
                                <i class="greatField">{{ o.greatField ? o.greatField.split(";").join("、") : '' }}</i>
                                <!--                  <div class="telbox">-->
                                <!--                      {{o.securityField?o.securityField.split(";").join("、"):'' }}-->
                                <!--                  </div>-->
                                <!--                  <p class="badge">-->
                                <!--                      {{ o.greatField?o.greatField.split(";").join("、"):''}}-->
                                <!--                  </p>-->
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <div class="right-list">
                    <el-card class="box-card">
                        <div class="card-header">
                            <p>看资讯</p>
                            <a @click="goNews">更多 &gt;</a>
                        </div>
                        <div class="card-body">
                            <ul class="num num1">
                                <li
                                        v-for="news in detailTotal2.news"
                                        :key="news.id"
                                        @click="goNewsDetail(news)"
                                >
                                    {{ news.title }}
                                </li>
                            </ul>
                            <hr/>
                        </div>
                        <!--                        <div class="card-header">-->
                        <!--                            <p>搜会议</p>-->
                        <!--                            <a @click="goMeeting">更多 &gt;</a>-->
                        <!--                        </div>-->
                        <!--                        <div class="card-body">-->
                        <!--                            <ul class="num num2">-->
                        <!--                                <li-->
                        <!--                                        v-for="meet in detailTotal2.meeting"-->
                        <!--                                        @click="goMeetDetail(meet)"-->
                        <!--                                        :key="meet.id"-->
                        <!--                                >-->
                        <!--                                    {{ meet.title }}-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                            <hr/>-->
                        <!--                        </div>-->
                    </el-card>
                    <el-card class="box-card">
                        <!--                        <div>-->
                        <!--                            <div class="card-header">-->
                        <!--                                <p>找职位</p>-->
                        <!--                                <a @click="goJob">更多 &gt;</a>-->
                        <!--                            </div>-->
                        <!--                            <div class="card-body">-->
                        <!--                                <ul class="num num1">-->
                        <!--                                    <li-->
                        <!--                                            v-for="j in detailTotal2.job"-->
                        <!--                                            @click="goJobDetail(j)"-->
                        <!--                                            :key="j.id"-->
                        <!--                                    >-->
                        <!--                                        {{ j.title }}-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                                <hr/>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div>
                            <div class="card-header">
                                <p>查规章</p>
                                <a @click="goDocument">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num3">
                                    <li
                                            v-for="d in detailTotal2.relus"
                                            @click="goDocumentDetail(d)"
                                            :key="d.id"
                                    >
                                        {{ d.relusTitle }}
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                        <div>
                            <div class="card-header">
                                <p>搜会议</p>
                                <a @click="goMeeting">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num2">
                                    <li
                                            v-for="meet in detailTotal2.meeting"
                                            @click="goMeetDetail(meet)"
                                            :key="meet.id"
                                    >
                                        {{ meet.title }}
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                        <div v-if="1 != 1">
                            <div class="card-header">
                                <p>找职位</p>
                                <a>更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num2">
                                    <li>111111111111111111111111</li>
                                    <li>222222222222222222222222222222</li>
                                    <li>333333333333333333333333333333333</li>
                                    <hr/>
                                </ul>
                            </div>
                        </div>
                    </el-card>
                    <div class="course-card">
                        <div class="card-header">
                            <p class="stu">案例库</p>
                            <a @click="goCourse" class="add">更多 &gt;</a>
                        </div>
                        <div>
                            <ul style="display: flex;justify-content: flex-start" class="ulList">
                                <li
                                        class="lesson"
                                        v-for="c in  detailTotal2.case"
                                        :key="c.id"
                                        @click="goInformation(c)"
                                >
                                    <div class="course-box" style="position: relative">
                                        <el-image
                                                :src="c.indexUrl"
                                                style="
                        height: 129px;
                        width: 100px;
                        border: 1px solid #e0e0e0;
                        border-radius: 4px;
                        display: inline-block;
                        vertical-align: text-top;
                      "
                                                fit="scale-down"
                                        ></el-image>
                                        <img v-if="c.isType===1"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/word.png"
                                             alt="">
                                        <img v-if="c.isType===0"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/pdf3.png"
                                             alt="">
                                        <img v-if="c.isType===2"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/jpg-1.png"
                                             alt="">
                                        <span style="width: 100%;cursor:pointer;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;margin-top: 5px">{{
                                            c.accidentName
                                            }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--第三个框框-->

        <div>
            <ul class="tatol">
                <li
                        :class="{ totalActive: activeIndex3 == index }"
                        v-for="(item, index) in DetailList3"
                        @click="showHomeDetails3(index, item)"
                        :key="item.id"
                >
                    {{ item.cateName }}
                </li>
            </ul>
        </div>

        <hr class="line-top"/>
        <div>
            <!-- 三级卡片详情 -->
            <div class="main-detail">
                <!-- 左侧卡片区 -->
                <el-row class="left-card">
                    <el-col
                            :span="8"
                            v-for="o in detailTotal3.teacher"
                            :key="o.id"
                            @click.native="goDetail(o)"
                    >
                        <el-card class="tea-box" :body-style="{ padding: '0px' }">
                            <!-- <el-image :src="o.picture" class="image" fit="cover"></el-image> -->
                            <div
                                    :style="{ 'background-image': 'url(' + o.picture + ')' }"
                                    class="cla-p"
                            ></div>
                            <div class="card-text">
                                <h3>{{ o.name }}</h3>
                                <i class="greatField">{{ o.greatField ? o.greatField.split(";").join("、") : '' }}</i>
                                <!--                  <div class="telbox">-->
                                <!--                      {{o.securityField?o.securityField.split(";").join("、"):'' }}-->
                                <!--                  </div>-->
                                <!--                  <p class="badge">-->
                                <!--                      {{ o.greatField?o.greatField.split(";").join("、"):''}}-->
                                <!--                  </p>-->
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <div class="right-list">
                    <el-card class="box-card">
                        <div class="card-header">
                            <p>看资讯</p>
                            <a @click="goNews">更多 &gt;</a>
                        </div>
                        <div class="card-body">
                            <ul class="num num1">
                                <li
                                        v-for="news in detailTotal3.news"
                                        :key="news.id"
                                        @click="goNewsDetail(news)"
                                >
                                    <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">{{
                                        news.title
                                        }}</span>
                                </li>
                            </ul>
                            <hr/>
                        </div>

                    </el-card>
                    <el-card class="box-card">
                        <!--                        <div>-->
                        <!--                            <div class="card-header">-->
                        <!--                                <p>找职位</p>-->
                        <!--                                <a @click="goJob">更多 &gt;</a>-->
                        <!--                            </div>-->
                        <!--                            <div class="card-body">-->
                        <!--                                <ul class="num num1">-->
                        <!--                                    <li-->
                        <!--                                            v-for="j in detailTotal3.job"-->
                        <!--                                            @click="goJobDetail(j)"-->
                        <!--                                            :key="j.id"-->
                        <!--                                    >-->

                        <!--                                        <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;"> {{-->
                        <!--                                            j.title-->
                        <!--                                            }}</span>-->
                        <!--                                    </li>-->
                        <!--                                </ul>-->
                        <!--                                <hr/>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div>
                            <div class="card-header">
                                <p>查规章</p>
                                <a @click="goDocument">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num3">
                                    <li
                                            v-for="d in detailTotal3.relus"
                                            @click="goDocumentDetail(d)"
                                            :key="d.id"
                                    >

                                        <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">{{
                                            d.relusTitle
                                            }}</span>
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                        <div>
                            <div class="card-header">
                                <p>搜会议</p>
                                <a @click="goMeeting">更多 &gt;</a>
                            </div>
                            <div class="card-body">
                                <ul class="num num2">
                                    <li
                                            v-for="meet in detailTotal3.meeting"
                                            @click="goMeetDetail(meet)"
                                            :key="meet.id"
                                    >

                                    <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">  {{
                                        meet.title
                                        }}</span>
                                    </li>
                                </ul>
                                <hr/>
                            </div>
                        </div>
                    </el-card>
                    <div class="course-card">
                        <div class="card-header">
                            <p class="stu">案例库</p>
                            <a @click="goCourse" class="add">更多 &gt;</a>
                        </div>
                        <div>
                            <ul style="display: flex;justify-content: flex-start" class="ulList">
                                <li
                                        class="lesson"
                                        v-for="c in  detailTotal3.case"
                                        :key="c.id"
                                        @click="goInformation(c)"
                                >
                                    <div class="course-box" style=" position: relative;">
                                        <el-image
                                                :src="c.indexUrl"
                                                style="
                        height: 129px;
                        width: 100px;
                        border: 1px solid #e0e0e0;
                        border-radius: 4px;
                        display: inline-block;
                        vertical-align: text-top;

                      "
                                                fit="scale-down"
                                        >
                                        </el-image>
                                        <img v-if="c.isType===1"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/word.png"
                                             alt="">
                                        <img v-if="c.isType===0"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/pdf3.png"
                                             alt="">
                                        <img v-if="c.isType===2"
                                             style="position: absolute;width: 20px;height: 20px;bottom: 25px;right: 4px"
                                             src="@/assets/images/jpg-1.png"
                                             alt="">
                                        <span style="width: 100%;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;margin-top: 5px;cursor: pointer">{{
                                            c.accidentName
                                            }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getHomeDetails, getHomeProfessionCate} from "@/api/home/home";


export default {
    data() {
        return {
            activeIndex: 2,
            tabPosition: "top",
            activeName: "first",
            activeName2: "",
            activeName1: "first",
            detailTotal: {},
            detailTotal2: {},
            detailTotal3: {},
            activeIndex2: 1,
            activeIndex3: 3,
            DetailList1: [],
            DetailList2: [],
            DetailList3: [],
            ProfessionCateList: [],
            fits: ["cover"],
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        showHomeDetails(index, item) {
            this.activeIndex = index;
            console.log(item.code)
            getHomeDetails(item.code).then((res) => {
                // console.log(item.code)
                if (res.code === 200) {
                    // console.log(res.data)
                    var reg = /[;；]/g;
                    res.data.teacher.map((item) => {
                        item.greatField = item.greatField.replace(reg, "、");
                    });
                    this.detailTotal = res.data;
                    // console.log(this.datailTotal, 1111111111111);
                }
            });
        },
        showHomeDetails2(index, item) {
            this.activeIndex2 = index;
            getHomeDetails(item.code).then((res) => {
                if (res.code === 200) {
                    this.detailTotal2 = res.data;
                }
            });
        },
        showHomeDetails3(index, item) {
            this.activeIndex3 = index;
            getHomeDetails(item.code).then((res) => {
                if (res.code === 200) {
                    this.detailTotal3 = res.data;
                    // console.log(111,this.detailTotal3)
                }
            });
        },
        goDetail(o) {
            this.$router.push({
                path: "/instructordetails?id=" + o.id,
            });
        },
        goInformation(item) {
            // console.log(item)
            this.$router.push({
                path: "/informationdetail", query: {id: item.id, type: item.isType}
            });
        },
        goNews() {
            this.$router.push("/news");
        },
        goMeeting() {
            this.$router.push("/searchconference");
        },
        goDocument() {
            this.$router.push("/selectregulations");
        },
        goCourse() {
            this.$router.push("/informationsearch");
        },
        goJob() {
            this.$router.push("/findtalent");
        },
        goNewsDetail(news) {
            this.$router.push("/newsdetails?id=" + news.id);
        },
        goMeetDetail(meet) {
            this.$router.push("/meetingdetails?id=" + meet.id);
        },
        goDocumentDetail(d) {
            this.$router.push("/selectregulationsDetails/" + d.articleId);
        },
        goCourseDetail(c) {
            this.$router.push("/coursedetails?id=" + c.id);
        },
        goJobDetail(j) {
            this.$router.push("/jobdetails?id=" + j.id);
        },
    },
    mounted() {
        getHomeDetails(13).then((res) => {
            if (res.code === 200) {
                var reg = /[;；]/g;
                res.data.teacher.map((item) => {
                    item.greatField = item.greatField.replace(reg, "、");
                });
                this.detailTotal = res.data;
                // console.log(res.data);
            }
        });
        getHomeDetails(15).then((res) => {
            if (res.code === 200) {
                this.detailTotal2 = res.data;
            }
        });
        getHomeDetails(20).then((res) => {
            if (res.code === 200) {
                this.detailTotal3 = res.data;
            }
        });
        getHomeProfessionCate().then((res) => {
            if (res.code === 200) {
                // console.log(111,res)
                this.ProfessionCateList = res.data;
                // console.log(this.ProfessionCateList)
                // console.log(111, this.ProfessionCateLis)
                this.DetailList1 =
                    this.ProfessionCateList && this.ProfessionCateList.slice(0, 3);
                this.DetailList2 =
                    this.ProfessionCateList && this.ProfessionCateList.slice(3, 6);
                this.DetailList3 =
                    this.ProfessionCateList && this.ProfessionCateList.slice(6, 10);
            }
        });
        // console.log(this.ProfessionCateList);
    },
    computed: {
        isJob() {
            return this.detailTotal.job == null;
        },
    },
};
</script>

<style lang='less' scoped>
.course-card {
  position: absolute;
  bottom: 0;
  left: 10px;
  background-color: #fff;
  width: 640px;
  height: 202px;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.75rem 0 rgb(0 0 0 / 10%);
}

.course-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.line-top {
  width: 100%;
  border: none;
  height: 2px;
  background-color: #ff3f29;
  margin-top: 10px;
}

.cla-p {
  width: 100%;
  height: 149px;
  background-size: cover;
  background-position: center top !important;
}

.tatol {
  display: flex;
  margin-left: 6px;
}

.tatol > li {
  width: 113px;
  height: 36px;
  margin-left: 1px;
  margin-right: 5px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.totalActive {
  color: #ff3f29;
}

.detailBox {
  margin-top: 30px;
  width: 1200px;
}

.el-radio-button {
  margin-left: 32px;
}

.left-card {
  margin: 0;
  width: 550px;
  display: flex;
  flex-wrap: wrap;

  .el-col {
    width: 175px;
    height: 246px;
    margin: 11px 7px 0 0;
    border-radius: 10px;
    overflow: hidden;
    // margin-left: 0;
    .el-card {
      height: 100%;
      box-sizing: border-box;
      margin: 0;

      .el-card__body {
        cursor: pointer;
        padding: 10px !important;

        .cla-p {
          height: 170px;
        }

        .card-text {
          display: flex;
          flex-direction: column;
          align-items: center;

          h3 {
            margin-top: 8px;
            text-align: center;
            color: #666 !important;
            line-height: 1.875rem;
            font-weight: 500;
            font-size: 1.125rem !important;
          }
        }
      }
    }
  }
}

.el-card /deep/ .el-card__body {
  height: 100% !important;
  padding: 10px;
}

.main-detail > .right-list {
  width: 600px;
  height: 512px !important;
  position: relative;
}

.main-detail {
  display: flex;
  height: 100%;
}

.tea-box {
  cursor: pointer;
  margin: 10px 10px 52px 0;
  position: relative;
  height: 218px;
}

.image {
  width: 100%;
  height: 149px;
}

.card-text > a {
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.card-text > button {
  height: 19px;
  width: 66px;
  border: 1px solid #ff8001;
  margin-left: 10px;
  background-color: #ffe6cc;
  color: #ff8001;
  white-space: nowrap;
  font-size: 14px;
  line-height: 19px;
}

.top-icon {
  position: absolute;
  background: url(../../../assets/images/card-background.png) center no-repeat;
  top: -5px;
  left: 8px;
  width: 83px;
  height: 21px;
  color: #8e530b;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.el-card {
  overflow: visible;
}

.clearfix {
  height: 10px;
}

.right-list {
  margin: 0;
  display: flex;
  flex: 1;
}

.box-card {
  margin-top: 11px;
  width: 282px;
  height: 286px;
  flex: 1;
  margin-left: 10px;
}

.card-header {
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.card-header > p {
  font-size: 16px;
  margin-left: 10px;
  font-weight: 600;
}

.card-header > a {
  margin-right: 5px;
  cursor: pointer;
  color: gray;
  font-size: 14px;
}

.card-body {
  /* height: 95px; */
  border-bottom: 1px solid #ccc;
}

.card-body > hr {
  border: none;
}

.card-body > ul > li {
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.num1 > li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #fa781a;
  vertical-align: middle;
  margin-right: 5px;
}

.num2 > li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #fa781a;
  vertical-align: middle;
  margin-right: 5px;
}

.num3 > li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #fa781a;
  vertical-align: middle;
  margin-right: 5px;
}

.el-card__body .card-body ul {
  // border-bottom: #ccc 1px solid;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.num {
  min-height: 89px;
  line-height: 1.5;
  /* border: none !important; */
}

.num > li {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}

.num > hr {
  margin: 20px 0;
  background-color: gray;
  /* border: 0; */
}


.lesson {
  width: 113px;
  height: 148px;
  margin: 0 10px 0 0;

  .el-image {
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
}

.ulList {
  padding: 0 10px;

  > li:nth-last-child(1) {
    margin: 0;
  }
}

// .left-card /deep/ .el-col{

// }
// .el-col /deep/ .el-card {
//   height: 80%;
// }
.card-header {
  .stu {
    margin-left: 20px !important;
  }

  .add {
    margin-right: 22px !important;
  }
}

.greatField {
  text-align: center;
  width: 80%;
  color: #333;
  // background-color: #dc3545;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 3px 0 6px 0;
}
</style>
