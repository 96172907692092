import request from '@/utils/request'

// 看资料-首页-免费文档推荐
export function freeDocumentList() {
  return request({
    url:'api/document/search/freeDocumentList',
    method:'get'
  })
}

// 更多免费文档
export function freeDocuments(data) {
  return request({
    url:'api/document/search/freeDocuments?current=' + data.current + '&orders=' + data.orders + '&size=' + data.size,
    method:'get'
  })
}

// 行业分类
export function industryTypeList(data) {
  return request({
    url:'api/document/search/industryTypeList?cateCode=' + data.cateCode,
    method: 'get',
  })
}

// 模版推荐
export function templateRecommend() {
  return request({
    url:'api/document/templateRecommend',
    method: 'get'
  })
}



// 热搜关键词
export function hotSearchKeywords(data) {
  return request({
    url:'api/document/search/hotSearchKeywords?count=' + data.count,
    method: 'get'
  })
}

// 最新上传 
export function latestUpload() {
  return request({
    url:'api/document/search/latestUpload',
    method:'get'
  })
}

// 热门文档
export function hotDocument() {
  return request({
    url:'api/document/search/hot',
    method:'get'
  })
}

// 下载资料
export function downloadDocument(id) {
  return request({
    url:'api/document/download?id=' + id+'&charge=1', 
    method:'get'
  })
}

// 用户贡献排行榜
export function userContributions() {
  return request({
    url:'api/document/search/userContributions',
    method:'get'
  })
}

// 看资料-搜索结果页-模糊搜索资料  行业类型切换搜索结果
export function searchDocument(data) {
  return request({
    url:'api/document/search/list?current=' + data.current + '&size=' + data.size + '&title=' + data.title + '&industryType=' + data.industryType,
    method:'get'
  })
}

// 收藏
export function collection(collectionId, type) {
  return request({
    url:'api/personalUser/collection' ,
    method: 'post',
    params: {
      collectionId: collectionId,
      type: type
  }
  })

}

// 取消收藏
export function cancelCollection(collectionId,type) {
  return request({
    url:'api/personalUser/cancelCollection',
    method: 'post',
    params: {
      collectionId: collectionId,
      type: type
  }
  })

}



