import request from '@/utils/request'

export function getnewpassword(emailName, newPassword) {
    return request({
        url: `api/email/forget/user/email/updatePassword`,
        method: 'post',
        data: {
            "emailUsername":emailName,
            "newPassword":newPassword

        }
    })
}