<template>
  <!--  看资讯-资讯详细页 -->

  <!-- 版心 -->
  <div class="news-details-container">
    <!-- 左边详情 start -->
    <div class="news-LeftDetails fl">
      <!-- 左边详情的头部 start -->
      <div class="news-LeftDetails-top">
        <h3>{{ detailsList.messageTitle }}</h3>
        <!-- 发布时间 -->
        <div class="news-release-time">
          <span style="margin-right: 4px">{{
            timestampToTime(detailsList.releaseTime)
          }}</span>

          <em style="margin-left: 20px">来源:</em>
          <em>{{ detailsList.source }}</em>
          <!-- <span style="float:right"></span> -->
          <span style="float: right">阅读量 : {{ detailsList.pageviews }}</span>
        </div>
      </div>
      <!-- 左边详情的头部 end -->
      <!-- 左边详情中间的图片 start -->
      <div class="news-LeftDetails-pic">
        <!-- <img
          :src="detailsList.picture"
          alt=""
        >-->
      </div>
      <!-- 左边详情中间的图片 end -->
      <!-- 左边详情的内容 end -->
      <!-- 转载声明 -->
      <div class="news-reprint-statement">
        <p>
          <span
            class="contect"
            style="display: inline-block; width: 730px"
            v-html="detailsList.messageContent"
          ></span>
        </p>
      </div>
    </div>
    <!-- 左边详情 end -->
    <!-- 右边 热门资讯 start -->
    <RightTemplate />
    <!-- 右边 热门资讯 end -->
    <div style="clear: both"></div>
  </div>
</template>

<script>
import { getDeatils } from "@/api/news";

export default {
  name: "NewsDetails",
  data() {
    return {
      detailsList: [],
    };
  },
  mounted() {
    this.$nextTick(function () {
      // DOM 更新了
      console.log(document.querySelector("video"));
    });

    // 会议详细
    getDeatils(this.$route.query.id).then((res) => {
        res.data.messageContent = res.data.messageContent.replace(/pt;/g, 'px;');
        res.data.messageContent = res.data.messageContent.replace(/font-family:宋体;/g, '');
      this.detailsList = res.data;
    });
  },
  methods: {
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "年" + MM + "月" + d + "日";
    },
    preHtml(str) {
      console.log(str);

      // 去除注释
      return str ? str.replace(/\\/g, "") : "";
    },
    // 去除html标签
    delHtmlTag(str) {
      return str ? str.replace(/<[^>]+>/g, "").replace(/&nbsp;/gi, "") : "";
    },
    removeStyle(str) {
        console.log(str)
      const arr = str ? str.replace(/\s/g, " ").trim() : ""; // 去除所有空格
      return arr.replace(/style/g, "style");
    },
  },
};
</script>

<style scoped>
.contect {
  /* font-size: 14pt !important; */
  /* font-family: "宋体" !important; */
  color: rgb(66, 61, 61) !important;
  line-height: 30px;
}
* {
  box-sizing: border-box;
}
.news-details-container {
  margin-bottom: 31px;
  width: 1200px;
  margin-top: 20px;
  display: flex;
}
/* 左边详情 start */
.news-LeftDetails {
  flex: 1;
  /* height: 1910px; */
  background-color: #fff;
  margin: 0;
}
/* 左边详情的头部 start */
.news-LeftDetails-top {
  position: relative;
  padding: 30px;
  width: 789px;
}
/* 头部下划线 */
.news-LeftDetails-top::after {
  content: "";
  position: absolute;
  left: 0;
  margin-top: 39px;
  width: 789px;
  height: 0;
  border: 1px solid #e5e5e5;
}
.news-LeftDetails-top h3 {
  text-align: center;
  width: 80%;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  line-height: 50px;
}
.news-release-time {
  position: relative;
  margin-top: 10px;
}
/* .news-release-time::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 0px;
  width: 0px;
  height: 11px;
  border: 1px solid #e5e5e5;
} */
.news-release-time span {
  float: left;
  font-size: 12px;
  color: #676c71;
}
.news-release-time em {
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #676c71;
}
/* 左边详情的头部 end */
/* 左边详情中间的图片 start */
.news-LeftDetails-pic {
  padding: 10px 15px;
  margin-top: 20px;
}
.news-LeftDetails-pic img {
  width: 759px;
  height: 505px;
}
/* 左边详情中间的图片 end */
/* 左边详情的内容 start */

/* 左边详情的内容的横线 */

.news-LeftDetails-content p {
  margin-left: 0;
  margin-bottom: 15px;
  font-size: 12px;
  color: #000;
}
/* 转载声明 */
.news-reprint-statement {
  box-sizing: border-box;
  /* margin: 15px; */
  width: 789px;
  /* height: 200px; */
  font-size: 16px;
  font-family: "微软雅黑", Arial,serif;
  padding-bottom: 20px;
}

.news-reprint-statement p {
  width: 730px;
  color: #000;
}
.news-reprint-statement p span >>> img {
  max-width: 730px !important;
}
.news-reprint-statement p span {
  /* font-size: 14px; */
  color: #676c71;
}
.news-reprint-statement .content > .TRS_Editor > div > span {
  display: block;
  height: 100%;
  width: 100%;
}
.news-reprint-statement /deep/ video {
  width: 100% !important;
}
/* 左边详情的内容 end */
/* 左边详情 end */
.news-reprint-statement {
  //margin-left: -15px;
}
.news-reprint-statement /deep/ p {
  //text-indent: 2em;
}
/* 右侧 */
</style>
