<template>
  <div class="management">
    <div class="picture">
      <a :href="this.url" target="blank">
        <img :src="banner" alt="" />
      </a>
    </div>
    <div class="main">
      <regulationsList />
      <search />
    </div>
  </div>
</template>
<script>
import regulationsList from "./component/regulationsList.vue";
import search from "./component/search.vue";
import { getHomeRecommend } from "@/api/home/home";

export default {
  components: {
    regulationsList,
    search,
  },
  data() {
    return {
      banner: "",
      url: "",
    };
  },
  computed: {},
  mounted() {
    getHomeRecommend(1, 34).then((res) => {
      if (res.code == 200) {
        this.banner = res.data[0].pictureUrl;
        this.url = res.data[0].targetAddress;
      }
    });
  },
  methods: {},
};
</script>
<style lang='less' scoped>
.management {
  //margin-top: 20px;
}
.picture {
  width: 100%;
  height: 300px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.main {
  width: 70%;
  margin: 0 auto;
}
.picture {
  width: 100%;
  height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
