import request from '@/utils/request'


export function getJobHot(data) {
    return request({
        url: "api/recruitment/allHotRecruitment",
        method: 'get',
    })
}

export function getHotJobList(industryType) {
    return request({
        url: `/api/recruitment/hotList`,
        method: 'get',
        params: {
            industryType: industryType,
        }
    })
}

export function getEnterpriseList(count) {
    return request({
        url: `api/recruitment/hotEnterpriseList`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data
        params: {
            count: count,
        }
    })
}

export function getRecruitment(id) {
    return request({
        url: `api/recruitment/hotRecruitment/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data

    })
}

export function getInstitution(id) {
    return request({
        url: `api/recruitment/InstitutionById/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data

    })
}

export function getexpert(id) {
    return request({
        url: `api/recruitment/expert/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data

    })
}

export function getJodDetail(id) {
    return request({
        url: `api/recruitment/detail/${id}`,
        // url: "api/meeting/searchMeeting",
        method: 'get',
        // data

    })
}

export function hotJod() {
    return request({
        url: 'api/recruitment/hotJob',
        method: 'get',

    })
}


export function getMoreJon(id, current, size) {
    return request({
        url: 'api/recruitment/listExpert',
        method: 'get',
        params: {
            id,
            current,
            size
        }

    })
}

export function getMoreJob1(id, current, size, title) {
    return request({
        url: 'api/recruitment/listRecruitment',
        method: 'get',
        params: {
            id,
            current,
            size,
            title
        }

    })
}

export function indexListMetting(id){
    return request({
        url:`api/institutionInfo/indexListMetting?id=${id}`,
        method:"get",
    })
}

export function indexListInstitutionCourse(InstitutionId,CouresId){
    return request({
        url:`api/InstitutionCouresInfo/indexListInstitutionCourse?InstitutionId=${InstitutionId}&CouresId=${CouresId}`,
        method:"get",
    })
}
