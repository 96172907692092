<template>
  <!-- 整体大背景图 -->
  <div class="scan-bg">
    <div class="scan-center">
      <!-- 左侧小背景图 -->
      <div class="scan-img"></div>
      <!-- 文字和二维码区域 -->
      <div class="scan-info">
        <p style="font-weight: 700" class="title">
          打开<span>最新微信APP</span>
        </p>
        <div class="scan-open">在「首页」右上角打开扫一扫</div>

        <div class="scan-code" id="weixinLogin">
          <wxlogin
            :appid="wx.APPID"
            :scope="wx.SCOPE"
            :state="wx.STATE"
            :theme="'black'"
            :redirect_uri="wx.REDIRECT_URI"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZxxx='"
            rel="external nofollow"
          >
          </wxlogin>
        </div>
        <p class="agreement">
          注册登录即表示已阅读并同意
          <a href="javascript:" @click="userservices">《用户服务协议》</a>
          和
          <a href="javascript:" @click="userprivacy">《用户隐私协议》</a>
        </p>
        <div class="scan-mobile" @click="goPersonal">
          <img src="../../../assets/images/手机.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
import { getLoginParam } from "@/api/login/weixin";

export default {
  name: "Login",
  data() {
    return {
      wx: {
        APPID: "wxed9954c01bb89b47",
        REDIRECT_URI:
          "http%3A%2F%2Flocalhost%3A8160%2Fapi%2Fucenter%2Fwx%2Fcallback",
        SCOPE: "snsapi_login",
        STATE: "1662710443445",
      },
    };
  },
  mounted() {
    // this.scanLogin()
    getLoginParam().then((res) => {
        console.log(res)
      this.wx.APPID = res.data.APPID;
      this.wx.REDIRECT_URI = res.data.REDIRECT_URI;
      this.wx.SCOPE = res.data.SCOPE;
      this.wx.STATE = res.data.STATE;
    });
  },

  methods: {
    userservices() {
      this.$router.push({
        path: "/userservices",
      });
    },
    userprivacy() {
      this.$router.push({
        path: "/userprivacy",
      });
    },

    goPersonal() {
      let url = "/personallogin";
      if (this.$route.query.callback) {
        url += "?callback=" + this.$route.query.callback;
      }
      this.$router.push({
        path: url,
      });
    },
  },

  components: {
    wxlogin,
  },
};
</script>

<style scoped>
.agreement {
  font-size: 14px !important;
  color: #ccc;
  text-align: center;
}
.agreement > a {
  color: #ed7d45;
}
.scan-bg {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/login-background.png") center
    no-repeat;
  background-size: cover;
  padding-top: 100px;
  overflow: hidden;
}
/* 背景图 */
.scan-center {
  display: flex;
  align-items: flex-start;
  width: 1238px;
  height: 682px;
  margin: 0 auto 0;
  border-radius: 8px;
  overflow: hidden;
}
.scan-center .scan-img {
  width: 49.99%;
  height: 100%;
  background: url("../../../assets/images/login-bg.png") no-repeat center center;
  background-size: contain;
}
/* 文字和二维码区域  */
.scan-center .scan-info {
  position: relative;
  flex: 1;
  width: 49.99%;
  height: 100%;
  text-align: center;
  background-color: #fff;
}
/* 第一行文字 */
.scan-info .title {
  font-size: 24px;
  margin-top: 86px;
  margin-bottom: 11px;
}
.scan-info p span {
  margin-left: 7px;
  color: #eb5139;
}
/* 第二行文字*/
.scan-info .scan-open {
  font-size: 16px;
  color: #000;
  margin-bottom: 65px;
}
/* 二维码 */
.scan-code img {
  width: 244px;
  height: 244px;
}
/* 手机图标 */
.scan-info .scan-mobile img {
  position: absolute;
  top: 0;
  right: 0;
  width: 101px;
  height: 101px;
}
.scan-info .scan-mobile img:hover {
  cursor: pointer;
}
</style>
