import request from '@/utils/request'


export function userPuserAuthentication(name, birthday, picture, qualifications, position, title, city, credential, introduce, securityField, greatField, honor, project, mainCourse, workHistory, industryType, quotation,phone) {
    return request({
        url: `api/puserAuthentication/user/puserAuthentication`,
        // url: "api/meeting/searchMeeting",
        method: 'post',
        // data
        data: {
            'name': name,
            'birthday': birthday,
            'picture': picture,
            'qualifications': qualifications,
            'position': position,
            'title': title,
            'city': city,
            'credential': credential,
            'introduce': introduce,
            //'jobTitle': jobTitle,
            //'researchField': researchField,
            'honor': honor,
            'project': project,
            'mainCourse': mainCourse,
            //'consultingDirection': consultingDirection,
            'industryType': industryType,
            'securityField': securityField,
            'greatField': greatField,
            'consultingDirection': workHistory,
            'quotation': quotation,
            "phone":phone
        }
    })
}