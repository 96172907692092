import request from '@/utils/request'

// 分类跳转-行业资讯 type=
// export function newsIndustry(data) {
//   return request({
//     url:'api/news/industryNews?type=' + data.type,
//     method:'get'
//   })
// }

// 按行业类型查找分页   title=搜索结果页的industyType
export function getPageByType(data) {
  return request({
    url:'api/news/getPageByType?current=' + data.current + '&size=' + data.size + '&type=' + data.type,
    method:'get'
  })
}

// 资讯详细页   id=
export function getDeatils(id) {
  return request({
    url:`api/news/getById`,
    method:'get',
    params: {
      'id':id
    }
  })
}

// 看资讯搜索结果   title=a   size 一页显示多少个 current当前所在页
export function newsSearch(data) {
  return request({
    url:'api/news/search?current=' + data.current +  '&size=' + data.size + '&title=' + data.title ,
    method:'get'
  })
}



