import request from '@/utils/request'

// 新增按钮
export function addMeeting(data) {
  return request({
    url:'api/institutionInfo/addMeeting',
    method:'post', 
    data
  })
}

// 会议管理  回显数据
export function institutionInfo(data) {
  return request({
    url:'api/institutionInfo/meeting?id=' +  data, 
    method: 'get',
   
  })
}

// 发布会议按钮
// export function publishMeeting(data) {
//   return request({
//     url:'api/institutionInfo/publishMeeting?id=' + data.id,
//     method:'put',
//     data
//   })
// }

// 编辑会议
// export function meetingEdit(data) {
//   return request({ 
//       url:'api/institutionInfo/meetingEdit',
//       method:'put',    
//       data
//   })
// }
// 


// 删除会议
export function deleteMeeting(data) {
  return request({
    url:'api/institutionInfo/deleteMeeting?id=' + data.id, 
    method: 'delete',
    data
  })
}

// 会议审核状态
export function listMeeting(data){
  return request({
    url:"api/institutionInfo/listMeeting?type=" + data.type + '&current=' + data.current + '&size=' + data.size,
    method: 'get'
  })
}

// 按课程名称搜索
export function searchMeeting(data) {
  return request({
    url:'api/institutionInfo/searchMeeting?current='+ data.current + "&size="+ data.size + "&title="+ data.title,
    method: 'get'
  })
}




 