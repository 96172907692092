module.exports = {
	/**
	 * @description token key
	 */
	TokenKey: 'LINGLIUPIN-TOKEN',
	/**
	 * @description 请求超时时间，毫秒（默认2分钟）
	 */
	timeout: 1200000,
	/**
	 * @description 部署环境
	 */
	environment: "develop",
	// environment: "production",
	/**
	 * @description 开发环境请求地址
	 */
	developUrl: 'https://test-api.smartdeer.co/api',
	// developUrl: 'http://10.37.17.89:8000/api',
	developWsUrl: 'ws://test-api.smartdeer.co/ws',
	/**
	 * @description 生产环境请求地址
	 */
	// productionUrl: 'https://api.smartdeer.co/api',
	get productionUrl() {
		return this.getEnv(this.environment).server
	},

	ImageBaseUrl: "",
	// developImgUrl: 'https://linglupin-test.oss-cn-beijing.aliyuncs.com/',
	developImgUrl: 'http://test-attachment.smartdeer.co/',
	get processImgUrl() {
		return this.getEnv(this.environment).imgUrl
	},
	// processImgUrl: 'https://linglupin-prod.oss-cn-beijing.aliyuncs.com/',
	/**
	 * @description 测试环境请求地址
	 */
	testUrl: '',
	/** 
	 *	@description 学生pc端域名
	 */
	// http://tp-www.smartdeer.co
	// https://www.smartdeer.co
	studentUrlProd() {
		return this.getEnv(this.environment).studentUrl
	},
	get studentUrl() {
		return this.getEnvUrl('http://tp-www.smartdeer.co', 'http://tp-www.smartdeer.co', this.studentUrlProd())
	},
	/**
	 *	@description 学生移动端域名
	 */
	// http://tp-m.smartdeer.co
	// https://m.smartdeer.co
	studentmUrlProd() {
		return this.getEnv(this.environment).studentmUrl
	},
	get studentmUrl() {
		return this.getEnvUrl('http://tp-m.smartdeer.co', 'http://tp-m.smartdeer.co', this.studentmUrlProd())
	},
	/**
	 *	@description 企业PC端域名
	 */
	// http://tp-hr.smartdeer.co
	// https://hr.smartdeer.co
	enterpriseUrlProd() {
		return this.getEnv(this.environment).enterpriseUrl
	},
	get enterpriseUrl() {
		return this.getEnvUrl('http://tp-hr.smartdeer.co', 'http://tp-hr.smartdeer.co', this.enterpriseUrlProd())
	},

	linglutuiUrlProd() {
		return this.getEnv(this.environment).linglutuiUrl
	},
	get linglutuiUrl() {
		return this.getEnvUrl('http://localhost:8014', 'http://t-c.smartdeer.co', this.linglutuiUrlProd())
	},

	getEnvUrl(devUrl, testUrl, prodUrl) {
		if (process.env.NODE_ENV === 'development') {
			return devUrl;
		} else if (process.env.NODE_ENV === 'production') {
			return prodUrl;
		} else if (process.env.NODE_ENV === 'test') {
			return testUrl;
		}
	},

	getEnv(environment) {
		let env = '';
		if (environment == 'production') {
			env = 'production'
		} else if (environment == 'develop') {
			env = 'develop'
		} else {
			env = 'test'
		}
		return configs[env]
	},
}

const configs = {
	develop: {
		server: 'https://test-api.smartdeer.co/api',
		imgUrl: 'http://test-attachment.smartdeer.co/',
		// imgUrl: 'https://linglupin-test.oss-cn-beijing.aliyuncs.com/',
		studentUrl: 'http://tp-www.smartdeer.co',
		studentmUrl: 'http://tp-m.smartdeer.co',
		enterpriseUrl: 'http://tp-hr.smartdeer.co',
		linglutuiUrl: 'http://localhost:8014',
	},
	production: {
		server: 'https://api.smartdeer.co/api',
		imgUrl: 'https://attachment.smartdeer.co/',
		// imgUrl: 'https://linglupin-prod.oss-cn-beijing.aliyuncs.com/',
		studentUrl: 'https://www.smartdeer.co',
		studentmUrl: 'https://m.smartdeer.co',
		enterpriseUrl: 'https://hr.smartdeer.co',
		linglutuiUrl: 'https://www.smartdeer.work/'
	},
	test: {
		server: 'https://test-api.smartdeer.co/api',
		imgUrl: 'http://test-attachment.smartdeer.co/',
		studentUrl: 'http://tp-www.smartdeer.co',
		studentmUrl: 'http://tp-m.smartdeer.co',
		enterpriseUrl: 'http://tp-hr.smartdeer.co',
		linglutuiUrl: 'https://t-c.smartdeer.co'
	}
}

