<template>
  <!-- 讲师详情页面-->
    <div class="details">
        <!-- 讲师详情页的头部区域 -->
        <div class="details-head">
            <!-- 背景图 -->
            <div class="details-background">
                <img src="../uploads/3.jpg" alt/>
            </div>
            <!-- 讲师详情 -->
            <ul class="details-instructor">
                <li>
                    <div class="left-details fl">
                        <div
                                :style="{ 'background-image': 'url(' + infoList.picture + ')' }"
                                class="cla-p"
                        ></div>
                    </div>
                    <div class="right-details fl">
                        <div class="right-details-top fl">
                            <h5
                                    style="
                  margin-right: 8px;
                  font-size: 36px;
                  border: 0;
                  line-height: 30px;
                  color: #fff;
                "
                                    class="fl"
                            >
                                {{ infoList.name }}
                            </h5>
                            <span>{{ infoList.title }}</span>
                            <!-- <span>{{infoList.position}}</span> -->
                            <div class="share fr" style="position: relative">
                                <div>
                                    <el-button
                                            v-if="infoList.isAttention === false"
                                            class="follow-btn"
                                            @click.stop="btnFollow()"
                                    >关注
                                    </el-button
                                    >
                                    <el-button
                                            v-else
                                            class="followed-btn"
                                            @click.stop="btnFollow2()"
                                    >已关注
                                    </el-button
                                    >
                                </div>

                                <div
                                        style="
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    opacity: 0;
                    z-index: 0;
                  "
                                        v-show="shareBox"
                                        @click.stop="shareBox = false"
                                ></div>
                                <div class="share_box" v-show="shareBox">
                                    <ul class="share-ways">
                                        <li
                                                class="share-QQ"
                                                @mouseenter="qqFn1"
                                                @mouseleave="qqFn2"
                                                @click="qqUrl"
                                        >
                                            <img :src="img" class="qq-img" alt=""/>
                                        </li>
                                        <li
                                                class="share-wechat"
                                                @mouseenter="wechatFn1"
                                                @mouseleave="wechatFn2"
                                        >
                                            <img :src="pic" class="weixin-img"/>
                                            <div class="share_wechat_ercodebox" v-show="wechatBox">
                                                <div class="share-wechat-ercode" ref="qrCodeUrls">
                                                    <!-- <div
                                                  id="qrcode"
                                                  ref="qrcode"
                                                ></div>
                                                <img
                                                  src=""
                                                  alt=""
                                                    >-->
                                                </div>
                                                <div class="arrow-down arrow-down-out"></div>
                                                <div class="arrow arrow-out"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="details-filedContent fl">
                            <h6 class="fl" style="display: inline-block; width: 75px">
                                专家类型 ：
                            </h6>
                            <p class="fr" style="height: 30px;">{{ infoList.securityField.split(";").join("、") }}</p>
                        </div>
                        <div class="details-filedContent fl">
                            <h6 class="fl" style="display: inline-block; width: 75px">
                                擅长领域 ：
                            </h6>
                            <p class="fr" style="height: 30px;">{{ infoList.greatField.split(";").join("、") }}</p>
                        </div>
                        <div class="details-filedContent fl">
                            <h6 class="fl" style="display: inline-block; width: 75px">
                                荣誉资质 ：
                            </h6>
                            <pre
                                    class="fr"
                                    style="font-size:13px;width:800px;max-height: 81px; overflow: hidden; line-height: 22px"
                            >{{ infoList.honor }}</pre
                            >
                        </div>
                    </div>
                    <el-button class="lecturerBtn fl" @click="open"><i class="el-icon-phone"
                                                                       style="margin-right: 5px"></i>联系讲师
                    </el-button>
                    <p class="city" style="height: 30px;">常驻城市 ：{{ infoList.city }}<i class="el-icon-location"
                                                                                          style="margin-left: 5px"></i>
                    </p>
                </li>
            </ul>
        </div>
        <!-- 主讲课程导航栏 -->
        <div class="details-MainCourse">
            <!-- 版心-->
            <div class="details-container">
                <!-- 左侧课程内容区域 start -->

                <div class="details-leftContainer fl">
                    <!-- 讲师资料 start -->
                    <div class="instructor-course-head1">
                        <h4 class="fl">个人介绍</h4>
                        <strong></strong>
                    </div>
                    <el-empty
                            v-if="!infoList.introduce"
                            description="暂无数据"
                            style=" background-color: #fff; padding: 10px"
                    ></el-empty>
                    <pre
                            class="recommend"
                            v-else
                            style=" background-color: #fff; padding: 10px"
                    >{{ infoList.introduce }}</pre
                    >
                    <div class="instructor-course-head1">
                        <h4 class="fl">工作经历</h4>
                        <strong></strong>
                    </div>
                    <el-empty
                            v-if="!infoList.consultingDirection"
                            description="暂无数据"
                            style=" background-color: #fff; padding: 10px"
                    ></el-empty>
                    <pre

                            v-else
                            style=" background-color: #fff; padding: 10px"
                    >{{ infoList.consultingDirection }}</pre
                    >
                    <!-- 讲师课程 start -->
                    <div class="instructor-course-head1">
                        <h4 class="fl">项目经历</h4>
                        <strong></strong>
                    </div>

                    <el-empty v-if="!infoList.project" description="暂无数据"
                              style=" background-color: #fff; padding: 10px"></el-empty>
                    <pre
                            v-else
                            style=" background-color: #fff; padding: 10px"

                    >{{ infoList.project }}</pre
                    >
                    <!--                    min-height: 233px;-->
                    <!-- 讲师课程 start -->
                    <div class="instructor-course-head1">
                        <h4 class="fl">主讲课程</h4>
                        <strong></strong>
                    </div>
                    <el-empty
                            v-if="!infoList.mainCourse"
                            description="暂无数据"
                            style=" background-color: #fff; padding: 10px"
                    ></el-empty>
                    <pre
                            v-else
                            style=" background-color: #fff; padding: 10px"
                    >{{ infoList.mainCourse }}</pre
                    >
                    <!-- 讲师课程 start -->
                    <div class="instructor-course">
                        <div class="instructor-course-head">
                            <h4 class="fl">专家课程</h4>
                            <a href="javascript:;" class="fr more1" @click="getMoreCourse"
                            >更多 &gt;</a
                            >
                            <strong></strong>
                        </div>
                        <ul class="instructor-course-pic"
                            style="overflow: hidden; background-color: #fff; padding: 10px">
                            <el-empty
                                    v-if="courseList && !courseList.length"
                                    description="暂无数据"
                            ></el-empty>
                            <li
                                    class="fl"
                                    v-for="item in courseList.slice(0, 6)"
                                    :key="item.id"
                                    @click="goCourseTwo(item.id)"
                            >
                                <img :src="item.surfacePlotUrl" alt/>
                                <div
                                        class="hot-activities-member"
                                        v-if="item.vipCurriculum === 1"
                                >
                                    会员免费
                                </div>
                                <div class="instructor-course-introduce">
                                    <p>{{ item.headline }}</p>
                                    <h5>
                                        {{ item.teacherName }}
                                        <em style="font-weight: 600">{{ item.affiliated }}</em>
                                    </h5>
                                    <strong class="fl" style="width: 92px; white-space: nowrap;overflow: hidden;">{{
                                        timestampToTime(item.onlineTime)
                                        }}</strong>
                                    <span class="fr">
                    ¥
                    <i>{{ item.originalPrice }}</i>
                  </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 讲师课程 end -->
                    <!-- 讲师资料 end -->
                </div>
                <!-- 左侧课程内容区域end -->

                <!-- 右侧讲师区域 start -->
                <div class="details-rightContainer fr">
                    <!-- 同领域讲师 start -->
                    <div class="hot-teacher fl">
                        <div class="nav_hot">
                            <div class="ico_h">
                                <i class="icon_t el-icon-trophy"></i>
                                <h4>同领域工匠</h4>
                            </div>
                            <!-- <a class="more" href="javascript:;" class="">更多 &gt;</a> -->
                        </div>
                        <ul class="hot-teacher-pic">
                            <el-empty
                                    v-if="fieldList && !fieldList.length"
                                    description="暂无数据"
                            ></el-empty>
                            <li
                                    v-for="(same, index) in fieldList.slice(0, 5)"
                                    :key="'one' + index"
                                    @click="goFieldInfo(same)"
                            >
                                <div class="imgs">
                                    <img :src="same.picture" alt class="fl"/>
                                </div>
                                <div class="r">
                                    <ul>
                                        <li class="title">{{ same.name }}</li>
                                        <!-- <li class="imgs">
                                          <img src="../../../assets/images/金牌讲师.jpg" alt="" />
                                        </li> -->
                                    </ul>
                                    <p style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                        {{ same.securityField.split(";").join("、") }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 同领域讲师 end -->

                    <!-- 同单位讲师 start -->
                    <div class="hot-teacher fl">
                        <div class="nav_hot">
                            <div class="ico_h">
                                <i class="icon_t el-icon-school"></i>
                                <h4>同行业工匠</h4>
                            </div>
                            <!-- <a class="more" href="javascript:;">更多 &gt;</a> -->
                        </div>
                        <ul class="hot-teacher-pic">
                            <el-empty
                                    v-if="unitList && !unitList.length"
                                    description="暂无数据"
                            ></el-empty>
                            <li
                                    v-for="(m, index) in unitList"
                                    :key="index"
                                    @click="goUnitListInfo(m)"
                            >
                                <div class="imgs">
                                    <img :src="m.picture" alt/>
                                </div>
                                <div class="r">
                                    <ul>
                                        <li class="title">{{ m.name }}</li>
                                        <!-- <li class="imgs">
                                          <img src="../../../assets/images/金牌讲师.jpg" alt="" />
                                        </li> -->
                                    </ul>
                                    <p style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                                        {{ m.securityField.split(";").join("、") }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 同单位讲师 end -->
                    <!-- 热门讲师 start -->
                    <div class="hot-teacher fl" style="border-bottom: 0">
                        <div class="nav_hot">
                            <div class="ico_h">
                                <i class="icon_t el-icon-office-building"></i>
                                <h4>热门工匠</h4>
                            </div>
                            <!-- <a class="more" href="javascript:;" @class="lecturer"
                              >更多 &gt;</a
                            > -->
                        </div>
                        <!-- 图片信息区域 -->
                        <InstructorTemplate/>
                    </div>
                    <!-- 热门讲师 end -->
                </div>
                <!-- 右侧讲师区域 end -->
            </div>
        </div>
        <!-- <div style="clear: both"></div> -->
    </div>
</template>

<script>
import {cancel, concern} from "@/api/teacher";
import {
    courseList,
    documentList,
    sameResearchField,
    sameUnit2,
    moreCourse,
    moreDocument,
} from "@/api/teacher";
import {teacherInfo} from "@/api/common";
import {toQQ} from "@/utils/share.js";
import QRCode from "qrcodejs2";

export default {
    name: "InstructorDetails",
    data() {
        return {
            teacherInfo: {},
            activeIndex: "1",
            courseList: [], //课程
            dataList: [], //资料
            fieldList: [], //领域
            unitList: [], //单位
            infoList: [], // 讲师详情
            activeName: "first",
            mCourseList: [], //更多课程
            mDocumentList: [], //更多资料
            id: this.$route.query.id,
            shareBox: false, // 分享盒子
            wechatBox: false, // 微信二维码盒子
            imgObj: {
                imgOne: require("../../../assets/uploads/qqOne.png"),
                imgTwo: require("../../../assets/uploads/qqTwo.png"),
            },
            img: require("../../../assets/uploads/qqOne.png"),
            picObj: {
                picOne: require("../../../assets/uploads/wechatOne.png"),
                picTwo: require("../../../assets/uploads/wechatTwo.png"),
            },
            pic: require("../../../assets/uploads/wechatOne.png"),
        };
    },
    async mounted() {
        // 讲师详情
        await teacherInfo(this.$route.query.id).then((res) => {
            if (res.code === 200) {
                // console.log(res.data)
                res.data.greatField = res.data.greatField.split(";").join("、")
                if (res.data.greatField[res.data.greatField.length - 1] === "、") {
                    res.data.greatField = res.data.greatField.slice(0, -1)
                }
                // console.log(res.data.greatField)
                this.infoList = res.data;
                // console.log(res.data);
            } else {
                const h = this.$createElement;
                this.$notify({
                    message: h("i", {style: "color: red"}, res.message),
                });
            }
        });
        // 讲师课程
        courseList(this.$route.query.id).then((res) => {
            if (res.code === 200) {
                this.courseList = res.data;
            }
        });
        // 讲师资料
        documentList(this.$route.query.id).then((res) => {
            if (res.code === 200) {
                this.dataList = res.data;
            }
        });
        // console.log(this.infoList.securityField);

        const data = {
            field: this.infoList.securityField,
            unit: this.infoList.industryType,
            id: this.infoList.id,
        };
        // 同行业讲师
        sameResearchField(data).then((res) => {
            if (res.code === 200) {
                this.unitList = res.data;
            }
        });
        // 同领域讲师
        sameUnit2(data).then((res) => {
            if (res.code === 200) {
                this.fieldList = res.data;
            }
        });
    },

    methods: {
        lecturer() {
            this.$router.push({
                path: "/consultingexperts",
            });
        },
        btnFollow2() {
            const data = {
                attentionId: this.$route.query.id,
            };
            cancel(data).then((res) => {
                // con.concern = '关注
                if (res.code === 200) {
                    this.$message({
                        title: "成功",
                        message: "取消关注成功",
                        type: "success",
                    });
                    this.infoList.isAttention = false;
                }
            });
        },
        btnFollow() {
            let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                : "";
            if (token && token.nickName) {
                this.dialogVisible = true;
                const data = {
                    attentionId: this.$route.query.id,
                };
                concern(data).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                            title: "成功",
                            message: "关注成功",
                            type: "success",
                        });
                        this.infoList.isAttention = true;
                    }
                    // 没有关注，就添加关注
                    // if (this.infoList.isAttention == false) {
                    //   // con.concern = '已关注'
                    //   console.log(123);
                    // } else {
                    //   // 已关注要取消关注
                    //   return;
                    // }
                    // this.infoList.isAttention = !this.infoList.isAttention;
                });
            } else {
                this.$message.error({
                    title: "错误",
                    message: "请先登录再试！",
                });
            }


            teacherInfo(this.$route.query.id).then((res) => {
                if (res.code === 200) {
                    this.infoList = res.data;
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
        // 点击分享按钮
        shareFn() {
            this.shareBox = true;
        },
        // qq
        qqFn1() {
            this.img = this.imgObj.imgTwo;
        },
        qqFn2() {
            this.img = this.imgObj.imgOne;
        },
        // 分享到QQ
        qqUrl() {
            let url = location.href;
            let title = "我是标题";
            toQQ(url, title);
        },
        // 微信
        wechatFn1() {
            this.pic = this.picObj.picTwo;
            this.wechatBox = true;
            // 分享到微信
            this.$nextTick(() => {
                var qrcodes = new QRCode(this.$refs.qrCodeUrls, {
                    text: location.href, // 需要转换为二维码的内容
                    width: 85,
                    height: 85,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
            });
        },
        wechatFn2() {
            this.pic = this.picObj.picOne;
            this.wechatBox = false;
            this.$refs.qrCodeUrls.innerHTML = "";
        },
        // 主讲课程
        handleClick(tab, event) {
            // console.log(tab, event);
            courseList(this.$route.query.id).then((res) => {
                if (res.code === 200) {
                    this.courseList = res.data;
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
        },
        open() {
            this.$alert("010-82965088", "联系电话", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //   type: 'info',
                    //   message: `${action}`
                    // });
                },
            });
        },
        goCourseOne(id) {
            if (id) {
                this.$router.push({
                    path: "/coursedetails?id=" + id,
                });
            } else {
                const h = this.$createElement;
                this.$notify({
                    message: h("i", {style: "color: red"}, "没有该课程！"),
                });
            }
        },
        goCourseTwo(id) {
            this.$router.push({
                path: "/coursedetails?id=" + id,
            });
        },
        goInformationDetail(id) {
            this.$router.push({
                path: "/informationdetail?id=" + id,
            });
        },
        //同领域讲师详情
        goFieldInfo(same) {
            teacherInfo(same.id).then((res) => {
                if (res.code === 200) {
                    this.infoList = res.data;
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
            this.$router.push({
                path:
                    "/instructordetails?id=" +
                    same.id +
                    "&industryType=" +
                    same.industryType +
                    "&workUnit=" +
                    same.workUnit,
            });
        },
        // 同单位讲师详情
        goUnitListInfo(m) {
            teacherInfo(m.id).then((res) => {
                if (res.code === 200) {
                    this.infoList = res.data;
                } else {
                    const h = this.$createElement;
                    this.$notify({
                        message: h("i", {style: "color: red"}, res.message),
                    });
                }
            });
            this.$router.push({
                path:
                    "/instructordetails?id=" +
                    m.id +
                    "&industryType=" +
                    m.industryType +
                    "&workUnit=" +
                    m.workUnit,
            });
        },
        // 更多讲师课程
        getMoreCourse() {
            const data = {
                current: 1,
                size: 6,
                id: this.id,
            };
            moreCourse(data).then((res) => {
                this.mCourseList = res.data;
                this.$router.push({
                    path:
                        "/allcourses?id=" +
                        data.id +
                        "&current=" +
                        data.current +
                        "&size=" +
                        data.size,
                });
            });
        },
        // 更多讲师资料
        getMoreDocument() {
            const data = {
                current: 1,
                id: this.id,
                size: 5,
            };
            moreDocument(data).then((res) => {
                this.mDocumentList = res.data;
                this.$router.push({
                    path:
                        "/allinformation?id=" +
                        data.id +
                        "&current=" +
                        data.current +
                        "&size=" +
                        data.size,
                });
            });
        },
        timestampToTime(date) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var date = new Date(date);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日" + h + ":" + m;
        },
    },
};
</script>

<style lang='less' scoped>

.more1 {
  font-size: 16px !important;
  color: #666;
}

.more {
  font-size: 12px !important;
  color: #666;
}

.recommend {
  //text-indent: 2em;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 30px;
  box-sizing: border-box;
}

.follow-btn {
  background: #eb5139 !important;
  border: none;
  color: #fff;
}

.followed-btn {
  background: #858585 !important;
  border: none;
  color: #fff;
}

.city {
  font-size: 13px;
  display: inline-block;
  margin-top: 33px;
  margin-left: 50px;
  font-weight: 600;
  color: #fff;
}

.details {
  width: 100%;
  height: auto;
  display: block;
  user-select: none
}

/* 讲师详情页的头部区域 start */
.details-head {
  margin-top: 100px;
  width: 100%;
  /* height: 527px; */
  overflow: hidden;
}

.classify-second-message1 /deep/ .follow-btn {
  background: #eb5139;
}

.details-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 527px;
}

.details-background img {
  width: 100%;
  height: 527px;
}

.details-instructor {
  position: relative !important;
}

/* 讲师详情页的头部区域 */
.details-head .details-instructor {
  padding-top: 20px;
  width: 1200px;
  height: 350px;
  box-sizing: border-box;
}

/* 讲师详情左边 */
.details-instructor .left-details .cla-p {
  width: 239px;
  height: 261px;
  background-size: cover;
  background-position: center top !important;
  border-radius: 15px;
}

/* 右边 */
.right-details {
  margin-left: 50px;
  margin-top: 10px;
  width: 900px;
}

/* 右边头部 */
.right-details-top {
  height: 50px;
}

/* 白色边框的文字 */
.right-details-top span {
  margin: 0 8px;
  padding: 0 15px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  border-radius: 26px;
  font-weight: 600;
  color: #fff;
  border: 1px solid #fff;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

/* 每一个领域 */
.details-filedContent {
  font-size: 13px;
  color: #fff;
}

.details-filedContent h6 {
  width: 55px;
  font-weight: 600;
}

/* 领域区域的文字 */
.details-filedContent p {
  width: 800px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 联系讲师按钮 */
.lecturerBtn {
  position: absolute;
  right: 5%;
  margin-top: 40px;
  margin-left: 50px;
  width: 161px;
  height: 45px;
  text-align: center;
  color: #fff;
  background: #eb5139;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
}

.share {
  margin-left: 100px;
  margin-right: 150px;
  width: 80px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}

.share i {
  font-size: 30px;
  font-weight: 400;
}

/* 分享盒子 */
.share_box {
  padding-left: 14px;
  width: 100px;
  height: 40px;
  line-height: 55px;
  border: 1px solid #eb5139;
  position: absolute;
  top: -2px;
  left: 90px;
}

.share_box .share-ways {
  height: 40px;
}

.share-ways li {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
}

.share-ways li .qq-img,
.share-ways li .weixin-img {
  display: block;
  width: 28px;
  height: 28px;
}

.share-wechat {
  position: relative;
}

.share_wechat_ercodebox {
  position: absolute;
  top: -35px;
  right: -115px;
  width: 93px;
  height: 110px;
  background-color: transparent;
}

.share-wechat-ercode {
  position: relative;
  box-sizing: border-box;
  padding-top: 3px;
  width: 93px;
  height: 93px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 2px;
  vertical-align: top;
}

.share_box .arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #ccc;
  position: absolute;
}

.share_box .arrow-down-out {
  bottom: 52px;
  right: 93px;
}

.share_box .arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 11px solid #fff;
  position: absolute;
}

.share_box .arrow-out {
  bottom: 52px;
  right: 92px;
}

/* 讲师详情的头部区域end */

/* 主讲课程导航栏 start */
.details-MainCourse {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  clear: both;
}

.detailBox {
  margin-top: 15px;
  width: 100%;
  height: 60px;
}

.tatol {
  display: flex;
}

.tatol > li {
  margin-left: 0px;
  width: 60px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
}

.totalActive {
  border-bottom: 3px solid #eb5139;
}

.details-MainCourse /deep/ .el-menu-demo {
  padding-left: 120px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
}

.details-MainCourse /deep/ .el-menu-item {
  padding: 0 20px;
}

.details-MainCourse /deep/ .el-menu-item.is-active {
  border-bottom: 3px solid #eb5139;
}

/* 讲师详情页的头部区域 end */

/* 左侧课程区域的内容start */
/* 课程版心 */
.details-container {
  width: 1200px;
  height: auto;
  margin-top: 2px;
  background-color: #f5f7fa;
}

/* 课程的内容 */
.course-content {
  /* width: 779px; */
  height: 328px;
  padding: 20px 20px;
  background: #ffffff;
}

.course-content ul li {
  font-size: 14px;
  font-weight: 600;
  color: #676c71;
  margin-bottom: 10px;
}

.details-MainCourse /deep/ .el-tabs {
  height: 60px;
}

/* 通栏导航栏 */
.details-MainCourse /deep/ .el-tabs__nav-scroll {
  padding-left: 25%;
  height: 60px;
  background-color: #fff;
}

.details-MainCourse /deep/ .el-tabs__nav {
  margin-left: -90px;
}

.details-MainCourse /deep/ .el-tabs__header {
  margin-bottom: 0;
}

/* 下面的内容区域 */
.details-MainCourse /deep/ .el-tabs__content {
  margin-left: -430px;
  height: 368px;
}

/* 去掉默认蓝色边框 */
/* 不选中样式 */
.details-MainCourse /deep/ .el-tabs__item {
  box-shadow: none !important;
  color: #676c71;
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
}

/* 每个选项下面默认的蓝色横线 */
.details-MainCourse /deep/ .el-tabs__active-bar,
.details-MainCourse /deep/ .el-tabs__active-bar.is-top {
  background-color: #eb5139;
  height: 3px;
}

/* 选中样式 */
.details-MainCourse /deep/ .el-tabs__item.is-active {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}

/* 课程内容 */
.courseContent {
  width: 779px;
  height: 328px;
  padding: 20px 20px;
  background: #ffffff;
}

.courseContent ul li {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

/* 讲师课程 start*/
/* 讲师课程的头部 start */
.instructor-course-head {
  position: relative;
  width: 789px;
  height: 25px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-top: 20px;
}

/* 更多 */
.instructor-course-head a {
  color: #676c71;
}

.instructor-course-head h4 {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #eb5139;
}

/* 更多 */
.instructor-course-head a {
  font-size: 18px;
  color: #676c71;
}

/* 橙色的下划线 */
.instructor-course-head strong {
  position: absolute;
  top: 30px;
  left: 12px;
  width: 52px;
  height: 4px;
  background: #eb5139;
}

/*  讲师课程的头部 end */
/* 讲师资料头部 */
.instructor-course-head1 {
  position: relative;
  margin-top: 22px;
  height: 25px;
  margin-bottom: 30px;
}

.instructor-course-head1 h4 {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #eb5139;
}

/* 更多 */
.instructor-course-head1 a {
  font-size: 18px;
  color: #676c71;
}

/* 橙色的下划线 */
.instructor-course-head1 strong {
  position: absolute;
  top: 30px;
  left: 12px;
  width: 52px;
  height: 4px;
  background: #eb5139;
}

/* 讲师资料头部 */
/* 讲师课程 图片start */
.instructor-course .instructor-course-pic {
  position: relative;
  margin: 0;
  margin-bottom: 31px;
  margin-right: 0;
  background: #fff;
}

.instructor-course-pic li:nth-child(3),
.instructor-course-pic li:nth-child(6) {
  margin-right: 10px;
}

.instructor-course-pic li {
  position: relative;
  width: 220px;
  height: 204px;
  margin: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 27px 26px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  cursor: pointer;
}

/* 讲师课程 的每一个图片 */
.instructor-course-pic li img {
  width: 220px;
  height: 117px;
}

/* 左上角会员免费的定位 */
.instructor-course-pic li .hot-activities-member {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(180deg, #e97f37 0%, #eea03f 100%);
  border-radius: 0px 0px 8px 0px;
}

/* 讲师课程介绍 */

.instructor-course-introduce p {
  margin-bottom: 5px;
  width: 200px;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.instructor-course-introduce h5 {
  font-size: 12px;
  font-weight: 600;
  color: #919498;
  margin-bottom: 9px;
  margin-left: 10px;
}

.instructor-course-introduce h5 em {
  margin-left: 17px;
}

.instructor-course-introduce strong {
  width: 143px;
  font-size: 12px;
  font-weight: 600;
  color: #676c71;
  margin-bottom: 10px;
  margin-left: 10px;
}

.instructor-course-introduce span {
  margin-right: 10px;
  margin-top: 3px;
  font-size: 12px;
  color: #919498;
  line-height: 12px;
}

.instructor-course-introduce span i {
  font-size: 12px;
  font-weight: 400;
  color: #ff843f;
}

/* 讲师课程 图片end */
/* 讲师课程 end*/

/* 讲师资料 start */
/* 文档区域 */
.free-documentation-picture li:nth-child(1) {
  margin-left: 15px;
}

/* 每一块文档 */
.free-documentation-picture li {
  position: relative;
  margin: 0 10px;
  width: 135px;
  height: 215px;
  cursor: pointer;
}

/* 文档背景图 */
.free-documentation-picture li img {
  width: 135px;
  height: 185px;
}

/* 文档背景图下面的文字 */
.free-documentation-picture li h5 {
  font-weight: 600;
  margin: 5px 10px;
  font-size: 14px;
  color: #333;
}

/* 需要定位的  容量大小 */
.free-documentation-picture li .size {
  position: absolute;
  left: 27px;
  top: 40px;
  font-weight: 600;
}

/* 第一行文字 */
.free-documentation-picture li .size h6 {
  font-size: 14px;
  color: #000;
}

/* 第二行文字 */
.free-documentation-picture li .size p {
  color: #676c71;
  font-size: 12px;
}

/* 需要定位的  积分 */
.free-documentation-picture li .integral {
  position: absolute;
  left: 100px;
  top: 120px;
  font-weight: 600;
}

/* 文档图标 */
.free-documentation-picture li .integral img {
  width: 30px;
  height: 30px;
}

.free-documentation-picture li .integral p {
  color: #ff7d69;
  font-size: 12px;
  margin-left: -25px;
}

/*  文档区域end */
/* 讲师资料 end */
/* 左侧课程区域的内容end */

/* 右侧讲师区域 start */
.details-rightContainer {
  margin-top: 77px;
  width: 371px;
  background-color: #fff;
  margin-bottom: 31px;
}

/* 下面的橙色横线  */
.hot-teacher span {
  display: inline-block;
  width: 65px;
  height: 8px;
  background: #eb5139;
}

/* 图片信息区域 */
.hot-teacher-pic {
  display: flex;
  flex-direction: column;
}

.hot-teacher-pic li {
  padding: 10px 0;
  cursor: pointer;
}

.hot-teacher-pic .hot-teacher-information {
  width: 340px;
  height: 43px;
}

.hot-teacher-information img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.hot-teacher-pic li .hot-teacher-information h5 {
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

/* 热门讲师  */
.hot-teacher-pic li .hot-teacher-information h5 p {
  line-height: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #676c71;
}

.nav_hot {
  position: relative;
  width: 100%;
  height: 22px;
  padding-bottom: 12px;
  border-bottom: 1px solid #edf0f5;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
}

.nav_hot a {
  line-height: 30px;
  text-align: right;
  margin: 0;
}

.ico_h {
  display: flex;
  color: #eb5139;
  width: 150px;
  margin: 0;
}

.icon_t {
  /* color: #ff3f29; */
  font-size: 30px;
  margin: 0 10px 0 0;
}

.ico_h h4 {
  margin: 0;
  line-height: 30px;
  font-weight: 600;
}

// 讲师
.hot-teacher {
  padding: 20px;
  padding-top: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.hot-teacher-pic {
  > li {
    display: flex;
    align-items: center;
    padding: 14px 10px;
    border-bottom: 1px solid #ebebeb;

    > .imgs {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background: #666;

      img {
        width: 100%;
      }
    }

    .r {
      width: 197px;
      height: 45px;
      margin-left: 12px;

      ul {
        display: flex;

        li {
          margin: 0;
        }

        .title {
          color: #333;
          font-size: 16px;
          line-height: 22px;
        }

        .imgs {
          width: 70px;
          height: 20px;
          border-radius: 3px;
          margin-left: 10px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }

      p {
        line-height: 25px;
        color: #666;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        font-size: 14px;
        font-family: "微软雅黑", Arial;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }
}

/* 推荐讲师 end */
.details-leftContainer {
  width: 820px;
}
</style>
