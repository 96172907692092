<template>
  <div class="main">
    <span class="detail-head">最近浏览</span>
    <hr class="ling-top1" />
    <div class="cla">
      <ul>
        <li>
          <div class="box-content">
            <img src="../../../assets/2021/course 3.png" alt />
            <span>零基础学习IT零基础学习IT零基础学习IT</span>
            <div class="box-btn">
              <button>继续学习</button>
            </div>
          </div>
        </li>
        <li>
          <div class="box-content">
            <img src="../../../assets/2021/course 3.png" alt />
            <span>零基础学习IT1零基础学习IT零基础学习IT</span>
            <div class="box-btn">
              <button>继续学习</button>
            </div>
          </div>
        </li>
        <li>
          <div class="box-content">
            <img src="../../../assets/2021/course 3.png" alt />
            <span>零基础学习IT零基础学习IT零基础学习IT</span>
            <div class="box-btn">
              <button>继续学习</button>
            </div>
          </div>
        </li>
        <li>
          <div class="box-content">
            <img src="../../../assets/2021/course 3.png" alt />
            <span>零基础学习IT零基础学习IT零基础学习IT</span>
            <div class="box-btn">
              <button>继续学习</button>
            </div>
          </div>
        </li>
        <li>
          <div class="box-content">
            <img src="../../../assets/2021/course 3.png" alt />
            <span>零基础学习IT零基础学习IT零基础学习IT</span>
            <div class="box-btn">
              <button>继续学习</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.main {
  width: 100%;
  max-width: 1333px;
  margin-left: 0;
}
.ling-top1 {
  margin-top: 10px;
  background-color: #e5e5e5;
  height: 1px;
  border: none;
  width: 1450px;
}
.detail-head {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #676c71;
  line-height: 19px;
}
.cla > ul > li {
  width: 288px;
  margin: 10px;
}
.cla > ul {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}
.cla {
  width: 100%;
  margin-left: 30px;
}
.box-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
}
.box-content > img {
  margin: 10px;
}
.box-content > span {
  width: 263px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box-btn > button {
  width: 268px;
  height: 42px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e5e5e5;
  margin: 13px;
  font-size: 16px;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  color: #000000;
}
</style>