<template>
  <!-- 圆点 -->
  <!-- 带圆点的课程 -->
  <ul class="latest-upload fl">
    <li
      v-for="(point,index ) in latestList.slice(0,10)"
      :key="index"
      @click="goInformation(point)"
    >
      <div class="point fl"></div>
      <p> {{point.title}}</p>
    </li>
  </ul>
</template>

<script>
import { latestUpload } from '@/api/data/index'
export default {
  name: 'DotTemplate',
  data () {
    return {
      latestList: [],  // 最新上传列表
    }
  },
  mounted () {
    // 最新上传
    latestUpload().then(res => {
      this.latestList = res.data
    })
  },
  methods: {
    goInformation (point) {
      this.$router.push({
        path: '/informationdetail?id=' + point.id + '&industryType=' + point.industryType
      });
    },

  }
}
</script>

<style scoped>
.latest-upload {
  position: relative;
  height: auto;
  padding: 0 25px;
  border-bottom: 1px solid #f5f7fa;
}

/* 每一行 */
.latest-upload li {
  margin: 10px 4px;
  width: 315px;
  text-align: center;
  cursor: pointer;
}
/* 小圆点 */
.latest-upload li .point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #676c71;
}
/* 文字 */
.latest-upload li p {
  width: 280px;
  height: 20px;
  line-height: 15px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #000000;
}
</style>