<template>
    <div class="bigBox">
        <div>
            <div>
                <div>{{ caseDetail.accidentName }}</div>
                <div>
                    <div>
                        <div>发生日期：</div>
                        <div>{{ timeslineToTime(caseDetail.occurDate) }}</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div>
                        <div>发生地点：</div>
                        <div>{{ caseDetail.economizeName }}&nbsp;{{ caseDetail.markentName }}</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div>
                        <div>所属行业：</div>
                        <div>{{ caseDetail.industryName }}&nbsp;<span v-if="caseDetail.subsetdName"
                                                                      style="font-weight: 500">|&nbsp;</span>{{
                            caseDetail.subsetdName
                            }}
                        </div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div>
                        <div>事故类型：</div>
                        <div>{{ caseDetail.occurName }}</div>
                    </div>
                    <el-divider direction="vertical"></el-divider>
                    <div>
                        <div>事故等级：</div>
                        <div>{{ caseDetail.grade }}</div>
                    </div>
                </div>
            </div>
            <div :style="{ height: loginTrue ? '800px' : '' }">
                <div>
                    <img :src="caseDetail.indexUrl" alt="">
                    <div>
                        <div @click="downFile">
                            <img src="@/assets/images/lock.png" alt="">
                            下载观看文件内容
                        </div>
                        <div v-if="vipTrue" @click="goVip">
                            <img src="@/assets/images/studyVipIcon.png" alt="">
                            开通会员更多权限
                        </div>
                    </div>
                    <div v-if="vipTrue">
                        <div>
                            <img src="@/assets/images/file.png" alt=""><span>案例下载次数增加</span>
                        </div>
                        <div>
                            <img src="@/assets/images/vadio.png" alt=""><span>会员视频免费观看</span>
                        </div>
                        <!--                        <div></div>-->
                    </div>
                </div>
                <div>
                    <div @click="downFile"><img src="@/assets/images/down.png" alt="">下载文件
                        <el-tooltip class="item" style="margin-left: 5px"
                                    content="非会员每天免费下载1篇,会员每天免费下载10篇" placement="top">
                            <i
                                    class="el-icon-question"></i>
                        </el-tooltip>
                    </div>
                    <div>
                        <div>报告摘要</div>
                        <div>
                            <div>
                                <span>事故名称：</span>{{ caseDetail.accidentName.slice(0, -4) }}
                            </div>
                            <div>
                                <span>事故发生日期：</span>{{ timestampToTime(caseDetail.occurDate) }}
                            </div>
                            <div>
                                <span>事故发生时间：</span>
                                <span style="font-weight: 500" v-if="caseDetail.occurCreateTime !==0 && caseDetail.occurCreateTime !==null">{{timesToTime(caseDetail.occurCreateTime) }}</span>
                            </div>
                            <!--                            <div>-->
                            <!--                                <span>报告公布日期：</span><span style="font-weight: 500"-->
                            <!--                                                                v-if="caseDetail.publicationDate !==0 && caseDetail.publicationDate !==null">{{-->
                            <!--                                timestampToTime(caseDetail.publicationDate)-->
                            <!--                                }}</span>-->
                            <!--                            </div>-->
                            <div>
                                <span> 事故死亡人数：</span>{{ caseDetail.deathNumber }}
                            </div>
                            <div>
                                <span>事故受伤人数：</span>{{ caseDetail.injuredNumber }}
                            </div>
                            <div>
                                <span>事故下落不明人数：</span>{{ caseDetail.unknownNumber }}
                            </div>
                            <div>
                                <span>追责人数：</span>{{ caseDetail.accountabilityNumber }}
                            </div>
                            <div>
                                <span>事故直接经济损失（万元）：</span>{{ caseDetail.loss }}
                            </div>
                            <div>
                                <span>事故发生地：</span>{{ caseDetail.economizeName }}&nbsp;{{ caseDetail.markentName }}
                            </div>
                            <div>
                                <span>事故类型：</span>{{ caseDetail.occurName }}
                            </div>
                            <div>
                                <span>所属行业：</span>{{ caseDetail.industryName }}&nbsp;<span
                                    v-if="caseDetail.subsetdName"
                                    style="font-weight: 500">|&nbsp;</span>{{ caseDetail.subsetdName }}
                            </div>
                            <div>
                                <span>事故等级：</span>{{ caseDetail.grade }}
                            </div>
                            <div>
                                <span>是否挂牌督办：</span>{{ caseDetail.supervise }}
                            </div>
                            <div>
                                <span>挂牌督办单位：</span>{{ caseDetail.unit ? caseDetail.unit : '无' }}
                            </div>
                            <div>
                                <span>是否提格调查：</span>{{ caseDetail.investigate }}
                            </div>
                            <div>
                                <span>事故调查组牵头单位：</span>{{ caseDetail.mainUnit }}
                            </div>
                            <div>
                                <span>事故性质：</span>{{ caseDetail.nature }}
                            </div>
                            <div>
                                <span>事故直接原因：</span>{{ caseDetail.directCause }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loginTrue">
                    <div @click="goLogin">
                        <img src="@/assets/images/lock.png" alt="">
                        登录观看全部内容
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div><span>同类型</span>事故案例</div>
                    <div @click="goType">更多<i class="el-icon-arrow-right"></i></div>
                </div>
                <div>
                    <ul>
                        <li v-for="item in sameType" :key="item.id" @click="goDetail(item.id)">
                            <div>
                                <img :src="item.indexUrl" alt="">
                                <img v-if="item.isType===1" src="@/assets/images/word.png"
                                     alt="">
                                <img v-if="item.isType===0" src="@/assets/images/pdf3.png"
                                     alt="">
                                <img v-if="item.isType===2" src="@/assets/images/jpg-1.png"
                                     alt="">
                            </div>
                            <p>{{ item.accidentName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <div><span>同行业</span>事故案例</div>
                    <div @click="goPro">更多<i class="el-icon-arrow-right"></i></div>
                </div>
                <div>
                    <ul>
                        <li v-for="item in sameProfession.slice(0,7)" :key="item.id" @click="goDetail(item.id)">
                            <div>
                                <img :src="item.indexUrl" alt="">
                                <img v-if="item.isType===1" src="@/assets/images/word.png"
                                     alt="">
                                <img v-if="item.isType===0" src="@/assets/images/pdf3.png"
                                     alt="">
                                <img v-if="item.isType===2" src="@/assets/images/jpg-1.png"
                                     alt="">
                            </div>
                            <p>{{ item.accidentName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--        <div>-->
        <!--            <el-dialog-->
        <!--                    title="文件下载"-->
        <!--                    :visible.sync="dialogVisible"-->
        <!--                    width="30%"-->
        <!--                    class="popUp"-->
        <!--            >-->
        <!--                <div>-->
        <!--                    <div>温馨提示:</div>-->
        <!--                    <div> 1、非会员每天免费下载1篇</div>-->
        <!--                    <div>2、会员每天下载10篇</div>-->
        <!--                </div>-->
        <!--                <span slot="footer" class="dialog-footer">-->
        <!--    <span @click="dialogVisible = false">取 消</span>-->
        <!--                    &lt;!&ndash;   <span><a v-if="downFalse" :href="downAddress" @click>确 定</a><span v-if="!downFalse">确定</span></span>&ndash;&gt;-->
        <!--                     <span @click="downfile">-->
        <!--                         确定-->
        <!--                     </span>-->
        <!--  </span>-->
        <!--            </el-dialog>-->
        <!--        </div>-->
    </div>
</template>

<script>
import {getByDetailsId, getCaseId, getPopular, sameType} from "@/api/case/caseList";


export default {
    name: "InformationDetail",
    data() {
        return {
            vipTrue: true,
            loginTrue: true,//未登入
            caseDetail: {},
            fileType: '',
            fileName: '',
            dialogVisible: false,
            downAddress: '',//下载地址
            downFalse: true,//是否能下载
            sameType: [],//同类型
            sameProfession: [],//同行业
            lvList: [
                {
                    name: '全部',
                    id: -1
                },
                {
                    name: '一般事故',
                    id: 0
                },
                {
                    name: '较大事故',
                    id: 1
                },
                {
                    name: '重大事故',
                    id: 2
                },
                {
                    name: '特别重大事故',
                    id: 3
                }
            ],//事故等级
        };
    },
    mounted() {
        this.fileType = this.$route.query.type
        let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
            ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
            : "";
        if (token && token.nickName) {
            this.loginTrue = false
        }
        if (token && token.isVip === 1) {
            this.vipTrue = false
        }
        getByDetailsId(this.$route.query.id).then(res => {
            if (res.code === 200) {
                res.data.supervise = res.data.supervise === 0 ? "是" : "否";
                res.data.investigate = res.data.investigate === 0 ? "是" : "否";
                this.fileName = res.data.accidentName
                // console.log(this.fileName)
                res.data.grade = this.lvList[res.data.grade + 1].name
                this.caseDetail = res.data
                sameType(res.data.occurId).then(typeRes => {
                    if (typeRes.code === 200) {
                        this.sameType = typeRes.data
                    }
                })
                getPopular(res.data.industryCode).then(industryRes => {
                    if (industryRes.code === 200) {
                        this.sameProfession = industryRes.data
                    }
                })
            }
        })
    },
    methods: {
        timeslineToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            const date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d;
        },

        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            const date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "年" + MM + "月" + d + "日";
        },
        timesToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            const date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return h + "时" + m + "分"
        },
        goType() {
            this.$router.push("/informationsearch")
        },
        goPro() {
            this.$router.push("/informationsearch")
        },
        goLogin() {
            this.$router.push("/personallogin")
        },
        downFile() {
            let token = JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                ? JSON.parse(localStorage.getItem("USER_AUTH_INFO"))
                : "";
            if (token && token.nickName) {
                // this.dialogVisible = true
                getCaseId(this.$route.query.id).then((res) => {
                    if (res.code === 200) {
                        // console.log(111);
                        let xhr = new XMLHttpRequest();
                        xhr.open('get', res.data.accidentFile, true); // 第二个参数是网络路径
                        if (this.fileType === '0') {
                            xhr.setRequestHeader('Content-Type', `application/.pdf`)
                        }
                        if (this.fileType === '1') {
                            xhr.setRequestHeader('Content-Type', 'application/msword'); // 修改 Content-Type
                        }
                        if (this.fileType === '2') {
                            xhr.setRequestHeader('Content-Type', 'image/jpeg'); // 修改 Content-Type
                        }
                        xhr.responseType = 'blob';
                        const self = this;
                        xhr.onload = function () {
                            if (this.status === 200) {
                                // 接受二进制文件流
                                const blob = this.response;
                                let downloadElement = document.createElement('a');
                                let href = blob;
                                if (typeof blob == 'string') {
                                    downloadElement.target = '_blank';
                                } else {
                                    href = window.URL.createObjectURL(blob); // 创建下载的链接
                                }
                                downloadElement.href = href;
                                if (self.fileType === '0') {
                                    downloadElement.download = self.fileName + '.pdf' //下载后文件名
                                }
                                if (self.fileType === '1') {
                                    downloadElement.download = self.fileName + '.doc'; // 修改文件名扩展名为 .doc
                                }
                                if (self.fileType === '2') {
                                    downloadElement.download = self.fileName + '.jpg'; // 修改文件名扩展名为图片格式的扩展名
                                }
                                document.body.appendChild(downloadElement);
                                downloadElement.click(); // 点击下载
                                document.body.removeChild(downloadElement); // 下载完成移除元素
                                if (typeof blob != 'string') {
                                    window.URL.revokeObjectURL(href); // 释放掉 blob 对象
                                }
                            }
                        };
                        xhr.send();
                        this.dialogVisible = false;
                        this.$message.success({
                            title: '成功',
                            message: '下载成功',
                        });
                        // let a = document.createElement('a');
                        // a.style.display = 'none';
                        // let url = res.data.accidentFile;
                        // a.href = url;
                        // document.body.appendChild(a);
                        // // a.setAttribute('download', "file.pdf");
                        // // 添加一个小延迟，在点击前稍作等待
                        // a.click();
                        // URL.revokeObjectURL(url); // 销毁对象URL
                        // document.body.removeChild(a);
                        // this.dialogVisible = false;
                        // this.$message.success({
                        //     title: "成功",
                        //     message: "下载成功",
                        // });
                    }
                    if (res.code === 400) {
                        this.dialogVisible = false
                        this.$message.error(res.message);
                    }
                })
            } else {
                this.$message.error({
                    title: "错误",
                    message: "请先登录再尝试下载！",
                });
            }
        },
        goVip() {
            this.$router.push("/joinvip");
        },
        goDetail(e) {
            this.$router.push({path: "/informationdetail", query: {id: e}})
        },
    }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.bigBox {
  > div:nth-child(1) {
    width: 1200px;
    margin: auto;
    padding: 20px 0;

    > div:nth-child(1) {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 5px 5px 5px #F3F4F6;

      > div:nth-child(1) {
        font-size: 18px;
      }

      > div:nth-child(2) {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        color: #999999;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;

          > div:nth-child(2) {
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;
      position: relative;
      overflow: hidden;

      > div:nth-child(1) {
        position: relative;
        width: 70%;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 5px 5px 5px #F3F4F6;

        > img {
          width: 100%;
          height: 1189px;
          object-fit: scale-down;
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: center;
          position: absolute;
          width: 880px;
          bottom: 20px;
          left: 0;
          padding: 140px 0 40px 0;
          background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #fff 73%);

          > div:nth-child(1) {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #EB5139;
            justify-content: center;
            border-radius: 20px;
            padding: 10px 0;
            border: 1px solid #EB5139;
            width: 170px;
            //margin: auto;
            margin-right: 20px;

            > img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }

          > div:nth-child(1):hover {
            background-color: rgba(255, 00, 00, 0.1);
          }

          > div:nth-child(2) {
            cursor: pointer;
            display: flex;
            align-items: center;
            //color: #ECAD71;
            background-color: #FAE8D0;
            justify-content: center;
            padding: 10px 0;
            border-radius: 20px;
            border: 1px solid #ECAD71;
            width: 170px;
            //margin: auto;

            > img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }

          > div:nth-child(2):hover {
            background-color: #ECAD71;
            opacity: 0.7;
          }

        }

        > div:nth-child(3) {
          position: absolute;
          bottom: 20px;
          left: 0;
          height: 20px;
          width: 100%;
          background: linear-gradient(90deg, #fff 0%, #fff4e5 43%, #fff4e5 57%, #fff 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 0;
          //> img {
          //  width: 100%;
          //  height: 100%;
          //}
          > div {
            display: flex;
            align-items: center;

            > img {
              width: 20px;
              margin-right: 5px;
            }
          }

          > div:nth-child(1) {
            margin-right: 15px;
          }
        }

        > div:nth-child(3):after {
          position: absolute;
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #fff4e5;
          top: -6px;
          right: 38%;

        }
      }

      > div:nth-child(2) {
        width: 25%;

        > div:nth-child(1) {
          width: 100%;
          padding: 10px 0;
          font-size: 18px;
          color: white;
          background-color: #EF9601;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-bottom: 15px;
          cursor: pointer;

          > img {
            width: 23px;
            height: 23px;
            margin-right: 5px;
          }
        }

        > div:nth-child(2) {
          background-color: white;
          border-radius: 10px;


          > div:nth-child(1) {
            text-align: center;
            padding: 10px 0;
            font-size: 18px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            background-color: #F8CDAC;
            font-weight: bolder;
          }

          > div:nth-child(2) {
            padding: 15px;
            height: 1100px;

            > div {
              margin-bottom: 10px;
              line-height: 2;
              text-align: justify;

              > span {
                font-weight: bold;
              }
            }

            > div:nth-last-child(1) {
              height: 364px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 13;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      > div:nth-child(3) {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 1200px;
        bottom: 0;
        left: 0;
        padding: 140px 0 40px 0;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, #fff 73%);

        > div:nth-child(1) {
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #EB5139;
          justify-content: center;
          border-radius: 20px;
          padding: 10px 0;
          border: 1px solid #EB5139;
          width: 170px;
          //margin: auto;
          margin-right: 20px;

          > img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

        > div:nth-child(1):hover {
          background-color: rgba(255, 00, 00, 0.1);
        }

      }
    }

    > div:nth-child(3) {
      margin-top: 15px;
      padding: 20px;
      background-color: white;
      border-radius: 10px;

      > div:nth-child(1) {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div:nth-child(1) {
          > span {
            color: #EB5139;
          }
        }

        > div:nth-child(2) {
          font-size: 14px;
          color: #999999;
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        > ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > li {
            width: 146px;
            padding-top: 20px;
            margin-right: 23px;


            > div:nth-child(1) {
              position: relative;
              transition: 0.5s all;


              > img:nth-child(1) {
                width: 144px;
                height: 200px;
                border: 1px solid #E0E0E0;
                border-radius: 5px;
                box-shadow: 4px 4px 2px #E0E0E0;
                cursor: pointer;
                object-fit: scale-down;
              }

              > img:nth-child(2) {
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  right: -2px;
                  bottom: 4px;
              }
            }

            > div:nth-child(1):hover {
              transform: translateY(-8px);
            }

            > p:nth-child(2) {
              margin-top: 8px;
              height: 38px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }

            > p:nth-child(2):hover {
              color: #EB5139;
            }
          }

          > li:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    > div:nth-child(4) {
      margin-top: 15px;
      padding: 20px;
      background-color: white;
      border-radius: 10px;

      > div:nth-child(1) {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div:nth-child(1) {
          > span {
            color: #EB5139;
          }
        }

        > div:nth-child(2) {
          font-size: 14px;
          color: #999999;
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        > ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > li {
            width: 146px;
            padding-top: 20px;
            margin-right: 23px;


            > div:nth-child(1) {
              position: relative;
              transition: 0.5s all;


              > img:nth-child(1) {
                width: 144px;
                height: 200px;
                border: 1px solid #E0E0E0;
                border-radius: 5px;
                box-shadow: 4px 4px 2px #E0E0E0;
                cursor: pointer;
                object-fit: scale-down;
              }

              > img:nth-child(2) {
                  position: absolute;
                  width: 22px;
                  height: 22px;
                  right: -2px;
                  bottom: 4px;
              }
            }

            > div:nth-child(1):hover {
              transform: translateY(-8px);
            }

            > p:nth-child(2) {
              margin-top: 8px;
              height: 38px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }

            > p:nth-child(2):hover {
              color: #EB5139;
            }
          }

          > li:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }


  }

  > div:nth-child(2) {
    > .popUp {
      > div {
        div {
          margin-top: -20px;

          div {
            margin-top: 5px;
          }
        }
      }

    }
  }
}

.dialog-footer {
  > span:nth-child(1) {
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 5px;
    background-color: white;
    //border: 1px solid #999;
    color: #EB5139;
  }

  > span:nth-child(2) {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #EB5139;
    color: white;
    border-radius: 5px;

    > a {
      color: white;
    }

    > a:hover {
      color: white;
    }
  }
}
</style>
