<template>
  <div>
    <div class="main">
      <ul class="free-documentation-picture">
        <!-- 每一块文档 -->
        <li v-for="(item,index) in fileList.list" :key="item.id">
          <!-- 文档白色背景图 -->
          <img src="../../assets/uploads/findinformation document.png" alt />
          <h5>{{item.title}}</h5>
          <!-- 大小 -->
          <div class="size">
            <h6>{{item.type}}</h6>
            <p>大小:{{Math.floor(item.size)}}MB</p>
          </div>
          <!-- 积分 -->
          <div class="integral">
            <!-- 图标图片 -->
            <img :src="item.cover" alt />
            <p>积分:{{item.integral}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="Pga" v-if="fileList.total > 10">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :page-size="10"
        :current-page.sync="currentPage"
        :total="fileList.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileList",
  data() {
    return {
      currentPage: this.$store.state.user.donloadPageNum
    };
  },
  props: ["fileList"],
  methods: {
    handleCurrentChange(val) {
      this.$store.dispatch("setDonloadPageNum", val);
    }
  }
};
</script>

<style scoped>
.Pga {
  display: flex;
}
.main {
  margin-top: 10px;
  width: 100%;
}
.free-documentation-picture {
  display: flex;
  flex-wrap: wrap;
}
.free-documentation-picture > li {
  position: relative;
  margin: 10px 3%;
  width: 14%;
}
/* 文档背景图 */
.free-documentation-picture > li > img {
  width: 100%;
}
/* 文档背景图下面的文字 */
.free-documentation-picture > li > h5 {
  font-weight: 600;
  margin: 0;
  font-size: calc(100vw * 14 / 1920);
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 需要定位的  容量大小 */
.free-documentation-picture > li .size {
  position: absolute;
  left: 8%;
  top: 18%;
  font-weight: 600;
}
/* 第一行文字 */
.free-documentation-picture > li .size > h6 {
  font-size: calc(100vw * 14 / 1920);
  color: #000;
}
/* 第二行文字 */
.free-documentation-picture > li .size > p {
  color: #676c71;
  font-size: calc(100vw * 12 / 1920);
}
/* 需要定位的  积分 */
.free-documentation-picture > li .integral {
  position: absolute;
  right: 3%;
  bottom: 10%;
  font-weight: 600;
}
/* 文档图标 */
.free-documentation-picture > li .integral > img {
  width: 25px;
}
.free-documentation-picture > li .integral > p {
  color: #ff7d69;
  font-size: calc(100vw * 12 / 1920);
  margin-left: -25px;
}
</style>