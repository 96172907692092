import request from "@/utils/request";

export function login(phone,code) {
 const data = {
    'phone':phone,
    'code':code
  }
  return request({
    url:'api/auth/user/login',
    method:'post',
    data

  })
}

export function getCode(phone) {
  return request({
    url:'api/auth/user/code/' + phone,
    method:'get',
   
  })
}

export  function getIntegralCalculation(courseId,integralType,vipId,userId){
    return request({
        url:'api/personalUser/getIntegralCalculation',
        method:'post',
        data:{
            courseId: courseId,
            integralType: integralType,
            vipId: vipId,
            userId: userId
        }
    })
}

export  function isIntegralSuccess(userId){
    return request({
        url:`api/personalUser/isIntegralSuccess?userId=${userId}`,
        method:'get',
    })
}
